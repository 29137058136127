import { AxiosResponse } from 'axios';
import { Invoice } from '../interfaces/invoice';
import { InvoiceLine } from '../interfaces/invoice-line';
import { BackendApiManager } from '../managers/backend-api';
import { Logger } from '../utils/logger';

export class InvoicesRepository {
    // Singleton Instance
    private static _instance: InvoicesRepository;

    // Private
    private logger: Logger;

    static get instance(): InvoicesRepository {
        if (!this._instance) {
            this._instance = new InvoicesRepository();
        }
        return this._instance;
    }

    constructor() {
        // Create Logger
        this.logger = new Logger('InvoicesRepository');
    }

    public async getProviderInvoices(
        providerId: string,
        startDate: Date,
        endDate: Date,
    ): Promise<
        Array<
            Invoice & {
                lines: Array<InvoiceLine>;
            }
        >
    > {
        // Set url
        const url: string = `/providers/${providerId}/invoices?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;

        // Get notifications from backend
        const result: AxiosResponse<
            Array<
                Invoice & {
                    lines: Array<InvoiceLine>;
                }
            >
        > = await BackendApiManager.instance.get<
            Array<
                Invoice & {
                    lines: Array<InvoiceLine>;
                }
            >
        >(url);

        // Return
        return result.data;
    }
}
