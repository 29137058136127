import { useTranslation } from 'react-i18next';
import { MainOfficePage } from '../../../../components/main-office-page/main-office-page';

export function OfficeMainAcademy() {
    // Translations
    const { t } = useTranslation();

    // Build
    return (
        <MainOfficePage
            title={[t('routes.office.main.academy.titleLine1'), t('routes.office.main.academy.titleLine2')]}
            color={'#38b6ff'}
        >
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
            <p>Office Main Academy</p>
        </MainOfficePage>
    );
}
