import { Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useContext, useEffect, useState } from 'react';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { AppContext } from '../../contexts/app/context';
import { AppContextType } from '../../contexts/app/types';
import { Container } from '../container/container';
import { Loader } from '../loader/loader';
import styles from './main-office-page.module.scss';

const TRIANGLE_HEIGHT = 60;
const SCROLL_THRESHOLD = 16;
const TOOLBAR_HEIGHT = 56;

export function MainOfficePage(props: {
    children: React.ReactNode;
    title: Array<string>;
    color: string;
    height?: number;
    actions?: Array<React.ReactNode>;
    onRefresh?: () => Promise<any>;
    className?: string;
}) {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);

    // Hold State
    const [scrollY, setScrollY] = useState<number>(0);
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    // Actions
    const handleScroll = (event: any) => {
        // Update scrollY
        setScrollY(window.scrollY);
    };

    const handleResize = (event: any) => {
        // Update windowWidth
        setWindowWidth(window.innerWidth);
    };

    // On mount
    useEffect(() => {
        // Listen for events
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        // Scroll to top
        window.scrollTo(0, 0);

        // On unmount
        return () => {
            // Remove event listeners
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Build
    return (
        <PullToRefresh
            isPullable={props.onRefresh !== undefined}
            onRefresh={props.onRefresh ?? (() => Promise.resolve())}
            refreshingContent={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        paddingTop: `${appContext.state.insets.top + 16}px`,
                    }}
                >
                    <Loader size={36} />
                </Box>
            }
            pullingContent={<></>}
            pullDownThreshold={67 + appContext.state.insets.top}
            maxPullDownDistance={95 + appContext.state.insets.top}
        >
            <Box sx={{ flexGrow: 1 }}>
                <AppBar
                    position="fixed"
                    elevation={scrollY > SCROLL_THRESHOLD ? 4 : 0}
                    color="inherit"
                    sx={{
                        height: `${TOOLBAR_HEIGHT + appContext.state.insets.top}px`,
                        backgroundColor: props.color,
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Toolbar
                        sx={{
                            height: `${TOOLBAR_HEIGHT}px !important`,
                            minHeight: `${TOOLBAR_HEIGHT}px !important`,
                            paddingLeft: `${appContext.getDefaultSidePaddingValue()}px !important`,
                            paddingRight: `${appContext.getDefaultSidePaddingValue()}px !important`,
                        }}
                    >
                        {appContext.state.insets.left > 0 && (
                            <Box
                                sx={{
                                    width: `${appContext.state.insets.left}px`,
                                }}
                            ></Box>
                        )}

                        {/* Title */}
                        {scrollY <= SCROLL_THRESHOLD && (
                            <Typography
                                variant="h3"
                                component="div"
                                sx={{
                                    flexGrow: 1,
                                }}
                                fontWeight={400}
                            >
                                <ul className={styles.titleLines}>
                                    {props.title.map((line: string, index: number) => {
                                        return <li key={`title_line_${index}`}>{line}</li>;
                                    })}
                                </ul>
                            </Typography>
                        )}
                        {scrollY > SCROLL_THRESHOLD && (
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                    flexGrow: 1,
                                }}
                                fontWeight={400}
                            >
                                {props.title.join(' ').trim()}
                            </Typography>
                        )}

                        {/* Right Action s*/}
                        <Box
                            sx={{
                                zIndex: 2,
                                display: 'flex',
                                marginRight: '-8px',
                            }}
                        >
                            {props.actions?.map((action: React.ReactNode, index: number) => {
                                return <Box key={`action_${index}`}>{action}</Box>;
                            })}
                        </Box>

                        {appContext.state.insets.right > 0 && (
                            <Box
                                sx={{
                                    width: `${appContext.state.insets.right}px`,
                                }}
                            ></Box>
                        )}
                    </Toolbar>
                </AppBar>
                <Box
                    className={styles.triangle}
                    sx={{
                        position: 'absolute',
                        top: `${TOOLBAR_HEIGHT + appContext.state.insets.top - 1}px`,
                        borderWidth: `${TRIANGLE_HEIGHT}px ${windowWidth}px 0 0;`,
                        borderColor: `${props.color} transparent transparent transparent`,
                        overflowX: 'hidden',
                    }}
                ></Box>
                <Box
                    sx={{
                        paddingTop: `${TOOLBAR_HEIGHT + appContext.state.insets.top + TRIANGLE_HEIGHT}px`,
                        paddingBottom: `${TOOLBAR_HEIGHT + appContext.state.insets.bottom + SCROLL_THRESHOLD}px`,
                        paddingLeft: `${appContext.getDefaultSidePaddingValue() + appContext.state.insets.left}px`,
                        paddingRight: `${appContext.getDefaultSidePaddingValue() + appContext.state.insets.right}px`,
                    }}
                >
                    <Container>{props.children}</Container>
                </Box>
            </Box>
        </PullToRefresh>
    );
}
