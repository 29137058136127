import axios, { Axios, AxiosError, AxiosRequestConfig, AxiosResponse, CancelTokenStatic } from 'axios';
import { User } from 'firebase/auth';
import i18n from 'i18next';
import { Environment } from '../enums/environment';
import { OperativeSystem } from '../enums/operative-system';
import { AppLanguageTag } from '../i18n/types';
import { Logger } from '../utils/logger';
import { EnvironmentManager } from './environment';

export class BackendApiManager {
    // Singleton Instance
    private static _instance: BackendApiManager;

    // Private
    private client!: Axios;
    private logger: Logger;
    private user: User | undefined;
    private impersonationToken: string | undefined;
    private baseUrl: string | undefined;
    private operativeSystem!: OperativeSystem;

    static get instance(): BackendApiManager {
        if (!this._instance) {
            this._instance = new BackendApiManager();
        }
        return this._instance;
    }

    constructor() {
        // Create Logger
        this.logger = new Logger('BackendApiManager');

        // Init
        this.client = axios.create();

        // Sync base url
        this.syncBaseUrl();

        // Add interceptor
        this.client.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error: AxiosError) => {
                const originalRequest = error.config;

                // Retry only for 502 status code
                if (error.response?.status === 502) {
                    try {
                        // You can add delay before retrying the request if needed
                        await new Promise((resolve) => setTimeout(resolve, 500));

                        // Warn
                        this.logger.warn('Retrying request...');

                        // Clone the original request and retry it
                        const retryConfig = { ...originalRequest };
                        return this.client.request(retryConfig);
                    } catch (error) {
                        // If the retry also fails, you can handle the error accordingly
                        throw new Error('Retry failed');
                    }
                }

                // For other error codes or network issues, throw the error
                throw error;
            },
        );

        this.client.interceptors.response.use((originalResponse) => {
            // ISO Date Format Regex
            const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}(Z|[+-]\d{2}:\d{2})$/;

            // Is ISO Date
            function isIsoDateString(value: any): boolean {
                return value && typeof value === 'string' && isoDateFormat.test(value);
            }

            // Handle Dates
            function handleDates(body: any) {
                // Validate body
                if (body === null || body === undefined || typeof body !== 'object') {
                    return body;
                }

                // For each key
                for (const key of Object.keys(body)) {
                    // Map
                    const value = body[key];

                    // Test for ISO Date string
                    if (isIsoDateString(value)) {
                        // It is
                        body[key] = new Date(value);
                    } else if (typeof value === 'object') {
                        // Recursively handle dates
                        handleDates(value);
                    }
                }
            }

            // Start handling dates
            handleDates(originalResponse.data);

            // Done
            return originalResponse;
        });

        // Init
        this.logger.info('Loaded');
    }

    private syncBaseUrl() {
        // Set base url
        if (EnvironmentManager.instance.current === Environment.Localhost) {
            // Localhost development
            // this.baseUrl = 'http://127.0.0.1:5001/asvaidosas-prod/europe-west1/helloWorld';
            this.baseUrl = 'http://127.0.0.1:8080';

            // Check OS.
            if (this.operativeSystem === OperativeSystem.Android || this.operativeSystem === OperativeSystem.iOS) {
                // Android or iOS
                this.baseUrl = 'http://192.168.1.161:8080/';
            }
        } else {
            // Production
            this.baseUrl = 'https://helloworld-oeaaw5a5ca-ew.a.run.app';
        }
    }

    public init(operativeSystem: OperativeSystem) {
        // Set operative system
        this.operativeSystem = operativeSystem;

        // Sync base url
        this.syncBaseUrl();
    }

    private async getHeaders() {
        // Get accept language. Fallback language to be used on the backend if no language is set for the user.
        let acceptLanguage = i18n.language || AppLanguageTag.ptPT;
        acceptLanguage = acceptLanguage.replace('_', '-');

        const result: any = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': acceptLanguage,
        };

        // Check if auth is available
        if (this.impersonationToken) {
            // Append Header
            result.Authorization = `Bearer ${await this.impersonationToken}`;
        } else if (this.user) {
            // Append Header
            result.Authorization = `Bearer ${await this.user.getIdToken()}`;
        }

        // Done
        return result;
    }

    public async hookUser(user: User | undefined): Promise<void> {
        // Log
        if (user) {
            // Debug Token
            this.logger.info('Authorized User added');
        } else {
            // Log
            this.logger.debug('Authorized User removed');
        }

        // Set
        this.user = user;
    }

    public async hookImpersonation(authToken: string): Promise<void> {
        // Set
        this.impersonationToken = authToken;

        // Log
        this.logger.info('Authorized Impersonation added');
    }

    public getBaseUrl(): string {
        return this.baseUrl ? this.baseUrl : '';
    }

    public async get<T>(path: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.client.get<T>(path, {
            ...config,
            // https://github.com/axios/axios#request-config
            baseURL: this.baseUrl,
            headers: await this.getHeaders(),
        });
    }

    public async post<T>(path: string, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.client.post<T>(path, data, {
            ...config,
            // https://github.com/axios/axios#request-config
            baseURL: this.baseUrl,
            headers: await this.getHeaders(),
        });
    }

    public async put<T>(path: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.client.put<T>(path, data, {
            ...config,
            // https://github.com/axios/axios#request-config
            baseURL: this.baseUrl,
            headers: await this.getHeaders(),
        });
    }

    public async patch<T>(path: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.client.patch<T>(path, data, {
            ...config,
            // https://github.com/axios/axios#request-config
            baseURL: this.baseUrl,
            headers: await this.getHeaders(),
        });
    }

    public async delete<T>(path: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.client.delete<T>(path, {
            ...config,
            // https://github.com/axios/axios#request-config
            baseURL: this.baseUrl,
            headers: await this.getHeaders(),
        });
    }

    public getCancelToken(): CancelTokenStatic {
        return axios.CancelToken;
    }
}
