/**
 * The type of notification
 */
export enum NotificationType {
    /**
     * A test for development purposes.
     */
    HelloWorld = 'hello-world',

    /**
     * A new appointment was created.
     */
    NewAppointment = 'new-appointment',

    /**
     * An appointment was updated.
     */
    AppointmentUpdated = 'appointment-updated',

    /**
     * An appointment was cancelled by the provider.
     */
    AppointmentCancelledByProvider = 'appointment-cancelled-by-provider',

    /**
     * An appointment was cancelled by the user.
     */
    AppointmentCancelledByUser = 'appointment-cancelled-by-user',

    /**
     * Client did not show up for the appointment.
     */
    AppointmentUserNoShow = 'appointment-user-no-show',

    /**
     * Appointment was completed.
     */
    AppointmentCompleted = 'appointment-completed',

    /**
     * Client reached the target number of points and was offered a gift.
     */
    PointsTargetGiftOffer = 'points-target-gift-offer',

    /**
     * Invoices were processed.
     */
    InvoicesProcessing = 'invoices-processing',
}
