/**
 * Calculate the travel time based on the distance and speed
 */
export function calculateTravelTimeInMinutes(distanceMeters: number, speedKph: number): number {
    const distanceKm = distanceMeters / 1000; // Convert distance to kilometers
    const timeHours = distanceKm / speedKph; // Calculate time in hours

    const hours = Math.floor(timeHours);
    const minutes = Math.round((timeHours - hours) * 60);

    return hours * 60 + minutes;
}
