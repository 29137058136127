import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControlLabel,
    FormGroup,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../contexts/app/context';
import { AppContextType } from '../../contexts/app/types';
import { AuthContext } from '../../contexts/auth/context';
import { AuthContextType } from '../../contexts/auth/types';
import { ServiceCategories } from '../../databases/service-categories';
import { OperativeSystem } from '../../enums/operative-system';
import { ServiceCategory } from '../../enums/service-category';
import { Provider } from '../../interfaces/provider';
import { ProviderService } from '../../interfaces/provider-service';
import { Service } from '../../interfaces/service';
import { ProvidersRepository } from '../../repositories/providers';
import { ServicesRepository } from '../../repositories/services';
import { emptyId } from '../../utils/ts-utils';
import { Container } from '../container/container';
import { EmptyState } from '../empty-state/empty-state';
import { ErrorState } from '../error-state/error-state';
import { Loader } from '../loader/loader';
import { OfficeTitle } from '../office-title/office-title';
import { Page } from '../page/page';

export interface ServiceCategoryDataLineForm {
    isActive: boolean;
    durationInMinutes: number;
    price: number;
    photosUrls: Array<string>;
}

export interface ServiceCategoryDataLine {
    providerId: string;
    service: Service;
    providerService?: ProviderService;
    form: ServiceCategoryDataLineForm;
}

export interface ServiceCategoryData {
    category: ServiceCategory;
    lines: Array<ServiceCategoryDataLine>;
}

function ServiceCategoryDataBlockLine({
    serviceCategoryDataLine,
}: {
    serviceCategoryDataLine: ServiceCategoryDataLine;
}) {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);
    const authContext: AuthContextType = useContext(AuthContext);

    // Translations
    const { t } = useTranslation();

    // Hold state
    const [wasChanged, setWasChanged] = useState<boolean>(false);
    const [formData, setFormData] = useState<ServiceCategoryDataLineForm>(
        Object.assign({}, serviceCategoryDataLine.form),
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Aux state
    const [durationInMinutes, setDurationInMinutes] = useState<unknown>(serviceCategoryDataLine.form.durationInMinutes);
    const [price, setPrice] = useState<unknown>(serviceCategoryDataLine.form.price);
    const [photosUrls, setPhotosUrls] = useState<string>(serviceCategoryDataLine.form.photosUrls.join(';'));

    // Build
    return (
        <Accordion
            sx={{
                borderRadius: '4px',
                overflow: 'hidden',
            }}
        >
            <AccordionSummary
                sx={{
                    borderRadius: '4px',
                    background: serviceCategoryDataLine.service.category
                        ? `linear-gradient(270deg, rgba(246,246,246,0) 97%, ${
                              ServiceCategories[serviceCategoryDataLine.service.category].color
                          } 100%);`
                        : undefined,
                    opacity: formData.isActive ? 1 : 0.5,
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`ServiceCategoryDataBlockLine-${serviceCategoryDataLine.service.id}`}
                id={`ServiceCategoryDataBlockLine-${serviceCategoryDataLine.service.id}-header`}
            >
                {serviceCategoryDataLine.service.name}
                {`${wasChanged ? ' ⚠️' : ''}`}
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    borderRadius: '4px',
                }}
            >
                <Typography variant="subtitle2" gutterBottom>
                    {serviceCategoryDataLine.service.description}
                </Typography>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formData.isActive}
                                onChange={(event, checked) => {
                                    setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        isActive: checked,
                                    }));
                                    setWasChanged(true);
                                }}
                            />
                        }
                        label={t('routes.office.professional.services.form.isActive')}
                    />
                    <Box
                        sx={{
                            marginTop: '16px',
                            display: 'flex',
                            gap: '16px',
                        }}
                    >
                        <TextField
                            sx={{
                                flex: 1,
                            }}
                            type="number"
                            label={t('routes.office.professional.services.form.durationInMinutes')}
                            value={durationInMinutes}
                            onChange={(event) => {
                                // Update
                                setDurationInMinutes(event.target.value as any);

                                // Try to parse
                                const value = parseInt(event.target.value);
                                if (!isNaN(value)) {
                                    setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        durationInMinutes: value,
                                    }));
                                    setWasChanged(true);
                                }
                            }}
                        />
                        <TextField
                            sx={{
                                flex: 1,
                            }}
                            type="number"
                            label={t('routes.office.professional.services.form.price')}
                            value={price}
                            onChange={(event) => {
                                // Update
                                setPrice(event.target.value);

                                // Try to parse
                                const value = parseFloat(event.target.value);
                                if (!isNaN(value)) {
                                    setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        price: value,
                                    }));
                                    setWasChanged(true);
                                }
                            }}
                        />
                    </Box>

                    {authContext.state.me?.systemAdministratorProfile &&
                        appContext.state.operativeSystem === OperativeSystem._Unknown && (
                            <Box
                                sx={{
                                    marginTop: '16px',
                                    display: 'flex',
                                    gap: '16px',
                                }}
                            >
                                <TextField
                                    label={t('routes.office.professional.services.form.photosUrls')}
                                    variant="outlined"
                                    fullWidth
                                    value={photosUrls}
                                    onChange={(event) => {
                                        // Update
                                        setPhotosUrls(event.target.value);

                                        // Try to parse
                                        setFormData((prevFormData) => ({
                                            ...prevFormData,
                                            photosUrls: event.target.value.split(';'),
                                        }));
                                        setWasChanged(true);
                                    }}
                                />
                            </Box>
                        )}

                    {wasChanged && (
                        <Box
                            sx={{
                                width: '100%',
                                marginTop: '16px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Divider
                                sx={{
                                    width: '100%',
                                    marginBottom: '16px',
                                }}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '16px',
                                }}
                            >
                                {!isLoading && (
                                    <>
                                        <Button
                                            startIcon={<CloseIcon />}
                                            color="inherit"
                                            type="button"
                                            variant="contained"
                                            onClick={() => {
                                                // Reset Form
                                                setFormData(Object.assign({}, serviceCategoryDataLine.form));
                                                setDurationInMinutes(serviceCategoryDataLine.form.durationInMinutes);
                                                setPrice(serviceCategoryDataLine.form.price);
                                                setPhotosUrls(serviceCategoryDataLine.form.photosUrls.join(';'));

                                                // Mark as not changed
                                                setWasChanged(false);
                                            }}
                                        >
                                            {t('routes.office.professional.services.form.cancel')}
                                        </Button>
                                        <Button
                                            startIcon={<SaveIcon />}
                                            type="submit"
                                            variant="contained"
                                            onClick={async () => {
                                                // Start Loading
                                                setIsLoading(true);

                                                // Request
                                                await ProvidersRepository.instance.updateProviderService(
                                                    serviceCategoryDataLine.providerId,
                                                    serviceCategoryDataLine.service.id ?? '',
                                                    {
                                                        isActive: formData.isActive,
                                                        durationInMinutes: formData.durationInMinutes,
                                                        price: formData.price,
                                                        photosUrls: formData.photosUrls,
                                                    },
                                                );

                                                // Mark as not changed
                                                setWasChanged(false);

                                                // Update original form
                                                serviceCategoryDataLine.form = Object.assign({}, formData);

                                                // Stop Loading
                                                setIsLoading(false);
                                            }}
                                        >
                                            {t('routes.office.professional.services.form.saveChanges')}
                                        </Button>
                                    </>
                                )}
                                {isLoading && <CircularProgress size={36} />}
                            </Box>
                        </Box>
                    )}
                </FormGroup>
            </AccordionDetails>
        </Accordion>
    );
}

function ServiceCategoryDataBlock({ serviceCategoryData }: { serviceCategoryData: ServiceCategoryData }) {
    // Translations
    const { t } = useTranslation();

    // Build
    return (
        <Box
            sx={{
                marginBottom: '48px',
            }}
        >
            <OfficeTitle
                text={t(ServiceCategories[serviceCategoryData.category].translationKey)}
                color={ServiceCategories[serviceCategoryData.category].color}
                align="center"
            />
            {serviceCategoryData.lines &&
                serviceCategoryData.lines.length > 0 &&
                serviceCategoryData.lines.map((serviceCategoryDataLine: ServiceCategoryDataLine) => {
                    // Build
                    return (
                        <ServiceCategoryDataBlockLine
                            serviceCategoryDataLine={serviceCategoryDataLine}
                            key={`serviceCategoryDataLine-${serviceCategoryData.category}-${
                                serviceCategoryDataLine.service.id ?? ''
                            }`}
                        />
                    );
                })}
        </Box>
    );
}

export function ServicesScreen({ provider }: { provider: Provider }) {
    // Get Context
    const authContext: AuthContextType = useContext(AuthContext);

    // Translations
    const { t } = useTranslation();

    // Hold state
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [data, setData] = useState<Array<ServiceCategoryData> | null>(null);

    // Action: Reset all
    const load = async () => {
        // Reset state
        setError(false);
        setData(null);

        // Start loading
        setIsLoading(true);

        try {
            // Get data
            const data = await Promise.all([
                // 0: Get All Services
                ServicesRepository.instance.getServices(0, 1000),

                // 1: Get Provider Services
                ProvidersRepository.instance.getProviderServices(provider.id ?? '', 0, 1000),
            ]);

            // Init results
            const results: Array<ServiceCategoryData> = [];

            // Put everything together
            const serviceCategories: Array<ServiceCategory> = Object.keys(ServiceCategories) as Array<ServiceCategory>;

            // For each category
            for (const serviceCategory of serviceCategories) {
                // Create lines
                const lines: Array<ServiceCategoryDataLine> = [];

                // For each service
                for (const service of data[0].data) {
                    // Check if it's the same category
                    if (service.category === serviceCategory) {
                        // Find provider service
                        const providerService = data[1].data.find(
                            (providerService: ProviderService) => providerService.serviceId === service.id,
                        );

                        // Add line
                        lines.push({
                            providerId: provider.id ?? '',
                            service: service,
                            providerService: providerService,
                            form: {
                                isActive: providerService?.isActive ?? false,
                                durationInMinutes: providerService?.durationInMinutes ?? 0,
                                price: providerService?.price ?? 0,
                                photosUrls: providerService?.photosUrls ?? [],
                            },
                        });
                    }
                }

                // Create a new Entry
                results.push({
                    category: serviceCategory,
                    lines,
                });
            }

            // Set
            setData(results);
        } catch (error) {
            // Set error
            setError(true);
        } finally {
            // Stop loading
            setIsLoading(false);
        }
    };

    // On mount
    useEffect(() => {
        // Load
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Calculate page title
    let pageTitle = t('routes.office.professional.services.title');
    if (provider.id === emptyId()) {
        pageTitle += ` | (${t('routes.office.main.home.professional.services.noProvider')})`;
    } else if (provider.id !== authContext.state.me?.provider?.id) {
        pageTitle += ` | ${provider.name.split(' ')[0]}`;
    }

    // Build
    return (
        <>
            <Page
                title={pageTitle}
                onRefresh={async () => {
                    // Reset state
                    load();
                }}
            >
                <Container>
                    {isLoading && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '32px',
                            }}
                        >
                            <Loader size={48} />
                        </Box>
                    )}
                    {!isLoading && !error && (
                        <>
                            {data && data.length === 0 && (
                                <EmptyState
                                    height={80}
                                    icon={<VolunteerActivismIcon />}
                                    title={t('routes.office.professional.services.emptyTitle')}
                                    description={t('routes.office.professional.services.emptyDescription')}
                                />
                            )}
                            {data &&
                                data.length > 0 &&
                                data.map((serviceCategoryData: ServiceCategoryData) => {
                                    // Build
                                    return (
                                        <ServiceCategoryDataBlock
                                            serviceCategoryData={serviceCategoryData}
                                            key={`serviceCategoryData-${serviceCategoryData.category}`}
                                        />
                                    );
                                })}
                        </>
                    )}
                    {!isLoading && error && (
                        <ErrorState
                            icon={<VolunteerActivismIcon />}
                            onTryAgainClick={() => {
                                // Reset state
                                setError(false);
                            }}
                        />
                    )}
                </Container>
            </Page>
        </>
    );
}
