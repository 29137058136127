import moment from 'moment';

/**
 * Returns an empty Id in Mongo DB standarts
 * @returns '000000000000000000000000'
 */
export function emptyId(): string {
    return '000000000000000000000000';
}

/**
 * Converts a hex color to RGB
 * @param hex Hex color
 * @returns RGB color
 */
export function hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
}

/**
 * shuffleArray takes an array as input, makes a copy of it, and then
 * iterates over the copy from the end to the beginning. In each iteration,
 * it generates a random index within the range of the elements that haven't
 * been shuffled yet and swaps the current element with the element at the
 * randomly chosen index. Finally, it returns the shuffled array.
 * @param array
 * @returns
 */
export function shuffleArray<T>(array: T[]): T[] {
    const shuffledArray = [...array]; // Make a copy of the original array
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1)); // Generate a random index
        // Swap elements at i and j
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
}

export function moveElementOnArray(array: Array<any>, fromIndex: number, toIndex: number) {
    // Get element to move
    const element = array.splice(fromIndex, 1)[0];

    // Add element to new index
    array.splice(toIndex, 0, element);

    // Return new array
    return array;
}

export function generateTimeSlots(
    nextAvailableBookingDay: moment.Moment | undefined,
    intervals: Array<{
        start: Date;
        end: Date;
    }>,
    jobDuration: number,
    nextAvailableBookingTimeMoment?: moment.Moment,
): Date[] {
    // Hold results
    const results = Array<Date>();

    // For each interval, generate time slots
    for (const interval of intervals) {
        // Define last slot
        const lastSlotStart = moment(interval.end).subtract(jobDuration, 'minutes');

        // Define current moment
        let currentSlot = moment(interval.start);

        // Do it again
        while (currentSlot.isSameOrBefore(lastSlotStart)) {
            // Add If
            if (
                nextAvailableBookingTimeMoment &&
                // Slot in the future (next day)
                (moment(currentSlot).startOf('day').isAfter(moment(nextAvailableBookingDay).startOf('day')) ||
                    // Slot in the future (same day)
                    moment(currentSlot).isSameOrAfter(nextAvailableBookingTimeMoment))
            ) {
                results.push(currentSlot.toDate());
            }

            // Add 15 minutes
            currentSlot.add(15, 'minutes');
        }
    }

    // Done
    return results;
}
