import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlaceIcon from '@mui/icons-material/Place';
import { Box } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../contexts/app/context';
import { AppContextType } from '../../contexts/app/types';
import { RoutesNames } from '../../routes-names';

export function LocationSearchBox() {
    // Get Contexts
    const appContext: AppContextType = useContext(AppContext);

    // Get navigation
    const navigate = useNavigate();

    // Build
    return (
        <Box
            sx={{
                textAlign: 'right',
                fontSize: '14px',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
            }}
            onClick={() => {
                // Navigate to Location Picker
                navigate(`../../${RoutesNames.Office_LocationPicker}`);
            }}
        >
            <PlaceIcon fontSize="small" color={appContext.state.userLocation.isPrecise ? 'primary' : 'warning'} />
            <Box>{appContext.state.userLocation.name}</Box>
            <ExpandMoreIcon
                fontSize="small"
                sx={{
                    opacity: 0.73,
                }}
            />
        </Box>
    );
}
