import { FormControl } from '@mui/base';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PersonIcon from '@mui/icons-material/Person';
import PhotoIcon from '@mui/icons-material/Photo';
import PlaceIcon from '@mui/icons-material/Place';
import ReviewsIcon from '@mui/icons-material/Reviews';
import ScheduleIcon from '@mui/icons-material/Schedule';
import {
    Badge,
    Box,
    Button,
    Fade,
    FormControlLabel,
    Grow,
    Paper,
    Radio,
    RadioGroup,
    Rating,
    Tab,
    Tabs,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useTheme,
} from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useNavigate, useParams } from 'react-router';
import { Container } from '../../../components/container/container';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { ErrorState } from '../../../components/error-state/error-state';
import { FooterSheet } from '../../../components/footer-sheet/footer-sheet';
import { FullPage } from '../../../components/full-page/full-page';
import { ImagesStepper } from '../../../components/images-stepper/images-stepper';
import { Loader } from '../../../components/loader/loader';
import { OfficeTitle } from '../../../components/office-title/office-title';
import { SelectedServices } from '../../../components/selected-services/selected-services';
import { TimeTravellerNav } from '../../../components/time-traveller-nav/time-traveller-nav';
import { AppContext } from '../../../contexts/app/context';
import { AppContextType } from '../../../contexts/app/types';
import { ServiceCategories } from '../../../databases/service-categories';
import { CountryCode } from '../../../enums/country-code';
import { OperativeSystem } from '../../../enums/operative-system';
import { ServiceCategory } from '../../../enums/service-category';
import { ThemeMode } from '../../../enums/theme-mode';
import { Provider } from '../../../interfaces/provider';
import { ProviderService } from '../../../interfaces/provider-service';
import { Service } from '../../../interfaces/service';
import { ProvidersRepository } from '../../../repositories/providers';
import { RoutesNames } from '../../../routes-names';
import { generateTimeSlots, hexToRgb } from '../../../utils/ts-utils';
import variables from '../../../variables.module.scss';

enum ShowcaseTabs {
    Services = 0,
    Schedule = 1,
    Location = 2,
    Availability = 3,
}

function ScheduleDay({
    day,
    schedule,
    index,
}: {
    day: string;
    schedule: Array<{ start: string; end: string }>;
    index: number;
}) {
    // Translations
    const { t } = useTranslation();

    // Build
    return (
        <Fade
            in={true}
            style={{
                transitionDelay: `${index * 100}ms`,
            }}
        >
            <Paper
                sx={{
                    display: 'flex',
                    padding: '16px',
                    justifyContent: 'flex-start',
                }}
            >
                <Box
                    sx={{
                        width: '60%',
                    }}
                >
                    <Typography variant="body1" fontWeight={500}>
                        {t(`enums.daysOfTheWeek.${day}`)}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        width: '30%',
                    }}
                >
                    {schedule.length > 0 && (
                        <Box
                            sx={{
                                marginBottom: '4px',
                            }}
                        >
                            <Typography variant="body1">✅ {t('routes.office.showcase.open')}</Typography>
                        </Box>
                    )}
                    {schedule.map((time, index) => {
                        return (
                            <Box
                                key={`schedule-${day}-${index}`}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {time.start} - {time.end}
                                </Typography>
                            </Box>
                        );
                    })}
                    {schedule.length === 0 && (
                        <Box
                            sx={{
                                opacity: 0.5,
                            }}
                        >
                            <Typography variant="body1">🚫 {t('routes.office.showcase.closed')}</Typography>
                        </Box>
                    )}
                </Box>
            </Paper>
        </Fade>
    );
}

function ProviderServicePaper({
    providerService,
    index,
    viewMode,
}: {
    providerService: ProviderService;
    index: number;
    viewMode: 'list' | 'photo';
}) {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);

    // Get theme
    const theme = useTheme();

    // Translations
    const { t } = useTranslation();

    // In view hook
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
        triggerOnce: true,
    });

    // Is Selected
    const isSelected = appContext.state.services.find((s) => s.id === providerService.service?.id);

    // Define background color
    let background = undefined;
    if (isSelected) {
        // Convert to RGB
        const mainRGB = hexToRgb(variables.firstColor);
        const backRGB = hexToRgb(appContext.state.themeMode === ThemeMode.Light ? '#ffffff' : '#121212');

        // Build
        background = `linear-gradient(270deg, rgba(${backRGB?.r}, ${backRGB?.g}, ${backRGB?.b}, 1) 32%, rgba(${mainRGB?.r}, ${mainRGB?.g}, ${mainRGB?.b}, 1) 100%)`;
    }

    // Build
    return (
        <Grow
            in={true}
            style={{
                transitionDelay: `${index * 100}ms`,
            }}
            ref={ref}
        >
            <Paper
                sx={{
                    width: '100%',
                    background: background,
                    position: 'relative',
                    overflow: 'hidden',
                    display: viewMode === 'list' ? 'flex' : 'block',
                }}
                onClick={() => {
                    if (providerService.service) {
                        // Check if this service was already selected or not
                        const alreadySelected = appContext.state.services.find(
                            (s) => s.id === providerService.service?.id,
                        );

                        // Eval
                        if (alreadySelected) {
                            // Remove Service
                            appContext.setServices(
                                appContext.state.services.filter((s) => s.id !== providerService.service?.id),
                            );
                        } else {
                            // Set Service
                            appContext.setServices([...appContext.state.services, providerService.service]);
                        }
                    }
                }}
            >
                {viewMode === 'list' && (
                    <>
                        {providerService.service && (
                            <Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        paddingTop: '8px',
                                        paddingLeft: '8px',
                                        paddingRight: '8px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            flex: 1,
                                            borderRadius: '4px',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        {(!providerService.photosUrls || providerService.photosUrls.length === 0) && (
                                            <Box
                                                sx={{
                                                    aspectRatio: '1 / 1',
                                                    backgroundColor: theme.palette.divider,
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        gap: '8px',
                                                        justifyContent: 'space-between',
                                                        rotate: '15deg',
                                                        marginLeft: '-50%',
                                                        opacity: 0.3,
                                                    }}
                                                >
                                                    {Array.from({ length: 73 }).map((_, index) => (
                                                        <span key={`provider-${providerService.id}-award-${index}`}>
                                                            💛
                                                        </span>
                                                    ))}
                                                </Box>
                                            </Box>
                                        )}
                                        {providerService.photosUrls && providerService.photosUrls.length > 0 && (
                                            <Box
                                                sx={{
                                                    aspectRatio: '1 / 1',
                                                }}
                                            >
                                                {inView && (
                                                    <ImagesStepper
                                                        className="provider-tile"
                                                        imagesUrls={providerService.photosUrls}
                                                    />
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            flex: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            paddingLeft: '8px',
                                            paddingBottom: '8px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                paddingLeft: '8px',
                                                paddingRight: '8px',
                                                paddingTop: '2px',
                                                paddingBottom: '2px',
                                                backgroundColor:
                                                    ServiceCategories[providerService.service.category ?? ''].color,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderRadius: '24px',
                                                color: 'white',
                                                marginBottom: '8px',
                                            }}
                                        >
                                            <Typography variant="caption" fontWeight={500}>
                                                {t(
                                                    ServiceCategories[providerService.service.category ?? '']
                                                        .translationKey,
                                                )}
                                            </Typography>
                                        </Box>
                                        <Typography variant="body2" fontWeight={500}>
                                            {providerService.service.name}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '8px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            padding: '8px',
                                            display: 'flex',
                                            gap: '8px',
                                        }}
                                    >
                                        <ReviewsIcon />
                                        <Typography variant="body1">{`${
                                            providerService.averageRating?.toFixed(1) ?? '0.0'
                                        }`}</Typography>
                                        <Typography variant="body1">{`(${
                                            providerService.totalReviews ?? 0
                                        })`}</Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            padding: '8px',
                                            display: 'flex',
                                            gap: '8px',
                                        }}
                                    >
                                        <HourglassTopIcon />
                                        <Typography variant="body1">
                                            {appContext.getDisplayDurationText(providerService.durationInMinutes ?? 0)}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            padding: '8px',
                                            display: 'flex',
                                            gap: '8px',
                                            flex: 1,
                                            justifyContent: 'right',
                                        }}
                                    >
                                        <LocalOfferIcon />
                                        <Typography variant="body1">{`${providerService.price?.toFixed(
                                            2,
                                        )}€`}</Typography>
                                    </Box>
                                </Box>
                                {/* // ACTION */}
                                <Box
                                    sx={{
                                        paddingLeft: '8px',
                                        paddingRight: '8px',
                                        paddingBottom: '8px',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    {!isSelected && (
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            endIcon={<AddCircleOutlineIcon />}
                                            sx={{
                                                backgroundColor: 'transparent !important',
                                                '&:hover': {
                                                    backgroundColor: 'transparent !important',
                                                },
                                            }}
                                        >
                                            {t('components.servicePicker.select')}
                                        </Button>
                                    )}
                                    {isSelected && (
                                        <Button
                                            size="small"
                                            variant="contained"
                                            endIcon={<CheckCircleOutlineIcon />}
                                            sx={{
                                                boxShadow: 'none',
                                            }}
                                        >
                                            {t('components.servicePicker.selected')}
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        )}
                    </>
                )}
                {viewMode === 'photo' && (
                    <>
                        <Box>
                            {(!providerService.photosUrls || providerService.photosUrls.length === 0) && (
                                <Box
                                    sx={{
                                        aspectRatio: '2 / 1',
                                        backgroundColor: theme.palette.divider,
                                        overflow: 'hidden',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            gap: '16px',
                                            justifyContent: 'space-between',
                                            rotate: '15deg',
                                            marginLeft: '-20%',
                                            marginTop: '-20%',
                                            opacity: 0.3,
                                        }}
                                    >
                                        {Array.from({ length: 120 }).map((_, index) => (
                                            <span key={`provider-${providerService.id}-award-${index}`}>💛</span>
                                        ))}
                                    </Box>
                                </Box>
                            )}
                            {providerService.photosUrls && providerService.photosUrls.length > 0 && (
                                <Box
                                    sx={{
                                        aspectRatio: '1 / 1',
                                    }}
                                >
                                    {inView && (
                                        <ImagesStepper
                                            className="provider-tile"
                                            imagesUrls={providerService.photosUrls}
                                        />
                                    )}
                                </Box>
                            )}
                        </Box>
                        {providerService.service && (
                            <Box>
                                <Box
                                    sx={{
                                        padding: '8px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            paddingLeft: '8px',
                                            paddingRight: '8px',
                                            paddingTop: '2px',
                                            paddingBottom: '2px',
                                            backgroundColor:
                                                ServiceCategories[providerService.service.category ?? ''].color,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderRadius: '24px',
                                            color: 'white',
                                            marginBottom: '8px',
                                        }}
                                    >
                                        <Typography variant="caption" fontWeight={500}>
                                            {t(
                                                ServiceCategories[providerService.service.category ?? '']
                                                    .translationKey,
                                            )}
                                        </Typography>
                                    </Box>
                                    <Typography variant="body1" fontWeight={500}>
                                        {providerService.service.name}
                                    </Typography>
                                </Box>
                                {/* <Divider /> */}
                                <Box
                                    sx={{
                                        padding: '8px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '8px',
                                        }}
                                    >
                                        <ReviewsIcon />
                                        <Typography variant="body1">{`${
                                            providerService.averageRating?.toFixed(1) ?? '0.0'
                                        }`}</Typography>
                                        <Rating name="read-only" value={providerService.averageRating} readOnly />
                                        <Typography variant="body1">{`(${
                                            providerService.totalReviews ?? 0
                                        })`}</Typography>
                                    </Box>
                                </Box>
                                {/* <Divider /> */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '8px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            padding: '8px',
                                            display: 'flex',
                                            gap: '8px',
                                            flex: 1,
                                        }}
                                    >
                                        <HourglassTopIcon />
                                        <Typography variant="body1">
                                            {appContext.getDisplayDurationText(providerService.durationInMinutes ?? 0)}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            padding: '8px',
                                            display: 'flex',
                                            gap: '8px',
                                        }}
                                    >
                                        <LocalOfferIcon />
                                        <Typography variant="body1">{`${providerService.price?.toFixed(
                                            2,
                                        )}€`}</Typography>
                                    </Box>
                                </Box>
                                {/* // ACTION */}
                                <Box
                                    sx={{
                                        padding: '8px',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    {!isSelected && (
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            endIcon={<AddCircleOutlineIcon />}
                                            sx={{
                                                backgroundColor: 'transparent !important',
                                                '&:hover': {
                                                    backgroundColor: 'transparent !important',
                                                },
                                            }}
                                        >
                                            {t('components.servicePicker.select')}
                                        </Button>
                                    )}
                                    {isSelected && (
                                        <Button
                                            size="small"
                                            variant="contained"
                                            endIcon={<CheckCircleOutlineIcon />}
                                            sx={{
                                                boxShadow: 'none',
                                            }}
                                        >
                                            {t('components.servicePicker.selected')}
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        )}
                    </>
                )}
            </Paper>
        </Grow>
    );
}

export function OfficeShowcase() {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);

    // Get params
    let { providerId } = useParams();

    // Translations
    const { t } = useTranslation();

    // Get theme
    const theme = useTheme();

    // Get navigation
    const navigate = useNavigate();

    // Get query
    const query = new URLSearchParams(window.location.search);
    const queryValues = {
        schedule: query.get('schedule'),
        services: query.get('services'),
    };

    // Hold state
    const [scrollY, setScrollY] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [document, setDocument] = useState<Provider | null>(null);
    const [currentTab, setCurrentTab] = useState(queryValues.schedule ? ShowcaseTabs.Availability : 0);
    const [nextAvailableBookingDay, setNextAvailableBookingDay] = useState<moment.Moment | undefined>(undefined);
    const [nextAvailableBookingTime, setNextAvailableBookingTime] = useState<moment.Moment | undefined>(undefined);
    const [day, setDay] = useState<moment.Moment>(moment());
    const [providerServicesData, setProviderServicesData] = useState<Array<ProviderService> | null>(null);
    const [availability, setAvailability] = useState<Array<Date> | null | undefined>(undefined);
    const [selectedSlot, setSelectedSlot] = useState<string | null>(null);
    const [selectedProviderServices, setSelectedProviderServices] = useState<Array<ProviderService>>([]);
    const [slotTitle, setSlotTitle] = useState<string>('');
    const [servicesListViewMode, setServicesListViewMode] = useState<'list' | 'photo'>(
        queryValues.services ? 'list' : 'photo',
    );

    // Actions: Load Profile
    const loadProfileAndServices = async () => {
        try {
            // Reset error
            setError(false);

            // Start loading
            setIsLoading(true);
            setProviderServicesData(null);

            // Get user
            const provider = await ProvidersRepository.instance.getProviderById(providerId ?? '', true);

            // Get services
            const providerServices = await ProvidersRepository.instance.getProviderServices(
                providerId ?? '',
                0,
                1000,
                true,
            );

            // Filter services: only active ones
            providerServices.data = providerServices.data.filter((providerService) => providerService.isActive);

            // Set
            setDocument(provider);
            setProviderServicesData(providerServices.data);
        } catch (e) {
            // Log error
            console.log(e);

            // Set error
            setError(true);
            setProviderServicesData([]);
        } finally {
            // Stop loading
            setIsLoading(false);
        }
    };

    // Actions: Update Availability
    const updateAvailability = async (dayToUse?: moment.Moment) => {
        // Define selectedProviderServices
        const _selectedProviderServices =
            providerServicesData?.filter((providerService) =>
                appContext.state.services.find((service) => service.id === providerService.serviceId),
            ) ?? [];

        // Set selected services
        setSelectedProviderServices(_selectedProviderServices);

        // Eval
        if (_selectedProviderServices.length === 0) {
            // Nothing selected, therefore, nothing to do
            return;
        }

        // Eval keepDay
        let nextAvailableBookingDayMoment: moment.Moment | undefined = undefined;
        let nextAvailableBookingTimeMoment: moment.Moment | undefined = undefined;
        if (!dayToUse) {
            // Get
            const nextAvailableBookingDate = await ProvidersRepository.instance.getProviderNextAvailability(
                providerId ?? '',
                _selectedProviderServices.map((providerService) => providerService.serviceId),
            );

            // Parse
            nextAvailableBookingDayMoment = moment(nextAvailableBookingDate.start).startOf('day');
            nextAvailableBookingTimeMoment = moment(nextAvailableBookingDate.start);

            // Set
            setNextAvailableBookingDay(nextAvailableBookingDayMoment);
            setNextAvailableBookingTime(nextAvailableBookingTimeMoment);
            setDay(nextAvailableBookingDayMoment);
        } else if (nextAvailableBookingTime) {
            // Check if it's the same day
            if (dayToUse.isSame(nextAvailableBookingDay, 'day')) {
                // Set
                nextAvailableBookingDayMoment = nextAvailableBookingDay;
                nextAvailableBookingTimeMoment = nextAvailableBookingTime;
            }
        }

        // Get availability
        const data = await ProvidersRepository.instance.getProviderDayAvailability(
            providerId ?? '',
            nextAvailableBookingDayMoment?.toDate() ?? dayToUse?.toDate() ?? day.toDate(),
        );

        // Calculate job duration needed
        let jobDuration = 0;
        for (const providerService of _selectedProviderServices) {
            // Check
            if (providerService) {
                // Add
                jobDuration += providerService.durationInMinutes ?? 0;
            }
        }

        // Generate time slots
        const timeSlots = generateTimeSlots(
            nextAvailableBookingDay,
            data,
            jobDuration,
            nextAvailableBookingTimeMoment ?? dayToUse ?? day,
        );

        // Eval
        if (timeSlots.length > 0) {
            // Set First Slot as selected
            setSelectedSlot(moment(timeSlots[0]).format('HH:mm'));
        } else {
            // Set
            setSelectedSlot(null);
        }

        // Set
        setAvailability(timeSlots);
    };

    // On mount
    useEffect(() => {
        // Get
        loadProfileAndServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // On tab change
    useEffect(() => {
        // Scroll to top
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab]);

    // On Selected Services change
    useEffect(() => {
        // When Selected Services change + When provider services data is available
        if (providerId && providerServicesData) {
            updateAvailability();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerId, appContext.state.services, providerServicesData]);

    // On internal Value change
    useEffect(() => {
        // Split slot into hours and minutes
        const [hours, minutes] = selectedSlot?.split(':') ?? ['0', '0'];

        // Build value
        const value = moment(day).startOf('day').set('hour', parseInt(hours)).set('minute', parseInt(minutes));

        // Init
        let newTitle = '';

        // Eval
        if (value.isSame(moment(), 'day')) {
            newTitle = t('components.timeTraveller.today');
        } else if (value.isSame(moment().add(1, 'day'), 'day')) {
            newTitle = t('components.timeTraveller.tomorrow');
        } else if (value.isSame(moment().subtract(1, 'day'), 'day')) {
            newTitle = t('components.timeTraveller.yesterday');
        } else {
            newTitle = value.fromNow();
        }

        // Add day of the week
        setSlotTitle(`${newTitle}, ${value.format('dddd')}, ${value.format('HH:mm')}`);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [day, selectedSlot]);

    // Provider subtitle
    let providerSubtitleParts = [];
    if (document && document.city) {
        // Add City name
        providerSubtitleParts.push(document.city.name);
    }
    if (document && document.birthday) {
        // Add City name
        providerSubtitleParts.push(
            t('routes.office.book.providerBirthday', {
                years: moment().diff(document.birthday, 'years'),
            }),
        );
    }
    if (document && document.countryCode) {
        // Add City name
        switch (document.countryCode) {
            case CountryCode.PT:
                providerSubtitleParts.push('🇵🇹');
                break;
            case CountryCode.BR:
                providerSubtitleParts.push('🇧🇷');
                break;
        }
    }

    // photosUrls
    const photosUrls: Array<string> = document?.photosUrls ?? [];

    // serviceCategories
    const serviceCategories: Array<ServiceCategory> = [];
    if (document && document.serviceCategories) {
        // Add service categories
        for (const data of Object.values(ServiceCategories)) {
            if (document.serviceCategories.includes(data.category)) {
                serviceCategories.push(data.category);
            }
        }
    }

    // Maps Url
    const canShowMap =
        appContext.state.operativeSystem === OperativeSystem._Unknown ||
        // Or if running on Native Knight version 2 or higher
        (appContext.state.nativeKnightApiVersion ?? 1) >= 2;
    let mapsUrl: string | undefined = undefined;
    if (currentTab === ShowcaseTabs.Location && document && document.location?.coordinates.length === 2) {
        // Eval
        if (canShowMap) {
            // Define base
            // https://developers.google.com/maps/documentation/embed/embedding-map?hl=pt-br#place_mode
            mapsUrl = 'https://www.google.com/maps/embed/v1/';

            // Check for precise location
            if (appContext.state.userLocation.isPrecise) {
                // Directions map
                mapsUrl += `directions?origin=${appContext.state.userLocation.coordinates[1]},${appContext.state.userLocation.coordinates[0]}&destination=${document.location.coordinates[1]},${document.location.coordinates[0]}&zoom=12`;
            } else {
                // Place map
                mapsUrl += `place?q=${document.location.coordinates[1]},${document.location.coordinates[0]}`;
            }

            // Add key
            mapsUrl += `&key=AIzaSyATz5Qzkci_U_fsBFqgbUzt08QWKyeTFVQ&`;
        } else {
            // Provider link
            mapsUrl = `https://www.google.com/maps/search/?api=1&query=${document.location?.coordinates[1]},${document.location?.coordinates[0]}`;
        }
    }

    // Footer services
    const footerServices: Array<Service> = [];
    for (const selectedProviderService of selectedProviderServices) {
        if (selectedProviderService.service) {
            footerServices.push(selectedProviderService.service);
        }
    }

    // Build
    return (
        <FullPage
            title={scrollY > 16 ? document?.name ?? '' : ''}
            onRefresh={async () => {
                // Load again
                loadProfileAndServices();
            }}
            actions={
                [
                    // <Box
                    //     sx={{
                    //         display: 'flex',
                    //         alignItems: 'center',
                    //     }}
                    // >
                    //     <Typography variant="body1">1.1K</Typography>
                    //     <IconButton aria-label="favorite" size="small">
                    //         <FavoriteBorderIcon />
                    //     </IconButton>
                    // </Box>,
                ]
            }
            handleScroll={(event: any) => {
                // Update scroll
                setScrollY(window.scrollY);
            }}
        >
            {isLoading && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '32px',
                        paddingTop: `${appContext.state.insets.top + 32 + 56}px`,
                    }}
                >
                    <Loader size={48} />
                </Box>
            )}
            {!isLoading && !error && providerId && document && (
                <>
                    <Box
                        sx={{
                            position: 'relative',
                        }}
                    >
                        {photosUrls.length === 0 && (
                            <Box
                                sx={{
                                    aspectRatio: '2 / 1',
                                    backgroundColor: theme.palette.divider,
                                    overflow: 'hidden',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '16px',
                                        justifyContent: 'space-between',
                                        rotate: '15deg',
                                        marginLeft: '-20%',
                                        marginTop: '-20%',
                                        opacity: 0.3,
                                    }}
                                >
                                    {Array.from({ length: 120 }).map((_, index) => (
                                        <span key={`provider-${document.id}-award-${index}`}>💛</span>
                                    ))}
                                </Box>
                            </Box>
                        )}
                        {photosUrls.length > 0 && (
                            <Box
                                sx={{
                                    height: '30vh',
                                }}
                            >
                                <ImagesStepper className="provider-tile" imagesUrls={photosUrls} />
                            </Box>
                        )}
                    </Box>

                    <Container>
                        <Box
                            sx={{
                                paddingBottom: `${16 + appContext.state.insets.bottom}px`,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    marginBottom: '8px',
                                    paddingLeft: `${
                                        appContext.getDefaultSidePaddingValue() + appContext.state.insets.left
                                    }px`,
                                    paddingRight: `${
                                        appContext.getDefaultSidePaddingValue() + appContext.state.insets.right
                                    }px`,
                                    position: 'relative',
                                }}
                            >
                                <Grow in={true}>
                                    <Box
                                        sx={{
                                            width: '120px',
                                            height: '120px',
                                            borderRadius: '50%',
                                            overflow: 'hidden',
                                            border: '4px solid',
                                            borderColor: variables.firstColor,
                                            position: 'absolute',
                                            top: '-60px',
                                        }}
                                    >
                                        {document.pictureUrl && (
                                            <img
                                                src={document.pictureUrl}
                                                alt={document.name}
                                                style={{
                                                    objectFit: 'cover',
                                                    width: '100%',
                                                    height: '100%',
                                                }}
                                            />
                                        )}
                                        {!document.pictureUrl && (
                                            <Box
                                                sx={{
                                                    height: '100%',
                                                    width: '100%',
                                                    backgroundColor: theme.palette.background.paper,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    fontSize: '48px',
                                                }}
                                            >
                                                👩‍⚕️
                                            </Box>
                                        )}
                                    </Box>
                                </Grow>

                                {/* <Box
                                    sx={{
                                        width: '120px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '8px',
                                        marginTop: '68px',
                                    }}
                                >
                                    {document.phoneNumber && (
                                        <>
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                startIcon={<CallIcon />}
                                                fullWidth
                                                target="_blank"
                                                href={`tel:${document.phoneNumber}`}
                                            >
                                                {t('routes.office.showcase.call')}
                                            </Button>
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                startIcon={<WhatsAppIcon />}
                                                fullWidth
                                                sx={{
                                                    borderColor: '#128c7e',
                                                    color: '#128c7e',
                                                }}
                                                target="_blank"
                                                href={`https://wa.me/${document.phoneNumber.replace('+', '')}`}
                                            >
                                                WhatsApp
                                            </Button>
                                        </>
                                    )}
                                </Box> */}

                                <Box
                                    sx={{
                                        flex: 1,
                                        paddingLeft: '136px',
                                        paddingTop: '8px',
                                        minHeight: '50px',
                                    }}
                                >
                                    <Typography variant="h4" fontWeight={500} gutterBottom>
                                        {document.name}
                                    </Typography>
                                    <Box
                                        sx={{
                                            marginTop: '4px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '4px',
                                        }}
                                    >
                                        <Typography variant="body2">
                                            {providerSubtitleParts.join(', ').trim()}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    marginBottom: '8px',
                                    paddingLeft: '16px',
                                    paddingRight: '16px',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '16px',
                                }}
                            >
                                <Box
                                    sx={{
                                        marginTop: '8px',
                                        display: 'flex',
                                        gap: '8px',
                                    }}
                                >
                                    <Typography variant="body1">{`${(document.averageRating ?? 0).toFixed(
                                        1,
                                    )}`}</Typography>
                                    <Rating name="read-only" value={document.averageRating} readOnly />
                                    <Typography variant="body1">{`(${document.totalReviews ?? 0})`}</Typography>
                                </Box>
                                {serviceCategories.length > 0 && (
                                    <Box
                                        sx={{
                                            flex: 1,
                                            marginTop: '8px',
                                            display: 'flex',
                                            gap: '8px',
                                            flexWrap: 'wrap',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        {serviceCategories.map((serviceCategory, index) => {
                                            return (
                                                <Grow
                                                    in={true}
                                                    key={`service-category-${index}`}
                                                    style={{
                                                        transitionDelay: `${index * 100}ms`,
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            paddingLeft: '8px',
                                                            paddingRight: '8px',
                                                            paddingTop: '2px',
                                                            paddingBottom: '2px',
                                                            backgroundColor: ServiceCategories[serviceCategory].color,
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            borderRadius: '24px',
                                                            color: 'white',
                                                        }}
                                                    >
                                                        <Typography variant="caption" fontWeight={500}>
                                                            {t(ServiceCategories[serviceCategory].translationKey)}
                                                        </Typography>
                                                    </Box>
                                                </Grow>
                                            );
                                        })}
                                    </Box>
                                )}
                            </Box>
                            {/* <Divider
                                sx={{
                                    marginTop: '16px',
                                }}
                            /> */}

                            <Tabs
                                sx={{}}
                                value={currentTab}
                                onChange={(event: React.SyntheticEvent, newValue: number) => {
                                    setCurrentTab(newValue);
                                }}
                                aria-label="icon tabs example"
                            >
                                <Tab
                                    sx={{
                                        minWidth: '0',
                                        flex: 1,
                                    }}
                                    icon={<MenuBookIcon />}
                                    aria-label="services"
                                />
                                <Tab
                                    sx={{
                                        minWidth: '0',
                                        flex: 1,
                                    }}
                                    icon={<ScheduleIcon />}
                                    aria-label="working-hours"
                                />
                                <Tab
                                    sx={{
                                        minWidth: '0',
                                        flex: 1,
                                    }}
                                    icon={<PlaceIcon />}
                                    aria-label="working-hours"
                                />
                                <Tab
                                    sx={{
                                        minWidth: '0',
                                        flex: 1,
                                    }}
                                    icon={
                                        <Badge badgeContent={selectedProviderServices.length} color="primary">
                                            <EditCalendarIcon />
                                        </Badge>
                                    }
                                    aria-label="schedule"
                                />
                            </Tabs>
                            <Box>
                                {currentTab === 0 && (
                                    <Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                gap: '16px',
                                                width: '100%',
                                                marginTop: '16px',
                                                paddingLeft: `${
                                                    appContext.getDefaultSidePaddingValue() +
                                                    appContext.state.insets.left
                                                }px`,
                                                paddingRight: `${
                                                    appContext.getDefaultSidePaddingValue() +
                                                    appContext.state.insets.right
                                                }px`,
                                                position: 'relative',
                                            }}
                                        >
                                            <OfficeTitle
                                                text={t('routes.office.showcase.services')}
                                                color={'#ff66c4'}
                                                align="left"
                                            />
                                            <Box
                                                sx={{
                                                    flex: 1,
                                                }}
                                            ></Box>
                                            <ToggleButtonGroup
                                                sx={{
                                                    marginBottom: '16px',
                                                }}
                                                size="small"
                                                exclusive
                                                value={servicesListViewMode}
                                                onChange={(
                                                    event: React.MouseEvent<HTMLElement>,
                                                    newValue: string | null,
                                                ) => {
                                                    setServicesListViewMode((newValue as any) ?? 'photo');
                                                }}
                                                aria-label="services-view-mode"
                                                color="primary"
                                            >
                                                <ToggleButton value="photo" aria-label="Photo" size="small">
                                                    <PhotoIcon />
                                                </ToggleButton>
                                                <ToggleButton value="list" aria-label="List" size="small">
                                                    <FormatListBulletedIcon />
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Box>
                                        {!providerServicesData && (
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    padding: '32px',
                                                }}
                                            >
                                                <Loader size={48} />
                                            </Box>
                                        )}
                                        {providerServicesData && providerServicesData.length === 0 && (
                                            <EmptyState
                                                autoHeight
                                                // icon={<VolunteerActivismIcon />}
                                                title={t('routes.office.professional.services.emptyTitle')}
                                                description={t('routes.office.professional.services.emptyDescription')}
                                            />
                                        )}
                                        {providerServicesData && providerServicesData.length > 0 && (
                                            <>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: '16px',
                                                        width: '100%',
                                                        marginBottom: '16px',
                                                        paddingLeft: `${
                                                            appContext.getDefaultSidePaddingValue() +
                                                            appContext.state.insets.left
                                                        }px`,
                                                        paddingRight: `${
                                                            appContext.getDefaultSidePaddingValue() +
                                                            appContext.state.insets.right
                                                        }px`,
                                                        position: 'relative',
                                                    }}
                                                >
                                                    {providerServicesData.map(
                                                        (providerService: ProviderService, index: number) => {
                                                            // Build
                                                            return (
                                                                <ProviderServicePaper
                                                                    index={index}
                                                                    providerService={providerService}
                                                                    key={`providerService-${providerService.id ?? ''}`}
                                                                    viewMode={servicesListViewMode}
                                                                />
                                                            );
                                                        },
                                                    )}
                                                </Box>
                                            </>
                                        )}
                                        {providerServicesData && providerServicesData.length > 0 && (
                                            <SelectedServices
                                                onClick={() => {
                                                    // Navigate
                                                    setCurrentTab(3);
                                                }}
                                                nextLabel={t('routes.office.showcase.selectSchedule')}
                                                services={footerServices}
                                            />
                                        )}
                                    </Box>
                                )}
                                {currentTab === 1 && (
                                    <>
                                        {document.schedule && (
                                            <>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: '16px',
                                                        width: '100%',
                                                        marginTop: '16px',
                                                        paddingLeft: `${
                                                            appContext.getDefaultSidePaddingValue() +
                                                            appContext.state.insets.left
                                                        }px`,
                                                        paddingRight: `${
                                                            appContext.getDefaultSidePaddingValue() +
                                                            appContext.state.insets.right
                                                        }px`,
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <OfficeTitle
                                                        text={t('routes.office.showcase.schedule')}
                                                        color={'#ff66c4'}
                                                        align="left"
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: '16px',
                                                        width: '100%',
                                                        marginBottom: '16px',
                                                        paddingLeft: `${
                                                            appContext.getDefaultSidePaddingValue() +
                                                            appContext.state.insets.left
                                                        }px`,
                                                        paddingRight: `${
                                                            appContext.getDefaultSidePaddingValue() +
                                                            appContext.state.insets.right
                                                        }px`,
                                                        position: 'relative',
                                                    }}
                                                >
                                                    {document.schedule.monday && (
                                                        <ScheduleDay
                                                            index={1}
                                                            day="monday"
                                                            schedule={document.schedule.monday}
                                                        />
                                                    )}
                                                    {document.schedule.tuesday && (
                                                        <ScheduleDay
                                                            index={2}
                                                            day="tuesday"
                                                            schedule={document.schedule.tuesday}
                                                        />
                                                    )}
                                                    {document.schedule.wednesday && (
                                                        <ScheduleDay
                                                            index={3}
                                                            day="wednesday"
                                                            schedule={document.schedule.wednesday}
                                                        />
                                                    )}
                                                    {document.schedule.thursday && (
                                                        <ScheduleDay
                                                            index={4}
                                                            day="thursday"
                                                            schedule={document.schedule.thursday}
                                                        />
                                                    )}
                                                    {document.schedule.friday && (
                                                        <ScheduleDay
                                                            index={5}
                                                            day="friday"
                                                            schedule={document.schedule.friday}
                                                        />
                                                    )}
                                                    {document.schedule.saturday && (
                                                        <ScheduleDay
                                                            index={6}
                                                            day="saturday"
                                                            schedule={document.schedule.saturday}
                                                        />
                                                    )}
                                                    {document.schedule.sunday && (
                                                        <ScheduleDay
                                                            index={7}
                                                            day="sunday"
                                                            schedule={document.schedule.sunday}
                                                        />
                                                    )}
                                                </Box>
                                            </>
                                        )}
                                    </>
                                )}
                                {currentTab === 2 && document.address && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                // whiteSpace: 'pre-wrap',
                                                paddingTop: '16px',
                                                paddingLeft: `${
                                                    appContext.getDefaultSidePaddingValue() +
                                                    appContext.state.insets.left
                                                }px`,
                                                paddingRight: `${
                                                    appContext.getDefaultSidePaddingValue() +
                                                    appContext.state.insets.right
                                                }px`,
                                            }}
                                        >
                                            <OfficeTitle
                                                text={t('routes.office.showcase.location')}
                                                color={'#ff66c4'}
                                                align="left"
                                            />
                                        </Box>
                                        <Grow in={true}>
                                            <Typography
                                                variant="body1"
                                                align="center"
                                                sx={{
                                                    // whiteSpace: 'pre-wrap',
                                                    paddingBottom: '16px',
                                                    paddingLeft: `${
                                                        appContext.getDefaultSidePaddingValue() +
                                                        appContext.state.insets.left
                                                    }px`,
                                                    paddingRight: `${
                                                        appContext.getDefaultSidePaddingValue() +
                                                        appContext.state.insets.right
                                                    }px`,
                                                }}
                                            >
                                                {document.address}
                                            </Typography>
                                        </Grow>

                                        {canShowMap && mapsUrl && (
                                            <Box
                                                sx={{
                                                    aspectRatio: '1 / 1',
                                                }}
                                            >
                                                <iframe
                                                    title="provider-address"
                                                    width="100%"
                                                    height="100%"
                                                    style={{
                                                        border: 0,
                                                    }}
                                                    loading="lazy"
                                                    referrerPolicy="no-referrer-when-downgrade"
                                                    src={mapsUrl}
                                                ></iframe>
                                            </Box>
                                        )}
                                        {!canShowMap && mapsUrl && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    paddingLeft: '16px',
                                                    paddingRight: '16px',
                                                    paddingBottom: '16px',
                                                }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    target="_blank"
                                                    href={mapsUrl}
                                                    endIcon={<ForkRightIcon />}
                                                >
                                                    {t('routes.office.showcase.directions')}
                                                </Button>
                                            </Box>
                                        )}
                                    </Box>
                                )}
                                {currentTab === 3 && (
                                    <>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                width: '100%',
                                                marginTop: '16px',
                                                paddingLeft: `${
                                                    appContext.getDefaultSidePaddingValue() +
                                                    appContext.state.insets.left
                                                }px`,
                                                paddingRight: `${
                                                    appContext.getDefaultSidePaddingValue() +
                                                    appContext.state.insets.right
                                                }px`,
                                                position: 'relative',
                                            }}
                                        >
                                            <OfficeTitle
                                                text={t('routes.office.showcase.pickStartHour')}
                                                color={'#ff66c4'}
                                                align="left"
                                            />
                                        </Box>
                                        {selectedProviderServices.length > 0 && (
                                            <>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: '16px',
                                                        width: '100%',
                                                        marginBottom: '16px',
                                                        paddingLeft: `${
                                                            appContext.getDefaultSidePaddingValue() +
                                                            appContext.state.insets.left
                                                        }px`,
                                                        paddingRight: `${
                                                            appContext.getDefaultSidePaddingValue() +
                                                            appContext.state.insets.right
                                                        }px`,
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <TimeTravellerNav
                                                        minDate={nextAvailableBookingDay}
                                                        value={day}
                                                        onChange={(value: moment.Moment) => {
                                                            // Set Day
                                                            setDay(value);

                                                            // Update Availability
                                                            updateAvailability(value);
                                                        }}
                                                        disabled={availability === undefined}
                                                    />
                                                    {availability === undefined && (
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                padding: '32px',
                                                            }}
                                                        >
                                                            <Loader size={48} />
                                                        </Box>
                                                    )}
                                                    {availability && availability.length === 0 && (
                                                        <>
                                                            <Typography
                                                                variant="h6"
                                                                align="center"
                                                                sx={{
                                                                    marginTop: '16px',
                                                                }}
                                                            >
                                                                {t('routes.office.showcase.noAvailabilityForThisDay')}
                                                            </Typography>
                                                            <Typography variant="body1" align="center">
                                                                {t(
                                                                    'routes.office.showcase.noAvailabilityForThisDayDescription',
                                                                )}
                                                            </Typography>
                                                        </>
                                                    )}
                                                    {availability && availability.length > 0 && (
                                                        <FormControl>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="availability-radio-buttons-group-label"
                                                                name="availability-radio-buttons-group"
                                                                value={selectedSlot}
                                                                onChange={(
                                                                    event: React.ChangeEvent<HTMLInputElement>,
                                                                ) => {
                                                                    // Set
                                                                    setSelectedSlot(
                                                                        (event.target as HTMLInputElement).value,
                                                                    );
                                                                }}
                                                                sx={{
                                                                    justifyContent: 'center',
                                                                    display: 'flex',
                                                                    flexWrap: 'wrap',
                                                                    gap: '8px',
                                                                }}
                                                            >
                                                                {availability.map((slot: Date, index: number) => {
                                                                    // Build
                                                                    return (
                                                                        <FormControlLabel
                                                                            sx={{
                                                                                width: '90px',
                                                                                border: '1px solid #ff66c4',
                                                                                borderRadius: '24px',
                                                                                margin: '0',
                                                                            }}
                                                                            key={`availability-slot-${index}`}
                                                                            value={moment(slot).format('HH:mm')}
                                                                            control={<Radio />}
                                                                            label={moment(slot).format('HH:mm')}
                                                                        />
                                                                    );
                                                                })}
                                                            </RadioGroup>
                                                        </FormControl>
                                                    )}
                                                </Box>
                                                <Box
                                                    sx={{
                                                        height: '100px',
                                                    }}
                                                ></Box>
                                                {selectedSlot && (
                                                    <FooterSheet>
                                                        <Box
                                                            sx={{
                                                                marginTop: '8px',
                                                                marginBottom: '16px',
                                                                width: '100%',
                                                                maxWidth: '425px',
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                sx={{
                                                                    textAlign: 'center',
                                                                    color: '#ff66c4',
                                                                }}
                                                            >
                                                                {slotTitle}
                                                            </Typography>
                                                            <Button
                                                                variant="contained"
                                                                endIcon={<NavigateNextIcon />}
                                                                fullWidth
                                                                sx={{
                                                                    marginTop: '8px',
                                                                }}
                                                                onClick={() => {
                                                                    // Split slot into hours and minutes
                                                                    const [hours, minutes] = selectedSlot?.split(
                                                                        ':',
                                                                    ) ?? ['0', '0'];

                                                                    // Build value
                                                                    const value = moment(day)
                                                                        .startOf('day')
                                                                        .set('hour', parseInt(hours))
                                                                        .set('minute', parseInt(minutes));

                                                                    // Update data state
                                                                    appContext.setAppointment({
                                                                        provider: document,
                                                                        providerServices: selectedProviderServices,
                                                                        startTime: value,
                                                                    });

                                                                    // Replace navigation state
                                                                    let currentUrl =
                                                                        window.location.pathname +
                                                                        window.location.search;
                                                                    if (currentUrl.includes('?')) {
                                                                        currentUrl += '&';
                                                                    } else {
                                                                        currentUrl += '?';
                                                                    }

                                                                    // Replace current url
                                                                    navigate(
                                                                        `${window.location.pathname}?schedule=true`,
                                                                        {
                                                                            replace: true,
                                                                        },
                                                                    );

                                                                    // Go to Schedule
                                                                    navigate(
                                                                        `/${RoutesNames.Office}/${RoutesNames.Office_NewAppointment}`,
                                                                    );
                                                                }}
                                                            >
                                                                {t('routes.office.showcase.confirmAppointment')}
                                                            </Button>
                                                        </Box>
                                                    </FooterSheet>
                                                )}
                                            </>
                                        )}
                                        {selectedProviderServices.length === 0 && (
                                            <EmptyState
                                                autoHeight
                                                // icon={<EditCalendarIcon />}
                                                title={t('routes.office.showcase.noServicesSelected')}
                                                description={t('routes.office.showcase.noServicesSelectedDescription')}
                                                actions={[
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => {
                                                            // Navigate
                                                            setCurrentTab(0);
                                                        }}
                                                    >
                                                        {t('routes.office.showcase.selectServices')}
                                                    </Button>,
                                                ]}
                                            />
                                        )}
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Container>
                </>
            )}
            {!isLoading && error && (
                <ErrorState
                    icon={<PersonIcon />}
                    onTryAgainClick={() => {
                        // Load again
                        loadProfileAndServices();
                    }}
                />
            )}
        </FullPage>
    );
}
