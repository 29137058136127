export enum OperativeSystem {
    /**
     * Unknown (probably running on the web browser)
     */
    _Unknown = '_unknown',

    /**
     * Android
     */
    Android = 'android',

    /**
     * iOS
     */
    iOS = 'ios',
}
