export enum LocalStorageKey {
    /**
     * Theme option
     */
    ThemeMode = 'theme-mode',

    /**
     * Operative system
     */
    OperativeSystem = 'operative-system',

    /**
     * Native Knight API version
     */
    NativeKnightApiVersion = 'native-knight-api-version',

    /**
     * Session Id
     */
    SessionId = 'session-id',

    /**
     * Session started at
     */
    SessionStartedAt = 'session-started-at',

    /**
     * App version
     */
    AppVersion = 'app-version',

    /**
     * App build
     */
    AppBuild = 'app-build',

    /**
     * Notifications unread filter
     */
    NotificationsUnreadFilter = 'notifications-unread-filter',

    /**
     * Current place
     */
    CurrentPlace = 'current-place',

    /**
     * Current service
     */
    CurrentServices = 'current-services',

    /**
     * Appointment client name
     */
    AppointmentClientName = 'appointment-client-name',

    /**
     * Appointment client phone number
     */
    AppointmentClientPhoneNumber = 'appointment-client-phone-number',

    /**
     * Appointment client observations
     */
    AppointmentClientObservations = 'appointment-client-observations',
}
