import { Box } from '@mui/material';
import i18n from 'i18next';
import moment from 'moment';
import { LegalDocument } from '../../interfaces/legal-document';
import variables from '../../variables.module.scss';
import { Container } from '../container/container';
import { Page } from '../page/page';
import styles from './legal-document-page.module.scss';

export function LegalDocumentPage(props: { document: LegalDocument; title: string }) {
    // Define language
    const languageTag = i18n.language.split('_')[0];

    // Build
    return (
        <Page title={props.title} headerBackground={variables.forthColor}>
            <Container>
                <div className={styles.legalDocumentContent}>
                    <p>{(props.document.intro as any)[languageTag]}</p>

                    {props.document.sections.map((section, index1) => {
                        return (
                            <Box key={`legal-document-section-${index1}`}>
                                <h2>
                                    {index1 + 1}. {(section.title as any)[languageTag]}
                                </h2>
                                {section.paragraphs.map((paragraph, index2) => {
                                    return (
                                        <Box key={`legal-document-section-${index1}-paragraph-${index2}`}>
                                            <p>
                                                {index1 + 1}.{index2 + 1}. {(paragraph as any)[languageTag]}
                                            </p>
                                        </Box>
                                    );
                                })}
                            </Box>
                        );
                    })}

                    <p className="footer">
                        {props.document.version} | {moment(props.document.date).format('LL')}
                    </p>
                </div>
            </Container>
        </Page>
    );
}
