import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { AppBar, Toolbar, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { AppContext } from '../../contexts/app/context';
import { AppContextType } from '../../contexts/app/types';
import { ThemeMode } from '../../enums/theme-mode';
import { hexToRgb } from '../../utils/ts-utils';
import { Loader } from '../loader/loader';

export function FullPage(props: {
    children: React.ReactNode;
    title: string;
    actions?: Array<React.ReactNode>;
    onRefresh?: () => Promise<any>;
    handleScroll?: (event: any) => void;
    headerBackground?: string;
}) {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);

    // Get navigation
    const navigate = useNavigate();

    // Hold State
    const [scrollY, setScrollY] = useState<number>(0);

    // Actions
    const goBack = () => {
        navigate(-1);
    };

    const handleScroll = (event: any) => {
        // Update scroll
        setScrollY(window.scrollY);

        // Call custom scroll event
        if (props.handleScroll) {
            props.handleScroll(event);
        }
    };

    // On mount
    useEffect(() => {
        // Listen for scroll event
        window.addEventListener('scroll', handleScroll);

        // Scroll to top
        window.scrollTo(0, 0);

        // On unmount
        return () => {
            // Remove scroll event listener
            window.removeEventListener('scroll', handleScroll);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Calculate Header Background Color
    let headerBackground = undefined;
    if (props.headerBackground) {
        // Convert to RGB
        const mainRGB = hexToRgb(props.headerBackground);
        const backRGB = hexToRgb(appContext.state.themeMode === ThemeMode.Light ? '#ffffff' : '#000000');

        // Build
        headerBackground = `linear-gradient(0deg, rgba(${backRGB?.r}, ${backRGB?.g}, ${backRGB?.b}, 1) 32%, rgba(${mainRGB?.r}, ${mainRGB?.g}, ${mainRGB?.b}, 1) 100%)`;
    }

    // Build
    return (
        <PullToRefresh
            isPullable={props.onRefresh !== undefined}
            onRefresh={props.onRefresh ?? (() => Promise.resolve())}
            refreshingContent={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        paddingTop: `${appContext.state.insets.top + 16}px`,
                    }}
                >
                    <Loader size={36} />
                </Box>
            }
            pullingContent={<></>}
            pullDownThreshold={67 + appContext.state.insets.top}
            maxPullDownDistance={95 + appContext.state.insets.top}
        >
            <Box sx={{ flexGrow: 1, minHeight: '100%' }}>
                <AppBar
                    position="fixed"
                    elevation={scrollY > 16 ? 4 : 0}
                    color="inherit"
                    sx={{
                        height: `${56 + appContext.state.insets.top}px`,
                        backgroundColor:
                            scrollY > 16
                                ? appContext.state.themeMode === ThemeMode.Light
                                    ? '#fff'
                                    : '#000'
                                : 'transparent',
                        background: headerBackground,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        transition: 'background-color 0.3s',
                    }}
                >
                    <Toolbar
                        sx={{
                            height: '56px !important',
                            minHeight: '56px !important',
                            paddingLeft: `${appContext.getDefaultSidePaddingValue()}px !important`,
                            paddingRight: `${appContext.getDefaultSidePaddingValue()}px !important`,
                        }}
                    >
                        {appContext.state.insets.left > 0 && (
                            <Box
                                sx={{
                                    width: `${appContext.state.insets.left}px`,
                                }}
                            ></Box>
                        )}

                        {/* Left Actions */}
                        <IconButton
                            size={headerBackground ? 'large' : 'small'}
                            edge="start"
                            color="inherit"
                            sx={{
                                zIndex: 2,
                                backgroundColor:
                                    scrollY > 16 || headerBackground
                                        ? 'transparent'
                                        : appContext.state.themeMode === ThemeMode.Light
                                        ? '#fff'
                                        : '#000',
                            }}
                            onClick={goBack}
                        >
                            <ArrowBackIcon />
                        </IconButton>

                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                flexGrow: 1,
                            }}
                        >
                            {props.title}
                        </Typography>

                        {/* Right Action s*/}
                        <Box
                            sx={{
                                zIndex: 2,
                                display: 'flex',
                                marginRight: '-8px',
                                paddingLeft: '8px',
                                backgroundColor:
                                    scrollY > 16 || headerBackground
                                        ? 'transparent'
                                        : appContext.state.themeMode === ThemeMode.Light
                                        ? '#fff'
                                        : '#000',
                                borderRadius: '16px',
                            }}
                        >
                            {props.actions?.map((action: React.ReactNode, index: number) => {
                                return <Box key={`action_${index}`}>{action}</Box>;
                            })}
                        </Box>

                        {appContext.state.insets.right > 0 && (
                            <Box
                                sx={{
                                    width: `${appContext.state.insets.right}px`,
                                }}
                            ></Box>
                        )}
                    </Toolbar>
                </AppBar>

                {/* Left Actions */}
                <Box
                    sx={{
                        zIndex: 2,
                        position: 'fixed',
                        top: `${appContext.state.insets.top}px`,
                        left: `${appContext.state.insets.left + appContext.getDefaultSidePaddingValue()}px`,
                        height: `${56}px`,
                        // border: '1px solid red',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <IconButton size="large" edge="start" color="inherit" sx={{}} onClick={goBack}>
                        {appContext.state.operativeSystem === 'ios' && <ArrowBackIosIcon />}
                        {appContext.state.operativeSystem !== 'ios' && <ArrowBackIcon />}
                    </IconButton>
                </Box>

                {/* Right Action s*/}
                <Box
                    sx={{
                        zIndex: 2,
                        position: 'fixed',
                        top: `${appContext.state.insets.top}px`,
                        right: `${appContext.state.insets.right + appContext.getDefaultSidePaddingValue()}px`,
                        height: `${56}px`,
                        // border: '1px solid red',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {props.actions?.map((action: React.ReactNode, index: number) => {
                        return (
                            <Box
                                key={`action_${index}`}
                                sx={{
                                    marginRight: '-8px',
                                }}
                            >
                                {action}
                            </Box>
                        );
                    })}
                </Box>

                <Box
                    sx={{
                        // paddingTop: `${56 + appContext.state.insets.top}px`,
                        // paddingBottom: `${16 + appContext.state.insets.bottom}px`,
                        // paddingLeft: `${appContext.getDefaultSidePaddingValue() + appContext.state.insets.left}px`,
                        // paddingRight: `${appContext.getDefaultSidePaddingValue() + appContext.state.insets.right}px`,
                        minHeight: '100%',
                    }}
                >
                    {props.children}
                </Box>
            </Box>
        </PullToRefresh>
    );
}
