import { EventEmitter } from 'events';
import { initializeApp } from 'firebase/app';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import Root from './components/root';
import { DeviceOrientation } from './enums/device-orientation';
import { ThemeMode } from './enums/theme-mode';
import ErrorPage from './error-page';
import './i18n';
import './index.scss';
import { SafeAreaInsets } from './interfaces/safe-area-insets';
import reportWebVitals from './reportWebVitals';
import { RoutesNames } from './routes-names';
import DevToolsNativeKnightDiag from './routes/dev-tools/native-knight-diag';
import LocationPicker from './routes/office/location-picker/location-picker';
import { OfficeMainAcademy } from './routes/office/main/academy/academy';
import { OfficeMainBookings } from './routes/office/main/bookings/bookings';
import { OfficeMainCard } from './routes/office/main/card/card';
import { OfficeMainHome } from './routes/office/main/home/home';
import { OfficeMainMore } from './routes/office/main/more/more';
import { OfficeMainProfessionals } from './routes/office/main/professionals/professionals';
import OfficeMain from './routes/office/main/_root/main';
import { OfficeNewAppointment } from './routes/office/new-appointment/new-appointment';
import OfficeNotifications from './routes/office/notifications/notifications';
import { OfficeProfessionalInvoicing } from './routes/office/professional/invoicing/invoicing';
import { OfficeProfessionalSchedule } from './routes/office/professional/schedule/schedule';
import { OfficeProfessionalServices } from './routes/office/professional/services/services';
import { OfficeProfessionalUsers } from './routes/office/professional/users/users';
import { OfficeProviderNewEvent } from './routes/office/provider-new-event/provider-new-event';
import ProviderPicker from './routes/office/provider-picker/provider-picker';
import ServicePicker from './routes/office/service-picker/service-picker';
import { OfficeShowcase } from './routes/office/showcase/showcase';
import { OfficeSystemAdministratorInvoicing } from './routes/office/system-administrator/invoicing/invoicing';
import { OfficeSystemAdministratorProviders } from './routes/office/system-administrator/providers/providers';
import { OfficeSystemAdministratorSchedule } from './routes/office/system-administrator/schedule/schedule';
import { OfficeSystemAdministratorUsers } from './routes/office/system-administrator/users/users';
import OfficeUser from './routes/office/user/user';
import Office from './routes/office/_root/root';
import PrivacyPolicy from './routes/privacy-policy/privacy-policy';
import SignIn from './routes/sign-in/sign-in';
import TermsOfUse from './routes/terms-of-use/terms-of-use';

export const firebaseApp = initializeApp({
    apiKey: 'AIzaSyABQqWCDPE1QMUP-fGKlKy2pX7eWbio9QA',
    authDomain: 'asvaidosas-prod.firebaseapp.com',
    projectId: 'asvaidosas-prod',
    storageBucket: 'asvaidosas-prod.appspot.com',
    messagingSenderId: '318059696329',
    appId: '1:318059696329:web:e7e57c605ac8643b1f7d18',
    measurementId: 'G-HTFD2QWXKB',
});

// Define global events
export enum NativeKnightEvents {
    POST_MESSAGE = 'POST_MESSAGE',

    // Events
    EVENT_THEME_MODE_CHANGED = 'EVENT_THEME_MODE_CHANGED',
    EVENT_ORIENTATION_CHANGED = 'EVENT_ORIENTATION_CHANGED',
    EVENT_APP_BECOME_ACTIVE = 'EVENT_APP_BECOME_ACTIVE',
    EVENT_NEW_NOTIFICATION = 'EVENT_NEW_NOTIFICATION',

    // Returns
    RETURN_SAFE_AREA_INSETS = 'RETURN_SAFE_AREA_INSETS',
    RETURN_ORIENTATION = 'RETURN_ORIENTATION',
    RETURN_NOTIFICATIONS_PERMISSION = 'RETURN_NOTIFICATIONS_PERMISSION',
    RETURN_FIREBASE_MESSAGING_TOKEN = 'RETURN_FIREBASE_MESSAGING_TOKEN',
    RETURN_LOCATION = 'RETURN_LOCATION',
}

// Define global event emitter
export const NativeKnightEventEmitter = new EventEmitter();

// Listen for OS native messages
(window as any).nativeKnight = {
    /**
     * Native Operating System posted a message to the web app
     * @param message
     */
    postMessage: (message: string) => {
        NativeKnightEventEmitter.emit(NativeKnightEvents.POST_MESSAGE, message);
    },

    /**
     * Change theme mode
     * @param mode
     */
    event_themeModeChanged: (mode: ThemeMode) => {
        NativeKnightEventEmitter.emit(NativeKnightEvents.EVENT_THEME_MODE_CHANGED, mode);
    },

    /**
     * Orientation change
     * @param orientation
     */
    event_orientationChanged: (orientation: DeviceOrientation) => {
        NativeKnightEventEmitter.emit(NativeKnightEvents.EVENT_ORIENTATION_CHANGED, orientation);
    },

    /**
     * App become active
     */
    event_appBecomeActive: () => {
        NativeKnightEventEmitter.emit(NativeKnightEvents.EVENT_APP_BECOME_ACTIVE);
    },

    /**
     * New notification
     */
    event_newNotification: (notification: string) => {
        NativeKnightEventEmitter.emit(NativeKnightEvents.EVENT_NEW_NOTIFICATION, notification);
    },

    /**
     * Return Safe Area Insets
     * @param insets
     */
    return_safeAreaInsets: (insets: SafeAreaInsets) => {
        NativeKnightEventEmitter.emit(NativeKnightEvents.RETURN_SAFE_AREA_INSETS, insets);
    },

    /**
     * Return Orientation
     * @param insets
     */
    return_orientation: (orientation: DeviceOrientation) => {
        NativeKnightEventEmitter.emit(NativeKnightEvents.RETURN_ORIENTATION, orientation);
    },

    /**
     * Return Notifications Permission
     * @param insets
     */
    return_notifications_permission: (decision: 'yes' | 'no' | 'explain') => {
        NativeKnightEventEmitter.emit(NativeKnightEvents.RETURN_NOTIFICATIONS_PERMISSION, decision);
    },

    /**
     * Return Firebase Messaging Token
     * @param insets
     */
    return_firebase_messaging_token: (token: string) => {
        NativeKnightEventEmitter.emit(NativeKnightEvents.RETURN_FIREBASE_MESSAGING_TOKEN, token);
    },

    /**
     * Return Location Permission
     * @param insets
     */
    return_location: (latitude: number, longitude: number) => {
        NativeKnightEventEmitter.emit(NativeKnightEvents.RETURN_LOCATION, latitude, longitude);
    },
};

// Create Router
const router = createBrowserRouter([
    {
        path: '/',
        errorElement: <ErrorPage />,
        element: <Root />,
        children: [
            {
                path: '',
                element: <Navigate to={`/${RoutesNames.Office}`} />,
            },
            {
                path: RoutesNames.SignIn,
                element: <SignIn />,
            },
            {
                path: RoutesNames.TermsOfUse,
                element: <TermsOfUse />,
            },
            {
                path: RoutesNames.PrivacyPolicy,
                element: <PrivacyPolicy />,
            },
            {
                path: RoutesNames.Office,
                element: <Office />,
                children: [
                    {
                        path: '',
                        element: <Navigate to={`/${RoutesNames.Office}/${RoutesNames.Office_Main}`} />,
                    },
                    {
                        path: RoutesNames.Office_Main,
                        element: <OfficeMain />,
                        children: [
                            {
                                path: '',
                                element: (
                                    <Navigate
                                        to={`/${RoutesNames.Office}/${RoutesNames.Office_Main}/${RoutesNames.Office_Main_Home}`}
                                    />
                                ),
                            },
                            {
                                path: RoutesNames.Office_Main_Home,
                                element: <OfficeMainHome />,
                            },
                            {
                                path: RoutesNames.Office_Main_Card,
                                element: <OfficeMainCard />,
                            },
                            {
                                path: RoutesNames.Office_Main_Bookings,
                                element: <OfficeMainBookings />,
                            },
                            {
                                path: RoutesNames.Office_Main_Academy,
                                element: <OfficeMainAcademy />,
                            },
                            {
                                path: RoutesNames.Office_Main_More,
                                element: <OfficeMainMore />,
                            },
                            {
                                path: RoutesNames.Office_Main_Professionals,
                                element: <OfficeMainProfessionals />,
                            },
                        ],
                    },
                    {
                        path: RoutesNames.Office_Notifications,
                        element: <OfficeNotifications />,
                    },
                    {
                        path: `${RoutesNames.Office_Professional}/:providerId`,
                        children: [
                            {
                                path: RoutesNames.Office_Professional_Schedule,
                                element: <OfficeProfessionalSchedule />,
                            },
                            {
                                path: RoutesNames.Office_Professional_Invoicing,
                                element: <OfficeProfessionalInvoicing />,
                            },
                            {
                                path: RoutesNames.Office_Professional_Users,
                                element: <OfficeProfessionalUsers />,
                            },
                            {
                                path: RoutesNames.Office_Professional_Services,
                                element: <OfficeProfessionalServices />,
                            },
                        ],
                    },
                    {
                        path: RoutesNames.Office_SystemAdministrator,
                        children: [
                            {
                                path: RoutesNames.Office_SystemAdministrator_Schedule,
                                element: <OfficeSystemAdministratorSchedule />,
                            },
                            {
                                path: RoutesNames.Office_SystemAdministrator_Invoicing,
                                element: <OfficeSystemAdministratorInvoicing />,
                            },
                            {
                                path: RoutesNames.Office_SystemAdministrator_Users,
                                element: <OfficeSystemAdministratorUsers />,
                            },
                            {
                                path: RoutesNames.Office_SystemAdministrator_Providers,
                                element: <OfficeSystemAdministratorProviders />,
                            },
                        ],
                    },
                    {
                        path: `${RoutesNames.Office_User}/:userId`,
                        element: <OfficeUser />,
                    },
                    {
                        path: RoutesNames.Office_ServicePicker,
                        element: <ServicePicker />,
                    },
                    {
                        path: RoutesNames.Office_LocationPicker,
                        element: <LocationPicker />,
                    },
                    {
                        path: RoutesNames.Office_ProviderPicker,
                        element: <ProviderPicker />,
                    },
                    {
                        path: `${RoutesNames.Office_Showcase}/:providerId`,
                        element: <OfficeShowcase />,
                    },
                    {
                        path: RoutesNames.Office_NewAppointment,
                        element: <OfficeNewAppointment />,
                    },
                    {
                        path: RoutesNames.Office_ProviderNewEvent,
                        element: <OfficeProviderNewEvent />,
                    },
                ],
            },
            {
                path: RoutesNames.DevTools,
                children: [
                    {
                        path: RoutesNames.DevTools_NativeKnightDiag,
                        element: <DevToolsNativeKnightDiag />,
                    },
                ],
            },
            {
                path: '*',
                element: <Navigate to={`/${RoutesNames.Office}`} />,
            },
        ],
    },
]);

// Create React DOM Root element
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// Render app
root.render(
    // <React.StrictMode>
    <RouterProvider router={router} />,
    // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
