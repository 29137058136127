import EventIcon from '@mui/icons-material/Event';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { Alert, Box, Chip, Grow, IconButton, Paper, Typography } from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth/context';
import { AuthContextType } from '../../contexts/auth/types';
import { Invoice } from '../../interfaces/invoice';
import { InvoiceLine } from '../../interfaces/invoice-line';
import { Provider } from '../../interfaces/provider';
import { InvoicesRepository } from '../../repositories/invoices';
import { RoutesNames } from '../../routes-names';
import { emptyId } from '../../utils/ts-utils';
import { Container } from '../container/container';
import { EmptyState } from '../empty-state/empty-state';
import { ErrorState } from '../error-state/error-state';
import { Loader } from '../loader/loader';
import { Page } from '../page/page';
import { TimeTraveller } from '../time-traveller/time-traveller';

export function InvoicingScreen({ provider }: { provider: Provider }) {
    // Get Context
    const authContext: AuthContextType = useContext(AuthContext);

    // Translations
    const { t } = useTranslation();

    // Get navigation
    const navigate = useNavigate();

    // Get query
    const query = new URLSearchParams(window.location.search);
    const queryValues = {
        day: query.get('day'),
    };

    // Hold state
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [day, setDay] = useState<moment.Moment>(queryValues.day ? moment(queryValues.day) : moment());
    const [error, setError] = useState<boolean>(false);
    const [notAvailableYet, setNotAvailableYet] = useState<boolean>(false);
    const [cache, setCache] = useState<
        Map<
            string,
            Array<
                Invoice & {
                    lines: Array<InvoiceLine>;
                }
            >
        >
    >(
        new Map<
            string,
            Array<
                Invoice & {
                    lines: Array<InvoiceLine>;
                }
            >
        >(),
    );
    const [invoices, setInvoices] = useState<Array<
        Invoice & {
            lines: Array<InvoiceLine>;
        }
    > | null>(null);

    // Action: Reset all
    const load = async () => {
        // Reset state
        setError(false);
        setInvoices(null);
        setNotAvailableYet(false);

        // Eval date
        if (
            // Tomorrow or after tomorrow
            day.isAfter(moment().endOf('day'))
        ) {
            // Set not available yet
            setNotAvailableYet(true);
        } else {
            // Look in cache
            const cached = cache.get(day.toISOString());

            // Eval
            if (cached) {
                // Set
                setInvoices(cached);

                // Done
                return;
            } else {
                // Start loading
                setIsLoading(true);

                try {
                    // Get invoices
                    const results = await InvoicesRepository.instance.getProviderInvoices(
                        provider.id?.toString() ?? '',
                        day.startOf('day').toDate(),
                        day.endOf('day').toDate(),
                    );

                    // Set
                    setInvoices(results);

                    // Set cache
                    setCache(cache.set(day.toISOString(), results));

                    // Stop loading
                    setIsLoading(false);
                } catch (error) {
                    // Set error
                    setError(true);
                }
            }
        }
    };

    // On Day change
    useEffect(() => {
        // Print
        if (day) {
            // Load
            load();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [day, cache]);

    // Calculate dayTotal
    // let dayTotal = 0;
    // if (invoices) {
    //     for (const invoice of invoices) {
    //         for (const line of invoice.lines) {
    //             // Calculate total
    //             let total = 0;

    //             // // Eval
    //             // if (line.total && provider.percentageEarned) {
    //             //     // Calculate
    //             //     total = line.total * (provider.percentageEarned / 100);
    //             // }

    //             // Add
    //             dayTotal += total;
    //         }
    //     }
    // }

    // Calculate page title
    let pageTitle = t('routes.office.main.home.professional.invoicing.title');
    if (provider.id === emptyId()) {
        pageTitle += ` | (${t('routes.office.main.home.professional.invoicing.noProvider')})`;
    } else if (provider.id !== authContext.state.me?.provider?.id) {
        pageTitle += ` | ${provider.name.split(' ')[0]}`;
    }

    // Build
    return (
        <>
            <Page
                title={pageTitle}
                onRefresh={async () => {
                    // Clone cache
                    const cacheClone = new Map(cache);

                    // Reset cache
                    cacheClone.delete(day.toISOString());

                    // Update cache
                    setCache(cacheClone);

                    // Reset state
                    load();
                }}
                actions={[
                    <IconButton
                        aria-label="schedule"
                        onClick={() => {
                            // Replace state
                            navigate(`${window.location.pathname}?day=${day.format('YYYY-MM-DD')}`, {
                                replace: true,
                            });

                            // Go to Schedule
                            navigate(`../${RoutesNames.Office_Professional_Schedule}?day=${day.format('YYYY-MM-DD')}`);
                        }}
                    >
                        <EventIcon />
                    </IconButton>,
                ]}
            >
                <Container>
                    {isLoading && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '32px',
                            }}
                        >
                            <Loader size={48} />
                        </Box>
                    )}
                    {!isLoading && !error && (
                        <>
                            {notAvailableYet && (
                                <Alert severity="info">
                                    {t('routes.office.professional.invoicing.notAvailableYet')} ⏳
                                </Alert>
                            )}
                            {invoices && invoices.length === 0 && (
                                <EmptyState
                                    height={70}
                                    icon={<PointOfSaleIcon />}
                                    title={t('routes.office.professional.invoicing.emptyTitle')}
                                    description={t('routes.office.professional.invoicing.emptyDescription')}
                                />
                            )}
                            {invoices &&
                                invoices.length > 0 &&
                                invoices.map(
                                    (
                                        invoice: Invoice & {
                                            lines: Array<InvoiceLine>;
                                        },
                                    ) => {
                                        return (
                                            <Grow in={true} mountOnEnter unmountOnExit>
                                                <Paper
                                                    key={`invoice-${invoice.id?.toString()}`}
                                                    sx={{
                                                        paddingLeft: '16px',
                                                        paddingRight: '16px',
                                                        paddingTop: '16px',
                                                        marginBottom: '16px',
                                                    }}
                                                >
                                                    <Typography variant="body1">{invoice.number}</Typography>
                                                    <Typography variant="subtitle2">
                                                        {moment(invoice.registeredAt).format('LT')}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            marginTop: '8px',
                                                        }}
                                                    >
                                                        {invoice.lines &&
                                                            invoice.lines.length > 0 &&
                                                            invoice.lines.map((line: InvoiceLine) => {
                                                                // Calculate total
                                                                let total = 0;

                                                                // Eval
                                                                // if (line.total && provider.percentageEarned) {
                                                                //     // Calculate
                                                                //     total =
                                                                //         line.total * (provider.percentageEarned / 100);
                                                                // }

                                                                return (
                                                                    <Box
                                                                        key={`invoice-line-${line.id?.toString()}`}
                                                                        sx={{
                                                                            padding: '8px',
                                                                            marginBottom: '16px',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            borderLeft: '3px solid #ff66c4',
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            variant="subtitle2"
                                                                            sx={{
                                                                                flex: 1,
                                                                            }}
                                                                        >
                                                                            {line.title}
                                                                        </Typography>
                                                                        <Chip
                                                                            label={`${total.toFixed(2)}€`}
                                                                            color="success"
                                                                            sx={{
                                                                                marginLeft: '16px',
                                                                                fontWeight: 'bold',
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                );
                                                            })}
                                                    </Box>
                                                </Paper>
                                            </Grow>
                                        );
                                    },
                                )}
                            {invoices && invoices.length > 0 && (
                                <>
                                    <Typography
                                        variant="h5"
                                        textAlign="center"
                                        sx={{
                                            marginTop: '32px',
                                        }}
                                    >
                                        {t('routes.office.professional.invoicing.dayTotal')}
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        textAlign="center"
                                        fontWeight="bold"
                                        sx={{
                                            marginTop: '16px',
                                        }}
                                    >
                                        {/* {`🎉 ${dayTotal.toFixed(2)}€ 💰`} */}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        textAlign="center"
                                        sx={{
                                            marginTop: '16px',
                                        }}
                                    >
                                        {`💪 ${t('routes.office.professional.invoicing.goodJob')} 🙏`}
                                    </Typography>
                                </>
                            )}
                        </>
                    )}
                    {!isLoading && error && (
                        <ErrorState
                            icon={<PointOfSaleIcon />}
                            onTryAgainClick={() => {
                                // Reset state
                                setError(false);
                            }}
                        />
                    )}
                    <Box
                        sx={{
                            height: '160px',
                        }}
                    ></Box>
                </Container>
            </Page>
            <TimeTraveller
                value={day}
                onChange={(value: moment.Moment) => {
                    setDay(value);
                }}
                disabled={isLoading}
            />
        </>
    );
}
