import { AxiosResponse } from 'axios';
import { Booking } from '../interfaces/booking';
import { Provider } from '../interfaces/provider';
import { ProviderService } from '../interfaces/provider-service';
import { BackendApiManager } from '../managers/backend-api';
import { Logger } from '../utils/logger';

export class ProvidersRepository {
    // Singleton Instance
    private static _instance: ProvidersRepository;

    // Private
    private logger: Logger;

    static get instance(): ProvidersRepository {
        if (!this._instance) {
            this._instance = new ProvidersRepository();
        }
        return this._instance;
    }

    constructor() {
        // Create Logger
        this.logger = new Logger('ProvidersRepository');
    }

    public async getProviderById(providerId: string, includeMetadata?: boolean): Promise<Provider | null> {
        // Set url
        let url: string = `/providers/${providerId}`;

        // Include metadata
        if (includeMetadata) {
            url += '?includeMetadata=true';
        }

        // Get from backend
        const result: AxiosResponse<Provider | null> = await BackendApiManager.instance.get<Provider | null>(url);

        // Return
        return result.data;
    }

    public async getProviderServices(
        providerId: string,
        skip: number,
        limit: number,
        includeMetadata?: boolean,
    ): Promise<{ totalCount: number; data: Array<ProviderService> }> {
        // Set url
        let url: string = `/providers/${providerId}/services?skip=${skip}&limit=${limit}`;

        // Include metadata
        if (includeMetadata) {
            url += '&includeMetadata=true';
        }

        // Get from backend
        const result: AxiosResponse<Array<ProviderService>> =
            await BackendApiManager.instance.get<Array<ProviderService>>(url);

        // Return
        return { data: result.data, totalCount: parseInt(result.headers['x-total-count'] || '', 10) };
    }

    public async updateProviderService(
        providerId: string,
        serviceId: string,
        data: Partial<ProviderService>,
    ): Promise<ProviderService> {
        // Set url
        const url: string = `/providers/${providerId}/services/${serviceId}`;

        // Get from backend
        const result: AxiosResponse<ProviderService> = await BackendApiManager.instance.patch<ProviderService>(
            url,
            data,
        );

        // Return
        return result.data;
    }

    public async getProviderDayAvailability(
        providerId: string,
        day: Date,
    ): Promise<
        Array<{
            start: Date;
            end: Date;
        }>
    > {
        // Parse month
        const month = day.getMonth() + 1;

        // Date in UTC
        var dayValue: string = `${day.getFullYear()}-${month < 10 ? `0${month}` : month}-${
            day.getDate() < 10 ? `0${day.getDate()}` : day.getDate()
        }`;

        // Set url
        const url: string = `/providers/${providerId}/availability/${dayValue}`;

        // Get from backend
        const result: AxiosResponse<
            Array<{
                start: Date;
                end: Date;
            }>
        > = await BackendApiManager.instance.get<
            Array<{
                start: Date;
                end: Date;
            }>
        >(url);

        // Return
        return result.data;
    }

    public async getProviderNextAvailability(
        providerId: string,
        servicesIds: Array<string>,
    ): Promise<{
        start: Date;
    }> {
        // Set url
        let url: string = `/providers/${providerId}/availability/next`;

        // Eval length again
        if (servicesIds.length > 0) {
            // Add the rest
            for (let i = 0; i < servicesIds.length; i++) {
                if (i === 0) {
                    url += `?servicesIds=${servicesIds[i]}`;
                } else {
                    url += `&servicesIds=${servicesIds[i]}`;
                }
            }
        }

        // Get from backend
        const result: AxiosResponse<{
            start: Date;
        }> = await BackendApiManager.instance.get<{
            start: Date;
        }>(url);

        // Return
        return result.data;
    }

    public async getProviders(
        skip: number,
        limit: number,
        latitude?: number,
        longitude?: number,
        name?: string,
        includeMetadata?: boolean,
    ): Promise<{ totalCount: number; data: Array<Provider> }> {
        // Set url
        let url: string = `/providers?skip=${skip}&limit=${limit}`;

        // Add Name
        if (name) {
            url += `&name=${name}`;
        }

        // Add Latitude and Longitude
        if (latitude && longitude) {
            url += `&latitude=${latitude}&longitude=${longitude}`;
        }

        // Add metadata
        if (includeMetadata) {
            url += '&includeMetadata=true';
        }

        // Get from backend
        const result: AxiosResponse<Array<Provider>> = await BackendApiManager.instance.get<Array<Provider>>(url);

        // Return
        return { data: result.data, totalCount: parseInt(result.headers['x-total-count'] || '', 10) };
    }

    public async createBooking(
        providerId: string,
        startTime: Date,
        endTime: Date,
        notes: string | undefined,
    ): Promise<Booking> {
        // Set url
        let url: string = `/providers/${providerId}/bookings`;

        // Get notifications from backend
        const result: AxiosResponse<Booking> = await BackendApiManager.instance.post<Booking>(url, {
            startTime,
            endTime,
            notes,
        });

        // Return
        return result.data;
    }
}
