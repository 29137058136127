import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SearchIcon from '@mui/icons-material/Search';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import {
    Box,
    Button,
    FormControl,
    Grow,
    IconButton,
    InputLabel,
    OutlinedInput,
    Paper,
    Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router';
import { useDebounce } from 'usehooks-ts';
import { Container } from '../../../components/container/container';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { ErrorState } from '../../../components/error-state/error-state';
import { Loader } from '../../../components/loader/loader';
import { Page } from '../../../components/page/page';
import { SelectedServices } from '../../../components/selected-services/selected-services';
import { AppContext } from '../../../contexts/app/context';
import { AppContextType } from '../../../contexts/app/types';
import { ServiceCategories } from '../../../databases/service-categories';
import { ThemeMode } from '../../../enums/theme-mode';
import { Service } from '../../../interfaces/service';
import { ServicesRepository } from '../../../repositories/services';
import { RoutesNames } from '../../../routes-names';
import { hexToRgb } from '../../../utils/ts-utils';
import { useQuery } from '../../../utils/use-query';
import variables from '../../../variables.module.scss';

function ServiceTile({ service }: { service: Service }) {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);

    // Translations
    const { t } = useTranslation();

    // In view hook
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
        triggerOnce: true,
    });

    // Hold state
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showDescription, setShowDescription] = useState<boolean>(false);

    // Is Selected
    const isSelected = appContext.state.services.find((s) => s.id === service.id);

    // Define background color
    let background = undefined;
    if (isSelected) {
        // Convert to RGB
        const mainRGB = hexToRgb(variables.firstColor);
        const backRGB = hexToRgb(appContext.state.themeMode === ThemeMode.Light ? '#ffffff' : '#121212');

        // Build
        background = `linear-gradient(270deg, rgba(${backRGB?.r}, ${backRGB?.g}, ${backRGB?.b}, 1) 32%, rgba(${mainRGB?.r}, ${mainRGB?.g}, ${mainRGB?.b}, 1) 100%)`;
    }

    // Build
    return (
        <Grow in={true} mountOnEnter unmountOnExit ref={ref}>
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '200px',
                    marginBottom: '16px',
                    padding: '8px',
                    gap: '8px',
                    background: background,
                }}
                onClick={() => {
                    // Check if this service was already selected or not
                    const alreadySelected = appContext.state.services.find((s) => s.id === service.id);

                    // Eval
                    if (alreadySelected) {
                        // Remove Service
                        appContext.setServices(appContext.state.services.filter((s) => s.id !== service.id));
                    } else {
                        // Set Service
                        appContext.setServices([...appContext.state.services, service]);
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        height: '200px',
                        width: '100%',
                        gap: '8px',
                    }}
                >
                    <Box
                        sx={{
                            width: '50%',
                            overflow: 'hidden',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '4px',
                        }}
                    >
                        {inView && (
                            <img
                                style={{
                                    minWidth: '100%',
                                    minHeight: '100%',
                                    objectFit: 'cover',
                                }}
                                src={service.photoUrl}
                                alt={service.name}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            width: '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            paddingTop: '4px',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                marginBottom: '8px',
                            }}
                        >
                            {service.category && (
                                <Box
                                    sx={{
                                        paddingLeft: '8px',
                                        paddingRight: '8px',
                                        paddingTop: '2px',
                                        paddingBottom: '2px',
                                        backgroundColor: ServiceCategories[service.category].color,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '24px',
                                        color: 'white',
                                    }}
                                >
                                    <Typography variant="caption" fontWeight={500}>
                                        {t(ServiceCategories[service.category].translationKey)}
                                    </Typography>
                                </Box>
                            )}
                            <Box
                                sx={{
                                    flex: 1,
                                }}
                            ></Box>
                            <IconButton
                                aria-label="info"
                                size="small"
                                color="info"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setShowDescription(!showDescription);
                                }}
                            >
                                <InfoOutlined fontSize="inherit" />
                            </IconButton>
                        </Box>

                        <Typography
                            gutterBottom
                            fontWeight={500}
                            sx={{
                                fontSize: '14px',
                            }}
                        >
                            {service.name}
                        </Typography>

                        {service.minPrice && service.maxPrice && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    opacity: 0.8,
                                    gap: '4px',
                                    marginTop: '4px',
                                }}
                            >
                                <LocalOfferIcon
                                    fontSize="small"
                                    sx={{
                                        opacity: 0.8,
                                    }}
                                />
                                {service.minPrice === service.maxPrice ? (
                                    <Typography variant="caption">
                                        {t('components.servicePicker.price', {
                                            price: service.minPrice.toFixed(2),
                                        })}
                                    </Typography>
                                ) : undefined}
                                {service.minPrice !== service.maxPrice ? (
                                    <Typography variant="caption">
                                        {t('components.servicePicker.priceInterval', {
                                            min: service.minPrice.toFixed(2),
                                            max: service.maxPrice.toFixed(2),
                                        })}
                                    </Typography>
                                ) : undefined}
                            </Box>
                        )}
                        {service.minDurationInMinutes && service.maxDurationInMinutes && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    opacity: 0.8,
                                    gap: '4px',
                                    marginTop: '4px',
                                }}
                            >
                                <HourglassFullIcon
                                    fontSize="small"
                                    sx={{
                                        opacity: 0.8,
                                    }}
                                />
                                {service.minDurationInMinutes === service.maxDurationInMinutes ? (
                                    <Typography variant="caption">
                                        {appContext.getDisplayDurationText(service.minDurationInMinutes)}
                                    </Typography>
                                ) : undefined}
                                {service.minDurationInMinutes !== service.maxDurationInMinutes ? (
                                    <Typography variant="caption">
                                        {t('components.servicePicker.durationInterval', {
                                            min: appContext.getDisplayDurationText(service.minDurationInMinutes),
                                            max: appContext.getDisplayDurationText(service.maxDurationInMinutes),
                                        })}
                                    </Typography>
                                ) : undefined}
                            </Box>
                        )}
                        <Box sx={{ flex: 1 }}></Box>
                        <Box
                            sx={{
                                marginTop: '8px',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            {!isSelected && (
                                <Button size="small" variant="outlined" endIcon={<AddCircleOutlineIcon />}>
                                    {t('components.servicePicker.select')}
                                </Button>
                            )}
                            {isSelected && (
                                <Button
                                    size="small"
                                    variant="contained"
                                    endIcon={<CheckCircleOutlineIcon />}
                                    sx={{
                                        boxShadow: 'none',
                                    }}
                                >
                                    {t('components.servicePicker.selected')}
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Box>
                {showDescription && (
                    <Grow in={showDescription} mountOnEnter unmountOnExit>
                        <Box
                            sx={{
                                paddingTop: '8px',
                                paddingBottom: '8px',
                            }}
                        >
                            <Typography variant="body2">{service.description}</Typography>
                        </Box>
                    </Grow>
                )}
            </Paper>
        </Grow>
    );
}

export default function ServicePicker() {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);

    // Translations
    const { t } = useTranslation();

    // Get navigation
    const navigate = useNavigate();

    // Read query
    const queryStringValues = useQuery();

    // Hold state
    const [category, setCategory] = useState<string | null>(queryStringValues.get('category') || null);
    const [error, setError] = useState<boolean>(false);
    const [totalDocumentsCount, setTotalDocumentsCount] = useState<number>(-1);
    const [documents, setDocuments] = useState<Array<Service> | null>(null);
    const [query, setQuery] = useState(queryStringValues.get('query') || '');
    const queryDebouncedValue = useDebounce<string>(query, 500);

    // Load
    const handleLoadMore = async (page: number) => {
        try {
            // Get
            const results: { totalCount: number; data: Array<Service> } = await ServicesRepository.instance.getServices(
                documents?.length ?? 0,
                10,
                query && query.trim() !== '' ? query : undefined,
                true,
                category || undefined,
            );

            // Update state
            setTotalDocumentsCount(results.totalCount);
            setDocuments([...(documents || []), ...results.data]);
        } catch (error) {
            // Log
            console.error(error);

            // Update state
            setError(true);
        } finally {
            // Fix iOS empty scroll problem
            appContext.fixIosEmptyScrollProblem();
        }
    };

    // Action: Reset all
    const resetState = async () => {
        // Reset state
        setError(false);
        setDocuments(null);
        setTotalDocumentsCount(-1);
    };

    // On Input Change
    useEffect(() => {
        // Reset state (and fire search by consequence)
        resetState();
    }, [queryDebouncedValue]);

    // Pick title
    let title = t('components.servicePicker.title');
    if (category) {
        title = t(ServiceCategories[category].translationKey);
    }

    // Build
    return (
        <Page
            title={title}
            onRefresh={async () => {
                // Reset state
                resetState();
            }}
            disableIosUnderTitle={true}
            underBar={
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}
                >
                    <FormControl fullWidth>
                        <InputLabel id="service-picker-input-label">{t('components.servicePicker.search')}</InputLabel>
                        <OutlinedInput
                            size="small"
                            id="service-picker-input"
                            label={t('components.servicePicker.search')}
                            placeholder={t('components.servicePicker.placeholder')}
                            fullWidth
                            startAdornment={
                                <SearchIcon
                                    sx={{
                                        marginRight: '8px',
                                    }}
                                />
                            }
                            autoFocus={!category}
                            value={query}
                            onChange={(event) => {
                                // Update
                                setQuery(event.target.value);
                            }}
                            endAdornment={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginRight: '-8px',
                                    }}
                                >
                                    {query && (
                                        <IconButton
                                            aria-label="clear"
                                            onClick={() => {
                                                // Clear Value
                                                setQuery('');
                                            }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    )}
                                </Box>
                            }
                        />
                    </FormControl>
                </Box>
            }
            underBarHeight={54}
            headerBackground={category ? ServiceCategories[category || ''].color : undefined}
        >
            <Container>
                {!error && (
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={handleLoadMore}
                        hasMore={documents?.length !== totalDocumentsCount}
                        loader={
                            <Box
                                key={`loader`}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '32px',
                                }}
                            >
                                <Loader size={48} />
                            </Box>
                        }
                    >
                        {documents?.length === 0 && !category && (
                            <EmptyState
                                height={80}
                                key={`empty`}
                                icon={<VolunteerActivismIcon />}
                                title={t('components.servicePicker.empty.title')}
                                description={t('components.servicePicker.empty.description')}
                            />
                        )}
                        {documents?.length === 0 && category && (
                            <EmptyState
                                key={`empty`}
                                icon={<VolunteerActivismIcon />}
                                title={t('components.servicePicker.empty.title')}
                                description={t('components.servicePicker.empty.descriptionCategory')}
                                height={80}
                                actions={[
                                    <Button
                                        key={`empty-action`}
                                        variant="contained"
                                        onClick={() => {
                                            // Reset state
                                            setCategory(null);
                                            resetState();
                                        }}
                                    >
                                        {t('components.servicePicker.empty.seeAllCategories')}
                                    </Button>,
                                ]}
                            />
                        )}
                        {documents?.map((document: Service, index: number) => {
                            return <ServiceTile key={`service-${index}`} service={document} />;
                        })}
                        <SelectedServices
                            onClick={() => {
                                // Navigate
                                navigate(`../${RoutesNames.Office_ProviderPicker}`);
                            }}
                            nextLabel={t('components.servicePicker.next')}
                        />
                    </InfiniteScroll>
                )}
                {error && (
                    <ErrorState
                        icon={<VolunteerActivismIcon />}
                        onTryAgainClick={() => {
                            // Reset state
                            setError(false);
                        }}
                    />
                )}
            </Container>
        </Page>
    );
}
