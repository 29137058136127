import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import ErrorIcon from '@mui/icons-material/Error';
import EventIcon from '@mui/icons-material/Event';
import LockClockIcon from '@mui/icons-material/LockClock';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import Menu from '@mui/material/Menu';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NativeKnightEventEmitter, NativeKnightEvents } from '../..';
import { AppContext } from '../../contexts/app/context';
import { AppContextType } from '../../contexts/app/types';
import { AuthContext } from '../../contexts/auth/context';
import { AuthContextType } from '../../contexts/auth/types';
import { BookingState } from '../../enums/booking-state';
import { DeviceOrientation } from '../../enums/device-orientation';
import { OperativeSystem } from '../../enums/operative-system';
import { ThemeMode } from '../../enums/theme-mode';
import { Booking } from '../../interfaces/booking';
import { Provider } from '../../interfaces/provider';
import { User } from '../../interfaces/user';
import { BookingsRepository } from '../../repositories/bookings';
import { RoutesNames } from '../../routes-names';
import { emptyId, hexToRgb } from '../../utils/ts-utils';
import variables from '../../variables.module.scss';
import { BookingCard } from '../booking-card/booking-card';
import { Container } from '../container/container';
import { EmptyState } from '../empty-state/empty-state';
import { ErrorState } from '../error-state/error-state';
import { FullPage } from '../full-page/full-page';
import { Loader } from '../loader/loader';
import { Page } from '../page/page';
import { TimeTraveller } from '../time-traveller/time-traveller';

const weekDaySlotHeight = 30;

function ScheduleScreenDay({ provider }: { provider: Provider }) {
    // Get Context
    const authContext: AuthContextType = useContext(AuthContext);

    // Translations
    const { t } = useTranslation();

    // Get navigation
    const navigate = useNavigate();

    // Get query
    const query = new URLSearchParams(window.location.search);
    const queryValues = {
        day: query.get('day'),
    };

    // Hold state
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [day, setDay] = useState<moment.Moment>(queryValues.day ? moment(queryValues.day) : moment());
    const [error, setError] = useState<boolean>(false);
    const [cache, setCache] = useState<Map<string, Array<Booking>>>(
        new Map<
            string,
            Array<
                Booking & {
                    user: User;
                }
            >
        >(),
    );
    const [bookings, setBookings] = useState<Array<Booking> | null>(null);

    // Action: Reset all
    const load = async () => {
        // Reset state
        setError(false);
        setBookings(null);

        // Look in cache
        const cached = cache.get(day.toISOString());

        // Eval
        if (cached) {
            // Set
            setBookings(cached);

            // Done
            return;
        } else {
            // Start loading
            setIsLoading(true);

            try {
                // Get bookings
                const results = await BookingsRepository.instance.getProviderBookings(
                    provider.id?.toString() ?? '',
                    day.clone().startOf('day').toDate(),
                    day.clone().endOf('day').toDate(),
                );

                // Set
                setBookings(results);

                // Set cache
                setCache(cache.set(day.toISOString(), results));

                // Stop loading
                setIsLoading(false);
            } catch (error) {
                // Set error
                setError(true);
            }
        }
    };

    // On Day change
    useEffect(() => {
        // Print
        if (day) {
            // Load
            load();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [day, cache]);

    // Calculate dayTotal
    let dayBookings: Array<Booking> = [];
    let dayServices: Array<string> = [];
    let dayClients: Array<string> = [];
    let dayTotal = 0;
    if (bookings) {
        for (const booking of bookings) {
            // Check service
            if (booking.serviceId && !dayServices.includes(booking.serviceId)) {
                dayServices.push(booking.serviceId);
            }

            // Check client
            if (booking.userId && !dayClients.includes(booking.userId)) {
                dayClients.push(booking.userId);
            }

            // Add to total
            dayTotal += booking.serviceData?.price ?? 0;

            // Add to bookings
            if (booking.userId) {
                dayBookings.push(booking);
            }
        }
    }

    // Calculate page title
    let pageTitle = t('routes.office.main.home.professional.schedule.title');
    if (provider.id === emptyId()) {
        pageTitle += ` | (${t('routes.office.main.home.professional.schedule.noProvider')})`;
    } else if (provider.id !== authContext.state.me?.provider?.id) {
        pageTitle += ` | ${provider.name.split(' ')[0]}`;
    }

    // Build
    return (
        <>
            <Page
                title={pageTitle}
                onRefresh={async () => {
                    // Clone cache
                    const cacheClone = new Map(cache);

                    // Reset cache
                    cacheClone.delete(day.toISOString());

                    // Update cache
                    setCache(cacheClone);

                    // Reset state
                    load();
                }}
                actions={[
                    <IconButton
                        aria-label="block-schedule"
                        onClick={() => {
                            // Replace state
                            navigate(`${window.location.pathname}?day=${day.format('YYYY-MM-DD')}`, {
                                replace: true,
                            });

                            // Go
                            navigate(`../../${RoutesNames.Office_ProviderNewEvent}?day=${day.format('YYYY-MM-DD')}`);
                        }}
                    >
                        <AddIcon />
                    </IconButton>,
                ]}
            >
                <Container>
                    {isLoading && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '32px',
                            }}
                        >
                            <Loader size={48} />
                        </Box>
                    )}
                    {!isLoading && !error && (
                        <>
                            {bookings && bookings.length === 0 && (
                                <EmptyState
                                    height={70}
                                    icon={<EventIcon />}
                                    title={t('routes.office.professional.schedule.emptyTitle')}
                                    description={t('routes.office.professional.schedule.emptyDescription')}
                                />
                            )}
                            {bookings &&
                                bookings.length > 0 &&
                                bookings.map((booking: Booking) => {
                                    // Build
                                    return (
                                        <BookingCard
                                            booking={booking}
                                            key={`booking-${booking.id?.toString()}`}
                                            onBookingUpdate={(booking: Booking) => {
                                                // Update state
                                                const newBookings = [...(bookings || [])];
                                                const bookingIndex = newBookings.findIndex((b) => b.id === booking.id);
                                                if (bookingIndex !== -1) {
                                                    newBookings[bookingIndex] = booking;
                                                }
                                                setBookings(newBookings);
                                            }}
                                        />
                                    );
                                })}
                            {bookings && bookings.length > 0 && (
                                <>
                                    <Typography
                                        variant="h5"
                                        textAlign="center"
                                        sx={{
                                            marginTop: '32px',
                                        }}
                                    >
                                        {t('routes.office.professional.schedule.summary')}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        textAlign="center"
                                        sx={{
                                            marginTop: '16px',
                                        }}
                                    >
                                        {`📋 ${t('routes.office.professional.schedule.services')}: ${
                                            dayBookings.length
                                        } (${dayServices.length})`}
                                    </Typography>
                                    <Typography variant="body1" textAlign="center" sx={{}}>
                                        {`🙎‍♀️ ${t('routes.office.professional.schedule.clients')}: ${dayClients.length}`}
                                    </Typography>
                                    <Typography variant="body1" textAlign="center" sx={{}}>
                                        {`💰 ${t(
                                            'routes.office.professional.schedule.expectedEarnings',
                                        )}: ${dayTotal.toFixed(2)}€`}
                                    </Typography>
                                </>
                            )}
                        </>
                    )}
                    {!isLoading && error && (
                        <ErrorState
                            icon={<EventIcon />}
                            onTryAgainClick={() => {
                                // Reset state
                                setError(false);
                            }}
                        />
                    )}
                    <Box
                        sx={{
                            height: '160px',
                        }}
                    ></Box>
                </Container>
            </Page>
            <TimeTraveller
                value={day}
                onChange={(value: moment.Moment) => {
                    setDay(value);
                }}
                disabled={isLoading}
            />
        </>
    );
}

function ScheduleScreenWeekDayEvent({
    booking,
    startTime,
    endTime,
    onBookingUpdate,
}: {
    booking: Booking;
    startTime: moment.Moment;
    endTime: moment.Moment;
    onBookingUpdate?: (booking: Booking) => void;
}) {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);

    // Get theme
    const theme = useTheme();

    // Translations
    const { t } = useTranslation();

    // Hold state
    const [eventAnchorElement, setEventAnchorElement] = useState<null | HTMLElement>(null);

    // Hold menu
    const eventMenuOpen = Boolean(eventAnchorElement);

    // Actions: Handle menu close
    const handleClose = () => {
        setEventAnchorElement(null);
    };

    // Get booking start
    let bookingStart = moment(booking.startDate);
    if (bookingStart.isBefore(startTime)) {
        bookingStart = startTime.clone();
    }

    // Get booking end
    let bookingEnd = moment(booking.endDate);
    if (bookingEnd.isAfter(endTime)) {
        bookingEnd = endTime.clone();
    }

    // Calculate how many 15 minutes slots the booking takes
    const bookingSlots = bookingEnd.diff(bookingStart, 'minutes') / 15;

    // Calculate height
    const height = bookingSlots * weekDaySlotHeight;

    // Define background color
    // Convert to RGB
    const mainRGB = hexToRgb(booking.userId ? variables.firstColor : variables.secondColor);
    const backRGB = hexToRgb(appContext.state.themeMode === ThemeMode.Light ? '#ffffff' : '#121212');

    // Build
    let background = `linear-gradient(270deg, rgba(${backRGB?.r}, ${backRGB?.g}, ${backRGB?.b}, 1) 1%, rgba(${mainRGB?.r}, ${mainRGB?.g}, ${mainRGB?.b}, 1) 100%)`;

    // Calculate booking duration in minutes
    const durationInMinutes = bookingEnd.diff(bookingStart, 'minutes');

    // Calculate diference between booking start and day start in minutes
    const startDiff = bookingStart.diff(startTime, 'minutes');

    // Calculate how many 15 minutes slots the difference takes
    const startSlots = startDiff / 15;

    // Calculate top
    const top = startSlots * weekDaySlotHeight + weekDaySlotHeight;

    // Define border
    let border = `1px solid ${theme.palette.divider}`;

    // Build
    return (
        <>
            <Box
                key={`booking-${moment(booking.startDate).format('HH:mm')}`}
                sx={{
                    width: '100%',
                    border,
                    height: `${height}px`,
                    background,
                    position: 'absolute',
                    top: `${top}px`,
                    opacity: 0.9,
                    padding: '1px',
                    fontSize: '14px',
                    overflow: 'hidden',
                }}
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setEventAnchorElement(event.currentTarget);
                }}
            >
                {/* {appContext.getDisplayDurationText(durationInMinutes)} */}
                {/* {moment(booking.endDate).format('HH:mm')} */}
                {booking.userId && (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <EditCalendarIcon
                                fontSize="small"
                                sx={{
                                    marginRight: '2px',
                                    fontSize: '12px',
                                }}
                            />
                            <Box
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                }}
                            >
                                {t('routes.office.providerNewEvent.appointment.title')}
                            </Box>
                        </Box>
                        {booking.service && durationInMinutes > 15 && (
                            <Box
                                sx={{
                                    display: 'block',
                                    overflow: 'hidden',
                                }}
                            >
                                <Box
                                    sx={{
                                        fontSize: '12px',
                                    }}
                                >
                                    {booking.service.name}
                                </Box>
                            </Box>
                        )}
                        {booking.user && durationInMinutes > 30 && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        fontSize: '12px',
                                    }}
                                >
                                    [{`${booking.user.firstName} ${booking.user.lastName}`.trim()}]
                                </Box>
                            </Box>
                        )}
                        {booking.notes && durationInMinutes > 45 && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        fontSize: '12px',
                                    }}
                                >
                                    "{booking.notes}"
                                </Box>
                            </Box>
                        )}
                    </>
                )}
                {!booking.userId && (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <LockClockIcon
                                fontSize="small"
                                sx={{
                                    marginRight: '2px',
                                    fontSize: '12px',
                                }}
                            />
                            <Box
                                sx={{
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                }}
                            >
                                {t('routes.office.providerNewEvent.lock.title')}
                            </Box>
                        </Box>
                        {booking.notes && durationInMinutes > 15 && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        fontSize: '12px',
                                    }}
                                >
                                    {booking.notes}
                                </Box>
                            </Box>
                        )}
                    </>
                )}
            </Box>
            <Menu
                anchorEl={eventAnchorElement}
                open={eventMenuOpen}
                onClose={handleClose}
                sx={{
                    padding: '0px !important',
                    ul: {
                        padding: '0px !important',
                        'div.MuiPaper-root': {
                            margin: '0px !important',
                        },
                    },
                }}
            >
                <BookingCard booking={booking} onBookingUpdate={onBookingUpdate} />
            </Menu>
        </>
    );
}

function ScheduleScreenWeekDay({
    start,
    end,
    day,
    workingBlocks,
    bookings,
    onBookingUpdate,
}: {
    start: string;
    end: string;
    day?: moment.Moment;
    workingBlocks?: Array<{
        // HH:MM
        start: string;
        end: string;
    }>;
    bookings?: Array<Booking>;
    onBookingUpdate?: (booking: Booking) => void;
}) {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);

    // Get theme
    const theme = useTheme();

    // Translations
    const { t } = useTranslation();

    // Define border
    let border = `1px solid ${theme.palette.divider}`;

    // Define day to use
    const dayToUse = day ? day : moment();

    // Define Start and End times
    const startTime = dayToUse
        ?.clone()
        .hour(parseInt(start.split(':')[0]))
        .minute(parseInt(start.split(':')[1]));
    const endTime = dayToUse
        ?.clone()
        .hour(parseInt(end.split(':')[0]))
        .minute(parseInt(end.split(':')[1]));

    // Create an array with every 15 minutes of the day between start and end
    const timeSlots = [];
    if (startTime) {
        for (let time = startTime.clone(); time.isBefore(endTime); time.add(15, 'minutes')) {
            timeSlots.push(time.clone());
        }
    }

    // Get bookings for the day
    const dayBookings =
        bookings?.filter((booking) => {
            // Check
            return (
                moment(booking.startDate).isSame(dayToUse, 'day') &&
                [BookingState.Completed, BookingState.Scheduled].includes(booking.state)
            );
        }) ?? [];

    // Calculate the opposite of the working blocks inside the start and end times
    const freeBlocks: Array<moment.Moment> = [];
    if (day) {
        for (let time = startTime.clone(); time.isBefore(endTime); time.add(15, 'minutes')) {
            // Flag
            let isFree = true;

            // For each working block
            for (const block of workingBlocks ?? []) {
                // Define block start and end
                const blockStart = dayToUse
                    .clone()
                    .hour(parseInt(block.start.split(':')[0]))
                    .minute(parseInt(block.start.split(':')[1]));
                const blockEnd = dayToUse
                    .clone()
                    .hour(parseInt(block.end.split(':')[0]))
                    .minute(parseInt(block.end.split(':')[1]));

                // Test it
                if (time.isSameOrAfter(blockStart) && time.isBefore(blockEnd)) {
                    // Failed
                    isFree = false;

                    // Stop loop
                    break;
                }
            }

            // Check
            if (isFree) {
                // Add to free blocks
                freeBlocks.push(time.clone());
            }
        }
    }

    // Build
    return (
        <Box
            sx={{
                flex: day ? 1 : 'none',
                width: !day ? '56px' : 'auto',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
            }}
        >
            {day && (
                <>
                    <Box
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                            border,
                            height: `${weekDaySlotHeight}px`,
                        }}
                    ></Box>
                    {timeSlots.map((time, index) => {
                        return (
                            <Box
                                key={`${day.format(
                                    t('routes.office.main.home.professional.schedule.weekDayDateFormat'),
                                )}-time-slot-${time.format('HH:mm')}`}
                                sx={{
                                    width: '100%',
                                    textAlign: 'center',
                                    border,
                                    height: `${weekDaySlotHeight}px`,
                                }}
                            ></Box>
                        );
                    })}
                </>
            )}
            {!day && (
                <>
                    <Box
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                            border,
                            height: `${weekDaySlotHeight}px`,
                        }}
                    ></Box>
                    {timeSlots.map((time, index) => {
                        return (
                            <Box
                                key={`label-time-slot-${time.format('HH:mm')}`}
                                sx={{
                                    width: '100%',
                                    textAlign: 'center',
                                    border,
                                    height: `${weekDaySlotHeight}px`,
                                }}
                            >
                                {time.format('HH:mm')}
                            </Box>
                        );
                    })}
                </>
            )}
            {freeBlocks.map((freeBlock, index) => {
                // Calculate diference between booking start and day start in minutes
                const startDiff = freeBlock.diff(startTime, 'minutes');

                // Calculate how many 15 minutes slots the difference takes
                const startSlots = startDiff / 15;

                // Calculate top
                const top = startSlots * weekDaySlotHeight + weekDaySlotHeight;

                // Build
                return (
                    <Box
                        key={`free-block-${index}`}
                        sx={{
                            width: '100%',
                            border,
                            height: `${weekDaySlotHeight}px`,
                            position: 'absolute',
                            top: `${top}px`,
                            opacity: 0.8,
                            padding: '1px',
                            fontSize: '14px',
                            overflow: 'hidden',
                            backgroundColor:
                                appContext.state.themeMode === ThemeMode.Light
                                    ? theme.palette.grey[300]
                                    : theme.palette.grey[900],
                            borderColor:
                                appContext.state.themeMode === ThemeMode.Light
                                    ? theme.palette.grey[300]
                                    : theme.palette.grey[900],
                        }}
                    >
                        {/* {appContext.getDisplayDurationText(durationInMinutes)} */}
                    </Box>
                );
            })}
            {dayBookings.map((booking, index) => {
                return (
                    <ScheduleScreenWeekDayEvent
                        booking={booking}
                        key={`booking-${index}`}
                        startTime={startTime}
                        endTime={endTime}
                        onBookingUpdate={onBookingUpdate}
                    />
                );
            })}
        </Box>
    );
}

function ScheduleScreenWeek({ provider }: { provider: Provider }) {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);

    // Translations
    const { t } = useTranslation();

    // Get theme
    const theme = useTheme();

    // Get navigation
    const navigate = useNavigate();

    // Get query
    const query = new URLSearchParams(window.location.search);
    const queryValues = {
        day: query.get('day'),
    };

    // Define Week first day initial value
    const weekFirstDayInitialValue = queryValues.day
        ? moment(queryValues.day).startOf('week')
        : moment().startOf('week');

    // Hold State
    const [weekFirstDay, setWeekFirstDay] = useState<moment.Moment>(weekFirstDayInitialValue);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [cache, setCache] = useState<Map<string, Array<Booking>>>(
        new Map<
            string,
            Array<
                Booking & {
                    user: User;
                }
            >
        >(),
    );
    const [bookings, setBookings] = useState<Array<Booking> | null>(null);

    // Find earliest and latest work hour
    let earliestWorkHour = weekFirstDay.clone().endOf('day');
    let latestWorkHour = weekFirstDay.clone().startOf('day');
    let _earliestWorkHour: string = '';
    let _latestWorkHour: string = '';

    // Map an array with all the days
    const allDays = provider.schedule ? Object.keys(provider.schedule) : [];
    for (const day of allDays) {
        if (!day.includes('_')) {
            // Map day
            const scheduleDay: Array<{
                start: string;
                end: string;
            }> = provider.schedule ? (provider.schedule as any)[day] : [];

            // For each block
            for (const block of scheduleDay) {
                // Split hours
                const start = block.start.split(':');
                const end = block.end.split(':');

                // Map day hours
                const startTime = weekFirstDay.clone().hour(parseInt(start[0])).minute(parseInt(start[1]));
                const endTime = weekFirstDay.clone().hour(parseInt(end[0])).minute(parseInt(end[1]));

                // Check
                if (startTime.isBefore(earliestWorkHour)) {
                    earliestWorkHour = startTime;
                    _earliestWorkHour = block.start;
                }
                if (endTime.isAfter(latestWorkHour)) {
                    latestWorkHour = endTime;
                    _latestWorkHour = block.end;
                }
            }
        }
    }

    // Define border
    let border = `1px solid ${theme.palette.divider}`;

    // Action: Reset all
    const load = async () => {
        // Reset state
        setError(false);
        setBookings(null);

        // Look in cache
        const cached = cache.get(weekFirstDay.toISOString());

        // Eval
        if (cached) {
            // Set
            setBookings(cached);

            // Done
            return;
        } else {
            // Start loading
            setIsLoading(true);

            try {
                // Get bookings
                const results = await BookingsRepository.instance.getProviderBookings(
                    provider.id?.toString() ?? '',
                    weekFirstDay.toDate(),
                    weekFirstDay.clone().endOf('week').toDate(),
                );

                // Set
                setBookings(results);

                // Set cache
                setCache(cache.set(weekFirstDay.toISOString(), results));

                // Stop loading
                setIsLoading(false);
            } catch (error) {
                // Set error
                setError(true);
            }
        }
    };

    // On weekFirstDay change
    useEffect(() => {
        // Print
        if (weekFirstDay) {
            // Load
            load();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weekFirstDay]);

    // Action: On Booking Update
    const onBookingUpdate = (booking: Booking) => {
        // Update state
        const newBookings = [...(bookings || [])];
        const bookingIndex = newBookings.findIndex((b) => b.id === booking.id);
        if (bookingIndex !== -1) {
            newBookings[bookingIndex] = booking;
        }
        setBookings(newBookings);
    };

    // Build
    return (
        <FullPage
            title={t('routes.office.main.home.professional.schedule.week', {
                weekNumber: weekFirstDay.week(),
            })}
            onRefresh={async () => {
                // Clone cache
                const cacheClone = new Map(cache);

                // Reset cache
                cacheClone.delete(weekFirstDay.toISOString());

                // Update cache
                setCache(cacheClone);

                // Reset state
                load();
            }}
            actions={[
                <IconButton
                    aria-label="block-schedule"
                    onClick={() => {
                        // Replace state
                        navigate(`${window.location.pathname}?day=${weekFirstDay.format('YYYY-MM-DD')}`, {
                            replace: true,
                        });

                        // Go
                        navigate(
                            `../../${RoutesNames.Office_ProviderNewEvent}?day=${weekFirstDay.format('YYYY-MM-DD')}`,
                        );
                    }}
                >
                    <AddIcon />
                </IconButton>,
                <IconButton
                    aria-label="previous week"
                    onClick={() => {
                        setWeekFirstDay(weekFirstDay.clone().subtract(1, 'week'));
                    }}
                >
                    <ArrowBackIosNewIcon />
                </IconButton>,
                <IconButton
                    aria-label="next week"
                    onClick={() => {
                        setWeekFirstDay(weekFirstDay.clone().add(1, 'week'));
                    }}
                >
                    <ArrowForwardIosIcon />
                </IconButton>,
            ]}
            headerBackground={variables.firstColor}
        >
            {earliestWorkHour.isBefore(latestWorkHour) && (
                <>
                    <Box
                        sx={{
                            paddingTop: `${56 + appContext.state.insets.top ?? 0}px`,
                            paddingBottom: `${16 + appContext.state.insets.bottom ?? 0}px`,
                            paddingLeft:
                                appContext.state.orientation === DeviceOrientation.LandscapeLeft
                                    ? `${appContext.state.insets.left ?? 0}px`
                                    : 0,
                            paddingRight:
                                appContext.state.orientation === DeviceOrientation.LandscapeRight
                                    ? `${appContext.state.insets.right ?? 0}px`
                                    : 0,
                            minHeight: '100%',
                            display: 'flex',
                        }}
                    >
                        <ScheduleScreenWeekDay start={_earliestWorkHour} end={_latestWorkHour} />
                        <ScheduleScreenWeekDay
                            start={_earliestWorkHour}
                            end={_latestWorkHour}
                            day={weekFirstDay}
                            workingBlocks={provider.schedule?.monday}
                            bookings={bookings || []}
                            onBookingUpdate={onBookingUpdate}
                        />
                        <ScheduleScreenWeekDay
                            start={_earliestWorkHour}
                            end={_latestWorkHour}
                            day={weekFirstDay.clone().add(1, 'day')}
                            workingBlocks={provider.schedule?.tuesday}
                            bookings={bookings || []}
                            onBookingUpdate={onBookingUpdate}
                        />
                        <ScheduleScreenWeekDay
                            start={_earliestWorkHour}
                            end={_latestWorkHour}
                            day={weekFirstDay.clone().add(2, 'day')}
                            workingBlocks={provider.schedule?.wednesday}
                            bookings={bookings || []}
                            onBookingUpdate={onBookingUpdate}
                        />
                        <ScheduleScreenWeekDay
                            start={_earliestWorkHour}
                            end={_latestWorkHour}
                            day={weekFirstDay.clone().add(3, 'day')}
                            workingBlocks={provider.schedule?.thursday}
                            bookings={bookings || []}
                            onBookingUpdate={onBookingUpdate}
                        />
                        <ScheduleScreenWeekDay
                            start={_earliestWorkHour}
                            end={_latestWorkHour}
                            day={weekFirstDay.clone().add(4, 'day')}
                            workingBlocks={provider.schedule?.friday}
                            bookings={bookings || []}
                            onBookingUpdate={onBookingUpdate}
                        />
                        <ScheduleScreenWeekDay
                            start={_earliestWorkHour}
                            end={_latestWorkHour}
                            day={weekFirstDay.clone().add(5, 'day')}
                            workingBlocks={provider.schedule?.saturday}
                            bookings={bookings || []}
                            onBookingUpdate={onBookingUpdate}
                        />
                        <ScheduleScreenWeekDay
                            start={_earliestWorkHour}
                            end={_latestWorkHour}
                            day={weekFirstDay.clone().add(6, 'day')}
                            workingBlocks={provider.schedule?.sunday}
                            bookings={bookings || []}
                            onBookingUpdate={onBookingUpdate}
                        />
                    </Box>
                    <Box
                        sx={{
                            position: 'fixed',
                            top: `${56 + appContext.state.insets.top ?? 0}px`,
                            backgroundColor: theme.palette.background.paper,
                            left:
                                appContext.state.orientation === DeviceOrientation.LandscapeLeft
                                    ? `${appContext.state.insets.left ?? 0}px`
                                    : 0,
                            right:
                                appContext.state.orientation === DeviceOrientation.LandscapeRight
                                    ? `${appContext.state.insets.right ?? 0}px`
                                    : 0,
                            display: 'flex',
                            height: `${weekDaySlotHeight}px`,
                        }}
                    >
                        <Box
                            sx={{
                                width: '56px',
                                border,
                                overflow: 'hidden',
                                textAlign: 'center',
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {t('routes.office.main.home.professional.schedule.hours')}
                        </Box>
                        {[0, 1, 2, 3, 4, 5, 6].map((index) => {
                            // Get day
                            const day = weekFirstDay.clone().add(index, 'day');
                            const isBeforeToday = day.isBefore(moment().startOf('day'));
                            const isToday = day.isSame(moment().startOf('day'), 'day');
                            return (
                                <Box
                                    key={`week-day-${index}`}
                                    sx={{
                                        flex: 1,
                                        border,
                                        overflow: 'hidden',
                                        textAlign: 'center',
                                        backgroundColor: theme.palette.background.paper,
                                        opacity: isBeforeToday ? 0.5 : 1,
                                        fontSize: '14px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: isToday ? theme.palette.primary.main : 'transparent',
                                            color: isToday ? theme.palette.primary.contrastText : 'inherit',
                                            borderRadius: '16px',
                                            paddingLeft: '4px',
                                            paddingRight: '4px',
                                        }}
                                    >
                                        {`${day.format('ddd')} ${day.format(
                                            t('routes.office.main.home.professional.schedule.weekDayDateFormat'),
                                        )}`}
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                    {isLoading && (
                        <Box
                            sx={{
                                position: 'fixed',
                                top: `${56 + appContext.state.insets.top ?? 0}px`,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    bottom: 0,
                                    backgroundColor: theme.palette.background.paper,
                                    left: 0,
                                    right: 0,
                                    opacity: 0.5,
                                }}
                            ></Box>
                            <Loader size={64} />
                        </Box>
                    )}
                    {error && (
                        <Box
                            sx={{
                                position: 'fixed',
                                top: `${56 + appContext.state.insets.top ?? 0}px`,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    bottom: 0,
                                    backgroundColor: theme.palette.background.paper,
                                    left: 0,
                                    right: 0,
                                    opacity: 0.5,
                                }}
                            ></Box>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <ErrorIcon color="error" fontSize="large" />
                            </Box>
                        </Box>
                    )}
                </>
            )}
        </FullPage>
    );
}

export function ScheduleScreen({ provider }: { provider: Provider }) {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);

    // Actions: Resize
    const calculateViewModeBasedOnWindowSize = (orientation?: DeviceOrientation): 'day' | 'week' => {
        // Set view mode
        if (orientation) {
            // Set view mode
            if (orientation === DeviceOrientation.LandscapeLeft || orientation === DeviceOrientation.LandscapeRight) {
                // Landscape
                return 'week';
            } else {
                // Portrait
                return 'day';
            }
        } else if (window.innerWidth > window.innerHeight) {
            // Landscape
            return 'week';
        } else {
            // Portrait
            return 'day';
        }
    };

    // Hold state
    const [viewMode, setViewMode] = useState<'day' | 'week'>(calculateViewModeBasedOnWindowSize());

    // Actions: Resize
    const handleResize = () => {
        // Set view mode
        setViewMode(calculateViewModeBasedOnWindowSize());
    };
    const handleResizeNative = (orientation: DeviceOrientation) => {
        // Set view mode
        setViewMode(calculateViewModeBasedOnWindowSize(orientation));
    };

    // On mount
    useEffect(() => {
        // Check OS
        if (appContext.state.operativeSystem !== OperativeSystem.iOS) {
            // Add event listener
            window.addEventListener('resize', handleResize);
        } else {
            // Add event listener
            NativeKnightEventEmitter.on(NativeKnightEvents.EVENT_ORIENTATION_CHANGED, handleResizeNative);
        }

        // On unmount
        return () => {
            // Check OS
            if (appContext.state.operativeSystem !== OperativeSystem.iOS) {
                // Remove event listener
                window.removeEventListener('resize', handleResize);
            } else {
                // Remove event listener
                NativeKnightEventEmitter.off(NativeKnightEvents.EVENT_ORIENTATION_CHANGED, handleResizeNative);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Eval
    if (viewMode === 'week') {
        return <ScheduleScreenWeek provider={provider} />;
    } else {
        return <ScheduleScreenDay provider={provider} />;
    }
}
