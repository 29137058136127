export enum ThemeMode {
    /**
     * Light
     */
    Light = 'light',

    /**
     * Dark
     */
    Dark = 'dark',
}
