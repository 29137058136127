import ClearIcon from '@mui/icons-material/Clear';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import EventIcon from '@mui/icons-material/Event';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PinDropIcon from '@mui/icons-material/PinDrop';
import ReviewsIcon from '@mui/icons-material/Reviews';
import SearchIcon from '@mui/icons-material/Search';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import {
    Box,
    Button,
    FormControl,
    Grow,
    IconButton,
    InputLabel,
    OutlinedInput,
    Paper,
    Rating,
    Typography,
    useTheme,
} from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { EmptyState } from '../../../../components/empty-state/empty-state';
import { ErrorState } from '../../../../components/error-state/error-state';
import { ImagesStepper } from '../../../../components/images-stepper/images-stepper';
import { Loader } from '../../../../components/loader/loader';
import { LocationSearchBox } from '../../../../components/location-search-box/location-search-box';
import { MainOfficePage } from '../../../../components/main-office-page/main-office-page';
import { AppContext } from '../../../../contexts/app/context';
import { AppContextType } from '../../../../contexts/app/types';
import { ServiceCategories } from '../../../../databases/service-categories';
import { CountryCode } from '../../../../enums/country-code';
import { ServiceCategory } from '../../../../enums/service-category';
import { Provider } from '../../../../interfaces/provider';
import { ProvidersRepository } from '../../../../repositories/providers';
import { RoutesNames } from '../../../../routes-names';
import { cosineDistanceBetweenPoints } from '../../../../utils/calculate-geo-distance';
import { calculateTravelTime } from '../../../../utils/calculate-travel-time';
import { calculateTravelTimeInMinutes } from '../../../../utils/calculate-travel-time-in-minutes';
import { useQuery } from '../../../../utils/use-query';
import variables from '../../../../variables.module.scss';

function ProviderCard({ provider }: { provider: Provider }) {
    // Get context
    const appContext: AppContextType = useContext(AppContext);

    // Translations
    const { t } = useTranslation();

    // Get navigation
    const navigate = useNavigate();

    // Get theme
    const theme = useTheme();

    // In view hook
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
        triggerOnce: true,
    });

    // Provider subtitle
    let providerSubtitleParts = [];
    if (provider.city) {
        // Add City name
        providerSubtitleParts.push(provider.city.name);
    }
    if (provider.birthday) {
        // Add City name
        providerSubtitleParts.push(
            t('routes.office.book.providerBirthday', {
                years: moment().diff(provider.birthday, 'years'),
            }),
        );
    }
    if (provider.countryCode) {
        // Add City name
        switch (provider.countryCode) {
            case CountryCode.PT:
                providerSubtitleParts.push('🇵🇹');
                break;
            case CountryCode.BR:
                providerSubtitleParts.push('🇧🇷');
                break;
        }
    }

    // Calculate distance
    let distance = 0;
    try {
        distance = cosineDistanceBetweenPoints(
            appContext.state.userLocation.coordinates[1],
            appContext.state.userLocation.coordinates[0],
            provider.location?.coordinates[1] ?? 0,
            provider.location?.coordinates[0] ?? 0,
        );
    } catch (e) {}

    // Distance warning enabled
    let distanceWarningEnabled: boolean = false;
    if (appContext.state.userLocation.cityId !== provider.cityId && distance > 10000) {
        // Enable
        distanceWarningEnabled = true;
    }

    // serviceCategories
    const serviceCategories: Array<ServiceCategory> = [];
    if (provider.serviceCategories) {
        // Add service categories
        for (const data of Object.values(ServiceCategories)) {
            if (provider.serviceCategories.includes(data.category)) {
                serviceCategories.push(data.category);
            }
        }
    }

    // Build
    return (
        <Grow in={true} mountOnEnter unmountOnExit ref={ref}>
            <Paper>
                <Box
                    sx={{
                        position: 'relative',
                    }}
                >
                    {(!provider.photosUrls || provider.photosUrls.length === 0) && (
                        <Box
                            sx={{
                                height: '30vh',
                                backgroundColor: theme.palette.divider,
                                overflow: 'hidden',
                            }}
                        >
                            {inView && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '16px',
                                        justifyContent: 'space-between',
                                        rotate: '15deg',
                                        marginLeft: '-20%',
                                        marginTop: '-20%',
                                        opacity: 0.3,
                                    }}
                                >
                                    {Array.from({ length: 120 }).map((_, index) => (
                                        <span key={`provider-${provider.id}-award-${index}`}>💛</span>
                                    ))}
                                </Box>
                            )}
                        </Box>
                    )}
                    {provider.photosUrls && provider.photosUrls.length > 0 && (
                        <Box
                            sx={{
                                aspectRatio: '2 / 1',
                            }}
                        >
                            {inView && <ImagesStepper className="provider-tile" imagesUrls={provider.photosUrls} />}
                        </Box>
                    )}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        position: 'relative',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '-50px',
                            left: '8px',
                            width: '100px',
                            height: '100px',
                            borderRadius: '50%',
                            overflow: 'hidden',
                            border: '4px solid',
                            borderColor: variables.firstColor,
                        }}
                    >
                        {provider.pictureUrl && (
                            <img
                                src={provider.pictureUrl}
                                alt={provider.name}
                                style={{
                                    objectFit: 'cover',
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                        )}
                        {!provider.pictureUrl && (
                            <Box
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    backgroundColor: theme.palette.background.paper,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '48px',
                                }}
                            >
                                👩‍⚕️
                            </Box>
                        )}
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            paddingLeft: '116px',
                            paddingTop: '4px',
                        }}
                    >
                        <Typography variant="h6">{provider.name}</Typography>
                        <Typography variant="subtitle2">{providerSubtitleParts.join(', ').trim()}</Typography>
                    </Box>
                    {serviceCategories.length > 0 && (
                        <Box
                            sx={{
                                paddingTop: '8px',
                                paddingLeft: '116px',
                                display: 'flex',
                                gap: '8px',
                                flexWrap: 'wrap',
                                marginBottom: '8px',
                            }}
                        >
                            {serviceCategories.map((serviceCategory, index) => {
                                return (
                                    <Grow
                                        in={true}
                                        key={`service-category-${index}`}
                                        style={{
                                            transitionDelay: `${index * 100}ms`,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                paddingLeft: '8px',
                                                paddingRight: '8px',
                                                paddingTop: '2px',
                                                paddingBottom: '2px',
                                                backgroundColor: ServiceCategories[serviceCategory].color,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderRadius: '24px',
                                                color: 'white',
                                            }}
                                        >
                                            <Typography variant="caption" fontWeight={500}>
                                                {t(ServiceCategories[serviceCategory].translationKey)}
                                            </Typography>
                                        </Box>
                                    </Grow>
                                );
                            })}
                        </Box>
                    )}
                </Box>
                {/* // 1: Distance */}
                {distance && (
                    <Box>
                        {/* <Divider /> */}
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '8px',
                            }}
                        >
                            <Box
                                sx={{
                                    padding: '8px',
                                    display: 'flex',
                                    gap: '8px',
                                    backgroundColor: distanceWarningEnabled
                                        ? theme.palette.warning.main
                                        : 'transparent',
                                    color: distanceWarningEnabled ? theme.palette.warning.contrastText : 'unset',
                                    borderRadius: distanceWarningEnabled ? '24px' : '0',
                                }}
                            >
                                <PinDropIcon />
                                {distance >= 1000 && (
                                    <Typography variant="body1">{Math.round(distance / 1000)} km</Typography>
                                )}
                                {distance < 1000 && <Typography variant="body1">{Math.round(distance)} m</Typography>}
                            </Box>
                            <Box
                                sx={{
                                    padding: '8px',
                                    display: 'flex',
                                    gap: '8px',
                                }}
                            >
                                <DirectionsCarIcon />
                                <Typography variant="body1">{calculateTravelTime(distance, 40)}</Typography>
                            </Box>

                            {calculateTravelTimeInMinutes(distance, 3.2) <= 60 && (
                                <Box
                                    sx={{
                                        padding: '8px',
                                        display: 'flex',
                                        gap: '8px',
                                    }}
                                >
                                    <DirectionsWalkIcon />
                                    <Typography variant="body1">{calculateTravelTime(distance, 3.2)}</Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}
                <Box>
                    {/* <Divider /> */}
                    <Box
                        sx={{
                            padding: '8px',
                            display: 'flex',
                            gap: '8px',
                        }}
                    >
                        <ReviewsIcon />
                        <Typography variant="body1">{`${(provider.averageRating ?? 0).toFixed(1)}`}</Typography>
                        <Rating name="read-only" value={provider.averageRating} readOnly />
                        <Typography variant="body1">{`(${provider.totalReviews ?? 0})`}</Typography>
                    </Box>
                </Box>
                {/* // ACTION */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    {/* <Divider /> */}
                    <Box
                        sx={{
                            padding: '8px',
                        }}
                    >
                        <Button
                            variant="contained"
                            endIcon={<NavigateNextIcon />}
                            fullWidth
                            onClick={() => {
                                // Navigate
                                navigate(`../../${RoutesNames.Office_Showcase}/${provider.id}?services=true`);
                            }}
                        >
                            {t('routes.office.book.selectProfessional', {
                                name: provider.name.split(' ')[0],
                            })}
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Grow>
    );
}

export function OfficeMainProfessionals() {
    // Get context
    const appContext: AppContextType = useContext(AppContext);

    // Translations
    const { t } = useTranslation();

    // Get navigation
    const navigate = useNavigate();

    // Read query
    const queryStringValues = useQuery();

    // Hold state
    const [error, setError] = useState<boolean>(false);
    const [totalDocumentsCount, setTotalDocumentsCount] = useState<number>(-1);
    const [documents, setDocuments] = useState<Array<Provider> | null>(null);
    const [query, setQuery] = useState(queryStringValues.get('query') || '');
    const queryDebouncedValue = useDebounce<string>(query, 500);

    // Refs
    const searchInputRef: any = useRef(null);

    // Actions: Handle Load More
    const handleLoadMore = async (page: number) => {
        try {
            // Get
            const results: { totalCount: number; data: Array<Provider> } =
                await ProvidersRepository.instance.getProviders(
                    documents?.length ?? 0,
                    10,
                    appContext.state.userLocation.coordinates[1],
                    appContext.state.userLocation.coordinates[0],
                    query && query.trim() !== '' ? query : undefined,
                    true,
                );

            // Update state
            setTotalDocumentsCount(results.totalCount);
            setDocuments([...(documents || []), ...results.data]);
        } catch (error) {
            // Log
            console.error(error);

            // Update state
            setError(true);
        } finally {
            // Fix iOS empty scroll problem
            appContext.fixIosEmptyScrollProblem();
        }
    };

    // Action: Reset all
    const resetState = async () => {
        // Reset state
        setError(false);
        setDocuments(null);
        setTotalDocumentsCount(-1);
    };

    // On Input Change
    useEffect(() => {
        // Replace state
        if (queryDebouncedValue?.trim() !== '') {
            navigate(`${window.location.pathname}?query=${queryDebouncedValue}`, {
                replace: true,
            });
        } else {
            navigate(`${window.location.pathname}`, {
                replace: true,
            });
        }

        // Reset state (and fire search by consequence)
        resetState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryDebouncedValue]);

    // Build
    return (
        <MainOfficePage
            title={[t('routes.office.main.professionals.titleLine1'), t('routes.office.main.professionals.titleLine2')]}
            color={variables.seventhColor}
            onRefresh={async () => {
                // Reset state
                resetState();
            }}
            actions={[
                <IconButton
                    aria-label="search-professional"
                    onClick={() => {
                        // Scroll to top
                        window.scrollTo(0, 0);

                        // Focus on input
                        if (searchInputRef && searchInputRef.current) {
                            searchInputRef.current.focus();
                        }
                    }}
                >
                    <SearchIcon />
                </IconButton>,
            ]}
        >
            <Box
                sx={{
                    paddingBottom: '24px',
                    position: 'relative',
                    top: '-24px',
                }}
            >
                {!error && (
                    <>
                        {/* Location */}
                        <Box
                            sx={{
                                // border: '1px solid #ff66c4',
                                marginBottom: '16px',
                            }}
                        >
                            <LocationSearchBox />
                        </Box>
                        <FormControl
                            fullWidth
                            sx={{
                                marginBottom: '16px',
                            }}
                        >
                            <InputLabel id="service-picker-input-label">
                                {t('routes.office.main.professionals.search')}
                            </InputLabel>
                            <OutlinedInput
                                inputRef={searchInputRef}
                                size="small"
                                id="service-picker-input"
                                label={t('routes.office.main.professionals.search')}
                                placeholder={t('routes.office.main.professionals.placeholder')}
                                fullWidth
                                startAdornment={
                                    <SearchIcon
                                        sx={{
                                            marginRight: '8px',
                                        }}
                                    />
                                }
                                autoFocus={false}
                                value={query}
                                onChange={(event) => {
                                    // Update
                                    setQuery(event.target.value);
                                }}
                                endAdornment={
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginRight: '-8px',
                                        }}
                                    >
                                        {query && (
                                            <IconButton
                                                aria-label="clear"
                                                onClick={() => {
                                                    // Clear Value
                                                    setQuery('');
                                                }}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        )}
                                    </Box>
                                }
                            />
                        </FormControl>
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={handleLoadMore}
                            hasMore={documents?.length !== totalDocumentsCount}
                            loader={
                                <Box
                                    key={`documents-loader`}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '32px',
                                    }}
                                >
                                    <Loader size={48} />
                                </Box>
                            }
                        >
                            {documents?.length === 0 && (
                                <EmptyState
                                    height={60}
                                    key={`professionals-empty`}
                                    icon={<VolunteerActivismIcon />}
                                    title={t('routes.office.main.professionals.empty.title')}
                                    description={t('routes.office.main.professionals.empty.description')}
                                />
                            )}
                            {documents && documents?.length > 0 && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '24px',
                                    }}
                                >
                                    {documents?.map((provider: Provider, index: number) => {
                                        return <ProviderCard key={`booking-${index}`} provider={provider} />;
                                    })}
                                </Box>
                            )}
                        </InfiniteScroll>
                    </>
                )}
                {error && (
                    <ErrorState
                        icon={<EventIcon />}
                        onTryAgainClick={() => {
                            // Reset state
                            setError(false);
                        }}
                    />
                )}
            </Box>
        </MainOfficePage>
    );
}
