import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { DeviceOrientation } from '../../enums/device-orientation';
import { LocalStorageKey } from '../../enums/local-storage-key';
import { OperativeSystem } from '../../enums/operative-system';
import { ThemeMode } from '../../enums/theme-mode';
import { ThemeOption } from '../../enums/theme-option';
import { Provider } from '../../interfaces/provider';
import { ProviderService } from '../../interfaces/provider-service';
import { SafeAreaInsets } from '../../interfaces/safe-area-insets';
import { Service } from '../../interfaces/service';
import { BackendApiManager } from '../../managers/backend-api';
import { LocationManager } from '../../managers/location';
import { NativeKnight } from '../../native-knight';
import { CitiesRepository } from '../../repositories/cities';
import { darkTheme } from '../../themes/dark';
import { lightTheme } from '../../themes/light';
import { Logger } from '../../utils/logger';
import { AppContext } from './context';
import reducer from './reducer';
import { AppContextActionType, AppContextState, UserLocation } from './types';

// Logger
const logger: Logger = new Logger('AppContext');

// Set Initial State
const initialState: AppContextState = {
    sessionWasRestored: true,
    appIsReady: false,
    operativeSystem: OperativeSystem._Unknown,
    themeOption: undefined,
    themeMode: undefined,
    theme: undefined,
    insets: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    orientation: undefined,
    nativeKnightApiVersion: undefined,
    sessionId: undefined,
    sessionStartedAt: undefined,
    appVersion: undefined,
    appBuild: undefined,
    userLocation: {
        cityId: '-1',
        isPrecise: false,
        name: 'n/a',
        coordinates: [0, 0],
    },
    // Schedule Appointment state package
    services: [],
    appointment: undefined,
};

// Helper: Get current theme
const getThemeForMode = (themeMode: ThemeMode): any => {
    // Set theme
    if (themeMode === ThemeMode.Light) {
        // Set theme
        return lightTheme;
    } else if (themeMode === ThemeMode.Dark) {
        // Set theme
        return darkTheme;
    }
};

export const AppContextProvider = (props: {
    children: React.ReactNode;
    os?: OperativeSystem;
    themeMode?: ThemeMode;
    nativeKnightApiVersion?: number;
    sessionId?: string;
    appVersion?: string;
    appBuild?: string;
}) => {
    // Translations
    const { t } = useTranslation();

    // Init functions
    const initOperativeSystem = async (operativeSystem: OperativeSystem): Promise<void> => {
        // Set operative system
        initialState.operativeSystem = operativeSystem;

        // Informe Native Knight about the operative system being used
        NativeKnight.instance.init(initialState.operativeSystem);

        // Inform Backend API Manager about the operative system being used
        BackendApiManager.instance.init(initialState.operativeSystem);

        // Save it to local storage for future use
        localStorage.setItem(LocalStorageKey.OperativeSystem, initialState.operativeSystem);

        // Log
        logger.debug('Current Operative System:', initialState.operativeSystem);
    };

    // Check operative system
    if (props.os && Object.values(OperativeSystem).includes(props.os) && props.os !== initialState.operativeSystem) {
        // Init operative system
        initOperativeSystem(props.os);

        // Save it to local storage for future use
        localStorage.setItem(LocalStorageKey.OperativeSystem, props.os);

        // Set session as new
        initialState.sessionWasRestored = false;
    } else {
        // Get operative system from local storage
        const operativeSystem: string | null = localStorage.getItem(LocalStorageKey.OperativeSystem);

        // If the local storage key is valid, override the current operative system. Otherwise, use the default one.
        if (operativeSystem && Object.values(OperativeSystem).includes(operativeSystem as any)) {
            // Init operative system
            initOperativeSystem(operativeSystem as any);
        }
    }

    // Check theme mode
    if (!initialState.themeOption || !initialState.themeMode) {
        // Evaluate
        if (props.themeMode && Object.values(ThemeMode).includes(props.themeMode)) {
            // Set theme mode
            initialState.themeOption = ThemeOption.System;
            initialState.themeMode = props.themeMode;
        } else {
            // Get user preference
            const userPreferredThemeMode: string | null = localStorage.getItem(LocalStorageKey.ThemeMode);

            // If the local storage key is valid, override the current them mode. Otherwise, use the system theme.
            if (userPreferredThemeMode === ThemeMode.Dark || userPreferredThemeMode === ThemeMode.Light) {
                // Set user preferences
                initialState.themeOption = userPreferredThemeMode as any;
                initialState.themeMode = userPreferredThemeMode;
            } else {
                // Set system theme (https://stackoverflow.com/a/57795495/13157395)
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    // Go dark mode
                    initialState.themeOption = ThemeOption.System;
                    initialState.themeMode = ThemeMode.Dark;
                } else {
                    // Go Light
                    initialState.themeOption = ThemeOption.System;
                    initialState.themeMode = ThemeMode.Light;
                }
            }
        }

        // Apply theme
        initialState.theme = getThemeForMode(initialState.themeMode);

        // Log
        logger.debug('Current theme mode:', initialState.themeMode);
    }

    // Check Version
    if (props.nativeKnightApiVersion) {
        // Set version
        initialState.nativeKnightApiVersion = parseInt(props.nativeKnightApiVersion.toString());

        // Save it to local storage for future use
        localStorage.setItem(LocalStorageKey.NativeKnightApiVersion, initialState.nativeKnightApiVersion.toString());

        // Log
        logger.debug('Current Native Knight Api Version:', initialState.nativeKnightApiVersion);
    } else {
        // Get version from local storage
        const nativeKnightApiVersion: string | null = localStorage.getItem(LocalStorageKey.NativeKnightApiVersion);

        // If the local storage key is valid, override value.
        if (nativeKnightApiVersion) {
            // Set version
            initialState.nativeKnightApiVersion = parseInt(nativeKnightApiVersion);

            // Log
            logger.debug('Current Native Knight Api Version:', initialState.nativeKnightApiVersion);
        }
    }

    // Check Session Id
    if (props.sessionId) {
        // Set session id
        initialState.sessionId = props.sessionId;
        initialState.sessionStartedAt = new Date();

        // Save it to local storage for future use
        localStorage.setItem(LocalStorageKey.SessionId, initialState.sessionId);
        localStorage.setItem(LocalStorageKey.SessionStartedAt, initialState.sessionStartedAt.toString());

        // Log
        logger.debug('Current Session Id:', initialState.sessionId);
        logger.debug('Session Started At:', initialState.sessionStartedAt);
    } else {
        // Get session id from local storage
        const sessionId: string | null = localStorage.getItem(LocalStorageKey.SessionId);
        const sessionStartedAt: string | null = localStorage.getItem(LocalStorageKey.SessionStartedAt);

        // If the local storage key is valid, override value.
        if (sessionId && sessionStartedAt) {
            // Set session id
            initialState.sessionId = sessionId;
            initialState.sessionStartedAt = new Date(sessionStartedAt);

            // Log
            logger.debug('Current Session Id:', initialState.sessionId);
            logger.debug('Session Started At:', initialState.sessionStartedAt);
        }
    }

    // Check App Version
    if (props.appVersion) {
        // Set app version
        initialState.appVersion = props.appVersion;

        // Save it to local storage for future use
        localStorage.setItem(LocalStorageKey.AppVersion, initialState.appVersion);

        // Log
        logger.debug('Current App Version:', initialState.appVersion);
    } else {
        // Get app version from local storage
        const appVersion: string | null = localStorage.getItem(LocalStorageKey.AppVersion);

        // If the local storage key is valid, override value.
        if (appVersion) {
            // Set app version
            initialState.appVersion = appVersion;

            // Log
            logger.debug('Current App Version:', initialState.appVersion);
        }
    }

    // Check App Build
    if (props.appBuild) {
        // Set app build
        initialState.appBuild = props.appBuild;

        // Save it to local storage for future use
        localStorage.setItem(LocalStorageKey.AppBuild, initialState.appBuild);

        // Log
        logger.debug('Current App Build:', initialState.appBuild);
    } else {
        // Get app build from local storage
        const appBuild: string | null = localStorage.getItem(LocalStorageKey.AppBuild);

        // If the local storage key is valid, override value.
        if (appBuild) {
            // Set app build
            initialState.appBuild = appBuild;

            // Log
            logger.debug('Current App Build:', initialState.appBuild);
        }
    }

    // Create Reducer
    const [state, dispatch] = useReducer(reducer, initialState);

    // Configure backend managers
    const init = async (): Promise<any> => {};

    // Change theme mode
    const changeThemeMode = (mode: ThemeMode) => {
        // Dispatch change
        dispatch({
            type: AppContextActionType.EVENT_THEME_MODE_CHANGED,
            themeOption: ThemeOption.System,
            themeMode: mode,
            theme: getThemeForMode(mode),
        });
    };

    // Set app as ready
    const setAppAsReady = async (
        orientation: DeviceOrientation | null,
        insets: any | null,
        userLocation: UserLocation,
        services: Array<Service>,
    ): Promise<any> => {
        // Dispatch change
        dispatch({
            type: AppContextActionType.APP_READY,
            appIsReady: true,
            orientation: orientation ?? DeviceOrientation.Portrait,
            insets: insets ?? initialState.insets,
            userLocation,
            services,
        });
    };

    // Get default side padding value
    const getDefaultSidePaddingValue = (): number => {
        // Check os
        if (state.operativeSystem === OperativeSystem.Android) {
            // Return
            return 16;
        } else if (state.operativeSystem === OperativeSystem.iOS) {
            // Return
            return 10;
        } else {
            // Return
            return 16;
        }
    };

    const getDeviceUniqueId = (): string => {
        // Define storage key
        const storageKey = 'device-unique-id';

        // Get from local storage
        let deviceUniqueId = localStorage.getItem(storageKey);

        // Check if it exists
        if (!deviceUniqueId) {
            // Create it now
            deviceUniqueId = uuidv4().replaceAll('-', '_');

            // Save it
            localStorage.setItem(storageKey, deviceUniqueId);
        }

        // Return
        return deviceUniqueId;
    };

    const changeOrientation = (orientation: DeviceOrientation, insets: SafeAreaInsets): void => {
        // Dispatch change
        dispatch({
            type: AppContextActionType.EVENT_ORIENTATION_CHANGED,
            orientation,
            insets,
        });
    };

    const getPreciseLocation = async (): Promise<UserLocation | null> => {
        // Check os
        if (state.operativeSystem !== OperativeSystem._Unknown) {
            // Precise location available?
            const isPreciseLocationAvailable = state.nativeKnightApiVersion && state.nativeKnightApiVersion >= 2;

            // Get Location
            const result: [number, number] | null = isPreciseLocationAvailable
                ? await NativeKnight.instance.getLocation()
                : null;

            // Eval
            if (result && result.length === 2 && result[0] !== 0 && result[1] !== 0) {
                // Get Nearest City
                let cities = undefined;
                try {
                    cities = await CitiesRepository.instance.getCities(0, 1, undefined, result[0], result[1]);
                } catch (error) {}

                // Eval
                if (cities && cities.data.length > 0) {
                    // Set Value
                    const info: UserLocation = {
                        cityId: cities.data[0].id ?? '',
                        name: `(${t('components.locationPicker.aroundMe')})`, // cities.data[0].name,
                        // administratorCityName: cities.data[0].administratorCityName,
                        isPrecise: true,
                        coordinates: [result[1], result[0]],
                    };

                    // Done
                    return info;
                } else {
                    // Set Value
                    const info: UserLocation = {
                        cityId: '0',
                        name: `(${t('components.locationPicker.aroundMe')})`,
                        isPrecise: true,
                        coordinates: [result[1], result[0]],
                    };

                    // Done
                    return info;
                }
            }
        } else {
            // Check
            if (navigator.geolocation) {
                // Get Location
                const promise = new Promise<GeolocationPosition>((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(
                        async (position) => {
                            resolve(position);
                        },
                        (error) => {
                            reject(error);
                        },
                    );
                });

                try {
                    // Wait
                    const position = await promise;

                    // Get Nearest City
                    let cities = undefined;
                    try {
                        cities = await CitiesRepository.instance.getCities(
                            0,
                            1,
                            undefined,
                            position.coords.latitude,
                            position.coords.longitude,
                        );
                    } catch (error) {}

                    // Eval
                    if (cities && cities.data.length > 0) {
                        // Set Value
                        const info: UserLocation = {
                            cityId: cities.data[0].id ?? '',
                            name: `(${t('components.locationPicker.aroundMe')})`, // cities.data[0].name,
                            // administratorCityName: cities.data[0].administratorCityName,
                            isPrecise: true,
                            coordinates: [position.coords.longitude, position.coords.latitude],
                        };

                        // Done
                        return info;
                    } else {
                        // Set Value
                        const info: UserLocation = {
                            cityId: '0',
                            name: `(${t('components.locationPicker.aroundMe')})`,
                            isPrecise: true,
                            coordinates: [position.coords.longitude, position.coords.latitude],
                        };

                        // Done
                        return info;
                    }
                } catch (error: any) {
                    // Log Error
                    console.error('Error getting location', error.message);

                    // No go
                    return null;
                }
            } else {
                // Log
                console.error('Geolocation is not supported by this browser.');

                // No go
                return null;
            }
        }

        // No go
        return null;
    };

    const getLocation = async (
        preciseLocation: boolean,
    ): Promise<
        UserLocation & {
            wasRestored: boolean;
        }
    > => {
        // Look for previous location on storage
        const currentPlaceData = localStorage.getItem(LocalStorageKey.CurrentPlace);

        // Eval
        if (currentPlaceData) {
            // Parse Data
            const currentPlace: UserLocation = JSON.parse(currentPlaceData);

            // Done
            return {
                ...currentPlace,
                wasRestored: true,
            };
        } else {
            // Get Location
            const location = await LocationManager.instance.getLocationFromIp();

            // Set Value
            if (location) {
                // Find City by Coordinates
                let cities = undefined;
                try {
                    cities = await CitiesRepository.instance.getCities(0, 1, undefined, location[0], location[1]);
                } catch (error) {}

                // Eval
                if (cities && cities.data.length > 0) {
                    // Build info
                    let info: UserLocation = {
                        cityId: cities.data[0].id ?? '',
                        name: cities.data[0].name,
                        administratorCityName: cities.data[0].administratorCityName,
                        isPrecise: false,
                        coordinates: cities.data[0].location?.coordinates || [0, 0],
                    };

                    // Check if we need to get precise location
                    if (preciseLocation) {
                        // Try to obtain precise location
                        const preciseLocation: UserLocation | null = await getPreciseLocation();

                        // Eval
                        if (preciseLocation) {
                            // Replace info
                            info = preciseLocation;
                        }
                    }

                    // Save info
                    localStorage.setItem(LocalStorageKey.CurrentPlace, JSON.stringify(info));

                    // Set Value
                    return {
                        ...info,
                        wasRestored: false,
                    };
                }
            }
        }

        // Default
        return {
            cityId: '0',
            name: `(${t('components.locationPicker.unknownLocation')})`,
            isPrecise: false,
            coordinates: [0, 0],
            wasRestored: false,
        };
    };

    const getServices = (): Array<Service> => {
        // Look for previous service on storage
        const currentServicesData = localStorage.getItem(LocalStorageKey.CurrentServices);

        // Eval
        if (currentServicesData) {
            // Parse Data
            const currentService: Array<Service> = JSON.parse(currentServicesData);

            // Done
            return currentService;
        }

        // No go
        return [];
    };

    const setLocation = (userLocation: UserLocation): void => {
        // Save info
        localStorage.setItem(LocalStorageKey.CurrentPlace, JSON.stringify(userLocation));

        // Dispatch change
        dispatch({
            type: AppContextActionType.SET_LOCATION,
            userLocation,
        });
    };

    const setServices = (services: Array<Service>): void => {
        // Save info
        localStorage.setItem(LocalStorageKey.CurrentServices, JSON.stringify(services));

        // Dispatch change
        dispatch({
            type: AppContextActionType.SET_SERVICES,
            services,
        });
    };

    const setAppointment = (
        appointment:
            | {
                  provider: Provider;
                  providerServices: Array<ProviderService>;
                  startTime: moment.Moment;
              }
            | undefined,
    ): void => {
        // Dispatch change
        dispatch({
            type: AppContextActionType.SET_APPOINTMENT,
            appointment,
        });
    };

    const getDisplayDurationText = (durationInMinutes: number): string => {
        // Define
        let hours: any = Math.floor(durationInMinutes / 60);
        let minutes: any = durationInMinutes % 60;

        // Eval
        if (hours > 0 && minutes > 0) {
            // Return
            return t('components.timePicker.durationTextHoursAndMinutes', { hours, minutes });
        } else if (hours > 0) {
            // Return
            return t('components.timePicker.durationTextHours', { hours });
        } else if (minutes > 0) {
            // Return
            return t('components.timePicker.durationTextMinutes', { minutes });
        } else {
            // Return
            return t('components.timePicker.durationTextZero');
        }
    };

    /**
     * For some stupid reason, iOS has a problem with infinite scroll.
     * When the results are displayed, nothing shows up.
     * The user has to scroll a little bit to make the results appear.
     * This is a workaround to fix this problem.
     */
    const fixIosEmptyScrollProblem = (): void => {
        if (state.operativeSystem === OperativeSystem.iOS) {
            setTimeout(() => {
                // Scroll to 1
                window.scrollTo(0, 1);
                window.scrollTo(0, 0);
            }, 100);
        }
    };

    // Component mount
    useEffect(() => {
        // Log
        logger.debug('App initialized', initialState);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppContext.Provider
            value={{
                state,
                // Methods
                init,
                changeThemeMode,
                setAppAsReady,
                getDefaultSidePaddingValue,
                getDeviceUniqueId,
                changeOrientation,
                getLocation,
                setLocation,
                getPreciseLocation,
                getServices,
                setServices,
                setAppointment,
                getDisplayDurationText,
                fixIosEmptyScrollProblem,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};
