import { AxiosResponse } from 'axios';
import { Service } from '../interfaces/service';
import { ServiceProviderExtended } from '../interfaces/service-provider';
import { BackendApiManager } from '../managers/backend-api';
import { Logger } from '../utils/logger';

export class ServicesRepository {
    // Singleton Instance
    private static _instance: ServicesRepository;

    // Private
    private logger: Logger;

    static get instance(): ServicesRepository {
        if (!this._instance) {
            this._instance = new ServicesRepository();
        }
        return this._instance;
    }

    constructor() {
        // Create Logger
        this.logger = new Logger('ServicesRepository');
    }

    public async getServices(
        skip: number,
        limit: number,
        name?: string,
        includeMetadata?: boolean,
        category?: string,
    ): Promise<{ totalCount: number; data: Array<Service> }> {
        // Set url
        let url: string = `/services?skip=${skip}&limit=${limit}`;

        // Add Name
        if (name) {
            url += `&name=${name}`;
        }

        // Include Metadata
        if (includeMetadata) {
            url += `&includeMetadata=${includeMetadata}`;
        }

        // Add Category
        if (category) {
            url += `&category=${category}`;
        }

        // Get from backend
        const result: AxiosResponse<Array<Service>> = await BackendApiManager.instance.get<Array<Service>>(url);

        // Return
        return { data: result.data, totalCount: parseInt(result.headers['x-total-count'] || '', 10) };
    }

    public async getServicesProviders(
        servicesIds: Array<string>,
        latitude: number,
        longitude: number,
    ): Promise<Array<ServiceProviderExtended>> {
        // Set url
        let url: string = '/services';

        // Check services ids length
        if (servicesIds.length === 0) {
            // Nothing to search for
            return [];
        } else {
            // Get by Id
            url = `/services/${servicesIds[0]}/providers?latitude=${latitude}&longitude=${longitude}`;

            // Eval length again
            if (servicesIds.length > 1) {
                // Add the rest
                for (let i = 0; i < servicesIds.length; i++) {
                    url += `&servicesIds=${servicesIds[i]}`;
                }
            }
        }

        // Get from backend
        const result: AxiosResponse<Array<ServiceProviderExtended>> =
            await BackendApiManager.instance.get<Array<ServiceProviderExtended>>(url);

        // Return
        return result.data;
    }
}
