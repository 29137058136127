import { NativeKnightEventEmitter, NativeKnightEvents } from '.';
import { DeviceOrientation } from './enums/device-orientation';
import { OperativeSystem } from './enums/operative-system';
import { SafeAreaInsets } from './interfaces/safe-area-insets';
import { Logger } from './utils/logger';

interface NativeKnightController {
    postMessage(message: string): void;
}

export interface NativeKnightMessage {
    type:
        | 'show-alert'
        | 'get-safe-area-insets'
        | 'get-orientation'
        | 'request-notifications-permission'
        | 'get-firebase-messaging-token'
        | 'show-notification'
        | 'set-badge-number'
        | 'get-location';
}

export interface ShowAlertNativeKnightMessage extends NativeKnightMessage {
    type: 'show-alert';
    title: string;
    message: string;
}

export interface GetSafeAreaInsetsNativeKnightMessage extends NativeKnightMessage {
    type: 'get-safe-area-insets';
}

export interface GetOrientationNativeKnightMessage extends NativeKnightMessage {
    type: 'get-orientation';
}

export interface RequestNotificationsPermissionNativeKnightMessage extends NativeKnightMessage {
    type: 'request-notifications-permission';
    confirmedAfterExplain?: boolean;
}

export interface GetFirebaseMessagingTokenNativeKnightMessage extends NativeKnightMessage {
    type: 'get-firebase-messaging-token';
}

export interface ShowNotificationNativeKnightMessage extends NativeKnightMessage {
    type: 'show-notification';
    notification: string;
}

export interface SetBadgeNumberNativeKnightMessage extends NativeKnightMessage {
    type: 'set-badge-number';
    number: number;
}

export interface GetLocationNativeKnightMessage extends NativeKnightMessage {
    type: 'get-location';
}

export class NativeKnight {
    // Singleton Instance
    private static _instance: NativeKnight;

    // Private variables
    private logger: Logger = new Logger('NativeKnight');
    private operativeSystem!: OperativeSystem;

    // Private constructor
    private constructor() {}

    static get instance(): NativeKnight {
        if (!this._instance) {
            this._instance = new NativeKnight();
        }

        return this._instance;
    }

    public init(operativeSystem: OperativeSystem) {
        // Set operative system
        this.operativeSystem = operativeSystem;
    }

    private getNativeController(): NativeKnightController | null {
        switch (this.operativeSystem) {
            case OperativeSystem.Android:
                return Android as any;
            case OperativeSystem.iOS:
                return (window as any).webkit?.messageHandlers?.ios;
            default:
                return null;
        }
    }

    private postMessage(message: NativeKnightMessage): boolean {
        // Get controller
        const controller = this.getNativeController();

        // Eval
        if (controller) {
            // Post message
            controller.postMessage(JSON.stringify(message));

            // Success
            return true;
        } else {
            // Could not post message
            return false;
        }
    }

    private async postMessageAndWaitForReturn(
        message: NativeKnightMessage,
        returnEvent: NativeKnightEvents,
        timeoutTime?: number,
    ): Promise<any[] | null> {
        return new Promise((resolve, reject) => {
            // Define Timeout
            let timeout: any;

            // Define Action
            const action = (...values: any[]) => {
                // Unsubscribe
                NativeKnightEventEmitter.off(returnEvent, action);

                // Clear timeout
                if (timeout) {
                    clearTimeout(timeout);
                }

                // Resolve
                resolve(values);
            };

            // Subscribe to message
            NativeKnightEventEmitter.on(returnEvent, action);

            // Post message
            const success = this.postMessage(message);

            // Eval
            if (!success) {
                // Unsubscribe
                NativeKnightEventEmitter.off(returnEvent, action);

                // Can't get safe area insets
                resolve(null);
            } else {
                // Set timeout
                timeout = setTimeout(
                    () => {
                        // Unsubscribe
                        NativeKnightEventEmitter.off(returnEvent, action);

                        // Can't get safe area insets
                        resolve(null);
                    },
                    timeoutTime ?? 30 * 1000,
                );
            }
        });
    }

    public async showAlert(title: string, message: string): Promise<boolean> {
        // Post message
        return this.postMessage({
            type: 'show-alert',
            title,
            message,
        } as ShowAlertNativeKnightMessage);
    }

    public async getSafeAreaInsets(): Promise<SafeAreaInsets | null> {
        const data: any[] | null = await this.postMessageAndWaitForReturn(
            {
                type: 'get-safe-area-insets',
            } as GetSafeAreaInsetsNativeKnightMessage,
            NativeKnightEvents.RETURN_SAFE_AREA_INSETS,
        );
        if (data) {
            return data[0] as SafeAreaInsets | null;
        }
        return null;
    }

    public async getOrientation(): Promise<DeviceOrientation | null> {
        const data: any[] | null = await this.postMessageAndWaitForReturn(
            {
                type: 'get-orientation',
            } as GetOrientationNativeKnightMessage,
            NativeKnightEvents.RETURN_ORIENTATION,
        );
        if (data) {
            return data[0] as DeviceOrientation | null;
        }
        return null;
    }

    public async requestNotificationsPermission(
        confirmedAfterExplain?: boolean,
    ): Promise<'yes' | 'no' | 'explain' | null> {
        const data: any[] | null = await this.postMessageAndWaitForReturn(
            {
                type: 'request-notifications-permission',
                confirmedAfterExplain,
            } as RequestNotificationsPermissionNativeKnightMessage,
            NativeKnightEvents.RETURN_NOTIFICATIONS_PERMISSION,
            // Wait for 10 minutes
            600000,
        );
        if (data) {
            return data[0] as 'yes' | 'no' | 'explain' | null;
        }
        return null;
    }

    public async getFirebaseMessagingToken(): Promise<string | null> {
        const data: any[] | null = await this.postMessageAndWaitForReturn(
            {
                type: 'get-firebase-messaging-token',
            } as GetFirebaseMessagingTokenNativeKnightMessage,
            NativeKnightEvents.RETURN_FIREBASE_MESSAGING_TOKEN,
            // Wait for 30 seconds
            30 * 1000,
        );
        if (data) {
            return data[0] as string | null;
        }
        return null;
    }

    public async showNotification(notification: string): Promise<boolean> {
        return this.postMessage({
            type: 'show-notification',
            notification,
        } as ShowNotificationNativeKnightMessage);
    }

    public async setBadgeNumber(number: number): Promise<boolean> {
        return this.postMessage({
            type: 'set-badge-number',
            number,
        } as SetBadgeNumberNativeKnightMessage);
    }

    public async getLocation(): Promise<[number, number] | null> {
        const data: any[] | null = await this.postMessageAndWaitForReturn(
            {
                type: 'get-location',
            } as GetLocationNativeKnightMessage,
            NativeKnightEvents.RETURN_LOCATION,
            60 * 1000,
        );
        if (data) {
            return [data[0], data[1]];
        }
        return null;
    }
}
