/**
 * The type of sign in error.
 */
export enum SignInError {
    Unknown = 'unknown',

    // 'Firebase Auth'
    InvalidEmail = 'invalid-email',
    UserDisabled = 'user-disabled',
    UserNotFound = 'user-not-found',
    WrongPassword = 'wrong-password',

    // Custom
    FailedToGetMe = 'failed-to-get-me',
}
