import axios from 'axios';

export class LocationManager {
    // Singleton Instance
    private static _instance: LocationManager;

    // Private constructor
    private constructor() {}

    static get instance(): LocationManager {
        if (!this._instance) {
            this._instance = new LocationManager();
        }

        return this._instance;
    }

    public async getLocationFromIp(): Promise<[number, number] | null> {
        // Try IP Guide
        const ipGuideCoords = await this.getLocationFromIp_IpGuide();
        if (ipGuideCoords) {
            return ipGuideCoords;
        }

        // Try IP Api
        const ipApiCoords = await this.getLocationFromIp_IpApi();
        if (ipApiCoords) {
            return ipApiCoords;
        }

        // Try IP Info
        const ipInfoCoords = await this.getLocationFromIp_IpInfo();
        if (ipInfoCoords) {
            return ipInfoCoords;
        }

        // Done
        return null;
    }

    private async getLocationFromIp_IpGuide(): Promise<[number, number] | null> {
        try {
            const response = await axios.get<{
                location: {
                    latitude: number;
                    longitude: number;
                };
            }>('https://ip.guide/');

            // Done
            return [response.data.location.latitude, response.data.location.longitude];
        } catch (error) {
            return null;
        }
    }

    private async getLocationFromIp_IpApi(): Promise<[number, number] | null> {
        try {
            const response = await axios.get<{
                latitude: number;
                longitude: number;
            }>('https://ipapi.co/json/');

            // Done
            return [response.data.latitude, response.data.longitude];
        } catch (error) {
            return null;
        }
    }

    private async getLocationFromIp_IpInfo(): Promise<[number, number] | null> {
        try {
            const response = await axios.get<{
                loc: string;
            }>('https://ipinfo.io?token=274f677a1def66');

            // Split
            const [latitude, longitude] = response.data.loc.split(',');

            // Done
            return [parseFloat(latitude), parseFloat(longitude)];
        } catch (error) {
            return null;
        }
    }
}
