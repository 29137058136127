import CircleIcon from '@mui/icons-material/Circle';
import FilterListIcon from '@mui/icons-material/FilterList';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import NotificationsIcon from '@mui/icons-material/Notifications';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grow,
    IconButton,
    Paper,
    Typography,
    useTheme,
} from '@mui/material';
import moment from 'moment';
import { MouseEventHandler, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { NativeKnightEventEmitter, NativeKnightEvents } from '../../..';
import { Container } from '../../../components/container/container';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { ErrorState } from '../../../components/error-state/error-state';
import { Loader } from '../../../components/loader/loader';
import { Page } from '../../../components/page/page';
import { AppContext } from '../../../contexts/app/context';
import { AppContextType } from '../../../contexts/app/types';
import { AuthContext } from '../../../contexts/auth/context';
import { AuthContextType } from '../../../contexts/auth/types';
import { LocalStorageKey } from '../../../enums/local-storage-key';
import { NotificationType } from '../../../enums/notification-type';
import { ThemeMode } from '../../../enums/theme-mode';
import { Notification } from '../../../interfaces/notification';
import { NotificationsRepository } from '../../../repositories/notifications';
import styles from './notifications.module.scss';

function NotificationCard({
    notification,
    onMarkAsRead,
}: {
    notification: Notification<any>;
    onMarkAsRead: MouseEventHandler<any> | undefined;
}) {
    // Get context
    const appContext: AppContextType = useContext(AppContext);

    // Get theme
    const theme = useTheme();

    // Hold state
    const [dateFormat, setDateFormat] = useState<'from-now' | 'full'>('from-now');
    const [pictureFormat, setPictureFormat] = useState<'mini' | 'mega'>('mini');

    // Notification was read
    const wasRead = notification.wasRead ?? false;

    // Build
    return (
        <Grow in={true} mountOnEnter unmountOnExit>
            <Box
                className={styles.notification}
                sx={{
                    backgroundColor: wasRead
                        ? theme.palette.background.default
                        : appContext.state.themeMode === ThemeMode.Light
                        ? '#ffdcf2'
                        : '#9a005f',
                }}
            >
                <Box
                    className={styles.line1}
                    onClick={() => {
                        // Switch action by type
                        switch (notification.type) {
                            case NotificationType.NewAppointment:
                                // // Check if current user is a provider
                                // if (authContext.state.me?.provider){
                                //     // Go to Booking
                                //     // http://localhost:3000/office/professional/6578839147fe0e0b29cbc04a/schedule/?day=2024-01-31
                                //     navigate(`/${RoutesNames.Office}/${RoutesNames.Office_Professional}/${notification.data?.bookingId}`);
                                // }
                                // // Go to User
                                // navigate(`/${RoutesNames.Office}/${RoutesNames.Office_User}/${booking.user.id}`);
                                break;
                        }
                    }}
                >
                    <Box className={styles.read} onClick={onMarkAsRead}>
                        {wasRead && (
                            <RadioButtonUncheckedIcon
                                color="primary"
                                sx={{
                                    width: '12px',
                                }}
                            />
                        )}
                        {!wasRead && (
                            <CircleIcon
                                color="primary"
                                sx={{
                                    width: '12px',
                                }}
                            />
                        )}
                    </Box>
                    <Box className={styles.from}>
                        {notification.fromProviderId && notification.provider && (
                            <Paper
                                elevation={1}
                                sx={{
                                    borderRadius: '50%',
                                }}
                            >
                                <Avatar
                                    alt={notification.provider.name}
                                    src={notification.provider.pictureUrl}
                                    sx={{
                                        width: 56,
                                        height: 56,
                                        backgroundColor: theme.palette.primary.main,
                                    }}
                                >
                                    {notification.provider.name[0]}
                                </Avatar>
                            </Paper>
                        )}
                        {!notification.fromProviderId && (
                            <Paper
                                elevation={1}
                                sx={{
                                    borderRadius: '50%',
                                }}
                            >
                                <Avatar
                                    src="/images/logo192.png"
                                    sx={{
                                        width: 56,
                                        height: 56,
                                        padding: '6px',
                                    }}
                                />
                            </Paper>
                        )}
                    </Box>
                    <Box className={styles.text}>
                        <Box className={styles.title}>
                            {notification.data?._notificationTitle && (
                                <Typography variant="body1" fontWeight={500}>
                                    {notification.data?._notificationTitle}
                                </Typography>
                            )}
                            {!notification.data?._notificationTitle && (
                                <Typography variant="body1" fontWeight={500}>
                                    {notification.type}
                                </Typography>
                            )}
                        </Box>
                        <Box className={styles.body}>
                            {notification.data?._notificationBody && (
                                <Typography variant="body2">{notification.data?._notificationBody}</Typography>
                            )}
                            {!notification.data?._notificationBody && (
                                <Typography variant="body2">{notification.type}</Typography>
                            )}
                        </Box>
                        <Box className={styles.date}>
                            {notification.createdAt && (
                                <Button
                                    sx={{
                                        minWidth: '0px',
                                        padding: '0px',
                                        paddingLeft: '8px',
                                        paddingRight: '8px',
                                        position: 'relative',
                                        left: '-8px',
                                        textTransform: 'none',
                                        color: theme.palette.text.secondary,
                                    }}
                                    onClick={() => {
                                        if (dateFormat === 'from-now') {
                                            setDateFormat('full');
                                        } else {
                                            setDateFormat('from-now');
                                        }
                                    }}
                                >
                                    <Typography variant="caption">
                                        {dateFormat === 'from-now' && moment(notification.createdAt).fromNow()}
                                        {dateFormat === 'full' && moment(notification.createdAt).format('LLL')}
                                    </Typography>
                                </Button>
                            )}
                        </Box>
                    </Box>
                    {notification.data?._notificationImageUrl && pictureFormat === 'mini' && (
                        <Grow in={true} mountOnEnter unmountOnExit>
                            <Box
                                className={styles.image}
                                onClick={() => {
                                    setPictureFormat('mega');
                                }}
                            >
                                <img src={notification.data?._notificationImageUrl} alt="Notification" />
                            </Box>
                        </Grow>
                    )}
                </Box>
                {notification.data?._notificationImageUrl && pictureFormat === 'mega' && (
                    <Grow in={true} mountOnEnter unmountOnExit>
                        <Box
                            className={styles.imageMega}
                            onClick={() => {
                                setPictureFormat('mini');
                            }}
                        >
                            <img src={notification.data?._notificationImageUrl} alt="Notification" />
                        </Box>
                    </Grow>
                )}
            </Box>
        </Grow>
    );
}

export default function OfficeNotifications() {
    // Get context
    const authContext: AuthContextType = useContext(AuthContext);

    // Translations
    const { t } = useTranslation();

    // Default values
    const defaultFilterUnread: boolean = localStorage.getItem(LocalStorageKey.NotificationsUnreadFilter) === 'true';

    // Hold state
    const [error, setError] = useState<boolean>(false);
    const [totalNotificationsCount, setTotalNotificationsCount] = useState<number>(-1);
    const [notifications, setNotifications] = useState<Array<Notification<any>> | null>(null);
    const [filterUnread, setFilterUnread] = useState<boolean>(defaultFilterUnread);
    const [markAllAsReadConfirmDialogOpen, setMarkAllAsReadConfirmDialogOpen] = useState<boolean>(false);

    // Load notifications
    const handleLoadMore = async (page: number) => {
        try {
            // Get Date
            let date = new Date();

            // Eval
            if (notifications && notifications.length > 0) {
                // Pick last notification
                const lastNotification: Notification<any> = notifications[notifications.length - 1];
                date = lastNotification.createdAt ?? date;
            }

            // Get notifications
            const results: { totalCount: number; data: Array<Notification<any>> } =
                await NotificationsRepository.instance.get(20, date, filterUnread === true ? false : undefined);

            // Update state
            setTotalNotificationsCount(results.totalCount);
            setNotifications([...(notifications || []), ...results.data]);
        } catch (error) {
            // Log
            console.error(error);

            // Update state
            setError(true);
        }
    };

    // Decide title
    let title = t('routes.office.notifications.title2');
    if (authContext.state.me?.unreadNotificationsCount) {
        if (authContext.state.me?.unreadNotificationsCount === 1) {
            title = `1 ${t('routes.office.notifications.title1')}`;
        } else if (authContext.state.me?.unreadNotificationsCount > 1) {
            title = `${authContext.state.me?.unreadNotificationsCount} ${t('routes.office.notifications.title2')}`;
        }
    }

    // Action: Reset all
    const resetState = async () => {
        // Reset state
        setError(false);
        setNotifications(null);
        setTotalNotificationsCount(-1);
    };

    // Action: App become active
    const onNativeOperative_Event_AppBecomeActive = async () => {
        // Get notifications count
        const results: { totalCount: number; data: Array<Notification<any>> } =
            await NotificationsRepository.instance.get(1, new Date(), false);

        // Evaluate
        if (results.totalCount !== authContext.state.me?.unreadNotificationsCount) {
            // Reset and update all
            resetState();
        }
    };

    // Action: New notification
    const onNativeOperative_Event_NewNotification = async (notification: string) => {
        // Print notification
        const newNotification = NotificationsRepository.instance.rebuildRemoteNotification(notification);

        // Push notification to the top
        setNotifications([newNotification, ...(notifications || [])]);

        // Update total count
        setTotalNotificationsCount(totalNotificationsCount === -1 ? 1 : totalNotificationsCount + 1);
    };

    // Action: Toggle Unread Filter
    const toggleUnreadFilter = async () => {
        // Update Preference
        localStorage.setItem(LocalStorageKey.NotificationsUnreadFilter, filterUnread ? 'false' : 'true');

        // Toogle filter
        setFilterUnread(!filterUnread);

        // Reset state
        resetState();
    };

    // On mount
    useEffect(() => {
        // Subscribe to events
        NativeKnightEventEmitter.on(
            NativeKnightEvents.EVENT_APP_BECOME_ACTIVE,
            onNativeOperative_Event_AppBecomeActive,
        );
        NativeKnightEventEmitter.on(NativeKnightEvents.EVENT_NEW_NOTIFICATION, onNativeOperative_Event_NewNotification);

        return () => {
            // Unsubscribe to events
            NativeKnightEventEmitter.off(
                NativeKnightEvents.EVENT_NEW_NOTIFICATION,
                onNativeOperative_Event_NewNotification,
            );
            NativeKnightEventEmitter.off(
                NativeKnightEvents.EVENT_APP_BECOME_ACTIVE,
                onNativeOperative_Event_AppBecomeActive,
            );
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authContext.state.me, notifications]);

    // Build
    return (
        <Page
            title={title}
            onRefresh={async () => {
                // Reset state
                resetState();
            }}
            actions={[
                <IconButton
                    aria-label="mark-all-as-read"
                    onClick={() => {
                        setMarkAllAsReadConfirmDialogOpen(true);
                    }}
                >
                    <MarkEmailReadIcon />
                </IconButton>,
                <IconButton
                    color={filterUnread ? 'primary' : 'default'}
                    aria-label="filter-unread"
                    onClick={() => {
                        toggleUnreadFilter();
                    }}
                >
                    <FilterListIcon />
                </IconButton>,
            ]}
        >
            <Container>
                {!error && (
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={handleLoadMore}
                        hasMore={notifications?.length !== totalNotificationsCount}
                        loader={
                            <Box
                                key={`notification-loader`}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '32px',
                                }}
                            >
                                <Loader size={48} />
                            </Box>
                        }
                    >
                        {notifications?.length === 0 && filterUnread === false && (
                            <EmptyState
                                key={`notification-empty`}
                                icon={<NotificationsIcon />}
                                title={t('routes.office.notifications.empty.title')}
                                description={t('routes.office.notifications.empty.description')}
                            />
                        )}
                        {notifications?.length === 0 && filterUnread === true && (
                            <EmptyState
                                key={`notification-empty`}
                                icon={<MarkEmailUnreadIcon />}
                                title={t('routes.office.notifications.emptyUnread.title')}
                                description={t('routes.office.notifications.emptyUnread.description')}
                                actions={[
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            toggleUnreadFilter();
                                        }}
                                    >
                                        {t('routes.office.notifications.emptyUnread.listAll')}
                                    </Button>,
                                ]}
                            />
                        )}
                        {notifications?.map((notification: Notification<any>, index: number) => {
                            return (
                                <NotificationCard
                                    notification={notification}
                                    key={`notification-${index}`}
                                    onMarkAsRead={() => {
                                        // Calculate new value
                                        const newValue = !(notification.wasRead ?? false);

                                        // Update on the backend
                                        NotificationsRepository.instance.markNotificationAsRead(
                                            notification.id ?? '',
                                            newValue,
                                        );

                                        // Update current list
                                        const newNotifications = [...(notifications || [])];
                                        newNotifications[index].wasRead = newValue;
                                        setNotifications(newNotifications);

                                        // Update unread notifications count
                                        if (newValue) {
                                            // Read, decrease value
                                            authContext.updateUnreadNotificationsCount(
                                                (authContext.state.me?.unreadNotificationsCount ?? 0) - 1,
                                            );
                                        } else {
                                            // Unread, increase value
                                            authContext.updateUnreadNotificationsCount(
                                                (authContext.state.me?.unreadNotificationsCount ?? 0) + 1,
                                            );
                                        }
                                    }}
                                />
                            );
                        })}
                    </InfiniteScroll>
                )}
                {error && (
                    <ErrorState
                        icon={<NotificationsIcon />}
                        onTryAgainClick={() => {
                            // Reset state
                            setError(false);
                        }}
                    />
                )}
            </Container>
            <Dialog
                open={markAllAsReadConfirmDialogOpen}
                onClose={() => {
                    setMarkAllAsReadConfirmDialogOpen(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle fontWeight="bold" id="alert-dialog-title">
                    {t('routes.office.notifications.markAllAsReadConfirmDialog.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('routes.office.notifications.markAllAsReadConfirmDialog.description')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="inherit"
                        onClick={() => {
                            setMarkAllAsReadConfirmDialogOpen(false);
                        }}
                    >
                        {t('routes.office.notifications.markAllAsReadConfirmDialog.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            // Close
                            setMarkAllAsReadConfirmDialogOpen(false);

                            // Update on the backend
                            NotificationsRepository.instance.markAllNotificationsAsRead(true);

                            // Read, decrease value
                            authContext.updateUnreadNotificationsCount(0);

                            setTimeout(() => {
                                // Just reset state
                                resetState();
                            }, 500);
                        }}
                        autoFocus
                    >
                        {t('routes.office.notifications.markAllAsReadConfirmDialog.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Page>
    );
}
