import { LegalDocument } from '../interfaces/legal-document';

export const LegalDocuments: {
    TermsOfUse: LegalDocument;
    PrivacyPolicy: LegalDocument;
} = {
    TermsOfUse: {
        intro: {
            pt: 'A presente página visa regulamentar as condições gerais de utilização do serviço e aplicação "As Vaidosas".',
            en: 'This page aims to regulate the general conditions of use of the service and application "As Vaidosas".',
        },
        sections: [
            {
                title: {
                    pt: 'Definições',
                    en: 'Definitions',
                },
                paragraphs: [
                    {
                        pt: 'Reconhece-se, para os efeitos do presente contrato, as definições seguintes.',
                        en: 'For the purposes of this contract, the following definitions are recognized.',
                    },
                    {
                        pt: '"Serviço" - conjunto de ferramentas de Pesquisa, Agendamento, Gestão e Marketing disponibilizadas pela "As Vaidosas";',
                        en: '"Service" - set of Research, Scheduling, Management and Marketing tools made available by "As Vaidosas";',
                    },
                    {
                        pt: '"As Vaidosas" - entidade prestadora do Serviço, legalmente representada pela em nome individual;',
                        en: '"As Vaidosas" - entity providing the Service, legally represented by in an individual capacity;',
                    },
                    {
                        pt: '"Profissional" - pessoa singular que utiliza o Serviço e que presta serviços próprios;',
                        en: '"Professional" - natural person who uses the Service and provides their own services;',
                    },
                    {
                        pt: '"Utilizador" - pessoa singular que utiliza o Serviço. Inclui todos os “Profissionais”.',
                        en: '"User" - natural person who uses the Service. Includes all "Professionals".',
                    },
                    {
                        pt: '"Conta" - Área Pessoal de cada Utilizador;',
                        en: '"Account" - Personal Area of each User;',
                    },
                    {
                        pt: '"Aplicação" - ferramenta onde é disponibilizado o Serviço, acessível através de download para smartphone Android na Google Play e iOS na App Store.',
                        en: '"Application" - tool where the Service is available, accessible through download for Android smartphone on Google Play and iOS on the App Store.',
                    },
                ],
            },
            {
                title: {
                    pt: 'Condições Gerais',
                    en: 'General Conditions',
                },
                paragraphs: [
                    {
                        pt: 'A utilização deste Serviço e da Aplicação implica a aceitação, plena e sem reservas, de todas as disposições incluídas nestes Termos e Condições de Utilização, bem como na Política de Privacidade de “As Vaidosas”.',
                        en: 'The use of this Service and the Application implies the full and unconditional acceptance of all the provisions included in these Terms and Conditions of Use, as well as in the Privacy Policy of "As Vaidosas".',
                    },
                    {
                        pt: 'A “As Vaidosas” reserva-se o direito de alterar periodicamente e sem aviso prévio os Termos e Condições e a Política de Privacidade, pelo que o Utilizador deverá consultar, previamente à utilização do Serviço, os documentos vigentes. Caso não concorde integralmente com as suas disposições, não deverá utilizar o Serviço.',
                        en: '"As Vaidosas" reserves the right to change the Terms and Conditions and the Privacy Policy periodically and without prior notice, so the User should consult the current documents prior to using the Service. If you do not fully agree with its provisions, you should not use the Service.',
                    },
                    {
                        pt: 'O Utilizador pode consultar a versão actual dos Termos e Condições a qualquer momento no seguinte endereço: https://asvaidosas.pt/condicoes-gerais.',
                        en: 'The User can consult the current version of the Terms and Conditions at any time at the following address: https://asvaidosas.pt/en/general-conditions.',
                    },
                    {
                        pt: 'Qualquer recurso novo que altere, melhore ou aperfeiçoe o Serviço actual, incluindo o lançamento de novas ferramentas, está sujeito às condições estabelecidas nos Termos e Condições e na Política de Privacidade.',
                        en: 'Any new feature that changes, improves or enhances the current Service, including the release of new tools, is subject to the conditions established in the Terms and Conditions and the Privacy Policy.',
                    },
                    {
                        pt: 'Ao utilizar o Serviço, o Utilizador está a aceitar tacitamente os Termos e Condições e a Política de Privacidade em vigor.',
                        en: 'By using the Service, the User is tacitly accepting the Terms and Conditions and the Privacy Policy in force.',
                    },
                    {
                        pt: 'A violação de qualquer das condições estabelecidas nos Termos e Condições confere à “As Vaidosas” o direito de encerrar a Área Pessoal do Utilizador infractor e de o impedir de utilizar o Serviço.',
                        en: 'The violation of any of the conditions established in the Terms and Conditions gives "As Vaidosas" the right to close the Personal Area of the infringing User and to prevent them from using the Service.',
                    },
                ],
            },
            {
                title: {
                    pt: 'Capacidade Jurídica',
                    en: 'Legal Capacity',
                },
                paragraphs: [
                    {
                        pt: 'Este Serviço destina-se a ser utilizado apenas por pessoas maiores de idade, com capacidade de exercício face à lei portuguesa.',
                        en: 'This Service is intended to be used only by persons of legal age, with legal capacity to act under Portuguese law.',
                    },
                    {
                        pt: 'Todas as pessoas residentes, domiciliadas ou sediadas noutros países, que não Portugal, deverão assegurar-se que lhes é permitido aceder e utilizar o Serviço e à Aplicação.',
                        en: 'All persons residing, domiciled or based in other countries, other than Portugal, should ensure that they are allowed to access and use the Service and the Application.',
                    },
                ],
            },
            {
                title: {
                    pt: 'Propriedade Intelectual',
                    en: 'Intellectual Property',
                },
                paragraphs: [
                    {
                        pt: 'Todos os direitos de propriedade intelectual sobre a Aplicação, os seus conteúdos e o Serviço disponibilizado são propriedade da “As Vaidosas” ou dos seus licenciados, salvo indicação expressa em contrário.',
                        en: 'All intellectual property rights in the Application, its contents and the Service provided are the property of "As Vaidosas" or its licensors, unless expressly stated otherwise.',
                    },
                    {
                        pt: 'O Utilizador aceita que o Serviço contém informações protegidas e confidenciais que são tuteladas pela legislação aplicável.',
                        en: 'The User accepts that the Service contains protected and confidential information that is protected by applicable law.',
                    },
                    {
                        pt: 'A “As Vaidosas” concede ao Utilizador uma licença limitada, não exclusiva e não transferível, para visualizar ou imprimir o conteúdo da Aplicação para uso exclusivamente pessoal, e não comercial.',
                        en: '"As Vaidosas" grants the User a limited, non-exclusive and non-transferable license to view or print the content of the Application for personal, non-commercial use only.',
                    },
                    {
                        pt: 'O Utilizador não pode duplicar, copiar, reproduzir, redistribuir ou reutilizar qualquer parte da Aplicação ou Serviço sem a expressa autorização por escrito da “As Vaidosas”, sob pena de incorrer em responsabilidade civil e criminal.',
                        en: 'The User may not duplicate, copy, reproduce, redistribute or reuse any part of the Application or Service without the express written permission of "As Vaidosas", under penalty of incurring civil and criminal liability.',
                    },
                    {
                        pt: 'O Utilizador é responsável por toda a actividade e por todo o conteúdo inserido na sua Área Pessoal. Ao enviar ou transmitir conteúdos para a Aplicação ou para a “As Vaidosas”, o Utilizador declara ser o proprietário dos direitos de utilização desses conteúdos e concede à “As Vaidosas” uma licença não exclusiva, mundial e gratuita para utilizar, reproduzir, explorar e preparar obras derivadas da informação submetida.',
                        en: 'The User is responsible for all activity and all content entered in their Personal Area. By submitting or transmitting content to the Application or to "As Vaidosas", the User declares to be the owner of the rights to use that content and grants "As Vaidosas" a non-exclusive, worldwide and free license to use, reproduce, exploit and prepare derivative works from the submitted information.',
                    },
                    {
                        pt: 'A informação partilhada pelo Utilizador em áreas públicas da Aplicação ou Serviço não será considerada informação confidencial.',
                        en: 'The information shared by the User in public areas of the Application or Service will not be considered confidential information.',
                    },
                    {
                        pt: 'O Utilizador entende e concorda que a “As Vaidosas” não pode ser responsabilizada por qualquer conteúdo inserido e apresentado durante a utilização do Serviço;',
                        en: 'The User understands and agrees that "As Vaidosas" cannot be held responsible for any content entered and presented during the use of the Service;',
                    },
                ],
            },
            {
                title: {
                    pt: 'Utilização da Aplicação ou Serviço',
                    en: 'Use of the Application or Service',
                },
                paragraphs: [
                    {
                        pt: 'Para poder utilizar todas as funcionalidades do Serviço, o Utilizador deve iniciar sessão na Aplicação, fornecendo determinados dados pessoais para o efeito. A utilização da Aplicação como Utilizador registado poder-lhe-á permitir o usufruto de promoções emitidas pelos  Profissionais, nos termos especificados em cada promoção disponível.',
                        en: 'In order to use all the features of the Service, the User must log in to the Application, providing certain personal data for this purpose. The use of the Application as a registered User may allow you to enjoy promotions issued by the Professionals, under the terms specified in each available promotion.',
                    },
                    {
                        pt: 'O Utilizador é inteiramente responsável pelos dados necessários para iniciar sessão, garantindo a confidencialidade dos mesmos. O Utilizador compromete-se a informar imediatamente a “As Vaidosas”, caso tenha conhecimento de que a confidencialidade dos seus dados esteja comprometida. A “As Vaidosas” não pode ser responsabilizada por qualquer perda ou dano resultante do acesso indevido de terceiros à Área Pessoal de um Utilizador.',
                        en: 'The User is entirely responsible for the data necessary to log in, ensuring their confidentiality. The User undertakes to inform "As Vaidosas" immediately if they become aware that the confidentiality of their data is compromised. "As Vaidosas" cannot be held responsible for any loss or damage resulting from the improper access of third parties to a User\'s Personal Area.',
                    },
                    {
                        pt: 'Não é permitida a partilha dos dados de acesso a uma Área Pessoal entre diferentes Utilizadores. Cada Utilizador deve definir e utilizar dados de acesso únicos e intransmissíveis;',
                        en: 'Sharing access data to a Personal Area between different Users is not allowed. Each User must define and use unique and non-transferable access data;',
                    },
                    {
                        pt: 'Para além destes Termos de Utilização, o uso que faz de ligações a websites próprios de  Profissionais ou de Parceiros da “As Vaidosas” pode estar sujeita a termos e condições adicionais. Se for o caso, certifique-se de que lê tais termos e condições adicionais antes de utilizar os websites e/ou comprar os serviços e produtos aí disponibilizados.',
                        en: 'In addition to these Terms of Use, your use of links to websites owned by Professionals or Partners of "As Vaidosas" may be subject to additional terms and conditions. If this is the case, make sure to read such additional terms and conditions before using the websites and/or purchasing the services and products available there.',
                    },
                    {
                        pt: 'O Utilizador reconhece que a “As Vaidosas” não patrocina nenhum dos websites para os quais haja links, nem nenhum website que tenha link para o Website, e que a “As Vaidosas” não é responsável pelo conteúdo desses websites.',
                        en: 'The User acknowledges that "As Vaidosas" does not sponsor any of the websites for which there are links, nor any website that has a link to the Website, and that "As Vaidosas" is not responsible for the content of those websites.',
                    },
                    {
                        pt: 'O Utilizador reconhece que: (i) a Internet é uma rede mundial de computadores e que qualquer informação enviada ou transmitida por ele será necessariamente encaminhada por computadores de terceiros; (ii) a As Vaidosas” não é responsável por falhas na segurança das comunicações e não assume qualquer responsabilidade pelo uso indevido da sua informação por terceiros; (iii) o Serviço é prestado em linha (online), pelo que a As Vaidosas” não se responsabiliza por qualquer falha de conectividade e inerente impossibilidade da sua utilização.',
                        en: 'The User acknowledges that: (i) the Internet is a worldwide network of computers and that any information sent or transmitted by them will necessarily be routed by third-party computers; (ii) "As Vaidosas" is not responsible for failures in the security of communications and assumes no responsibility for the misuse of your information by third parties; (iii) the Service is provided online, so "As Vaidosas" is not responsible for any connectivity failure and inherent impossibility of its use.',
                    },
                    {
                        pt: 'A As Vaidosas” não garante que (i) o Serviço satisfaça todas as necessidades específicas do Utilizador, (ii) o Serviço seja ininterrupto, atempado, seguro ou livre de erros, (iii) os resultados que possam ser obtidos com a utilização do Serviço sejam exactos ou confiáveis, e (iv) quaisquer erros que possam existir no Serviço sejam corrigidos.',
                        en: '"As Vaidosas" does not guarantee that (i) the Service meets all the specific needs of the User, (ii) the Service is uninterrupted, timely, secure or error-free, (iii) the results that may be obtained with the use of the Service are accurate or reliable, and (iv) any errors that may exist in the Service are corrected.',
                    },
                    {
                        pt: 'O Utilizador expressamente entende e concorda que a As Vaidosas” não pode ser responsabilizada por quaisquer danos directos, indirectos, acidentais, especiais, consequenciais ou danos exemplares, incluindo mas não limitados a: danos por lucros cessantes, danos por uso indevido, dados ou outras perdas tangíveis ou intangíveis resultantes da utilização do Serviço.',
                        en: 'The User expressly understands and agrees that "As Vaidosas" cannot be held responsible for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to: damages for lost profits, damages for misuse, data or other tangible or intangible losses resulting from the use of the Service.',
                    },
                ],
            },
            {
                title: {
                    pt: 'Restrições à Utilização da Aplicação ou Serviço',
                    en: 'Restrictions on the Use of the Application or Service',
                },
                paragraphs: [
                    {
                        pt: 'No âmbito da utilização da Aplicação, o Utilizador compromete-se a não praticar os pontos seguintes.',
                        en: 'In the scope of using the Application, the User undertakes not to practice the following points.',
                    },
                    {
                        pt: 'Não utilizar qualquer mecanismo, programa de computador ou tecnologia para interferir ou tentar interferir com o funcionamento normal da mesma;',
                        en: 'Not to use any mechanism, computer program or technology to interfere or attempt to interfere with its normal operation;',
                    },
                    {
                        pt: 'Não enviar ou transmitir para a Aplicação informação ilegal, fraudulenta, difamatória ou obscena;',
                        en: 'Not to send or transmit to the Application illegal, fraudulent, defamatory or obscene information;',
                    },
                    {
                        pt: 'Não enviar ou transmitir para a Aplicação informação que contenha vírus ou bugs;',
                        en: 'Not to send or transmit to the Application information that contains viruses or bugs;',
                    },
                    {
                        pt: 'Não publicar, representar, distribuir, criar obras derivadas, reproduzir ou utilizar o conteúdo da Aplicação (excluindo a utilização expressa e previamente permitida);',
                        en: 'Not to publish, represent, distribute, create derivative works, reproduce or use the content of the Application (excluding the express and previously permitted use);',
                    },
                    {
                        pt: 'Não enviar ou transmitir informação que viole os direitos de propriedade intelectual de terceiros;',
                        en: 'Not to send or transmit information that violates the intellectual property rights of third parties;',
                    },
                    {
                        pt: 'Não desenvolver qualquer actividade que sobrecarregue demasiadamente as infra-estruturas da “As Vaidosas”;',
                        en: 'Not to develop any activity that excessively overloads the infrastructures of "As Vaidosas";',
                    },
                    {
                        pt: 'Não inserir, alojar, fazer upload, transmitir ou enviar mensagens “spam” não solicitadas;',
                        en: 'Not to insert, host, upload, transmit or send unsolicited "spam" messages;',
                    },
                    {
                        pt: 'Não exibir qualquer parte do conteúdo da Aplicação mediante a utilização de framing, links ou outra tecnologia sem a autorização expressa e por escrito da “As Vaidosas”.',
                        en: 'Not to display any part of the content of the Application using framing, links or other technology without the express written permission of "As Vaidosas".',
                    },
                ],
            },
            {
                title: {
                    pt: 'Pagamentos e Reembolsos - Profissionais',
                    en: 'Payments and Refunds - Professionals',
                },
                paragraphs: [
                    {
                        pt: 'O Profissional pode experimentar gratuitamente uma versão limitada do Serviço, sem qualquer custo associado.',
                        en: 'The Professional can try a limited version of the Service for free, at no cost.',
                    },
                    {
                        pt: 'Sempre que um Profissional não procede ao cancelamento da subscrição do Serviço no prazo de trinta dias após o vencimento da fatura, a “As Vaidosas” reserva-se no direito de apagar permanentemente a conta do Profissional implicando perda de pontuações e histórico de serviços.',
                        en: 'Whenever a Professional does not cancel the subscription to the Service within thirty days after the expiration of the invoice, "As Vaidosas" reserves the right to permanently delete the Professional\'s account, implying loss of scores and service history.',
                    },
                    {
                        pt: 'O Serviço é cobrado até ao dia 8 do mês seguinte e não é reembolsável. Não haverá direito a reembolso ou a crédito sempre que (i) a subscrição do Serviço for cancelada ou (ii) existirem períodos de tempo sem utilização ou sem acesso à Área Pessoal.',
                        en: 'The Service is charged until the 8th of the following month and is non-refundable. There will be no right to refund or credit whenever (i) the subscription to the Service is canceled or (ii) there are periods of time without use or access to the Personal Area.',
                    },
                    {
                        pt: 'O Profissional pode efetuar o pagamento do Serviço contratado através de referência multibanco, transferência bancária, ou outras formas de pagamento indicadas na fatura ou na Aplicação. Os preços associados à subscrição do Serviço são apresentados em Euros e não incluem quaisquer taxas ou impostos exigidos pelas autoridades tributárias.',
                        en: 'The Professional can make the payment of the contracted Service through multibanco reference, bank transfer, or other payment methods indicated on the invoice or in the Application. The prices associated with the subscription to the Service are presented in Euros and do not include any fees or taxes required by the tax authorities.',
                    },
                ],
            },
            {
                title: {
                    pt: 'Cancelamento da Subscrição',
                    en: 'Subscription Cancellation',
                },
                paragraphs: [
                    {
                        pt: 'O Utilizador é o único responsável pelo cancelamento adequado da subscrição do Serviço. O cancelamento pode ser efectuado a qualquer momento através dos contactos disponibilizados na Aplicação para pedido de ajuda.',
                        en: 'The User is solely responsible for the proper cancellation of the subscription to the Service. Cancellation can be made at any time through the contacts available in the Application for help request.',
                    },
                    {
                        pt: 'O envio de uma mensagem de correio electrónico ou uma chamada telefónica a solicitar o cancelamento de subscrição só são considerados procedimentos válidos de cancelamento quando os mesmos tem uma resposta por parte da “As Vaidosas”.',
                        en: 'Sending an email message or a phone call requesting the cancellation of the subscription are only considered valid cancellation procedures when they have a response from "As Vaidosas".',
                    },
                    {
                        pt: 'Após o cancelamento da subscrição por parte do Utilizador, o conteúdo da sua Área Pessoal será eliminado e não será possível a recuperação da informação.',
                        en: 'After the cancellation of the subscription by the User, the content of their Personal Area will be deleted and the recovery of the information will not be possible.',
                    },
                    {
                        pt: 'A “As Vaidosas” poderá, a qualquer altura, sem justificação e sem aviso prévio, terminar, restringir, suspender ou revogar o acesso de um Utilizador a parte ou a todo o Serviço, caso entenda, discricionariamente, que está a utilizar indevidamente o Serviço ou que está a violar os Termos e Condições de Utilização.',
                        en: '"As Vaidosas" may, at any time, without justification and without prior notice, terminate, restrict, suspend or revoke a User\'s access to part or all of the Service, if it understands, at its discretion, that it is misusing the Service or that it is violating the Terms and Conditions of Use.',
                    },
                    {
                        pt: 'A “As Vaidosas” reserva-se o direito de recusar a prestação do Serviço a qualquer pessoa singular ou colectiva por qualquer motivo, a qualquer momento.',
                        en: '"As Vaidosas" reserves the right to refuse the provision of the Service to any natural or legal person for any reason, at any time.',
                    },
                ],
            },
            {
                title: {
                    pt: 'Modificações ao Serviço, Aplicação e Preços',
                    en: 'Modifications to the Service, Application and Prices',
                },
                paragraphs: [
                    {
                        pt: 'A “As Vaidosas” reserva-se o direito de, a qualquer momento, modificar ou descontinuar, temporária ou permanentemente, o Serviço (ou qualquer parte dele) sem aviso prévio.',
                        en: '"As Vaidosas" reserves the right to, at any time, modify or discontinue, temporarily or permanently, the Service (or any part of it) without prior notice.',
                    },
                    {
                        pt: 'O Utilizador expressamente aceita que a “As Vaidosas” lhe forneça automaticamente tais modificações ou correcções como parte do Serviço.',
                        en: 'The User expressly agrees that "As Vaidosas" will automatically provide such modifications or corrections to them as part of the Service.',
                    },
                    {
                        pt: 'Como parte do Serviço, a “As Vaidosas” poderá ainda proceder a descontinuidades temporárias e modificações no Serviço ou Aplicação, decorrentes de correcções de problemas de software, aperfeiçoamentos de funcionalidades, melhorias gerais, ou novas versões do Serviço, com o intuito de melhorar a prestação do mesmo, visando assim a máxima satisfação e beneficio para o Utilizador. A “As Vaidosas” não será responsável para com o Utilizador ou terceiros por quaisquer danos que possam resultar da cessação ou suspensão da Aplicação, da Área Pessoal ou do acesso do Utilizador ao Serviço.',
                        en: 'As part of the Service, "As Vaidosas" may also make temporary discontinuities and modifications to the Service or Application, resulting from software problem corrections, functionality improvements, general improvements, or new versions of the Service, with the aim of improving the provision of the same, thus aiming at maximum satisfaction and benefit for the User. "As Vaidosas" will not be responsible to the User or third parties for any damages that may result from the cessation or suspension of the Application, the Personal Area or the User\'s access to the Service.',
                    },
                    {
                        pt: 'Os preços praticados pela “As Vaidosas”, incluindo mas não limitando à subscrição mensal do Serviço, estão sujeitos a alterações mediante aviso prévio de trinta dias. Esse aviso pode ser fornecido a qualquer momento através da publicação das alterações na Aplicação da “As Vaidosas”.',
                        en: 'The prices practiced by "As Vaidosas", including but not limited to the monthly subscription to the Service, are subject to change upon thirty days prior notice. This notice can be provided at any time through the publication of the changes in the Application of "As Vaidosas".',
                    },
                    {
                        pt: 'O Utilizador ou terceiros não poderão responsabilizar contratual, extracontratual, criminal ou por qualquer outra forma a “As Vaidosas” por qualquer modificação ou alteração dos preços, suspensão ou encerramento do Serviço.',
                        en: 'The User or third parties may not hold "As Vaidosas" contractually, extracontractually, criminally or in any other way for any modification or alteration of prices, suspension or termination of the Service.',
                    },
                ],
            },
            {
                title: {
                    pt: 'Marcações de Serviços',
                    en: 'Service Bookings',
                },
                paragraphs: [
                    {
                        pt: 'O Utilizador entende e concorda que a “As Vaidosas” atua apenas como facilitador do processo de marcação de serviços.',
                        en: 'The User understands and agrees that "As Vaidosas" acts only as a facilitator of the service booking process.',
                    },
                    {
                        pt: 'O Utilizador confere à “As Vaidosas” poderes para, em sua representação, proceder à marcação, alteração ou cancelamento de serviços prestados por Profissionais.',
                        en: 'The User gives "As Vaidosas" the power to, on their behalf, make, change or cancel services provided by Professionals.',
                    },
                    {
                        pt: 'Caso o Utilizador deseje cancelar a sua marcação, pode fazê-lo utilizando a opção “Marcações” disponível na sua Área Pessoal.',
                        en: 'If the User wishes to cancel their booking, they can do so using the "Bookings" option available in their Personal Area.',
                    },
                    {
                        pt: 'Deverá certificar-se que chega atempadamente ao estabelecimento do Profissional. Caso não compareça no estabelecimento à hora marcada, a sua marcação poderá ser cancelada ou ser marcada como falta do utilizador.',
                        en: "You should make sure to arrive on time at the Professional's establishment. If you do not show up at the establishment at the scheduled time, your booking may be canceled or marked as a user absence.",
                    },
                    {
                        pt: 'O Utilizador entende e concorda ao ter uma marcação marcada como “falta do utilizador” resultará em (i) bloqueio da conta e impossibilidade de voltar a agendar novos serviços até (ii) efetuar o pagamento de 50% do valor dos serviços agendados, valor esse que reverte na totalidade para o Profissional como indemnização pelo tempo reservado e não utilizado. ',
                        en: 'The User understands and agrees that having a booking marked as "user absence" will result in (i) account blocking and inability to schedule new services until (ii) paying 50% of the value of the scheduled services, an amount that reverts in full to the Professional as compensation for the reserved and unused time.',
                    },
                    {
                        pt: 'A realização dos serviços, cuja marcação foi efetuada pelo Utilizador através da Aplicação, é da responsabilidade exclusiva do Profissional (que presta os respetivos serviços) e dos estabelecimentos onde estes operam.',
                        en: 'The provision of services, whose booking was made by the User through the Application, is the sole responsibility of the Professional (who provides the respective services) and the establishments where they operate.',
                    },
                    {
                        pt: 'A marcação de serviços através da Aplicação não confere ao Utilizador qualquer tipo de garantia de prestação dos serviços por parte do Profissional escolhido, na data selecionada.',
                        en: 'Booking services through the Application does not give the User any guarantee of service provision by the chosen Professional, on the selected date.',
                    },
                    {
                        pt: 'A “As Vaidosas” informa que os serviços que pode marcar através da Aplicação lhe serão faturados pelo Profissional, e não pela “As Vaidosas”.',
                        en: '"As Vaidosas" informs that the services that you can book through the Application will be invoiced to you by the Professional, and not by "As Vaidosas".',
                    },
                    {
                        pt: 'A Aplicação contém informações meramente indicativas sobre os preços praticados pelos Profissionais na prestação de serviços. A informação relativa a preços é fornecida pelo Profissional respectivo, não podendo a “As Vaidosas” dar qualquer garantia da sua constante actualização.',
                        en: 'The Application contains merely indicative information about the prices practiced by the Professionals in the provision of services. The information regarding prices is provided by the respective Professional, and "As Vaidosas" cannot give any guarantee of its constant updating.',
                    },
                    {
                        pt: 'O Utilizador entende e concorda que a “As Vaidosas” não pode ser responsabilizada por quaisquer danos directos, indirectos, acidentais, especiais, consequenciais ou danos exemplares, incluindo mas não limitados a: (i) danos derivados da não realização de serviços marcados através da Aplicação, (ii) danos derivados do atraso na realização de serviços marcados através da Aplicação, (iii) danos derivados da insatisfação pelos serviços marcados através da Aplicação, e (iv) dados ou outras perdas tangíveis ou intangíveis resultantes da utilização do Serviço.',
                        en: 'The User understands and agrees that "As Vaidosas" cannot be held responsible for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to: (i) damages derived from the non-performance of services booked through the Application, (ii) damages derived from the delay in the performance of services booked through the Application, (iii) damages derived from dissatisfaction with services booked through the Application, and (iv) data or other tangible or intangible losses resulting from the use of the Service.',
                    },
                ],
            },
            {
                title: {
                    pt: 'Cartão de Fidelidade e Cartão Presente',
                    en: 'Loyalty Card and Gift Card',
                },
                paragraphs: [
                    {
                        pt: 'Após uma marcação completa o Utilizador terá a oportunidade de atribuir uma avaliação ao serviço prestado pelo Profissional. Essa atribuição resultará numa oferta de um número de pontos, conforme indicado no momento pela Aplicação, pontos esses que irão ser acumulados no Cartão de Fidelização do cliente. Estes pontos são pessoais e intransmissíveis.',
                        en: "After a complete booking, the User will have the opportunity to assign an evaluation to the service provided by the Professional. This assignment will result in an offer of a number of points, as indicated at the time by the Application, points that will be accumulated on the customer's Loyalty Card. These points are personal and non-transferable.",
                    },
                    {
                        pt: 'Após atingir um determinado número de pontos, conforme indicado no momento pela Aplicação, o Utilizador poderá converter esses mesmos pontos em saldo passível de ser utilizado na aquisição de serviços, até ao valor máximo dos serviços agendados por sessão, por profissional. ',
                        en: 'After reaching a certain number of points, as indicated at the time by the Application, the User can convert these points into a balance that can be used to purchase services, up to the maximum value of the services scheduled per session, per professional.',
                    },
                    {
                        pt: 'O valor em saldo no cartão presente obtido através do cartão de fidelidade só pode ser utilizado para aquisição de serviços, não existindo qualquer conversão para dinheiro real.',
                        en: 'The balance value on the gift card obtained through the loyalty card can only be used to purchase services, with no conversion to real money.',
                    },
                    {
                        pt: 'Caso o Serviço prestado pela "As Vaidosas" cesse atividade, o valor em saldo no cartão presente obtido através do cartão de fidelidade será perdido e não terá lugar a qualquer tipo de conversão para dinheiro real.',
                        en: 'If the Service provided by "As Vaidosas" ceases activity, the balance value on the gift card obtained through the loyalty card will be lost and there will be no conversion to real money.',
                    },
                    {
                        pt: 'Completar uma marcação e/ou atribuir uma avaliação não são garantias de acumulação de pontos no cartão de Fidelização. “As Vaidosas” reservam-se no direito de disponibilizar ou remover esta funcionalidade do serviço sempre que assim o considerarem aplicável.',
                        en: 'Completing a booking and/or assigning an evaluation are not guarantees of accumulating points on the Loyalty Card. "As Vaidosas" reserve the right to make this feature available or remove it from the service whenever they consider it applicable.',
                    },
                ],
            },
            {
                title: {
                    pt: 'Dados Pessoais',
                    en: 'Personal Data',
                },
                paragraphs: [
                    {
                        pt: 'O Utilizador garante que todos os dados pessoais/informação que disponibiliza à “As Vaidosas” são verdadeiros e actualizados.',
                        en: 'The User guarantees that all personal data/information provided to "As Vaidosas" is true and up to date.',
                    },
                    {
                        pt: 'O Utilizador responsabiliza-se exclusivamente por quaisquer declarações falsas ou inexactas que preste e pelos prejuízos que provoque, directa ou indirectamente, à “As Vaidosas” ou aos seus parceiros com a informação que faculte.',
                        en: 'The User is solely responsible for any false or inaccurate statements made and for the damages caused, directly or indirectly, to "As Vaidosas" or its partners with the information provided.',
                    },
                    {
                        pt: 'A “As Vaidosas” tratará os dados pessoais do Utilizador para efeitos de execução do Serviço e das marcações efetuadas nos Profissionais seleccionados, com respeito pela legislação aplicável. Os dados pessoais dos Utilizadores podem ser utilizados para fornecer ao Profissional informações importantes sobre o Serviço que o Utilizador pretende utilizar, como o pedido de marcação, alteração ou cancelamento de serviços.',
                        en: '"As Vaidosas" will process the User\'s personal data for the purpose of executing the Service and the bookings made at the selected Professionals, with respect for the applicable legislation. The personal data of the Users may be used to provide the Professional with important information about the Service that the User intends to use, such as the request for booking, changing or canceling services.',
                    },
                    {
                        pt: 'Adicionalmente, com o consentimento do Utilizador, podem ser enviadas informações sobre outros Serviços da “As Vaidosas” ou dos seus Parceiros. A “As Vaidosas” não comunicará a terceiros os dados pessoais do Utilizador sem o consentimento do mesmo.',
                        en: 'Additionally, with the User\'s consent, information about other Services of "As Vaidosas" or its Partners may be sent. "As Vaidosas" will not communicate the User\'s personal data to third parties without the User\'s consent.',
                    },
                    {
                        pt: 'O Utilizador pode consultar, alterar e eliminar os dados pessoais que comunicou à “As Vaidosas” dirigindo-lhe um pedido por escrito. Para mais informações consulte a Política de Privacidade.',
                        en: 'The User can consult, change and delete the personal data communicated to "As Vaidosas" by sending a written request. For more information, see the Privacy Policy.',
                    },
                    {
                        pt: 'Os dados pessoais que recolhemos destinam-se, exclusivamente, a ser tratados no âmbito das relações comerciais / contratuais e pré-contratuais estabelecidas. Asseguramos a protecção da privacidade dos seus dados pessoais, designadamente, pelo seu armazenamento no nosso sistema, protegendo-os contra perda, destruição, acesso não autorizado, alteração ou divulgação, em cumprimento do disposto no Regulamento Geral sobre a Protecção de Dados Pessoais (Regulamento nº 2016/679 de Parlamento Europeu e do Conselho de 27 de Abril de 2016).',
                        en: 'The personal data we collect is intended exclusively to be processed within the scope of the commercial/contractual and pre-contractual relationships established. We ensure the protection of the privacy of your personal data, namely, by storing it in our system, protecting it against loss, destruction, unauthorized access, alteration or disclosure, in compliance with the provisions of the General Regulation on the Protection of Personal Data (Regulation no. 2016/679 of the European Parliament and of the Council of 27 April 2016).',
                    },
                ],
            },
            {
                title: {
                    pt: 'Exclusão de Responsabilidade',
                    en: 'Exclusion of Liability',
                },
                paragraphs: [
                    {
                        pt: 'O Utilizador reconhece que o acesso e utilização da Aplicação poderá sofrer interrupções e que a informação presente na Aplicação pode conter bugs, erros, falhas técnicas, problemas ou outras limitações e que o acesso pode ser impossível em certos momentos. A “As Vaidosas” não é responsável por quaisquer danos causados pela utilização da Aplicação.',
                        en: 'The User acknowledges that access and use of the Application may be interrupted and that the information present in the Application may contain bugs, errors, technical failures, problems or other limitations and that access may be impossible at certain times. "As Vaidosas" is not responsible for any damages caused by the use of the Application.',
                    },
                    {
                        pt: 'Nos termos máximos permitidos por lei, a “As Vaidosas” exclui qualquer responsabilidade pela utilização do Serviço.',
                        en: 'To the maximum extent permitted by law, "As Vaidosas" excludes any liability for the use of the Service.',
                    },
                ],
            },
            {
                title: {
                    pt: 'Lei e Foro',
                    en: 'Law and jurisdiction',
                },
                paragraphs: [
                    {
                        pt: 'Às presentes Condições de Utilização aplica-se a lei Portuguesa. Para qualquer litígio que surja entre a “As Vaidosas” e o Utilizador serão exclusivamente competentes os tribunais judiciais da comarca do Porto.',
                        en: 'These Terms of Use are governed by Portuguese law. For any dispute that arises between "As Vaidosas" and the User, the judicial courts of the district of Porto will be exclusively competent.',
                    },
                ],
            },
            {
                title: {
                    pt: 'Contactos',
                    en: 'Contacts',
                },
                paragraphs: [
                    {
                        pt: 'Caso pretenda contactar a “As Vaidosas” acerca do conteúdo dos Termos e Condições de Utilização, ou outras questões, poderá fazê-lo através de email (geral@asvaidosas.pt).',
                        en: 'If you wish to contact "As Vaidosas" about the content of the Terms and Conditions of Use, or other issues, you can do so by email (geral@asvaidosas.pt).',
                    },
                ],
            },
        ],
        version: 'v2.0.0',
        date: new Date('2024-03-10'),
    },
    PrivacyPolicy: {
        intro: {
            pt: 'Esta Política de Privacidade descreve como o nosso serviço e a nossa aplicação coletam, usam e protegem as informações pessoais de clientes, parceiros e visitantes do nosso site/aplicação. A privacidade dos clientes, parceiros e visitantes é muito importante para nós e estamos comprometidos em proteger as suas informações pessoais.',
            en: 'This Privacy Policy describes how our service and our application collect, use and protect the personal information of clients, partners and visitors to our website/application. The privacy of clients, partners and visitors is very important to us and we are committed to protecting their personal information.',
        },
        sections: [
            {
                title: {
                    pt: 'Definições',
                    en: 'Definitions',
                },
                paragraphs: [
                    {
                        pt: 'Reconhece-se, para os efeitos do presente contrato, as definições seguintes.',
                        en: 'For the purposes of this contract, the following definitions are recognized.',
                    },
                    {
                        pt: '"Serviço" - conjunto de ferramentas de Pesquisa, Agendamento, Gestão e Marketing disponibilizadas pela "As Vaidosas";',
                        en: '"Service" - set of Research, Scheduling, Management and Marketing tools made available by "As Vaidosas";',
                    },
                    {
                        pt: '"As Vaidosas" - entidade prestadora do Serviço, legalmente representada pela em nome individual;',
                        en: '"As Vaidosas" - entity providing the Service, legally represented by in an individual capacity;',
                    },
                    {
                        pt: '"Profissional" - pessoa singular que utiliza o Serviço e que presta serviços próprios;',
                        en: '"Professional" - natural person who uses the Service and provides their own services;',
                    },
                    {
                        pt: '"Utilizador" - pessoa singular que utiliza o Serviço. Inclui todos os “Profissionais”.',
                        en: '"User" - natural person who uses the Service. Includes all "Professionals".',
                    },
                    {
                        pt: '"Conta" - Área Pessoal de cada Utilizador;',
                        en: '"Account" - Personal Area of each User;',
                    },
                    {
                        pt: '"Aplicação" - ferramenta onde é disponibilizado o Serviço, acessível através de download para smartphone Android na Google Play e iOS na App Store.',
                        en: '"Application" - tool where the Service is available, accessible through download for Android smartphone on Google Play and iOS on the App Store.',
                    },
                ],
            },
            {
                title: {
                    pt: 'Recolha de Dados Pessoais',
                    en: 'Collection of Personal Data',
                },
                paragraphs: [
                    {
                        pt: 'Sempre que necessário, a "As Vaidosas" solicitará ao Utilizador informações que o identifiquem pessoalmente (“dados pessoais”) e que permitam contactá-lo no sentido de executar o Serviço contratado, fornecer informação que tenha solicitado ou registar-se na aplicação.',
                        en: 'Whenever necessary, "As Vaidosas" will request from the User information that identifies them personally ("personal data") and that allows them to be contacted in order to execute the contracted Service, provide information that they have requested or register in the application.',
                    },
                    {
                        pt: 'Os dados pessoais que recolhemos podem incluir nome, endereço de correio electrónico, morada e telefone ou telemóvel.',
                        en: 'The personal data we collect may include name, email address, address and telephone or mobile phone number.',
                    },
                    {
                        pt: 'A Aplicação pode recolher determinadas informações sobre a visita do Utilizador, entre as quais: o nome do fornecedor de Serviços de Internet; o endereço do Protocolo Internet (IP) através do qual acedeu à Aplicação; a data e hora de acesso; as páginas da Aplicação que foram acedidas; o endereço Internet do website a partir do qual estabeleceu ligação à nossa Aplicação. Estas informações são utilizadas pela "As Vaidosas" no sentido de analisar as tendências e melhorar a experiência de utilização da Aplicação.',
                        en: 'The Application may collect certain information about the User\'s visit, including: the name of the Internet Service Provider; the Internet Protocol (IP) address through which you accessed the Application; the date and time of access; the pages of the Application that were accessed; the Internet address of the website from which you connected to our Application. This information is used by "As Vaidosas" to analyze trends and improve the user experience of the Application.',
                    },
                ],
            },
            {
                title: {
                    pt: 'Utilização dos Dados Pessoais',
                    en: 'Use of Personal Data',
                },
                paragraphs: [
                    {
                        pt: 'Os dados pessoais que recolhemos destinam-se, exclusivamente, a ser tratados no âmbito das relações comerciais / contratuais e pré-contratuais estabelecidas. Asseguramos a protecção da privacidade dos seus dados pessoais, designadamente, pelo seu armazenamento no nosso sistema, protegendo-os contra perda, destruição, acesso não autorizado, alteração ou divulgação, em cumprimento do disposto no Regulamento Geral sobre a Protecção de Dados Pessoais (Regulamento nº 2016/679 de Parlamento Europeu e do Conselho de 27 de Abril de 2016).',
                        en: 'The personal data we collect is intended exclusively to be processed within the scope of the commercial/contractual and pre-contractual relationships established. We ensure the protection of the privacy of your personal data, namely, by storing it in our system, protecting it against loss, destruction, unauthorized access, alteration or disclosure, in compliance with the provisions of the General Regulation on the Protection of Personal Data (Regulation no. 2016/679 of the European Parliament and of the Council of 27 April 2016).',
                    },
                    {
                        pt: 'A "As Vaidosas" tratará os dados pessoais do Utilizador para efeitos de execução do Serviço e das marcações efetuadas nos Profissionais seleccionados, com respeito pela legislação aplicável. Os dados pessoais dos Utilizadores podem ser utilizados para fornecer ao Profissional informações importantes sobre o Serviço que o Utilizador pretende utilizar, como o pedido de marcação, alteração ou cancelamento de serviços.',
                        en: '"As Vaidosas" will process the User\'s personal data for the purpose of executing the Service and the bookings made at the selected Professionals, with respect for the applicable legislation. The personal data of the Users may be used to provide the Professional with important information about the Service that the User intends to use, such as the request for booking, changing or canceling services.',
                    },
                    {
                        pt: 'A "As Vaidosas" pode efetuar periodicamente comunicações electrónicas com o Utilizador no sentido de o informar sobre serviços técnicos, questões de segurança, realizar inquéritos ou confirmar a execução do Serviço que solicitou. O contacto com o Utilizador é uma parte intrínseca do Serviço prestado pela "As Vaidosas". Não será possível ao Utilizador efetuar o cancelamento da recepção deste tipo de mensagens, uma vez que são consideradas como essenciais para a prestação do Serviço contratado.',
                        en: '"As Vaidosas" may periodically make electronic communications with the User in order to inform them about technical services, security issues, conduct surveys or confirm the execution of the Service they requested. Contact with the User is an intrinsic part of the Service provided by "As Vaidosas". The User will not be able to cancel the receipt of this type of messages, as they are considered essential for the provision of the contracted Service.',
                    },
                    {
                        pt: 'Adicionalmente, com o consentimento do Utilizador, podem ser enviadas informações sobre outros Serviços da "As Vaidosas" ou dos seus Parceiros. A "As Vaidosas" não comunicará a terceiros os dados pessoais do Utilizador sem o consentimento do mesmo.',
                        en: 'Additionally, with the User\'s consent, information about other Services of "As Vaidosas" or its Partners may be sent. "As Vaidosas" will not communicate the User\'s personal data to third parties without the User\'s consent.',
                    },
                    {
                        pt: 'A "As Vaidosas" pode contratar empresas parceiras para fornecerem serviços limitados em nome de "As Vaidosas" como, por exemplo, o alojamento de páginas da Aplicação, o envio de correio electrónico ou mensagens SMS ao Utilizador, o processamento da faturação de serviços, entre outros serviços. São fornecidas a estas empresas apenas os dados pessoais necessários para a prestação do respetivo serviço. As empresas parceiras estão obrigadas a manter a total confidencialidade das informações processadas e estão proibidas de utilizar as informações para outros fins, actuando sempre como subcontratantes da "As Vaidosas".',
                        en: '"As Vaidosas" may contract partner companies to provide limited services on behalf of "As Vaidosas" such as, for example, hosting Application pages, sending email or SMS messages to the User, processing service billing, among other services. Only the personal data necessary for the provision of the respective service is provided to these partner companies. Partner companies are required to maintain full confidentiality of the processed information and are prohibited from using the information for other purposes, always acting as subcontractors of "As Vaidosas".',
                    },
                    {
                        pt: 'A "As Vaidosas" pode revelar os dados pessoais do Utilizador se tal for exigido por Lei ou, de boa-fé, acreditar que tal é necessário para: (a) estar em conformidade com a Lei; (b) proteger e defender os direitos do Utilizador e da "As Vaidosas"; (c) garantir a segurança dos Utilizadores ou Parceiros.',
                        en: '"As Vaidosas" may disclose the User\'s personal data if required by Law or, in good faith, believe that it is necessary to: (a) comply with the Law; (b) protect and defend the rights of the User and "As Vaidosas"; (c) ensure the safety of Users or Partners.',
                    },
                ],
            },
            {
                title: {
                    pt: 'Segurança e Controlo dos Dados Pessoais',
                    en: 'Security and Control of Personal Data',
                },
                paragraphs: [
                    {
                        pt: 'A "As Vaidosas" está empenhada em garantir a segurança dos dados pessoais dos Utilizadores. Utilizamos uma variedade de tecnologias e procedimentos de segurança para ajudar a proteger os dados pessoais contra acesso, utilização ou divulgação não autorizada, nomeadamente, o armazenamento de dados em servidores localizados em instalações controladas e com acesso limitado. Não obstante, a "As Vaidosas" não se responsabiliza por actos de terceiros.',
                        en: '"As Vaidosas" is committed to ensuring the security of Users\' personal data. We use a variety of security technologies and procedures to help protect personal data against unauthorized access, use or disclosure, including data storage on servers located in controlled facilities with limited access. However, "As Vaidosas" is not responsible for acts of third parties.',
                    },
                    {
                        pt: 'Os dados pessoais do Utilizador não serão partilhados com terceiros para efeitos de tratamentos dos dados, sem a permissão do Utilizador.',
                        en: "The User's personal data will not be shared with third parties for data processing purposes, without the User's permission.",
                    },
                ],
            },
            {
                title: {
                    pt: 'Proteção de Dados Pessoais de Crianças',
                    en: "Protection of Children's Personal Data",
                },
                paragraphs: [
                    {
                        pt: 'A nossa Aplicação é destinada audiências gerais e não recolhe intencionalmente informações sobre crianças.',
                        en: 'Our App is intended for general audiences and does not intentionally collect information about children.',
                    },
                ],
            },
            {
                title: {
                    pt: 'Cessação da Conta',
                    en: 'Account Termination',
                },
                paragraphs: [
                    {
                        pt: 'O Utilizador pode cessar e eliminar a sua conta e os seus dados na plataforma a qualquer momento. Para isso basta notificar o "As Vaidosas" a qualquer altura.',
                        en: 'The User can terminate and delete their account and data on the platform at any time. To do this, simply notify "As Vaidosas" at any time.',
                    },
                ],
            },
            {
                title: {
                    pt: 'Contactos',
                    en: 'Contacts',
                },
                paragraphs: [
                    {
                        pt: 'Caso pretenda contactar a "As Vaidosas" acerca do conteúdo da Política de Privacidade, ou outras questões, poderá fazê-lo através de email (geral@asvaidosas.pt).',
                        en: 'If you wish to contact "As Vaidosas" about the content of the Privacy Policy, or other issues, you can do so by email (geral@asvaidosas.pt).',
                    },
                ],
            },
        ],
        version: 'v2.0.0',
        date: new Date('2024-03-10'),
    },
};
