import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import DirectionsIcon from '@mui/icons-material/Directions';
import EditIcon from '@mui/icons-material/Edit';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import EventIcon from '@mui/icons-material/Event';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fade,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography,
} from '@mui/material';
import i18n from 'i18next';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Container } from '../../../components/container/container';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { ErrorState } from '../../../components/error-state/error-state';
import { Loader } from '../../../components/loader/loader';
import { OfficeTitle } from '../../../components/office-title/office-title';
import { Page } from '../../../components/page/page';
import PhoneNumberInput from '../../../components/phone-number-input/phone-number-input';
import { SuccessCheck } from '../../../components/success-check/success-check';
import { AppContext } from '../../../contexts/app/context';
import { AppContextType } from '../../../contexts/app/types';
import { AuthContext } from '../../../contexts/auth/context';
import { AuthContextType } from '../../../contexts/auth/types';
import { ServiceCategories } from '../../../databases/service-categories';
import { LocalStorageKey } from '../../../enums/local-storage-key';
import { AppLanguageTag } from '../../../i18n/types';
import { BookingsRepository } from '../../../repositories/bookings';
import { RoutesNames } from '../../../routes-names';
import { cosineDistanceBetweenPoints } from '../../../utils/calculate-geo-distance';
import { moveElementOnArray } from '../../../utils/ts-utils';
import variables from '../../../variables.module.scss';

export function OfficeNewAppointment() {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);
    const authContext: AuthContextType = useContext(AuthContext);

    // Get query
    const query = new URLSearchParams(window.location.search);
    const queryValues = {
        redirectedFrom: query.get('redirectedFrom'),
    };

    // Translations
    const { t } = useTranslation();

    // Get navigation
    const navigate = useNavigate();

    // Hold State
    const [name, setName] = useState<string>(
        authContext.state.me?.user?.firstName
            ? `${authContext.state.me?.user?.firstName} ${
                  authContext.state.me?.user?.lastName ? authContext.state.me?.user?.lastName : ''
              }`.trim()
            : localStorage.getItem(LocalStorageKey.AppointmentClientName) ?? '',
    );
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
        authContext.state.me?.user?.phoneNumber ??
            localStorage.getItem(LocalStorageKey.AppointmentClientPhoneNumber) ??
            '',
    );
    const [observations, setObservations] = useState<string>(
        localStorage.getItem(LocalStorageKey.AppointmentClientObservations) ?? '',
    );
    const [termsAccepted, setTermsAccepted] = useState<boolean>(
        queryValues.redirectedFrom === RoutesNames.SignIn ?? false,
    );
    const [distanceConfirmDialogOpen, setDistanceConfirmDialogOpen] = useState<boolean>(false);
    const [distanceFromProviderInKm, setDistanceFromProviderInKm] = useState<number>(-1);

    // Hold State (for errors)
    const [nameError, setNameError] = useState<boolean>(false);
    const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
    const [termsNotAccepted, setTermsNotAccepted] = useState<boolean>(false);
    const [submitButtonError, setSubmitButtonError] = useState<boolean>(false);

    // Hold State (for UI)
    const [currentStage, setCurrentStage] = useState<number>(0);
    const [currentState, setCurrentState] = useState<number>(0); // 0: Loading, 1: Success, 2: Error
    const [backToStartCountdown, setBackToStartCountdown] = useState<number>(-1);
    const [errorMessage, setErrorMessage] = useState<string>(t('routes.office.newAppointment.errorTitle'));
    const [errorCode, setErrorCode] = useState<string | null>(null);

    // Init
    let newTitle = '';
    let newTitleSub = '';

    // Eval
    if (appContext.state.appointment) {
        if (appContext.state.appointment.startTime.isSame(moment(), 'day')) {
            newTitle = t('components.timeTraveller.today');
        } else if (appContext.state.appointment.startTime.isSame(moment().add(1, 'day'), 'day')) {
            newTitle = t('components.timeTraveller.tomorrow');
        } else if (appContext.state.appointment.startTime.isSame(moment().subtract(1, 'day'), 'day')) {
            newTitle = t('components.timeTraveller.yesterday');
        } else {
            newTitle = appContext.state.appointment.startTime.fromNow();
        }

        // Add day of the week
        newTitleSub = `${appContext.state.appointment.startTime.format(
            'dddd',
        )}, ${appContext.state.appointment.startTime.format('DD/MM/YYYY HH:mm')}`;
    }

    // Generate maps link
    const mapsLink = `https://www.google.com/maps/search/?api=1&query=${appContext.state.appointment?.provider.location?.coordinates[1]},${appContext.state.appointment?.provider.location?.coordinates[0]}`;

    // Calculate start and end times
    let accumulatedTime = 0;
    const times: Array<{
        start: moment.Moment;
        end: moment.Moment;
    }> = [];
    if (appContext.state.appointment) {
        for (const providerService of appContext.state.appointment?.providerServices) {
            // Get duration
            const duration = providerService.durationInMinutes ?? 0;

            // Calculate start
            const start = appContext.state.appointment.startTime.clone().add(accumulatedTime, 'minutes');

            // Calculate end
            const end = start.clone().add(duration, 'minutes');

            // Add
            times.push({
                start: start,
                end: end,
            });

            // Accumulate
            accumulatedTime += duration;
        }
    }

    // Actions: Submit
    const submit = (distanceConfirmed?: boolean) => {
        // Clear all errors
        setNameError(false);
        setPhoneNumberError(false);
        setTermsNotAccepted(false);
        setErrorMessage('');
        setErrorCode(null);

        // Flag
        let hasErrors = false;

        // Validate Name
        if (!name || name.trim() === '') {
            setNameError(true);
            hasErrors = true;
        }

        // Validate Phone Number
        if (!phoneNumber || phoneNumber.trim() === '') {
            setPhoneNumberError(true);
            hasErrors = true;
        }

        // Validate Terms
        if (!termsAccepted) {
            setTermsNotAccepted(true);
            hasErrors = true;
        }

        // Compare distances
        if (
            !queryValues.redirectedFrom &&
            !distanceConfirmed &&
            appContext.state.userLocation.cityId !== appContext.state.appointment?.provider.cityId
        ) {
            // Calculate distance
            let distance = 0;
            try {
                distance = cosineDistanceBetweenPoints(
                    appContext.state.userLocation.coordinates[1],
                    appContext.state.userLocation.coordinates[0],
                    appContext.state.appointment?.provider.location?.coordinates[1] ?? 0,
                    appContext.state.appointment?.provider.location?.coordinates[0] ?? 0,
                );
            } catch (e) {
                console.log(e);
            }

            // Eval
            if (distance > 10000) {
                // Open confirm dialog
                setDistanceFromProviderInKm(Math.round(distance / 1000));
                setDistanceConfirmDialogOpen(true);

                // Stop execution here
                return;
            }
        }

        // Check for errors
        if (!hasErrors) {
            // Check auth state
            if (!authContext.state.firebaseUser) {
                // User not signed in, needs to sign in to book
                navigate(
                    `/${RoutesNames.SignIn}/?phoneNumber=${phoneNumber}&redirectTo=${RoutesNames.Office_NewAppointment}`,
                );

                // Stop execution here
                return;
            }

            // Change stage
            setCurrentStage(1);

            // Sent to backend
            (async () => {
                if (appContext.state.appointment) {
                    try {
                        await BookingsRepository.instance.createAppointment({
                            // Booking
                            providerId: appContext.state.appointment.provider.id ?? '',
                            servicesIds: appContext.state.appointment.providerServices.map((providerService) => {
                                return providerService.serviceId;
                            }),
                            startTime: appContext.state.appointment.startTime.toDate(),
                            // Client
                            clientName: name,
                            clientPhoneNumber: phoneNumber ?? '',
                            clientNotes: observations.trim() !== '' ? observations : undefined,
                        });

                        // Success
                        setCurrentState(1);

                        // If the user is the same as the one logged in
                        if (authContext.state.firebaseUser && authContext.state.me?.user?.phoneNumber === phoneNumber) {
                            // Set countdown to go back to start
                            setBackToStartCountdown(10);
                        }
                    } catch (error: any) {
                        // Look for a status code
                        if (
                            error.response &&
                            error.response.data &&
                            error.response.data.errors &&
                            error.response.data.errors.length > 0
                        ) {
                            // Display the error message
                            console.warn(error.response.data.errors[0].code);
                            console.log(error.response.status);

                            // Set error message
                            setErrorMessage(
                                t(`routes.office.newAppointment.errors.${error.response.data.errors[0].code}`),
                            );
                            setErrorCode(error.response.data.errors[0].code);
                        } else {
                            // Default error
                            setErrorMessage(t('routes.office.newAppointment.errorTitle'));
                        }

                        // Set error
                        setCurrentState(2);
                    }
                }
            })();
        } else {
            // Set button error
            setSubmitButtonError(true);

            // Set timeout
            setTimeout(() => {
                setSubmitButtonError(false);
            }, 1000);
        }
    };

    // On Countdown change
    useEffect(() => {
        var timeout: NodeJS.Timeout;
        if (backToStartCountdown === 0) {
            // Go to Home
            navigate(
                `/${RoutesNames.Office}/${RoutesNames.Office_Main}/${RoutesNames.Office_Main_Home}?refreshMe=true`,
            );
        } else if (backToStartCountdown !== -1) {
            timeout = setTimeout(() => {
                setBackToStartCountdown(backToStartCountdown - 1);
            }, 1000);
        }

        // On unmount
        return () => {
            clearTimeout(timeout);
        };
    }, [backToStartCountdown, navigate]);

    // On mount
    useEffect(() => {
        // Scroll to top
        window.scrollTo(0, 0);

        // On unmount
        return () => {
            // Clear cart
            if (currentState === 1) {
                appContext.setAppointment(undefined);
                appContext.setServices([]);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState]);

    // Listen for form changes
    useEffect(() => {
        // Save to local storage
        // Name
        if (name && name.trim() !== '') {
            localStorage.setItem(LocalStorageKey.AppointmentClientName, name.trim());
        } else {
            localStorage.removeItem(LocalStorageKey.AppointmentClientName);
        }

        // Phone Number
        if (phoneNumber) {
            localStorage.setItem(LocalStorageKey.AppointmentClientPhoneNumber, phoneNumber);
        } else {
            localStorage.removeItem(LocalStorageKey.AppointmentClientPhoneNumber);
        }

        // Observations
        if (observations && observations.trim() !== '') {
            localStorage.setItem(LocalStorageKey.AppointmentClientObservations, observations.trim());
        } else {
            localStorage.removeItem(LocalStorageKey.AppointmentClientObservations);
        }
    }, [name, phoneNumber, observations]);

    // On mount
    useEffect(() => {
        // Eval redirectedFrom
        if (queryValues.redirectedFrom && queryValues.redirectedFrom === RoutesNames.SignIn) {
            // Submit form
            submit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Build
    return (
        <Page
            title={t('routes.office.newAppointment.title')}
            hideBackButton={currentStage === 1}
            hideTitle={currentStage === 1}
            // actions={[
            //     <IconButton
            //         aria-label="exit"
            //         onClick={() => {
            //             navigate(`/${RoutesNames.Office}`);
            //         }}
            //     >
            //         <HomeIcon />
            //     </IconButton>,
            // ]}
        >
            <Container>
                {!appContext.state.appointment && (
                    <EmptyState
                        key={`new-appointment-empty`}
                        icon={<EditCalendarIcon />}
                        title={t('routes.office.newAppointment.emptyTitle')}
                        description={t('routes.office.newAppointment.emptyDescription')}
                        actions={[
                            <Button
                                variant="contained"
                                onClick={() => {
                                    // Go to Schedule
                                    navigate(`/${RoutesNames.Office}/${RoutesNames.Office_ServicePicker}`);
                                }}
                            >
                                {t('routes.office.newAppointment.selectServices')}
                            </Button>,
                        ]}
                    />
                )}
                {appContext.state.appointment && currentStage === 0 && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',
                        }}
                    >
                        <Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                }}
                            >
                                <Box
                                    sx={{
                                        flex: 1,
                                    }}
                                >
                                    <OfficeTitle
                                        color="#ff66c4"
                                        text={t('routes.office.newAppointment.when')}
                                        align="left"
                                    />
                                </Box>
                                <IconButton
                                    sx={{
                                        marginRight: '-8px',
                                    }}
                                    aria-label="edit"
                                    onClick={() => {
                                        navigate(
                                            `/${RoutesNames.Office}/${RoutesNames.Office_Showcase}/${appContext.state
                                                .appointment?.provider
                                                .id}?schedule=true&day=${appContext.state.appointment?.startTime.format(
                                                'YYYY-MM-DD',
                                            )}`,
                                        );
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Box>
                            <Typography variant="body1" gutterBottom>
                                {newTitleSub}
                            </Typography>
                            <Typography variant="body2">{newTitle}</Typography>
                        </Box>
                        <Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                }}
                            >
                                <Box
                                    sx={{
                                        flex: 1,
                                    }}
                                >
                                    <OfficeTitle
                                        color="#ff66c4"
                                        text={t('routes.office.newAppointment.what')}
                                        align="left"
                                    />
                                </Box>
                                <IconButton
                                    sx={{
                                        marginRight: '-8px',
                                    }}
                                    aria-label="edit"
                                    onClick={() => {
                                        navigate(`/${RoutesNames.Office}/${RoutesNames.Office_ServicePicker}`);
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '8px',
                                }}
                            >
                                {appContext.state.appointment.providerServices.map((providerService, index) => {
                                    return (
                                        <Box key={`provider-service-${index}`}>
                                            {times[index] && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        gap: '8px',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            flex: 1,
                                                        }}
                                                    >{`${times[index].start.format('HH:mm')} - ${times[
                                                        index
                                                    ].end.format('HH:mm')}`}</Box>
                                                    {times.length > 1 && (
                                                        <>
                                                            <IconButton
                                                                size="small"
                                                                aria-label="up"
                                                                onClick={() => {
                                                                    // Move up
                                                                    if (appContext.state.appointment?.provider) {
                                                                        appContext.setAppointment({
                                                                            ...appContext.state.appointment,
                                                                            providerServices: moveElementOnArray(
                                                                                appContext.state.appointment
                                                                                    .providerServices,
                                                                                index,
                                                                                index - 1,
                                                                            ),
                                                                        });
                                                                    }
                                                                }}
                                                                disabled={index === 0}
                                                            >
                                                                <ArrowUpwardIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                size="small"
                                                                aria-label="down"
                                                                onClick={() => {
                                                                    // Move down
                                                                    if (appContext.state.appointment?.provider) {
                                                                        appContext.setAppointment({
                                                                            ...appContext.state.appointment,
                                                                            providerServices: moveElementOnArray(
                                                                                appContext.state.appointment
                                                                                    .providerServices,
                                                                                index,
                                                                                index + 1,
                                                                            ),
                                                                        });
                                                                    }
                                                                }}
                                                                disabled={index === times.length - 1}
                                                            >
                                                                <ArrowDownwardIcon />
                                                            </IconButton>
                                                        </>
                                                    )}
                                                </Box>
                                            )}
                                            {providerService.service && (
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        gap: '8px',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                        marginTop:
                                                            appContext.state.appointment?.providerServices.length === 1
                                                                ? '8px'
                                                                : '0',
                                                    }}
                                                >
                                                    {providerService.service.category && (
                                                        <Box
                                                            sx={{
                                                                paddingLeft: '8px',
                                                                paddingRight: '8px',
                                                                paddingTop: '2px',
                                                                paddingBottom: '2px',
                                                                backgroundColor:
                                                                    ServiceCategories[providerService.service.category]
                                                                        .color,
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                borderRadius: '24px',
                                                                color: 'white',
                                                            }}
                                                        >
                                                            <Typography variant="caption" fontWeight={500}>
                                                                {t(
                                                                    ServiceCategories[providerService.service.category]
                                                                        .translationKey,
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                    <Typography
                                                        variant="caption"
                                                        fontWeight={500}
                                                        textAlign={'left'}
                                                        sx={{
                                                            flex: 1,
                                                        }}
                                                    >
                                                        {providerService.service.name}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Box>
                        <Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                }}
                            >
                                <Box
                                    sx={{
                                        flex: 1,
                                    }}
                                >
                                    <OfficeTitle
                                        color="#ff66c4"
                                        text={t('routes.office.newAppointment.who')}
                                        align="left"
                                    />
                                </Box>
                                <IconButton
                                    sx={{
                                        marginRight: '-8px',
                                    }}
                                    aria-label="edit"
                                    onClick={() => {
                                        navigate(`/${RoutesNames.Office}/${RoutesNames.Office_ProviderPicker}`);
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flex: 1,
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '56px',
                                        height: '56px',
                                        borderRadius: '50%',
                                        overflow: 'hidden',
                                        border: '2px solid',
                                        borderColor: variables.firstColor,
                                    }}
                                >
                                    <img
                                        src={appContext.state.appointment.provider.pictureUrl}
                                        alt={appContext.state.appointment.provider.name}
                                        style={{
                                            objectFit: 'cover',
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                        paddingLeft: '12px',
                                    }}
                                >
                                    <Typography variant="h6" fontWeight="bold">
                                        {appContext.state.appointment.provider.name}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    paddingLeft: '68px',
                                }}
                            >
                                <Typography variant="subtitle2">
                                    {appContext.state.appointment.provider.address}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '8px',
                                    marginTop: '8px',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                }}
                            >
                                {/* {appContext.state.appointment.provider.phoneNumber && (
                                    <Button
                                        size="small"
                                        sx={{
                                            height: '32px',
                                        }}
                                        variant="outlined"
                                        endIcon={<CallIcon />}
                                        target="_blank"
                                        href={`tel:${appContext.state.appointment.provider.phoneNumber}`}
                                    >
                                        {t('routes.office.showcase.call')}
                                    </Button>
                                )}
                                {appContext.state.appointment.provider.phoneNumber && (
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        endIcon={<WhatsAppIcon />}
                                        sx={{
                                            borderColor: '#128c7e',
                                            color: '#128c7e',
                                            height: '32px',
                                        }}
                                        target="_blank"
                                        href={`https://wa.me/${appContext.state.appointment.provider.phoneNumber.replace(
                                            '+',
                                            '',
                                        )}`}
                                    >
                                        WhatsApp
                                    </Button>
                                )} */}
                                <Button
                                    size="small"
                                    color="secondary"
                                    variant="outlined"
                                    endIcon={<DirectionsIcon />}
                                    sx={{
                                        height: '32px',
                                    }}
                                    href={mapsLink}
                                    target="_blank"
                                >
                                    {t('routes.office.newAppointment.directions')}
                                </Button>
                            </Box>
                        </Box>
                        <Box>
                            <OfficeTitle color="#ff66c4" text={t('routes.office.newAppointment.you')} align="left" />
                            <Box
                                sx={{
                                    marginTop: '16px',
                                    marginBottom: '16px',
                                }}
                            >
                                <PhoneNumberInput
                                    disabled={!!authContext.state.firebaseUser}
                                    error={phoneNumberError}
                                    variant="outlined"
                                    value={phoneNumber}
                                    onChange={(value: string | undefined) => {
                                        // Clear errors
                                        setPhoneNumberError(false);

                                        // Set phone number
                                        setPhoneNumber(value);
                                    }}
                                />
                            </Box>
                            <FormControl
                                fullWidth
                                sx={{
                                    marginBottom: '16px',
                                }}
                            >
                                <InputLabel id="first-and-last-names-label">
                                    {t('routes.office.newAppointment.form.firstAndLastName')}*
                                </InputLabel>
                                <OutlinedInput
                                    error={nameError}
                                    id="first-and-last-names"
                                    value={name}
                                    fullWidth
                                    label={`${t('routes.office.newAppointment.form.firstAndLastName')}*`}
                                    onChange={(event) => {
                                        // Clear errors
                                        setNameError(false);

                                        // Set name
                                        setName(event.target.value);
                                    }}
                                    endAdornment={
                                        name && name.trim() !== '' ? (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="clear-name"
                                                    onClick={() => {
                                                        setName('');
                                                    }}
                                                    edge="end"
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ) : null
                                    }
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="observations-label">
                                    {t('routes.office.newAppointment.form.observations')}
                                </InputLabel>
                                <OutlinedInput
                                    multiline
                                    rows={2}
                                    id="observations"
                                    value={observations}
                                    fullWidth
                                    label={t('routes.office.newAppointment.form.observations')}
                                    onChange={(event) => {
                                        // Set observations
                                        setObservations(event.target.value);
                                    }}
                                />
                            </FormControl>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={termsAccepted}
                                            onChange={(event) => {
                                                setTermsAccepted(event.target.checked);
                                            }}
                                            sx={{
                                                color: termsNotAccepted ? 'red' : 'text.primary',
                                            }}
                                        />
                                    }
                                    label={
                                        <Box
                                            sx={{
                                                fontSize: '14px',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                paddingTop: '16px',
                                                'a, a:hover, a:visited, a:active': {
                                                    color: 'inherit',
                                                    textDecoration: 'underline',
                                                },
                                            }}
                                        >
                                            {i18n.language === AppLanguageTag.ptPT && (
                                                <Typography variant="caption" gutterBottom textAlign="left" sx={{}}>
                                                    Li e concordo com as{' '}
                                                    <Link to={`/${RoutesNames.TermsOfUse}`}>Condições Gerais</Link> e{' '}
                                                    <Link to={`/${RoutesNames.PrivacyPolicy}`}>
                                                        Política de Privacidade
                                                    </Link>
                                                    .
                                                </Typography>
                                            )}
                                            {i18n.language === AppLanguageTag.enUS && (
                                                <Typography variant="caption" gutterBottom textAlign="left" sx={{}}>
                                                    I have read and agree to the{' '}
                                                    <Link to={`/${RoutesNames.TermsOfUse}`}>General Conditions</Link>{' '}
                                                    and <Link to={`/${RoutesNames.PrivacyPolicy}`}>Privacy Policy</Link>
                                                    .
                                                </Typography>
                                            )}
                                        </Box>
                                    }
                                />
                            </FormGroup>
                        </Box>
                        <Box>
                            <Button
                                variant="contained"
                                endIcon={<CheckCircleOutlineIcon />}
                                fullWidth
                                onClick={() => {
                                    // Submit
                                    submit();
                                }}
                                color={submitButtonError ? 'error' : 'primary'}
                            >
                                {t('routes.office.newAppointment.submit')}
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                height: '32px',
                            }}
                        ></Box>
                    </Box>
                )}
                {appContext.state.appointment && currentStage === 1 && (
                    <Box>
                        {currentState === 0 && (
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '32px',
                                }}
                            >
                                <Loader size={48} />
                            </Box>
                        )}
                        {currentState === 1 && (
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '32px',
                                    flexDirection: 'column',
                                }}
                            >
                                <SuccessCheck />
                                <Fade
                                    in={true}
                                    style={{
                                        transitionDelay: '1000ms',
                                    }}
                                >
                                    <Box>
                                        <OfficeTitle
                                            color="#ff66c4"
                                            text={t('routes.office.newAppointment.successTitle')}
                                            align="center"
                                        />
                                    </Box>
                                </Fade>
                                <Fade
                                    in={true}
                                    style={{
                                        transitionDelay: '2000ms',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            marginTop: '16px',
                                        }}
                                    >
                                        <Typography variant="body2" align="center">
                                            {t('routes.office.newAppointment.successDescription', {
                                                provider: appContext.state.appointment.provider.name.split(' ')[0],
                                            })}
                                        </Typography>
                                    </Box>
                                </Fade>
                                <Fade
                                    in={true}
                                    style={{
                                        transitionDelay: '5000ms',
                                    }}
                                >
                                    <Box>
                                        <Box
                                            sx={{
                                                marginTop: '48px',
                                            }}
                                        >
                                            {!authContext.state.firebaseUser && (
                                                <Alert severity="info">
                                                    <AlertTitle>
                                                        {t('routes.office.newAppointment.notSignedIn.title')}
                                                    </AlertTitle>
                                                    {t('routes.office.newAppointment.notSignedIn.description')}
                                                </Alert>
                                            )}
                                            {authContext.state.firebaseUser &&
                                                authContext.state.me?.user?.phoneNumber !== phoneNumber && (
                                                    <Alert
                                                        severity="warning"
                                                        sx={{
                                                            marginBottom: '24px',
                                                        }}
                                                    >
                                                        <AlertTitle>
                                                            {t('routes.office.newAppointment.diffPhoneNumber.title')}
                                                        </AlertTitle>
                                                        {t('routes.office.newAppointment.diffPhoneNumber.description')}
                                                    </Alert>
                                                )}
                                            {authContext.state.firebaseUser &&
                                                authContext.state.me?.user?.phoneNumber === phoneNumber && (
                                                    <Typography variant="body2" align="center">
                                                        {`${t(
                                                            'routes.office.newAppointment.goingBackToStart',
                                                        )} (${backToStartCountdown})`}
                                                    </Typography>
                                                )}
                                        </Box>
                                        {!authContext.state.firebaseUser && (
                                            <Button
                                                variant="contained"
                                                endIcon={<LoginIcon />}
                                                fullWidth
                                                sx={{
                                                    marginTop: '8px',
                                                    marginBottom: '24px',
                                                }}
                                                onClick={() => {
                                                    // Replace state
                                                    navigate(`/${RoutesNames.Office}`, {
                                                        replace: true,
                                                    });

                                                    // Go to Sign in
                                                    navigate(`/${RoutesNames.SignIn}?phoneNumber=${phoneNumber}`);
                                                }}
                                            >
                                                {t('routes.signIn.enter')}
                                            </Button>
                                        )}
                                        <Button
                                            variant={authContext.state.firebaseUser ? 'contained' : 'outlined'}
                                            startIcon={<HomeIcon />}
                                            fullWidth
                                            sx={{
                                                marginTop: '8px',
                                            }}
                                            onClick={() => {
                                                // Go to Home
                                                navigate(
                                                    `/${RoutesNames.Office}/${RoutesNames.Office_Main}/${RoutesNames.Office_Main_Home}?refreshMe=true`,
                                                );
                                            }}
                                        >
                                            {t('routes.office.newAppointment.startMenu')}
                                        </Button>
                                    </Box>
                                </Fade>
                            </Box>
                        )}
                        {currentState === 2 && (
                            <ErrorState
                                icon={<EventIcon />}
                                onTryAgainClick={() => {
                                    if (errorCode === 'user-is-blocked-from-booking') {
                                        // Reset state
                                        navigate(
                                            `/${RoutesNames.Office}/${RoutesNames.Office_Main}/${RoutesNames.Office_Main_More}`,
                                        );
                                    } else {
                                        // Reset state
                                        navigate(`/${RoutesNames.Office}/${RoutesNames.Office_ServicePicker}`);
                                    }
                                }}
                                text={errorMessage}
                            />
                        )}
                    </Box>
                )}
            </Container>
            <Dialog
                open={distanceConfirmDialogOpen}
                onClose={() => {
                    setDistanceConfirmDialogOpen(false);
                }}
                aria-labelledby="alert-distance-dialog-title"
                aria-describedby="alert-distance-dialog-description"
            >
                <DialogTitle fontWeight="bold" id="alert-distance-dialog-title">
                    ⚠️ {t('routes.office.newAppointment.distanceWarningDialog.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-distance-dialog-description">
                        <Trans
                            i18nKey="routes.office.newAppointment.distanceWarningDialog.description"
                            values={{
                                providerName: appContext.state.appointment?.provider.name.split(' ')[0],
                                cityName: appContext.state.appointment?.provider.city?.name,
                                distance: distanceFromProviderInKm,
                            }}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="inherit"
                        onClick={() => {
                            setDistanceConfirmDialogOpen(false);
                        }}
                    >
                        {t('routes.office.newAppointment.distanceWarningDialog.cancel')}
                    </Button>
                    <Button
                        color="warning"
                        onClick={() => {
                            // Close
                            setDistanceConfirmDialogOpen(false);

                            // Submit with distance confirmed
                            submit(true);
                        }}
                        autoFocus
                    >
                        {t('routes.office.newAppointment.distanceWarningDialog.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Page>
    );
}
