import { Environment } from '../enums/environment';

export class EnvironmentManager {
    // Singleton Instance
    private static _instance: EnvironmentManager;

    // Private constructor
    private constructor() {}

    static get instance(): EnvironmentManager {
        if (!this._instance) {
            this._instance = new EnvironmentManager();
        }

        return this._instance;
    }

    get current(): Environment {
        // Look for app domain
        if (window.location.hostname === 'app.asvaidosas.pt') {
            return Environment.Production;
        } else {
            return Environment.Localhost;
        }
    }
}
