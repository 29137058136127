import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';

export function TimeTravellerNav({
    value,
    onChange,
    disabled,
    mode,
    minDate,
}: {
    value: moment.Moment;
    onChange?: (value: moment.Moment) => void;
    disabled?: boolean;
    mode?: 'day' | 'month';
    minDate?: moment.Moment;
}) {
    // Hold State
    const [pickerOpen, setPickerOpen] = useState<boolean>(false);

    // Calculate view modes
    const viewModes = ['year'];
    if (!mode || mode === 'day') {
        viewModes.push('month');
        viewModes.push('day');
    } else if (mode === 'month') {
        viewModes.push('month');
    }

    // Render
    return (
        <Box
            sx={{
                display: 'flex',
                maxWidth: '425px',
                width: '100%',
            }}
        >
            <Button
                variant="contained"
                startIcon={<ArrowBackIosNewIcon />}
                sx={{
                    minWidth: '0px',
                    span: {
                        margin: 0,
                    },
                }}
                onClick={() => {
                    if (onChange) {
                        if (!mode || mode === 'day') {
                            onChange(moment(value).subtract(1, 'day'));
                        } else if (mode === 'month') {
                            onChange(moment(value).subtract(1, 'month'));
                        }
                    }
                }}
                disabled={disabled || (minDate && value.isSameOrBefore(minDate, 'day'))}
            ></Button>
            <Button
                variant="contained"
                sx={{
                    flex: 1,
                    marginLeft: '8px',
                    marginRight: '8px',
                    textAlign: 'center',
                }}
                onClick={() => {
                    setPickerOpen(true);
                }}
                disabled={disabled}
            >
                {(!mode || mode === 'day') && value.format('LL')}
                {mode && mode === 'month' && value.format('MMMM, YYYY')}
            </Button>
            <MobileDatePicker
                minDate={minDate}
                open={pickerOpen}
                sx={{
                    display: 'none',
                }}
                views={viewModes as any}
                value={value}
                onAccept={(newValue: moment.Moment | null) => {
                    if (newValue) {
                        onChange && onChange(newValue);
                    }
                }}
                onClose={() => {
                    setPickerOpen(false);
                }}
                disabled={disabled}
            />
            <Button
                variant="contained"
                startIcon={<ArrowForwardIosIcon />}
                sx={{
                    minWidth: '0px',
                    span: {
                        margin: 0,
                    },
                }}
                onClick={() => {
                    if (onChange) {
                        if (!mode || mode === 'day') {
                            onChange(moment(value).add(1, 'day'));
                        } else if (mode === 'month') {
                            onChange(moment(value).add(1, 'month'));
                        }
                    }
                }}
                disabled={disabled}
            ></Button>
        </Box>
    );
}
