import { Box, Paper, Slide } from '@mui/material';
import { MouseEventHandler, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../contexts/auth/context';
import { AuthContextType } from '../../contexts/auth/types';
import { CardType } from '../../enums/card-type';
import { ThemeMode } from '../../enums/theme-mode';
import { Card as ClientCard, FounderCardData, GiftCardData, LoyaltyCardData } from '../../interfaces/card';
import Logo from '../logo/logo';
import Square from '../square/square.component';
import styles from './vaidosa-card.module.scss';

export default function VaidosaCard({
    card,
    onClick,
    description: value,
    index,
    direction,
}: {
    card: ClientCard<any>;
    onClick?: MouseEventHandler | undefined;
    description?: string;
    index: number;
    direction?: 'left' | 'right' | 'up' | 'down';
}) {
    // Get Context
    const authContext: AuthContextType = useContext(AuthContext);

    // Translations
    const { t } = useTranslation();

    // Build
    return (
        <Slide
            direction={direction ?? 'down'}
            in={true}
            mountOnEnter
            unmountOnExit
            style={{
                transitionDelay: `${index * 100}ms`,
            }}
        >
            <Paper className={styles.paper} elevation={8} onClick={onClick}>
                <div className={`${styles.card} ${styles[card.type]}`}>
                    <Box className={styles.front}>
                        {card.type === CardType.Loyalty && (
                            <>
                                <Square top="-15%" right="-10%" width="25%" position="absolute" zIndex={1} />
                                <Square
                                    bottom="-15%"
                                    left="-10%"
                                    width="25%"
                                    color="#38B6FF"
                                    position="absolute"
                                    zIndex={1}
                                />
                            </>
                        )}
                        {card.type === CardType.Founder && (
                            <>
                                <Square
                                    top="-15%"
                                    right="-10%"
                                    width="25%"
                                    position="absolute"
                                    zIndex={1}
                                    color="#ffc834"
                                />
                                <Square
                                    bottom="-15%"
                                    left="-10%"
                                    width="25%"
                                    color="#d9dad9"
                                    position="absolute"
                                    zIndex={1}
                                />
                            </>
                        )}
                        {card.type === CardType.Gift && (
                            <>
                                <Square
                                    top="-15%"
                                    right="-10%"
                                    width="25%"
                                    position="absolute"
                                    zIndex={1}
                                    color="#7dd957"
                                />
                                <Square
                                    bottom="-15%"
                                    left="-10%"
                                    width="25%"
                                    color="#38b6ff"
                                    position="absolute"
                                    zIndex={1}
                                />
                            </>
                        )}

                        <Box
                            className={styles.logoBox}
                            sx={{
                                paddingTop: '16px',
                            }}
                        >
                            {card.type === CardType.Loyalty && <Logo themeMode={ThemeMode.Light} />}
                            {card.type === CardType.Founder && <Logo themeMode={ThemeMode.Dark} />}
                            {card.type === CardType.Gift && <Logo themeMode={ThemeMode.Dark} />}
                        </Box>
                        <Box className={styles.cardContent}>
                            <Box className={styles.cardName}>
                                {card.type === CardType.Loyalty && t('components.vaidosaCard.loyalty.title')}
                                {card.type === CardType.Founder && t('components.vaidosaCard.founder.title')}
                                {card.type === CardType.Gift && t('components.vaidosaCard.gift.title')}
                            </Box>
                            <Box
                                sx={{
                                    flex: 1,
                                }}
                            ></Box>
                            <Box
                                className={styles.description}
                                sx={{
                                    color: card.type === CardType.Gift ? '#fff' : '#000',
                                }}
                            >
                                {authContext.state.firebaseUser && (
                                    <>
                                        {card.type === CardType.Loyalty &&
                                            t('components.vaidosaCard.loyalty.description', {
                                                balance: Math.floor(
                                                    (card as ClientCard<LoyaltyCardData>).data?.balance ?? 0,
                                                ),
                                            })}
                                        {card.type === CardType.Founder &&
                                            t('components.vaidosaCard.founder.description', {
                                                price: (card as ClientCard<FounderCardData>).data?.price ?? 0,
                                            })}
                                        {card.type === CardType.Gift &&
                                            t('components.vaidosaCard.gift.description', {
                                                balance:
                                                    (card as ClientCard<GiftCardData>).data?.balance.toFixed(2) ?? 0,
                                            })}
                                    </>
                                )}
                                {!authContext.state.firebaseUser && (
                                    <>
                                        {card.type === CardType.Loyalty &&
                                            t('components.vaidosaCard.loyalty.description', {
                                                balance: '??',
                                            })}
                                        {card.type === CardType.Founder &&
                                            t('components.vaidosaCard.founder.description', {
                                                price: '??',
                                            })}
                                        {card.type === CardType.Gift &&
                                            t('components.vaidosaCard.gift.description', {
                                                balance: '??',
                                            })}
                                    </>
                                )}
                            </Box>
                            <Box
                                className={styles.clientName}
                                sx={{
                                    color: card.type === CardType.Gift ? '#fff' : '#000',
                                }}
                            >{`${(
                                (authContext.state.me?.user?.firstName ?? '') +
                                ' ' +
                                (authContext.state.me?.user?.lastName ?? '')
                            ).trim()}`}</Box>
                        </Box>
                    </Box>
                    {card.type === CardType.Loyalty && <Box></Box>}
                    {card.type === CardType.Founder && <Box className={styles.front}></Box>}
                    {card.type === CardType.Gift && <Box className={styles.front}></Box>}
                </div>
            </Paper>
        </Slide>
    );
}
