import CallIcon from '@mui/icons-material/Call';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Avatar, Box, IconButton, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Provider } from '../../interfaces/provider';
import { RoutesNames } from '../../routes-names';

export function ProviderLine({ provider, showContactInfo }: { provider: Provider; showContactInfo?: boolean }) {
    // Get navigation
    const navigate = useNavigate();

    // Get theme
    const theme = useTheme();

    // Generate maps link
    const mapsLink = `https://www.google.com/maps/search/?api=1&query=${provider.location?.coordinates[1]},${provider.location?.coordinates[0]}`;

    // Navigate to provider
    const navigateToProvider = () => {
        // Navigate
        navigate(`/${RoutesNames.Office}/${RoutesNames.Office_Showcase}/${provider.id}`);
    };

    // Build
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
            }}
        >
            <Avatar
                sx={{ width: 48, height: 48, marginRight: '8px', border: '2px solid #ff66c4' }}
                alt={provider.name}
                src={provider.pictureUrl}
                onClick={navigateToProvider}
            >
                {provider.name}
            </Avatar>
            <Typography variant="subtitle1" onClick={navigateToProvider} fontWeight={500}>
                {provider.name}
            </Typography>
            {showContactInfo && (
                <>
                    <Box sx={{ flex: 1 }}></Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: `1px solid ${theme.palette.divider}`,
                            borderRadius: '24px',
                        }}
                    >
                        {provider.phoneNumber && (
                            <IconButton
                                size="small"
                                aria-label="call"
                                target="_blank"
                                href={`tel:${provider.phoneNumber}`}
                            >
                                <CallIcon />
                            </IconButton>
                        )}

                        {provider.phoneNumber && (
                            <IconButton
                                size="small"
                                aria-label="whatsapp"
                                target="_blank"
                                href={`https://wa.me/${provider.phoneNumber.replace('+', '')}`}
                            >
                                <WhatsAppIcon />
                            </IconButton>
                        )}

                        {provider.location && (
                            <IconButton size="small" aria-label="directions" target="_blank" href={mapsLink}>
                                <ForkRightIcon />
                            </IconButton>
                        )}
                    </Box>
                </>
            )}
        </Box>
    );
}
