import { Button, Stack } from '@mui/material';
import { useContext, useRef, useState } from 'react';
import { Container } from '../../components/container/container';
import { Page } from '../../components/page/page';
import { AppContext } from '../../contexts/app/context';
import { AppContextType } from '../../contexts/app/types';
import { AuthContext } from '../../contexts/auth/context';
import { AuthContextType } from '../../contexts/auth/types';
import { NativeKnight } from '../../native-knight';

export default function DevToolsNativeKnightDiag() {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);
    const authContext: AuthContextType = useContext(AuthContext);

    // (Private) Get Device Code
    function getDeviceCode(): string {
        // Get Device Id
        const deviceUniqueId = appContext.getDeviceUniqueId();

        // Build device code
        return `${appContext.state.operativeSystem}-${deviceUniqueId}`;
    }

    // Test stuff
    let isNativeKnightAndroidAvailable: boolean = false;
    try {
        if (Android !== undefined && Android != null) {
            isNativeKnightAndroidAvailable = true;
        }
    } catch (error: any) {
        // Do nothing
    }

    // Hold State
    const [output, setOutput] = useState<string>(
        `${JSON.stringify(
            {
                href: window.location.href,
                sessionWasRestored: appContext.state.sessionWasRestored,
                appIsReady: appContext.state.appIsReady,
                operativeSystem: appContext.state.operativeSystem,
                themeOption: appContext.state.themeOption,
                themeMode: appContext.state.themeMode,
                nativeKnightApiVersion: appContext.state.nativeKnightApiVersion,
                sessionId: appContext.state.sessionId,
                sessionStartedAt: appContext.state.sessionStartedAt,
                appVersion: appContext.state.appVersion,
                appBuild: appContext.state.appBuild,
                nativeKnightAndroid: isNativeKnightAndroidAvailable ? 'OK' : 'Not found',
                nativeKnightIOS:
                    (window as any).webkit?.messageHandlers?.ios !== undefined &&
                    (window as any).webkit?.messageHandlers?.ios != null
                        ? 'OK'
                        : 'Not found',
                testLocalStorage: localStorage.getItem('testLocalStorage'),
                deviceCode: getDeviceCode(),
            },
            null,
            4,
        )}\n\n`,
    );

    // Hold Textarea ref
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    // Output
    const renderOutputLine = (title: string, line: string) => {
        setOutput((output) => output + title + ': ' + line + '\n\n');
        if (textareaRef.current) {
            textareaRef.current.scrollTop = textareaRef.current.scrollHeight + 50;
        }
    };

    // Print error
    const printError = (title: string, error: any) => {
        try {
            renderOutputLine(title, `Error: ${error && error.message ? error.message : JSON.stringify(error)}`);
        } catch (error2: any) {
            renderOutputLine(title, "Can't print error");
        }
    };

    // Render
    return (
        <Page title="Native Knight Diag">
            <Container>
                <h2>Output</h2>
                <textarea
                    ref={textareaRef}
                    value={output}
                    style={{
                        width: '100%',
                        height: '300px',
                    }}
                />
                <h2>Controls</h2>
                <Stack spacing={'20px'}>
                    <Button
                        variant="contained"
                        onClick={async () => {
                            try {
                                const result = await NativeKnight.instance.showAlert('Alert here', "I'm an alert");
                                renderOutputLine('showAlert', `${result}`);
                            } catch (error: any) {
                                printError('showAlert', error);
                            }
                        }}
                    >
                        showAlert
                    </Button>
                    <Button
                        variant="contained"
                        onClick={async () => {
                            try {
                                const result = await NativeKnight.instance.getSafeAreaInsets();
                                renderOutputLine('getSafeAreaInsets', `${result ? JSON.stringify(result) : result}`);
                            } catch (error: any) {
                                printError('getSafeAreaInsets', error);
                            }
                        }}
                    >
                        getSafeAreaInsets
                    </Button>
                    <Button
                        variant="contained"
                        onClick={async () => {
                            try {
                                const result = await NativeKnight.instance.getOrientation();
                                renderOutputLine('getOrientation', `${result}`);
                            } catch (error: any) {
                                printError('getOrientation', error);
                            }
                        }}
                    >
                        getOrientation
                    </Button>
                    <Button
                        variant="contained"
                        onClick={async () => {
                            try {
                                const result = await NativeKnight.instance.requestNotificationsPermission();
                                renderOutputLine('requestNotificationsPermission', `${result}`);
                            } catch (error: any) {
                                printError('requestNotificationsPermission', error);
                            }
                        }}
                    >
                        requestNotificationsPermission
                    </Button>
                    <Button
                        variant="contained"
                        onClick={async () => {
                            try {
                                const result = await NativeKnight.instance.getFirebaseMessagingToken();
                                renderOutputLine('getFirebaseMessagingToken', `${result}`);
                            } catch (error: any) {
                                printError('getFirebaseMessagingToken', error);
                            }
                        }}
                    >
                        getFirebaseMessagingToken
                    </Button>
                    <Button
                        variant="contained"
                        onClick={async () => {
                            try {
                                const result = await NativeKnight.instance.showNotification(
                                    JSON.stringify({
                                        data: {
                                            _notificationTitle: 'Notification Title',
                                            _notificationBody: 'Notification Body',
                                            _notificationImageUrl: 'https://asvaidosas.pt/img/team/1.webp',
                                        },
                                    }),
                                );
                                renderOutputLine('showNotification', `${result}`);
                            } catch (error: any) {
                                printError('showNotification', error);
                            }
                        }}
                    >
                        showNotification
                    </Button>
                    <Button
                        variant="contained"
                        onClick={async () => {
                            try {
                                const number = Math.round(Math.random() * 100);
                                const result = await NativeKnight.instance.setBadgeNumber(number);
                                renderOutputLine('setBadgeNumber', `${result}:${number}`);
                            } catch (error: any) {
                                printError('setBadgeNumber', error);
                            }
                        }}
                    >
                        setBadgeNumber
                    </Button>
                    <Button
                        variant="contained"
                        onClick={async () => {
                            try {
                                const number = Math.round(Math.random() * 100);
                                localStorage.setItem('testLocalStorage', number.toString());
                                renderOutputLine('testLocalStorage', `${localStorage.getItem('testLocalStorage')}`);
                            } catch (error: any) {
                                printError('testLocalStorage', error);
                            }
                        }}
                    >
                        testLocalStorage
                    </Button>
                    <Button
                        variant="contained"
                        onClick={async () => {
                            try {
                                authContext.unsubscribeToNotifications();
                            } catch (error: any) {
                                printError('deleteNotificationsSubscription', error);
                            }
                        }}
                    >
                        deleteNotificationsSubscription
                    </Button>
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={async () => {
                            window.location.reload();
                        }}
                    >
                        reload
                    </Button>
                </Stack>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
            </Container>
        </Page>
    );
}
