import { AxiosResponse } from 'axios';
import { Booking } from '../interfaces/booking';
import { BackendApiManager } from '../managers/backend-api';
import { Logger } from '../utils/logger';

export class BookingsRepository {
    // Singleton Instance
    private static _instance: BookingsRepository;

    // Private
    private logger: Logger;

    static get instance(): BookingsRepository {
        if (!this._instance) {
            this._instance = new BookingsRepository();
        }
        return this._instance;
    }

    constructor() {
        // Create Logger
        this.logger = new Logger('BookingsRepository');
    }

    public async getProviderBookings(providerId: string, startDate: Date, endDate: Date): Promise<Array<Booking>> {
        // Set url
        const url: string = `/providers/${providerId}/bookings?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;

        // Get from backend
        const result: AxiosResponse<Array<Booking>> = await BackendApiManager.instance.get<Array<Booking>>(url);

        // Return
        return result.data;
    }

    public async getUserBookings(
        startDate: Date,
        endDate: Date,
        userId?: string,
        sort?: string,
        excludeLocks?: boolean,
    ): Promise<Array<Booking>> {
        // Set url
        let url: string = `/bookings?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;

        // User Id
        if (userId) {
            url += `&userId=${userId}`;
        }

        // Sort
        if (sort) {
            url += `&sort=${sort}`;
        }

        // Exclude locks
        if (excludeLocks) {
            url += `&excludeLocks=${excludeLocks}`;
        }

        // Get notifications from backend
        const result: AxiosResponse<Array<Booking>> = await BackendApiManager.instance.get<Array<Booking>>(url);

        // Return
        return result.data;
    }

    public async createAppointment(set: {
        // Booking
        providerId: string;
        servicesIds: Array<string>;
        startTime: Date;

        // Client
        clientName: string;
        clientPhoneNumber: string;
        clientNotes?: string;
    }): Promise<Array<Booking>> {
        // Set url
        const url: string = `/appointments`;

        // Get notifications from backend
        const result: AxiosResponse<Array<Booking>> = await BackendApiManager.instance.post<Array<Booking>>(url, set);

        // Return
        return result.data;
    }

    public async get(
        userId: string,
        limit: number,
        skipDate: Date,
    ): Promise<{ totalCount: number; data: Array<Booking> }> {
        // Set url
        const url: string = `/users/${userId}/bookings?limit=${limit}&skipDate=${skipDate.toISOString()}`;

        // Get notifications from backend
        const result: AxiosResponse<Array<Booking>> = await BackendApiManager.instance.get<Array<Booking>>(url);

        // Return
        return { data: result.data, totalCount: parseInt(result.headers['x-total-count'] || '', 10) };
    }

    public async deleteAppointmentAsUser(userId: string, appointmentId: string): Promise<void> {
        // Set url
        const url: string = `/users/${userId}/appointments/${appointmentId}`;

        // Get notifications from backend
        await BackendApiManager.instance.delete(url);
    }

    public async deleteBookingAsProvider(providerId: string, bookingId: string): Promise<void> {
        // Set url
        const url: string = `/providers/${providerId}/bookings/${bookingId}`;

        // Get notifications from backend
        await BackendApiManager.instance.delete(url);
    }

    public async markBookingAsUserNoShow(providerId: string, bookingId: string): Promise<void> {
        // Set url
        const url: string = `/providers/${providerId}/bookings/${bookingId}/user-no-show`;

        // Get notifications from backend
        await BackendApiManager.instance.patch(url);
    }
}
