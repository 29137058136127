import { Box, Typography } from '@mui/material';
import { useContext } from 'react';
import { AppContext } from '../../contexts/app/context';
import { AppContextType } from '../../contexts/app/types';
import { OperativeSystem } from '../../enums/operative-system';
import styles from './empty-state.module.scss';

export function EmptyState({
    icon,
    title,
    description,
    height,
    actions,
    autoHeight,
}: {
    icon?: React.ReactNode;
    title: string;
    description?: string;
    height?: number;
    actions?: Array<React.ReactNode>;
    autoHeight?: boolean;
}) {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);

    // Calculate height
    let heightValue: string = '';
    if (height) {
        heightValue = `${height + (appContext.state.operativeSystem === OperativeSystem.iOS ? -10 : 0)}vh`;
    } else {
        heightValue = `${appContext.state.operativeSystem === OperativeSystem.iOS ? 70 : 80}vh`;
    }
    if (autoHeight) {
        heightValue = 'auto';
    }

    // Render
    return (
        <Box
            className={styles.empty}
            sx={{
                height: heightValue,
            }}
        >
            <Box
                sx={{
                    padding: '32px',
                }}
            >
                {icon && <Box className={styles.icon}>{icon}</Box>}
                <Typography variant="h6">{title}</Typography>
                {description && (
                    <Typography
                        variant="body1"
                        sx={{
                            marginTop: '16px',
                        }}
                    >
                        {description}
                    </Typography>
                )}
                {actions && (
                    <Box
                        sx={{
                            marginTop: '16px',
                        }}
                    >
                        {actions.map((action: React.ReactNode, index: number) => {
                            return <Box key={`action_${index}`}>{action}</Box>;
                        })}
                    </Box>
                )}
            </Box>
        </Box>
    );
}
