import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FooterSheet } from '../footer-sheet/footer-sheet';

export function ProviderSearch({
    value,
    onChange,
    onEnter,
    disabled,
}: {
    value: string;
    onChange?: (value: string) => void;
    onEnter?: () => void;
    disabled?: boolean;
}) {
    // Translations
    const { t } = useTranslation();

    // Render
    return (
        <FooterSheet>
            <Box
                sx={{
                    marginTop: '16px',
                    maxWidth: '425px',
                    width: '100%',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '16px',
                }}
            >
                <FormControl
                    variant="outlined"
                    sx={{
                        width: '100%',
                    }}
                    disabled={disabled}
                >
                    <InputLabel disabled={disabled} htmlFor="user-search-input">
                        {t('components.providerSearch.search')}
                    </InputLabel>
                    <OutlinedInput
                        disabled={disabled}
                        id="user-search-input"
                        label={t('components.providerSearch.search')}
                        placeholder={t('components.providerSearch.placeholder')}
                        value={value}
                        startAdornment={
                            <SearchIcon
                                sx={{
                                    marginRight: '8px',
                                }}
                            />
                        }
                        endAdornment={
                            value !== '' ? (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="clear search"
                                        onClick={() => {
                                            onChange && onChange('');
                                        }}
                                        edge="end"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ) : undefined
                        }
                        onChange={(event) => {
                            onChange && onChange(event.target.value);
                        }}
                        onKeyDown={(_) => {
                            onEnter && onEnter();
                        }}
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                    />
                </FormControl>
            </Box>
        </FooterSheet>
    );
}
