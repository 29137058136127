import { Box } from '@mui/material';
import React from 'react';

export function Container({ children }: { children: React.ReactNode }) {
    // Render
    return (
        <Box
            sx={{
                maxWidth: '1140px',
                margin: 'auto',
            }}
        >
            {children}
        </Box>
    );
}
