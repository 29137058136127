import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button, CircularProgress } from '@mui/material';
import { MouseEventHandler, useEffect } from 'react';

export enum SubmitButtonState {
    Normal = 'normal',
    Loading = 'loading',
    Success = 'success',
    Error = 'error',
}

export function SubmitButton<T>(props: {
    state: SubmitButtonState;
    onStateChange: (state: SubmitButtonState) => void;
    label: string;
    innerRef?: any;
    disabled?: boolean;
    type?: 'submit' | 'reset' | 'button';
    onClick?: MouseEventHandler<T> | undefined;
}) {
    // On mount
    useEffect(() => {
        // Eval
        if ([SubmitButtonState.Success, SubmitButtonState.Error].includes(props.state)) {
            // Reset state
            setTimeout(() => {
                // Reset state
                props.onStateChange(SubmitButtonState.Normal);
            }, 3000);
        }
        // On unmount
        return () => {};

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.state]);

    // Pick button color
    let buttonColor: 'primary' | 'info' | 'success' | 'error' = 'primary';
    if (props.state === SubmitButtonState.Success) {
        buttonColor = 'success';
    } else if (props.state === SubmitButtonState.Error) {
        buttonColor = 'error';
    } else if (props.state === SubmitButtonState.Loading) {
        buttonColor = 'info';
    }

    // Build
    return (
        <Button
            ref={props.innerRef}
            type={props.type}
            variant="contained"
            disabled={props.disabled}
            sx={{
                width: '100%',
                height: '36.5px',
            }}
            onClick={
                !props.disabled && props.type === 'button' && props.state === SubmitButtonState.Normal && props.onClick
                    ? (props.onClick as any)
                    : undefined
            }
            color={buttonColor}
        >
            {props.state === SubmitButtonState.Normal && props.label}
            {props.state === SubmitButtonState.Loading && <CircularProgress color="inherit" size={24} />}
            {props.state === SubmitButtonState.Success && <DoneIcon />}
            {props.state === SubmitButtonState.Error && <ErrorOutlineIcon />}
        </Button>
    );
}
