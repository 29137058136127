import { ServiceCategory } from '../enums/service-category';

export interface ServiceCategoryData {
    category: ServiceCategory;
    translationKey: string;
    color: string;
    imageUrl: string;
}

export const ServiceCategories: {
    [key: string]: ServiceCategoryData;
} = {
    [ServiceCategory.Manicure]: {
        category: ServiceCategory.Manicure,
        translationKey: 'enums.serviceCategory.manicure',
        color: '#ff66c4',
        imageUrl: '/images/salon.png',
    },
    [ServiceCategory.Pedicure]: {
        category: ServiceCategory.Pedicure,
        translationKey: 'enums.serviceCategory.pedicure',
        color: '#38b6ff',
        imageUrl: '/images/salon.png',
    },
    [ServiceCategory.Makeup]: {
        category: ServiceCategory.Makeup,
        translationKey: 'enums.serviceCategory.makeup',
        color: '#ffde59',
        imageUrl: '/images/salon.png',
    },
    [ServiceCategory.Hair]: {
        category: ServiceCategory.Hair,
        translationKey: 'enums.serviceCategory.hair',
        color: '#ff9e38',
        imageUrl: '/images/salon.png',
    },
    [ServiceCategory.FacialEpilation]: {
        category: ServiceCategory.FacialEpilation,
        translationKey: 'enums.serviceCategory.facialEpilation',
        color: '#7dd957',
        imageUrl: '/images/salon.png',
    },
    [ServiceCategory.Eyelashes]: {
        category: ServiceCategory.Eyelashes,
        translationKey: 'enums.serviceCategory.eyelashes',
        color: '#8a60ff',
        imageUrl: '/images/salon.png',
    },
    [ServiceCategory.Depilation]: {
        category: ServiceCategory.Depilation,
        translationKey: 'enums.serviceCategory.depilation',
        color: '#ff4d4d',
        imageUrl: '/images/salon.png',
    },
};
