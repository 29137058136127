import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PinDropIcon from '@mui/icons-material/PinDrop';
import PlaceIcon from '@mui/icons-material/Place';
import ReviewsIcon from '@mui/icons-material/Reviews';
import ScheduleIcon from '@mui/icons-material/Schedule';
import StoreIcon from '@mui/icons-material/Store';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {
    Box,
    Button,
    Chip,
    FormControl,
    Grow,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Rating,
    Select,
    SelectChangeEvent,
    Typography,
    useTheme,
} from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import { Container } from '../../../components/container/container';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { ErrorState } from '../../../components/error-state/error-state';
import { ImagesStepper } from '../../../components/images-stepper/images-stepper';
import { Loader } from '../../../components/loader/loader';
import { Page } from '../../../components/page/page';
import { AppContext } from '../../../contexts/app/context';
import { AppContextType } from '../../../contexts/app/types';
import { ServiceCategories } from '../../../databases/service-categories';
import { CountryCode } from '../../../enums/country-code';
import { Service } from '../../../interfaces/service';
import { ServiceProviderExtended } from '../../../interfaces/service-provider';
import { ServicesRepository } from '../../../repositories/services';
import { RoutesNames } from '../../../routes-names';
import { cosineDistanceBetweenPoints } from '../../../utils/calculate-geo-distance';
import { calculateTravelTime } from '../../../utils/calculate-travel-time';
import { calculateTravelTimeInMinutes } from '../../../utils/calculate-travel-time-in-minutes';
import { shuffleArray } from '../../../utils/ts-utils';
import variables from '../../../variables.module.scss';

enum SortOptions {
    Location = 'location',
    Availability = 'availability',
    ReviewsCount = 'reviewsCount',
    AverageRating = 'averageRating',
    Price = 'price',
    Duration = 'duration',
}

function ProviderTile({
    document,
}: {
    document: ServiceProviderExtended & {
        awards?: {
            bestLocation?: boolean;
            bestAvailability?: boolean;
            bestReviewsCount?: boolean;
            bestAverageRating?: boolean;
            bestPrice?: boolean;
            bestDuration?: boolean;
        };
    };
}) {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);

    // Translations
    const { t } = useTranslation();

    // Get navigation
    const navigate = useNavigate();

    // Get theme
    const theme = useTheme();

    // In view hook
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
        triggerOnce: true,
    });

    // Provider Service Category index
    const providerServiceIndex: { [providerServiceId: string]: Service } = {};
    for (const providerService of document.services) {
        // Look for the service id on the app context
        const service: Service | undefined = appContext.state.services.find((s) => s.id === providerService.serviceId);

        // Eval
        if (service) {
            // Set
            providerServiceIndex[providerService.serviceId] = service;
        }
    }

    // Next available booking date
    let nextAvailableBookingDateText = '';
    let nextAvailableBookingDateTextNow: boolean = false;
    if (document.nextAvailableBookingDate) {
        const bookingMoment = moment(document.nextAvailableBookingDate);
        if (document.nextAvailableBookingDate < new Date()) {
            // Available now
            nextAvailableBookingDateText = `${t('routes.office.book.availableNow')} 🎉`;
            nextAvailableBookingDateTextNow = true;
        } else if (bookingMoment.isSame(new Date(), 'day')) {
            // Today
            nextAvailableBookingDateText = t('routes.office.book.availableSoon', {
                time: bookingMoment.format('LT'),
                fromNow: bookingMoment.fromNow(),
            });
            nextAvailableBookingDateTextNow = true;
        } else if (bookingMoment.isSame(moment().add(1, 'day'), 'day')) {
            // Tomorrow
            nextAvailableBookingDateText = t('routes.office.book.availableTomorrow', {
                time: bookingMoment.format('LT'),
            });
        } else if (bookingMoment.isBefore(moment().add(7, 'day'), 'day')) {
            // This week
            nextAvailableBookingDateText = t('routes.office.book.availableThisWeek', {
                day: bookingMoment.format('dddd'),
                time: bookingMoment.format('LT'),
            });
        } else {
            // Next week
            nextAvailableBookingDateText = `${bookingMoment.format('DD')} ${moment(
                document.nextAvailableBookingDate,
            ).format('MMMM')}, ${bookingMoment.format('LT')} (${moment(document.nextAvailableBookingDate).fromNow()})`;
            nextAvailableBookingDateText = t('routes.office.book.availableAfter', {
                day: bookingMoment.format('D'),
                month: bookingMoment.format('MMMM'),
                time: bookingMoment.format('LT'),
                fromNow: bookingMoment.fromNow(),
            });
        }
    }

    // Provider subtitle
    let providerSubtitleParts = [];
    if (document.city) {
        // Add City name
        providerSubtitleParts.push(document.city.name);
    }
    if (document.provider.birthday) {
        // Add City name
        providerSubtitleParts.push(
            t('routes.office.book.providerBirthday', {
                years: moment().diff(document.provider.birthday, 'years'),
            }),
        );
    }
    if (document.provider.countryCode) {
        // Add City name
        switch (document.provider.countryCode) {
            case CountryCode.PT:
                providerSubtitleParts.push('🇵🇹');
                break;
            case CountryCode.BR:
                providerSubtitleParts.push('🇧🇷');
                break;
        }
    }

    // Merge photos
    let photosUrls: Array<string> = [];
    if (document.services.length > 1) {
        // Pick images from all services
        document.services.map((service) => {
            // Eval size
            if (service.photosUrls) {
                if (service.photosUrls.length > 2) {
                    // Pick the first 2
                    photosUrls.push(...service.photosUrls.slice(0, 2));
                } else {
                    // Pick all
                    photosUrls.push(...service.photosUrls);
                }
            }
            return service;
        });

        // Shuffle
        photosUrls = shuffleArray<string>(photosUrls);
    } else {
        // Get all from the single service
        photosUrls = document.services[0].photosUrls ?? [];
    }

    // Distance warning enabled
    let distanceWarningEnabled: boolean = false;
    if (appContext.state.userLocation.cityId !== document.provider.cityId) {
        // Calculate distance
        let distance = 0;
        try {
            distance = cosineDistanceBetweenPoints(
                appContext.state.userLocation.coordinates[1],
                appContext.state.userLocation.coordinates[0],
                document.provider.location?.coordinates[1] ?? 0,
                document.provider.location?.coordinates[0] ?? 0,
            );
        } catch (e) {}

        // Eval
        if (distance > 10000) {
            // Enable
            distanceWarningEnabled = true;
        }
    }

    // Build
    return (
        <Grow in={true} mountOnEnter unmountOnExit ref={ref}>
            <Paper
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '24px',
                    overflow: 'hidden',
                }}
            >
                <Box
                    sx={{
                        position: 'relative',
                    }}
                >
                    {photosUrls.length === 0 && (
                        <Box
                            sx={{
                                aspectRatio: '2 / 1',
                                backgroundColor: theme.palette.divider,
                                overflow: 'hidden',
                            }}
                        >
                            {inView && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '16px',
                                        justifyContent: 'space-between',
                                        rotate: '15deg',
                                        marginLeft: '-20%',
                                        marginTop: '-20%',
                                        opacity: 0.3,
                                    }}
                                >
                                    {Array.from({ length: 120 }).map((_, index) => (
                                        <span key={`provider-${document.provider.id}-award-${index}`}>💛</span>
                                    ))}
                                </Box>
                            )}
                        </Box>
                    )}
                    {photosUrls.length > 0 && (
                        <Box
                            sx={{
                                aspectRatio: '1 / 1',
                            }}
                        >
                            {inView && <ImagesStepper className="provider-tile" imagesUrls={photosUrls} />}
                        </Box>
                    )}

                    {document.awards && (
                        <Box
                            className="awards"
                            sx={{
                                position: 'absolute',
                                top: '8px',
                                right: '8px',
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'flex-end',
                                gap: '8px',
                            }}
                        >
                            {document.awards && document.awards.bestLocation && (
                                <Chip
                                    size="small"
                                    label={`📍 ${t('routes.office.book.awards.bestLocation')}`}
                                    sx={{
                                        backgroundColor: variables.secondColor,
                                        color: 'white',
                                        fontWeight: 'bold',
                                    }}
                                />
                            )}
                            {document.awards && document.awards.bestAvailability && (
                                <Chip
                                    size="small"
                                    label={`🕓 ${t('routes.office.book.awards.bestAvailability')}`}
                                    sx={{
                                        backgroundColor: variables.forthColor,
                                        color: 'white',
                                        fontWeight: 'bold',
                                    }}
                                />
                            )}
                            {document.awards && document.awards.bestReviewsCount && (
                                <Chip
                                    size="small"
                                    label={`✨ ${t('routes.office.book.awards.bestReviewsCount')}`}
                                    sx={{
                                        backgroundColor: variables.seventhColor,
                                        color: 'white',
                                        fontWeight: 'bold',
                                    }}
                                />
                            )}
                            {document.awards && document.awards.bestAverageRating && (
                                <Chip
                                    size="small"
                                    label={`⭐️ ${t('routes.office.book.awards.bestAverageRating')}`}
                                    sx={{
                                        backgroundColor: variables.firstColor,
                                        color: 'white',
                                        fontWeight: 'bold',
                                    }}
                                />
                            )}
                            {document.awards && document.awards.bestPrice && (
                                <Chip
                                    size="small"
                                    label={`🏷️ ${t('routes.office.book.awards.bestPrice')}`}
                                    sx={{
                                        backgroundColor: variables.sixthColor,
                                        color: 'white',
                                        fontWeight: 'bold',
                                    }}
                                />
                            )}
                            {document.awards && document.awards.bestDuration && (
                                <Chip
                                    size="small"
                                    label={`⏳ ${t('routes.office.book.awards.bestDuration')}`}
                                    sx={{
                                        backgroundColor: variables.thirdColor,
                                        color: 'white',
                                        fontWeight: 'bold',
                                    }}
                                />
                            )}
                        </Box>
                    )}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '16px',
                        position: 'relative',
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '-50px',
                            left: '8px',
                            width: '100px',
                            height: '100px',
                            borderRadius: '50%',
                            overflow: 'hidden',
                            border: '4px solid',
                            borderColor: variables.firstColor,
                        }}
                    >
                        {document.provider.pictureUrl && (
                            <img
                                src={document.provider.pictureUrl}
                                alt={document.provider.name}
                                style={{
                                    objectFit: 'cover',
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                        )}
                        {!document.provider.pictureUrl && (
                            <Box
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    backgroundColor: theme.palette.background.paper,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '48px',
                                }}
                            >
                                👩‍⚕️
                            </Box>
                        )}
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            paddingLeft: '116px',
                            paddingTop: '4px',
                            minHeight: '50px',
                        }}
                    >
                        <Typography variant="h6">{document.provider.name}</Typography>
                        <Typography variant="subtitle2">{providerSubtitleParts.join(', ').trim()}</Typography>
                    </Box>
                </Box>
                {/* // 0: Next Available Booking Date */}
                {document.nextAvailableBookingDate && (
                    <Box
                        sx={{
                            marginTop: '8px',
                        }}
                    >
                        {/* <Divider /> */}
                        <Box
                            sx={{
                                padding: '8px',
                                display: 'flex',
                                gap: '8px',
                            }}
                        >
                            <ScheduleIcon
                                sx={{
                                    color: nextAvailableBookingDateTextNow ? 'green' : 'text.primary',
                                }}
                            />
                            <Typography
                                variant="body1"
                                fontWeight={nextAvailableBookingDateTextNow ? 'bold' : 'normal'}
                                color={nextAvailableBookingDateTextNow ? 'green' : 'text.primary'}
                            >
                                {nextAvailableBookingDateText}
                            </Typography>
                        </Box>
                    </Box>
                )}
                {!document.nextAvailableBookingDate && (
                    <Box
                        sx={{
                            marginTop: '8px',
                        }}
                    >
                        {/* <Divider /> */}
                        <Box
                            sx={{
                                padding: '8px',
                                display: 'flex',
                                gap: '8px',
                            }}
                        >
                            <ScheduleIcon
                                sx={{
                                    color: 'red',
                                }}
                            />
                            <Typography variant="body1" color="red">
                                {t('routes.office.book.notAvailable')} 🚫
                            </Typography>
                        </Box>
                    </Box>
                )}
                {/* // 1: Distance */}
                {document.distance && (
                    <Box>
                        {/* <Divider /> */}
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '8px',
                            }}
                        >
                            <Box
                                sx={{
                                    padding: '8px',
                                    display: 'flex',
                                    gap: '8px',
                                    backgroundColor: distanceWarningEnabled
                                        ? theme.palette.warning.main
                                        : 'transparent',
                                    color: distanceWarningEnabled ? theme.palette.warning.contrastText : 'unset',
                                    borderRadius: distanceWarningEnabled ? '24px' : '0',
                                }}
                            >
                                <PinDropIcon />
                                {document.distance >= 1000 && (
                                    <Typography variant="body1">{Math.round(document.distance / 1000)} km</Typography>
                                )}
                                {document.distance < 1000 && (
                                    <Typography variant="body1">{Math.round(document.distance)} m</Typography>
                                )}
                            </Box>
                            <Box
                                sx={{
                                    padding: '8px',
                                    display: 'flex',
                                    gap: '8px',
                                }}
                            >
                                <DirectionsCarIcon />
                                <Typography variant="body1">{calculateTravelTime(document.distance, 40)}</Typography>
                            </Box>

                            {calculateTravelTimeInMinutes(document.distance, 3.2) <= 60 && (
                                <Box
                                    sx={{
                                        padding: '8px',
                                        display: 'flex',
                                        gap: '8px',
                                    }}
                                >
                                    <DirectionsWalkIcon />
                                    <Typography variant="body1">
                                        {calculateTravelTime(document.distance, 3.2)}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}
                {/* // 2: Provider Average rating for this service + 3: Number of ratings */}
                <Box>
                    {/* <Divider /> */}
                    <Box
                        sx={{
                            padding: '8px',
                            display: 'flex',
                            gap: '8px',
                        }}
                    >
                        <ReviewsIcon />
                        <Typography variant="body1">{`${(document.averageRating ?? 0).toFixed(1)}`}</Typography>
                        <Rating name="read-only" value={document.averageRating} readOnly />
                        <Typography variant="body1">{`(${document.ratingsCount ?? 0})`}</Typography>
                    </Box>
                </Box>
                {/* // 3: Totals */}
                {document.services.length > 0 && (
                    <Box>
                        {/* <Divider /> */}
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '8px',
                            }}
                        >
                            <Box
                                sx={{
                                    padding: '8px',
                                    display: 'flex',
                                    gap: '8px',
                                    flex: 1,
                                }}
                            >
                                <HourglassTopIcon />
                                <Typography variant="body1">
                                    {appContext.getDisplayDurationText(document.totalServicesDuration ?? 0)}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    padding: '8px',
                                    display: 'flex',
                                    gap: '8px',
                                }}
                            >
                                <LocalOfferIcon />
                                <Typography variant="body1">{`${document.totalServicesPrice?.toFixed(2)}€`}</Typography>
                            </Box>
                        </Box>
                        {/* // 4: Services Price table */}
                        {document.services.length > 1 && (
                            <Box
                                sx={{
                                    paddingLeft: '8px',
                                    paddingRight: '8px',
                                    paddingTop: '4px',
                                }}
                            >
                                {document.services.map((service, index) => {
                                    return (
                                        <Box
                                            className={`selected-service-${index}`}
                                            key={`selected-service-${index}`}
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                gap: '8px',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                marginBottom: '8px',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    paddingLeft: '8px',
                                                    paddingRight: '8px',
                                                    paddingTop: '2px',
                                                    paddingBottom: '2px',
                                                    backgroundColor:
                                                        ServiceCategories[
                                                            providerServiceIndex[service.serviceId].category ?? ''
                                                        ].color,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    borderRadius: '24px',
                                                    color: 'white',
                                                }}
                                            >
                                                <Typography variant="caption" fontWeight={500}>
                                                    {t(
                                                        ServiceCategories[
                                                            providerServiceIndex[service.serviceId].category ?? ''
                                                        ].translationKey,
                                                    )}
                                                </Typography>
                                            </Box>
                                            <Typography
                                                variant="caption"
                                                fontWeight={500}
                                                textAlign={'left'}
                                                sx={{
                                                    flex: 1,
                                                    paddingRight: '8px',
                                                }}
                                            >
                                                {providerServiceIndex[service.serviceId].name}
                                                {` (${appContext.getDisplayDurationText(
                                                    service.durationInMinutes ?? 0,
                                                )})`}
                                            </Typography>
                                            {service.price && (
                                                <Typography variant="caption" fontWeight={500} textAlign={'left'}>
                                                    {service.price.toFixed(2)}€
                                                </Typography>
                                            )}
                                        </Box>
                                    );
                                })}
                            </Box>
                        )}
                    </Box>
                )}
                {/* // ACTION */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    {/* <Divider /> */}
                    <Box
                        sx={{
                            padding: '8px',
                        }}
                    >
                        <Button
                            disabled={!document.nextAvailableBookingDate}
                            variant="contained"
                            endIcon={<NavigateNextIcon />}
                            fullWidth
                            onClick={() => {
                                // Navigate
                                navigate(`../${RoutesNames.Office_Showcase}/${document.provider.id}?schedule=true`);
                            }}
                        >
                            {t('routes.office.book.selectProfessional', {
                                name: document.provider.name.split(' ')[0],
                            })}
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Grow>
    );
}

export default function ProviderPicker() {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);

    // Get navigation
    const navigate = useNavigate();

    // Translations
    const { t } = useTranslation();

    // Hold State
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [documents, setDocuments] = useState<Array<ServiceProviderExtended> | null>(null);
    const [sort, setSort] = useState<SortOptions>(SortOptions.Location);

    // Helper: Calculate awards
    function calculateAwards(
        services: Array<
            ServiceProviderExtended & {
                awards?: {
                    bestLocation?: boolean;
                    bestAvailability?: boolean;
                    bestReviewsCount?: boolean;
                    bestAverageRating?: boolean;
                    bestPrice?: boolean;
                    bestDuration?: boolean;
                };
            }
        >,
    ) {
        // Hold variables
        let bestLocation_smallerDistance: number = Number.MAX_VALUE;
        let bestAvailability_smallerDate: Date = new Date('2100-01-01');
        let bestReviewsCount_biggerCount: number = 0;
        let bestAverageRating_biggerRating: number = 0;
        let bestPrice_smallerPrice: number = Number.MAX_VALUE;
        let bestDuration_smallerDuration: number = Number.MAX_VALUE;

        // Iterate
        for (const service of services) {
            // Calculate distance
            if (service.distance && service.distance < bestLocation_smallerDistance) {
                bestLocation_smallerDistance = service.distance;
            }

            // Calculate availability
            if (service.nextAvailableBookingDate && service.nextAvailableBookingDate < bestAvailability_smallerDate) {
                bestAvailability_smallerDate = service.nextAvailableBookingDate;
            }

            // Calculate reviews count
            if (service.ratingsCount && service.ratingsCount > bestReviewsCount_biggerCount) {
                bestReviewsCount_biggerCount = service.ratingsCount;
            }

            // Calculate average rating
            if (service.averageRating && service.averageRating > bestAverageRating_biggerRating) {
                bestAverageRating_biggerRating = service.averageRating;
            }

            // Calculate price
            if (service.totalServicesPrice && service.totalServicesPrice < bestPrice_smallerPrice) {
                bestPrice_smallerPrice = service.totalServicesPrice;
            }

            // Calculate duration
            if (service.totalServicesDuration && service.totalServicesDuration < bestDuration_smallerDuration) {
                bestDuration_smallerDuration = service.totalServicesDuration;
            }
        }

        // Iterate again
        for (const service of services) {
            // Calculate distance
            if (service.distance && service.distance === bestLocation_smallerDistance) {
                service.awards = service.awards || {};
                service.awards.bestLocation = true;
            }

            // Calculate availability
            if (
                service.nextAvailableBookingDate &&
                service.nextAvailableBookingDate.getTime() === bestAvailability_smallerDate.getTime()
            ) {
                service.awards = service.awards || {};
                service.awards.bestAvailability = true;
            }

            // Calculate reviews count
            if (service.ratingsCount && service.ratingsCount === bestReviewsCount_biggerCount) {
                service.awards = service.awards || {};
                service.awards.bestReviewsCount = true;
            }

            // Calculate average rating
            if (service.averageRating && service.averageRating === bestAverageRating_biggerRating) {
                service.awards = service.awards || {};
                service.awards.bestAverageRating = true;
            }

            // Calculate price
            if (service.totalServicesPrice && service.totalServicesPrice === bestPrice_smallerPrice) {
                service.awards = service.awards || {};
                service.awards.bestPrice = true;
            }

            // Calculate duration
            if (service.totalServicesDuration && service.totalServicesDuration === bestDuration_smallerDuration) {
                service.awards = service.awards || {};
                service.awards.bestDuration = true;
            }
        }
    }

    // Action: Reset all
    const resetState = async () => {
        // Reset state
        setError(false);
        setDocuments(null);
    };

    // Actions: Load
    const load = async (listSort?: SortOptions) => {
        // Reset state
        resetState();

        // Start loading
        setIsLoading(true);

        try {
            // Get
            const results: Array<
                ServiceProviderExtended & {
                    awards?: {
                        bestLocation?: boolean;
                        bestAvailability?: boolean;
                        bestReviewsCount?: boolean;
                        bestAverageRating?: boolean;
                        bestPrice?: boolean;
                        bestDuration?: boolean;
                    };
                }
            > = await ServicesRepository.instance.getServicesProviders(
                appContext.state.services.map((service) => service.id ?? ''),
                appContext.state.userLocation.coordinates[1],
                appContext.state.userLocation.coordinates[0],
            );

            // Calculate awards
            calculateAwards(results);

            // Check sort
            if (listSort !== SortOptions.Location) {
                switch (listSort) {
                    case SortOptions.Availability:
                        results.sort((a, b) => {
                            if (a.nextAvailableBookingDate && b.nextAvailableBookingDate) {
                                return a.nextAvailableBookingDate.getTime() - b.nextAvailableBookingDate.getTime();
                            } else if (a.nextAvailableBookingDate) {
                                return -1;
                            } else if (b.nextAvailableBookingDate) {
                                return 1;
                            } else {
                                return 0;
                            }
                        });
                        break;
                    case SortOptions.ReviewsCount:
                        results.sort((a, b) => {
                            if (a.ratingsCount && b.ratingsCount) {
                                return b.ratingsCount - a.ratingsCount;
                            } else if (a.ratingsCount) {
                                return 1;
                            } else if (b.ratingsCount) {
                                return -1;
                            } else {
                                return 0;
                            }
                        });
                        break;
                    case SortOptions.AverageRating:
                        results.sort((a, b) => {
                            if (a.averageRating && b.averageRating) {
                                return b.averageRating - a.averageRating;
                            } else if (a.averageRating) {
                                return 1;
                            } else if (b.averageRating) {
                                return -1;
                            } else {
                                return 0;
                            }
                        });
                        break;
                    case SortOptions.Price:
                        results.sort((a, b) => {
                            if (a.totalServicesPrice && b.totalServicesPrice) {
                                return a.totalServicesPrice - b.totalServicesPrice;
                            } else if (a.totalServicesPrice) {
                                return -1;
                            } else if (b.totalServicesPrice) {
                                return 1;
                            } else {
                                return 0;
                            }
                        });
                        break;
                    case SortOptions.Duration:
                        results.sort((a, b) => {
                            if (a.totalServicesDuration && b.totalServicesDuration) {
                                return a.totalServicesDuration - b.totalServicesDuration;
                            } else if (a.totalServicesDuration) {
                                return -1;
                            } else if (b.totalServicesDuration) {
                                return 1;
                            } else {
                                return 0;
                            }
                        });
                        break;
                }
            }

            // Update state
            setDocuments([...results]);
        } catch (error) {
            // Log
            console.error(error);

            // Update state
            setError(true);
        } finally {
            // Stop loading
            setIsLoading(false);

            // Fix iOS empty scroll problem
            appContext.fixIosEmptyScrollProblem();
        }
    };

    // On Mount
    useEffect(() => {
        // Load
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Validate data
    if (!appContext.state.services || appContext.state.services.length === 0) {
        // Redirect to Service Picker
        // navigate(`../${RoutesNames.Office_ServicePicker}`);
        navigate(-1);
        return <></>;
    }

    // // Define list of services names
    // const servicesNames = appContext.state.services.map((service) => service.name);
    // let servicesNamesString = servicesNames.join(', ');
    // if (appContext.state.services.length === 1) {
    //     servicesNamesString = `${t(ServiceCategories[appContext.state.services[0].category ?? ''].translationKey)} > ${
    //         appContext.state.services[0].name
    //     }`;
    // }

    // Build
    return (
        <Page
            title={t('routes.office.book.title')}
            onRefresh={async () => {
                // Reload
                load();
            }}
            disableIosUnderTitle={true}
            underBar={
                <Box
                    sx={{
                        paddingTop: '4px',
                        flex: 1,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                    }}
                >
                    {/* {appContext.state.services && (
                        <OutlinedInput
                            size="small"
                            fullWidth
                            startAdornment={
                                <VolunteerActivismIcon
                                    sx={{
                                        marginRight: '8px',
                                    }}
                                />
                            }
                            value={servicesNamesString}
                            onFocus={(event) => {
                                event.stopPropagation();
                                event.preventDefault();

                                // // Define Route
                                // let route = `../${RoutesNames.Office_ServicePicker}`;

                                // // Eval
                                // if (appContext.state.services.length === 1) {
                                //     // Define Route
                                //     route += `?query=${appContext.state.services[0].name}`;
                                // }

                                // // Navigate to Service Picker
                                // navigate(route);

                                navigate(-1);
                            }}
                        />
                    )} */}
                    <FormControl fullWidth>
                        <InputLabel id="location-select-label">{t('routes.office.book.location')}</InputLabel>
                        <OutlinedInput
                            id="location-select"
                            size="small"
                            value={appContext.state.userLocation.name}
                            fullWidth
                            startAdornment={
                                <PlaceIcon
                                    sx={{
                                        marginRight: '8px',
                                    }}
                                />
                            }
                            // value={query}
                            onFocus={(event) => {
                                event.stopPropagation();
                                event.preventDefault();

                                // Navigate to Service Picker
                                navigate(`../${RoutesNames.Office_LocationPicker}`);
                            }}
                            label={t('routes.office.book.location')}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="sort-select-label">{t('routes.office.book.sort')}</InputLabel>
                        <Select
                            size="small"
                            labelId="sort-select-label"
                            id="sort-select"
                            value={sort}
                            label={t('routes.office.book.sort')}
                            onChange={(event: SelectChangeEvent) => {
                                // Set sort
                                setSort(event.target.value as SortOptions);

                                // Load again
                                load(event.target.value as SortOptions);
                            }}
                            startAdornment={
                                <SwapVertIcon
                                    sx={{
                                        marginRight: '8px',
                                    }}
                                />
                            }
                        >
                            <MenuItem value={SortOptions.Location}>
                                {t('routes.office.book.sortOptions.location')}
                            </MenuItem>
                            <MenuItem value={SortOptions.Availability}>
                                {t('routes.office.book.sortOptions.availability')}
                            </MenuItem>
                            <MenuItem value={SortOptions.ReviewsCount}>
                                {t('routes.office.book.sortOptions.reviewsCount')}
                            </MenuItem>
                            <MenuItem value={SortOptions.AverageRating}>
                                {t('routes.office.book.sortOptions.averageRating')}
                            </MenuItem>
                            <MenuItem value={SortOptions.Price}> {t('routes.office.book.sortOptions.price')}</MenuItem>
                            <MenuItem value={SortOptions.Duration}>
                                {' '}
                                {t('routes.office.book.sortOptions.duration')}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            }
            underBarHeight={116}
        >
            <Container>
                {isLoading && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '32px',
                        }}
                    >
                        <Loader size={48} />
                    </Box>
                )}
                {!isLoading && !error && (
                    <>
                        {documents?.length === 0 && (
                            <EmptyState
                                key={`empty`}
                                icon={<StoreIcon />}
                                title={t('routes.office.book.empty.title')}
                                description={t('routes.office.book.empty.description')}
                                height={55}
                            />
                        )}
                        {documents && documents.length > 0 && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                {documents?.map((document: ServiceProviderExtended, index: number) => {
                                    return <ProviderTile key={`provider-${index}`} document={document} />;
                                })}
                            </Box>
                        )}
                    </>
                )}
                {!isLoading && error && (
                    <ErrorState
                        icon={<StoreIcon />}
                        onTryAgainClick={() => {
                            // Reset state
                            setError(false);
                        }}
                    />
                )}
            </Container>
        </Page>
    );
}
