/**
 * Calculate the travel time based on the distance and speed
 */
export function calculateTravelTime(distanceMeters: number, speedKph: number): string {
    const distanceKm = distanceMeters / 1000; // Convert distance to kilometers
    const timeHours = distanceKm / speedKph; // Calculate time in hours

    const hours = Math.floor(timeHours);
    const minutes = Math.round((timeHours - hours) * 60);

    let timeString = '';
    if (hours > 0) {
        timeString += `${hours}h`;
    }
    if (minutes > 0) {
        timeString += ` ${minutes}min`;
    }

    return timeString;
}
