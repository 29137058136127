import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CallIcon from '@mui/icons-material/Call';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import Person2Icon from '@mui/icons-material/Person2';
import TodayIcon from '@mui/icons-material/Today';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Avatar, Box, Button, Grow, IconButton, Paper, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { BookingState } from '../../enums/booking-state';
import { Booking } from '../../interfaces/booking';
import { Provider } from '../../interfaces/provider';
import { SanitizationManager } from '../../managers/sanitization';
import { BookingsRepository } from '../../repositories/bookings';
import { ProvidersRepository } from '../../repositories/providers';
import { RoutesNames } from '../../routes-names';
import { Container } from '../container/container';
import { EmptyState } from '../empty-state/empty-state';
import { ErrorState } from '../error-state/error-state';
import { Loader } from '../loader/loader';
import { Page } from '../page/page';
import { ProviderSearch } from '../provider-search/provider-search';
import styles from './providers-screen.module.scss';

function ProviderLine({
    provider,
    query,
    todayClientsViewEnabled,
}: {
    provider: Provider;
    query: string;
    todayClientsViewEnabled: boolean;
}) {
    // Translations
    const { t } = useTranslation();

    // Get theme
    const theme = useTheme();

    // Get navigation
    const navigate = useNavigate();

    // Get name
    const userFullName: string | null = provider.name;
    const words = (userFullName ?? '').split(' ');
    const initials = [words[0].charAt(0), words.length > 1 ? words[words.length - 1].charAt(0) : ''].join('').trim();
    const firstAndLastNamesOnly = [words[0], words.length > 1 ? words[words.length - 1] : ''].join(' ').trim();

    // Generate maps link
    const mapsLink = `https://www.google.com/maps/search/?api=1&query=${provider.location?.coordinates[1]},${provider.location?.coordinates[0]}`;

    // Actions: Before navigate
    const beforeNavigate = () => {
        // Build url
        let replaceUrl = `${window.location.pathname}?todayView=${todayClientsViewEnabled}`;

        // Eval query
        if (query !== '') {
            replaceUrl += `&query=${query}`;
        }

        // Replace state
        navigate(replaceUrl, {
            replace: true,
        });
    };

    // Build
    return (
        <Grow in={true} mountOnEnter unmountOnExit>
            <Paper
                className={styles.userLine}
                sx={{
                    paddingTop: '8px',
                    paddingBottom: '8px',
                }}
            >
                <Box
                    className={styles.line1}
                    onClick={() => {
                        // Before navigate
                        beforeNavigate();

                        // Navigate to User Profile
                        navigate(`/${RoutesNames.Office}/${RoutesNames.Office_Showcase}/${provider.id}`);
                    }}
                >
                    <Box className={styles.from}>
                        <Paper
                            elevation={1}
                            sx={{
                                borderRadius: '50%',
                            }}
                        >
                            <Avatar
                                src={provider.pictureUrl}
                                alt={userFullName ?? ''}
                                sx={{
                                    width: 56,
                                    height: 56,
                                    backgroundColor: theme.palette.primary.main,
                                    border: '2px solid #ff66c4',
                                }}
                            >
                                {initials === '' ? null : initials}
                            </Avatar>
                        </Paper>
                    </Box>
                    <Box className={styles.text}>
                        <Box className={styles.title}>
                            {userFullName && (
                                <Typography variant="body1" fontWeight="bold">
                                    {firstAndLastNamesOnly}
                                </Typography>
                            )}
                            {!userFullName && (
                                <Typography variant="body1" fontWeight={500} color="GrayText">
                                    {t('routes.office.professional.providers.withoutName')}
                                </Typography>
                            )}
                        </Box>
                        <Box className={styles.body}>
                            {provider.phoneNumber && (
                                <Typography variant="body2">
                                    {SanitizationManager.instance.formatPhoneNumber(provider.phoneNumber)}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box
                    className="provider-actions"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '8px',
                    }}
                >
                    {provider.phoneNumber && (
                        <IconButton size="small" aria-label="call" target="_blank" href={`tel:${provider.phoneNumber}`}>
                            <CallIcon />
                        </IconButton>
                    )}
                    {provider.phoneNumber && (
                        <IconButton
                            size="small"
                            aria-label="whatsapp"
                            target="_blank"
                            href={`https://wa.me/${provider.phoneNumber.replace('+', '')}`}
                        >
                            <WhatsAppIcon />
                        </IconButton>
                    )}
                    {provider.location && (
                        <IconButton size="small" aria-label="directions" target="_blank" href={mapsLink}>
                            <ForkRightIcon />
                        </IconButton>
                    )}
                    <IconButton
                        aria-label="schedule"
                        onClick={() => {
                            // Before navigate
                            beforeNavigate();

                            // Navigate to User Profile
                            navigate(
                                `/${RoutesNames.Office}/${RoutesNames.Office_Professional}/${provider.id}/${RoutesNames.Office_Professional_Schedule}`,
                            );
                        }}
                    >
                        <CalendarTodayIcon />
                    </IconButton>
                    <IconButton
                        aria-label="services"
                        onClick={() => {
                            // Before navigate
                            beforeNavigate();

                            // Navigate to User Profile
                            navigate(
                                `/${RoutesNames.Office}/${RoutesNames.Office_Professional}/${provider.id}/${RoutesNames.Office_Professional_Services}`,
                            );
                        }}
                    >
                        <VolunteerActivismIcon />
                    </IconButton>
                </Box>
            </Paper>
        </Grow>
    );
}

export function ProvidersScreen() {
    // Translations
    const { t } = useTranslation();

    // Get query
    const queryString = new URLSearchParams(window.location.search);
    const queryStringValues = {
        todayView: queryString.get('todayView'),
        query: queryString.get('query'),
    };

    // Hold state
    const [error, setError] = useState<boolean>(false);
    const [totalProvidersCount, setTotalProvidersCount] = useState<number>(-1);
    const [providers, setProviders] = useState<Array<Provider> | null>(null);
    const [todayProvidersViewEnabled, setTodayClientsViewEnabled] = useState<boolean>(
        queryStringValues.todayView === 'true' ? true : false,
    );
    const [query, setQuery] = useState<string>(queryStringValues.query ?? '');
    const searchDebouncedValue = useDebounce<string>(query, 500);

    // Load users
    const handleLoadMore = async (page: number) => {
        try {
            // Evaluate running mode
            if (todayProvidersViewEnabled) {
                // Get bookings
                const day = moment();
                let results: Array<Booking> = await BookingsRepository.instance.getUserBookings(
                    day.startOf('day').toDate(),
                    day.endOf('day').toDate(),
                    undefined,
                    undefined,
                    true,
                );

                // Exclude bookings by state
                results = results.filter((booking: Booking) => {
                    return booking.state === BookingState.Scheduled || booking.state === BookingState.Completed;
                });

                // Get providers
                const providers: Array<Provider> = [];
                for (const booking of results) {
                    if (booking.provider) {
                        providers.push(booking.provider);
                    }
                }

                // Exclude duplicates
                const uniqueProviders: Array<Provider> = [];
                providers.forEach((provider: Provider) => {
                    if (uniqueProviders.findIndex((p: Provider) => p.id === provider.id) === -1) {
                        uniqueProviders.push(provider);
                    }
                });

                // Update state
                setTotalProvidersCount(uniqueProviders.length);
                setProviders(uniqueProviders);
            } else {
                // Get users
                const results: { totalCount: number; data: Array<Provider> } =
                    await ProvidersRepository.instance.getProviders(
                        providers?.length ?? 0,
                        20,
                        undefined,
                        undefined,
                        query !== '' ? query : undefined,
                        undefined,
                    );

                // Update state
                setTotalProvidersCount(results.totalCount);
                setProviders([...(providers || []), ...results.data]);
            }
        } catch (error) {
            // Log
            console.error(error);

            // Update state
            setError(true);
        }
    };

    // Action: Reset all
    const resetState = async () => {
        // Reset state
        setError(false);
        setProviders(null);
        setTotalProvidersCount(-1);
    };

    // Action: Toggle Today Clients View Enabled
    const toggleTodayClientsViewEnabled = async () => {
        // Update Preference
        // localStorage.setItem(LocalStorageKey.NotificationsUnreadFilter, todayClientsViewEnabled ? 'false' : 'true');

        // Toogle filter
        setTodayClientsViewEnabled(!todayProvidersViewEnabled);

        // Reset state
        resetState();
    };

    // Execute Search
    useEffect(() => {
        // Reset state (and fire search by consequence)
        resetState();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchDebouncedValue]);

    // Build
    return (
        <>
            <Page
                title={t('routes.office.professional.providers.title')}
                onRefresh={async () => {
                    // Reset state
                    resetState();
                }}
                actions={[
                    <IconButton
                        color={todayProvidersViewEnabled ? 'primary' : 'default'}
                        aria-label="filter-unread"
                        onClick={() => {
                            toggleTodayClientsViewEnabled();
                        }}
                    >
                        <TodayIcon />
                    </IconButton>,
                ]}
            >
                <Container>
                    {!error && (
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={handleLoadMore}
                            hasMore={providers?.length !== totalProvidersCount}
                            loader={
                                <Box
                                    key={`users-loader`}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '32px',
                                    }}
                                >
                                    <Loader size={48} />
                                </Box>
                            }
                        >
                            {providers?.length === 0 && todayProvidersViewEnabled === false && (
                                <EmptyState
                                    key={`users-empty`}
                                    icon={<Person2Icon />}
                                    title={t('routes.office.professional.providers.empty.title')}
                                    description={t('routes.office.professional.providers.empty.description')}
                                />
                            )}
                            {providers?.length === 0 && todayProvidersViewEnabled === true && (
                                <EmptyState
                                    key={`users-empty`}
                                    icon={<TodayIcon />}
                                    title={t('routes.office.professional.providers.emptyToday.title')}
                                    description={t('routes.office.professional.providers.emptyToday.description')}
                                    actions={[
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                toggleTodayClientsViewEnabled();
                                            }}
                                        >
                                            {t('routes.office.professional.providers.emptyToday.listAll')}
                                        </Button>,
                                    ]}
                                />
                            )}
                            {providers?.map((provider: Provider, index: number) => {
                                return (
                                    <ProviderLine
                                        provider={provider}
                                        key={`provider-${index}`}
                                        query={query}
                                        todayClientsViewEnabled={todayProvidersViewEnabled}
                                    />
                                );
                            })}
                        </InfiniteScroll>
                    )}
                    {error && (
                        <ErrorState
                            icon={<Person2Icon />}
                            onTryAgainClick={() => {
                                // Reset state
                                setError(false);
                            }}
                        />
                    )}
                    {!todayProvidersViewEnabled && (
                        <Box
                            sx={{
                                height: '120px',
                            }}
                        ></Box>
                    )}
                </Container>
            </Page>
            {!todayProvidersViewEnabled && (
                <ProviderSearch
                    value={query}
                    onChange={(value: string) => {
                        // Update state
                        setQuery(value);
                    }}
                    // disabled={isLoading}
                />
            )}
        </>
    );
}
