import { AxiosResponse } from 'axios';
import { City } from '../interfaces/city';
import { BackendApiManager } from '../managers/backend-api';
import { Logger } from '../utils/logger';

export class CitiesRepository {
    // Singleton Instance
    private static _instance: CitiesRepository;

    // Private
    private logger: Logger;

    static get instance(): CitiesRepository {
        if (!this._instance) {
            this._instance = new CitiesRepository();
        }
        return this._instance;
    }

    constructor() {
        // Create Logger
        this.logger = new Logger('CitiesRepository');
    }

    public async getCities(
        skip: number,
        limit: number,
        name?: string,
        latitude?: number,
        longitude?: number,
    ): Promise<{ totalCount: number; data: Array<City> }> {
        // Set url
        let url: string = `/cities?skip=${skip}&limit=${limit}`;

        // Add Name
        if (name) {
            url += `&name=${name}`;
        }

        // Add Latitude and Longitude
        if (latitude && longitude) {
            url += `&latitude=${latitude}&longitude=${longitude}`;
        }

        // Get from backend
        const result: AxiosResponse<Array<City>> = await BackendApiManager.instance.get<Array<City>>(url);

        // Return
        return { data: result.data, totalCount: parseInt(result.headers['x-total-count'] || '', 10) };
    }
}
