import { AppContextAction, AppContextActionType, AppContextState } from './types';

const reducer = (prevState: AppContextState, action: AppContextAction) => {
    switch (action.type) {
        case AppContextActionType.APP_READY:
            return {
                ...prevState,
                appIsReady: action.appIsReady,
                orientation: action.orientation,
                insets: action.insets,
                userLocation: action.userLocation,
                services: action.services,
            };
        case AppContextActionType.SET_LOCATION:
            return {
                ...prevState,
                userLocation: action.userLocation,
            };
        case AppContextActionType.SET_SERVICES:
            return {
                ...prevState,
                services: action.services,
            };
        case AppContextActionType.SET_APPOINTMENT:
            return {
                ...prevState,
                appointment: action.appointment,
            };
        //
        case AppContextActionType.EVENT_THEME_MODE_CHANGED:
            return {
                ...prevState,
                themeOption: action.themeOption,
                themeMode: action.themeMode,
                theme: action.theme,
            };
        case AppContextActionType.EVENT_ORIENTATION_CHANGED:
            return {
                ...prevState,
                orientation: action.orientation,
                insets: action.insets,
            };
    }
};
export default reducer;
