import { AuthContextAction, AuthContextActionType, AuthContextState } from './types';

const reducer = (prevState: AuthContextState, action: AuthContextAction): AuthContextState => {
    switch (action.type) {
        case AuthContextActionType.RESTORE_SESSION:
            return {
                ...prevState,
                isRestoringSession: false,
                firebaseUser: action.firebaseUser,
                me: action.me,
            };
        case AuthContextActionType.SIGN_IN:
            return {
                ...prevState,
                firebaseUser: action.firebaseUser,
                me: action.me,
            };
        case AuthContextActionType.SIGN_OUT:
            return {
                ...prevState,
                isRestoringSession: false,
                firebaseUser: undefined,
                me: undefined,
            };
        case AuthContextActionType.REFRESH_ME:
            return {
                ...prevState,
                me: action.me,
                isReloadingMe: false,
            };
        case AuthContextActionType.SET_NOTIFICATIONS_EXPLAIN_DIALOG_OPEN:
            return {
                ...prevState,
                notificationsExplainDialogOpen: action.notificationsExplainDialogOpen,
            };
        case AuthContextActionType.START_RELOADING_ME:
            return {
                ...prevState,
                isReloadingMe: true,
            };
    }
};
export default reducer;
