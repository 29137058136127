import moment from 'moment';
import { MomentLanguageCode } from '../enums/moment-language-code';
import { LanguageTranslation } from './types';

// Overriding
// https://github.com/moment/moment/blob/develop/locale/en-gb.js
moment.updateLocale(MomentLanguageCode.enGB, {
    longDateFormat: {
        LT: 'h:mm A',
        LTS: 'h:mm:ss A',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd, D MMMM YYYY HH:mm',
    },
    week: {
        dow: 1,
    },
});

export const en_US: LanguageTranslation = {
    components: {
        phoneNumberInput: {
            country: 'Country',
            mobilePhoneNumber: 'Phone number',
        },
        auxPage: {
            back: 'Back',
        },
        vaidosaCard: {
            gift: {
                title: 'Gift',
                description: '{{balance}}€ available',
            },
            founder: {
                title: 'Founder',
                description: '{{price}}€',
            },
            loyalty: {
                title: 'Loyalty',
                description: '{{balance}} points / 100',
            },
        },
        errorState: {
            title: 'Ooops! An error occurred.',
            tryAgain: 'Try again',
        },
        signInAd: {
            title: 'Hello Vaidosa? 👋',
            description: 'Sign in the app for free with just your mobile number to',
            button: 'Sign in 🚪',
        },
        timeTraveller: {
            yesterday: 'Yesterday',
            today: 'Today',
            tomorrow: 'Tomorrow',
            previousMonth: 'Previous month',
            thisMonth: 'This month',
            nextMonth: 'Next month',
        },
        userSearch: {
            search: 'Search',
            placeholder: 'Name or phone number',
        },
        providerSearch: {
            search: 'Search',
            placeholder: 'Name',
        },
        servicePicker: {
            title: 'Choose the services',
            placeholder: 'Name of the service',
            empty: {
                title: 'No services were found',
                description: "We couldn't find any services with the name you entered. Try another name. 😉",
                descriptionCategory:
                    'We could not find any services with the name you entered in this category. Try another name or search all categories. 😉',
                seeAllCategories: 'See all categories',
            },
            bookNow: 'Book now',
            priceInterval: 'From {{min}} to {{max}}€',
            durationInterval: 'From {{min}} to {{max}}',
            price: '{{price}}€',
            duration: '{{duration}} minutes',
            next: 'Choose the professional',
            select: 'Select',
            selected: 'Selected',
            search: 'Search',
        },
        locationPicker: {
            title: 'Pick a city',
            placeholder: 'Search by city',
            empty: {
                title: "We couldn't find the city you're looking for",
                description: 'No city could be found with the name you entered. Try another name. 😉',
            },
            whereAreYou: 'Where are you Vaidosa?',
            locationInput: 'Enter name name of your nearest city',
            aroundMe: 'Near me',
            unknownLocation: 'Unknown location',
        },
        timePicker: {
            durationTextHoursAndMinutes: '{{hours}}h {{minutes}}min',
            durationTextHours: '{{hours}}h',
            durationTextMinutes: '{{minutes}}min',
            durationTextZero: '0min',
        },
        bookingCard: {
            cancelLock: 'Cancel lock',
            cancelBooking: 'Cancel appointment',
            clientDidNotShowUp: 'Client did not show up',
            keep: 'Keep',
            cancel: 'Cancel',
            confirm: 'Confirm',
            cancelBookingTitle: 'Cancel Service',
            cancelBookingDescription:
                'Are you sure you want to cancel this service? The {{clientName}} will be notified.',
            userNoShowBookingTitle: 'Customer did not show up',
            userNoShowBookingDescription:
                'We will notify {{clientName}} to make the 50% payment for this service in order to be able to book again.',
            bookedByClient: 'Client',
            bookedByProvider: 'Professional',
        },
    },
    routes: {
        signIn: {
            welcome1: 'Hello Vaidosa!',
            welcome2: 'Welcome to our community!',
            welcome3: 'Sign in the app for free with your mobile number',
            welcomeNewAppointment:
                'In order to continue with the appointment, please log in to the app for free with your mobile number.',
            enter: 'Sign in',
            legalDocumentsAcceptance: 'By logging in, you agree to our Terms of Use and Privacy Policy',
            help: 'Need help? Call us here',
            otpMessage: "We've sent you a confirmation code to your cell phone number.",
            otpInput: 'Enter the 6-digit confirmation code sent by SMS.',
            otpCode: 'Confirmation code',
            otpWarn: "If you don't receive the confirmation code within 60 seconds, go back to the previous step",
            back: 'Go back',
            confirm: 'Confirm',
        },
        termsOfUse: {
            title: 'General Conditions',
        },
        privacyPolicy: {
            title: 'Privacy Policy',
        },
        office: {
            main: {
                bottomNavigation: {
                    home: 'Home',
                    academy: 'Academy',
                    professionals: 'Professionals',
                    bookings: 'Bookings',
                    card: 'Cards',
                    more: 'More',
                },
                home: {
                    goodMorning: 'Good morning',
                    goodAfternoon: 'Good afternoon',
                    goodEvening: 'Good evening',
                    hello: 'Hello',
                    nextAppointment: 'Your next appointment',
                    previousAppointment: 'Your previous appointment',
                    noAppointments: {
                        title: 'Appointments',
                        letsgo: "Let's go Vaidosa?",
                        description:
                            "You haven't booked any appointments yet. Come on, book your next appointment now!",
                    },
                    loyalty: 'Loyalty',
                    gift: 'Gift',
                    points: 'points',
                    available: 'available',
                    whatDoYouWantToDo: 'Find Appointment for Today',
                    forYou: 'Your bookings',
                    searchByServiceCategory: 'Specialties',
                    searchByService: 'Search by services name',
                    manicure: 'Manicure',
                    pedicure: 'Pedicure',
                    makeup: 'Makeup',
                    yourSalon: 'Your salon',
                    bookingsSignInAd: 'view for your next appointment.',
                    knowMore: 'Know more',
                    accordions: {
                        administrator: 'Vaidosa Administrator',
                        professional: 'Vaidosa Professional',
                    },
                    professional: {
                        schedule: {
                            title: 'Agenda',
                            help: 'Services done this month',
                            noProvider: 'No provider',
                            weekDayDateFormat: 'M/D',
                            week: 'Week {{weekNumber}}',
                            hours: 'Hours',
                        },
                        invoicing: {
                            title: 'Invoicing',
                            help: 'Invoicing this month',
                            noProvider: 'No provider',
                        },
                        clients: {
                            title: 'Clients',
                            help: 'Clients this month',
                        },
                        myServices: {
                            title: 'Services',
                            help: 'Define prices and times for your services',
                        },
                        ocurrencies: {
                            title: 'Occurrences',
                            help: 'Register of situations to be resolved',
                        },
                        diaries: {
                            title: 'Diaries',
                            help: 'Day diaries reported',
                        },
                        providers: {
                            title: 'Professionals',
                            help: 'Professionals this month',
                        },
                    },
                },
                card: {
                    titleLine1: 'Vaidosa',
                    titleLine2: 'Cards',
                    transactions: 'Transactions',
                    alert: {
                        loyalty:
                            'You earn 10 points for each appointment you book. When you reach 100 points, you accumulate a €1 balance on your Gift Card for a discount on your next service.',
                        gift: 'Use the balance on your Gift Card to discount the services you want. You can also top up the balance on a Gift Card to give to someone.',
                    },
                    empty: {
                        loyalty:
                            "Haven't had a service on Vaidosas yet? Come on, book your appointment now and start earning points!",
                        gift: "You haven't earned a balance on your Gift Card yet. For every 100 points on your Loyalty Card we'll give you €5! Keep an eye on our competitions and promotions and earn even more points.",
                    },
                    cardsSignInAd: 'to see your points and available balance.',
                    transactionsSignInAd: 'check your points and balance transactions.',
                    bookingSignInAd: 'to list all your appointments already completed and scheduled.',
                },
                bookings: {
                    titleLine1: 'Your',
                    titleLine2: 'Bookings',
                    empty: {
                        title: "You haven't booked an appointment yet",
                        description: 'Come on, book your next appointment now!',
                    },
                    cancel: 'Cancel Appointment',
                    cancelDescription:
                        'Are you sure you want to cancel this appointment? {{providerName}} will be notified.',
                    keep: 'Keep',
                },
                academy: {
                    titleLine1: 'Vaidosas',
                    titleLine2: 'Academy',
                },
                more: {
                    titleLine1: 'More',
                    titleLine2: 'Vaidosa',
                    legalDocuments: 'Legal Documents',
                    account: 'Account',
                    logout: 'Logout',
                    help: 'Need help?',
                    help_call: 'Phone (10am-1pm)',
                    help_whatsapp: 'WhatsApp (24h)',
                    help_website: 'Website',
                },
                bookingButton: 'Book an appointment',
                professionals: {
                    titleLine1: "Vaidosa's",
                    titleLine2: 'Professionals',
                    empty: {
                        title: "We couldn't find any professionals",
                        description: 'Try searching under another name. 😉',
                    },
                    search: 'Search',
                    placeholder: 'Name of the professional',
                },
            },
            book: {
                title: 'Select a professional',
                errorTitle: 'Hello Vaidosa!',
                errorMessage: 'We were unable to access the system. Please contact us directly.',
                empty: {
                    title: "We couldn't find any available professionals",
                    description: 'Try decreasing the number of services or try again later. 😉',
                },
                selectProfessional: 'Agendar com a {{name}}',
                minutes: '{{minutes}} min',
                minutesComplete: '{{minutes}} minutes',
                notAvailable: 'Schedule full',
                availableNow: 'Available now!',
                availableSoon: 'Available at {{time}} ({{fromNow}})',
                availableTomorrow: 'Tomorrow at {{time}}',
                availableThisWeek: '{{day}} at {{time}}',
                availableAfter: '{{month}} {{day}} at {{time}} ({{fromNow}})',
                providerBirthday: '{{years}} years',
                awards: {
                    bestLocation: 'Nearest',
                    bestAvailability: 'Best availability',
                    bestReviewsCount: 'Most reviews',
                    bestAverageRating: 'Best rating',
                    bestPrice: 'Best price',
                    bestDuration: 'Fastest',
                },
                location: 'Location',
                sort: 'Sorting',
                sortOptions: {
                    location: 'Location',
                    availability: 'Availability',
                    reviewsCount: 'Reviews',
                    averageRating: 'Rating',
                    price: 'Price',
                    duration: 'Duration',
                },
            },
            notificationsExplainDialog: {
                title: 'Can we send you notifications? 🔔',
                description:
                    'We will notify you about your next appointment, when you earn points, offer you exclusive promotions, inform you about new services and much more.',
                noThanks: 'No, thanks',
                confirm: 'Let’s do it! 💅',
            },
            notifications: {
                title1: 'Notification',
                title2: 'Notifications',
                empty: {
                    title: 'You have no notifications yet.',
                    description: 'Whenever we have communications for you, we will notify you here.',
                },
                emptyUnread: {
                    title: 'There are no unread notifications',
                    description:
                        'All notifications are marked as read. Review all notifications by clicking the button below.',
                    listAll: 'Show all notifications',
                },
                markAllAsReadConfirmDialog: {
                    title: 'Mark all as read?',
                    description: 'This will mark all notifications as read.',
                    confirm: 'Confirm',
                    cancel: 'Cancel',
                },
            },
            professional: {
                invoicing: {
                    notAvailableYet: "The day's billing is only calculated during the day itself Come back later.",
                    emptyTitle: 'There are no invoices',
                    emptyDescription: 'No services provided by you have been invoiced on this day',
                    dayTotal: 'Day total',
                    goodJob: 'Good job!',
                },
                schedule: {
                    emptyTitle: 'There are no appointments',
                    emptyDescription: 'There are no appointments scheduled on this day',
                    summary: 'Summary of the day',
                    clients: 'Customers',
                    services: 'Services',
                    expectedEarnings: 'Expected earnings',
                },
                users: {
                    title: 'Customers',
                    empty: {
                        title: 'No customers',
                        description: 'There are no customers registered in the system',
                    },
                    emptyToday: {
                        title: 'There are no customers for today',
                        description: "You don't currently have any customers booked for today",
                        listAll: 'View all customers',
                    },
                    withoutName: '(Without name)',
                },
                services: {
                    title: 'My services',
                    emptyTitle: 'There are no services.',
                    emptyDescription: 'Not expected. Please contact your System Administrator.',
                    form: {
                        isActive: 'Available for clients',
                        durationInMinutes: 'Duration in minutes',
                        price: 'Price in euros (€)',
                        photosUrls: 'Photos URLs',
                        cancel: 'Cancel',
                        saveChanges: 'Save',
                    },
                },
                providers: {
                    title: 'Provider',
                    empty: {
                        title: 'No provider',
                        description: 'There are no providers registered in the system',
                    },
                    emptyToday: {
                        title: 'There are no providers for today',
                        description: "You don't currently have any providers booked for today",
                        listAll: 'View all providers',
                    },
                    withoutName: '(Without name)',
                },
            },
            systemAdministator: {
                invoicing: {
                    notAvailableYet:
                        'Invoicing for the month is only calculated during the month itself. Come back later.',
                    emptyTitle: 'There are no invoices.',
                    emptyDescription: 'No services were billed this month.',
                    monthTotal: 'Total for the month',
                    goodJob: 'Good job!',
                    earned: 'Paying',
                    contributed: 'Contributed',
                    noProvider: 'No provider',
                    totalInvoiced: 'Total invoiced',
                    providersEarningsTotal: 'Total earnings of providers',
                    balanceBeforeExpenses: 'Balance before expenses',
                    businessExpensesTotal: 'Total business expenses',
                    finalBalance: 'Final balance',
                    isGonnaBeAllRight: "It's going to be all right! Keep going!",
                },
                schedule: {
                    emptyTitle: 'There are no appointments',
                    emptyDescription: 'There are no appointments scheduled on this month',
                    scheduled: 'Scheduled',
                    cancelled: 'Cancelled',
                    completed: 'Completed',
                    monthTotal: 'Total for the month',
                    totalScheduled: 'Total scheduled',
                    totalCancelled: 'Total cancelled',
                    totalCompleted: 'Total completed',
                    goodJob: 'Good job!',
                    isGonnaBeAllRight: "It's going to be all right! Keep going!",
                },
            },
            user: {
                tabs: {
                    profile: 'Profile',
                    history: 'History',
                    loyalty: 'Loyalty',
                    gift: 'Gift',
                },
                profile: {
                    firstName: 'First name',
                    lastName: 'Last name',
                    phoneNumber: 'Phone number',
                    emailAddress: 'Email address',
                    birthDate: 'Birth date',
                    taxPayerNumber: 'Taxpayer number',
                    isActive: 'Active',
                },
                history: {
                    empty: {
                        title: 'No bookings',
                        description: 'The client never made an appointment',
                    },
                },
                card: {
                    empty: {
                        title: 'Sem transações',
                        description: 'Não existem transações registadas para este cartão',
                    },
                },
            },
            showcase: {
                scheduleAppointment: 'Schedule an appointment',
                call: 'Call',
                closed: 'Closed',
                open: 'Open',
                selectSchedule: 'Schedule',
                pickStartHour: "Select the service's start time",
                location: 'Location',
                schedule: 'Working hours',
                services: 'Services',
                noServicesSelected: 'No services selected',
                noServicesSelectedDescription: 'Select at least one service to continue',
                selectServices: 'Select services',
                noAvailabilityForThisDay: 'No availability for this day',
                noAvailabilityForThisDayDescription: 'Select another day to continue',
                confirmAppointment: 'Confirm appointment',
                directions: 'Get directions / View map',
            },
            newAppointment: {
                title: 'Confirm appointment',
                emptyTitle: 'No services selected',
                emptyDescription: 'Please select at least one service to be able to schedule',
                selectServices: 'Select services',
                when: 'When',
                who: 'Where',
                what: 'Services',
                you: 'Client',
                submit: 'Schedule',
                successTitle: 'Appointment scheduled successfully!',
                successDescription:
                    'The {{provider}} will be waiting for you at the appointed time! Please change or cancel your appointment in good time if you are unable to attend',
                errorTitle: 'It was not possible to schedule your appointment. Please restart the process.',
                restartProcess: 'Restart',
                goingBackToStart: "Appointment booked successfully! Let's go back to the start...",
                startMenu: 'Back to the start',
                directions: 'Directions',
                form: {
                    firstAndLastName: 'First and last name',
                    observations: 'Notes',
                },
                notSignedIn: {
                    title: 'Hey! are we signing in?',
                    description: 'To receive notifications, edit or cancel your appointment, we need you to sign in.',
                },
                diffPhoneNumber: {
                    title: 'Different cell phone number?',
                    description:
                        'This appointment was made with a different cell phone number than the one you have in your account and only that number will receive notifications and be able to manage the appointment.',
                },
                errors: {
                    'provider-not-found': "Professional's profile could not be found. Please contact us directly.",
                    'provider-services-mismatch':
                        'Professional no longer has the services you selected available. Please try again.',
                    'user-not-active': 'Your account is deactivated. Please contact us directly.',
                    'user-is-blocked-from-booking':
                        'Your account is blocked and therefore you cannot schedule bookings. Please contact us directly.',
                    'provider-not-available':
                        'The Professional is no longer available for the time you selected. Please try again.',
                    'user-cannot-be-the-provider': "You can't book an appointment with yourself.",
                },
                distanceWarningDialog: {
                    title: 'Distance Alert!',
                    description:
                        'A <strong>{{providerName}}</strong> is located in <strong>{{cityName}}</strong>, more than <strong>{{distance}} km</strong> away. Are you sure you want to continue with this appointment?',
                    confirm: 'Confirm',
                    cancel: 'Cancel',
                },
            },
            providerNewEvent: {
                title: 'New event',
                type: 'Type',
                when: 'When',
                what: 'Services',
                you: 'Client',
                submit: 'Schedule',
                form: {
                    firstAndLastName: 'First and last name',
                    observations: 'Notes',
                },
                lock: {
                    title: 'Lock',
                    start: 'Start',
                    end: 'End',
                    details: 'Details',
                    submit: 'Lock',
                    observations: 'Notes',
                    info: 'You should block your calendar to limit the number of appointments you can make during working hours. You can block your schedule for vacations, events, or any other situation that prevents you from accepting appointments. Note that you can create several blocks for the same day, but each block must start and end on the same day.',
                    errors: {
                        'provider-not-found': "Professional's profile could not be found. Please contact us directly.",
                        'invalid-dates': 'The start date must be before the end date.',
                    },
                    errorTitle: 'It was not possible to block your address book. Please start the process again.',
                },
                appointment: {
                    title: 'Appointment',
                    pickServices: 'Select services',
                    pickDate: 'Select time',
                    cancel: 'Cancel',
                    submit: 'Select',
                    emptyServices: 'No services selected.',
                    emptyDate: 'No date selected.',
                    errors: {
                        'provider-not-found': 'Your profile could not be found. Please contact us directly.',
                        'provider-services-mismatch':
                            'You no longer have the services you selected available. Please try again.',
                        'user-not-active': "Client's account is deactivated. Please contact us directly.",
                        'user-is-blocked-from-booking':
                            "Client's account is blocked and therefore it cannot schedule bookings. Please contact us directly.",
                        'provider-not-available':
                            'You no longer have the available time you selected. Please try again.',
                        'user-cannot-be-the-provider': "You can't book an appointment with yourself.",
                    },
                    errorTitle: 'It was not possible to schedule the appointment. Please restart the process.',
                    info: 'You should schedule the appointment so that the client can be informed and reminded of the appointment via notifications and so that she can score points for your services and thus earn points on her Loyalty Card.',
                },
            },
        },
    },
    enums: {
        daysOfTheWeek: {
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            sunday: 'Sunday',
        },
        bookingState: {
            scheduled: 'Scheduled ⏰',
            'cancelled-by-provider': 'Cancelled 👩‍⚕️',
            'cancelled-by-user': 'Cancelled ❌',
            'user-no-show': 'Missing 🙅‍♀️',
            completed: 'Completed ✅',
            'deleted-by-provider': 'Deleted 🗑️',
        },
        serviceCategory: {
            manicure: 'Manicure',
            pedicure: 'Pedicure',
            makeup: 'Makeup',
            hair: 'Hair',
            facialEpilation: 'Facial epilation',
            eyelashes: 'Eyelashes',
            depilation: 'Depilation',
        },
    },
    //
    legal_documents: {
        terms_and_conditions: 'Terms and Conditions',
        privacy_policy: 'Privacy Policy',
    },
    products: {
        bluebird: 'Sioslife',
        bluebird_with_gender: 'to Sioslife',
    },
};
