/**
 * Moment Language Codes (for the App)
 */
export enum MomentLanguageCode {
    /**
     * Portuguese (Portugal)
     */
    ptPT = 'pt',

    /**
     * English (UK) => They don't seem to have an English (US)
     */
    enGB = 'en-gb',

    /**
     * Spanish (es)
     */
    esES = 'es',
}
