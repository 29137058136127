import {
    Auth,
    getAuth,
    RecaptchaVerifier,
    signInWithEmailAndPassword,
    signInWithPhoneNumber,
    User as FirebaseUser,
    UserCredential,
} from 'firebase/auth';
import i18next from 'i18next';
import React, { useContext, useReducer } from 'react';
import { NativeKnightEventEmitter, NativeKnightEvents } from '../..';
import { Environment } from '../../enums/environment';
import { LocalStorageKey } from '../../enums/local-storage-key';
import { OperativeSystem } from '../../enums/operative-system';
import { SignInError } from '../../enums/sign-in-error';
import { Me } from '../../interfaces/me';
import { BackendApiManager } from '../../managers/backend-api';
import { EnvironmentManager } from '../../managers/environment';
import { NativeKnight } from '../../native-knight';
import { MeRepository } from '../../repositories/me';
import { Logger } from '../../utils/logger';
import { AppContext } from '../app/context';
import { AppContextType } from '../app/types';
import { AuthContext } from './context';
import reducer from './reducer';
import { AuthContextActionType, AuthContextState, SignInCredentials } from './types';

// Logger
const logger: Logger = new Logger('AuthContext');

// Set initial state
const initialState: AuthContextState = {
    isRestoringSession: true,
    firebaseUser: undefined,
    me: undefined,
    notificationsExplainDialogOpen: false,
};

// onAuthStateChanged Subscription
let onAuthStateChangedSubscription: any;

const OPERATIVE_SYSTEMS_WITH_NOTIFICATIONS = [OperativeSystem.Android, OperativeSystem.iOS];

export const AuthContextProvider = (props: { children: React.ReactNode }) => {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);

    // Create reducer
    const [state, dispatch] = useReducer(reducer, initialState);

    // (Private) Apply user
    async function applyUser(firebaseUser: FirebaseUser): Promise<Me | null> {
        // Rehook backend auth
        await BackendApiManager.instance.hookUser(firebaseUser);

        try {
            // Load session
            const me: Me = await MeRepository.instance.get();

            // Log
            logger.debug('ℹ️ Me obtained:', me);

            // Done
            return me;
        } catch (error) {
            // Something went sideways
            logger.error('🔴 Could not GET /me.', error);

            // Cannot continue, logout
            return null;
        }
    }

    // (Private) Get Device Code
    function getDeviceCode(): string {
        return 'ios-ec426342_1e87_4a3a_b2f6_276f556695c2';
        // // Get Device Id
        // const deviceUniqueId = appContext.getDeviceUniqueId();

        // // Build device code
        // return `${appContext.state.operativeSystem}-${deviceUniqueId}`;
    }

    // (Private) Subscribe to Notifications
    async function subscribeToNotifications(token: string): Promise<void> {
        // Send token to backend
        await BackendApiManager.instance.put(`/notifications/subscriptions/${getDeviceCode()}`, {
            token,
            applicationLanguageCode: i18next.language.replace('_', '-'),
        });
    }

    // Unsubscribe to Notifications
    const unsubscribeToNotifications = async (): Promise<void> => {
        // Stop listen for token changes
        NativeKnightEventEmitter.off(NativeKnightEvents.RETURN_FIREBASE_MESSAGING_TOKEN, subscribeToNotifications);

        // Delete token on backend
        await BackendApiManager.instance.delete(`/notifications/subscriptions/${getDeviceCode()}`);
    };

    // Restore session
    const restorePreviousSession = async () => {
        // If a previous subscription was put in place
        if (onAuthStateChangedSubscription) {
            // Unsubscribe
            onAuthStateChangedSubscription();

            // Null it
            onAuthStateChangedSubscription = null;
        }

        // Subscribe for auth state Change
        onAuthStateChangedSubscription = getFirebaseAuth().onAuthStateChanged(
            async (firebaseUser: FirebaseUser | null) => {
                // Unsubscribe
                onAuthStateChangedSubscription();

                // Log
                logger.debug('onAuthStateChanged', firebaseUser);

                // Handle user state changes
                if (!firebaseUser) {
                    // No previous session, update UI
                    dispatch({
                        type: AuthContextActionType.RESTORE_SESSION,
                        firebaseUser: undefined,
                        me: undefined,
                    });
                } else {
                    // Load me
                    const me: Me | null = await applyUser(firebaseUser);

                    // Evaluate
                    if (me) {
                        // Update UI
                        dispatch({
                            type: AuthContextActionType.RESTORE_SESSION,
                            firebaseUser: firebaseUser,
                            me,
                        });
                    } else {
                        // Update UI
                        dispatch({
                            type: AuthContextActionType.RESTORE_SESSION,
                            firebaseUser: undefined,
                            me: undefined,
                        });
                    }
                }
            },
        );
    };

    // Sign in
    const signIn = async (credentials: SignInCredentials): Promise<SignInError | undefined> => {
        try {
            // Try sign in
            const userCredential: UserCredential = await signInWithEmailAndPassword(
                getFirebaseAuth(),
                credentials.email,
                credentials.password,
            );

            // Log
            logger.debug('ℹ️ User Signed In:', userCredential);

            // Load me
            const me: Me | null = await applyUser(userCredential.user);

            // Evaluate
            if (me) {
                // Login went well
                dispatch({
                    type: AuthContextActionType.SIGN_IN,
                    firebaseUser: userCredential.user,
                    me,
                });

                // Done
                return undefined;
            } else {
                // Update UI
                dispatch({
                    type: AuthContextActionType.SIGN_IN,
                    firebaseUser: undefined,
                    me: undefined,
                });

                // Cannot continue, logout
                return SignInError.FailedToGetMe;
            }
        } catch (error: any) {
            // Error occurred
            logger.debug('🔴 Sign In error', error);

            // Pick error
            // https://firebase.google.com/docs/auth/admin/errors
            let signInError: SignInError = SignInError.Unknown;
            switch (error.code) {
                case 'auth/invalid-email':
                    // Thrown if the email address is not valid.
                    signInError = SignInError.InvalidEmail;
                    break;
                case 'auth/user-disabled':
                    // Thrown if the user corresponding to the given email has been disabled.
                    signInError = SignInError.UserDisabled;
                    break;
                case 'auth/user-not-found':
                    // Thrown if there is no user corresponding to the given email.
                    signInError = SignInError.UserNotFound;
                    break;
                case 'auth/wrong-password':
                    // Thrown if the password is invalid for the given email, or the account corresponding to the email does not have a password set.
                    signInError = SignInError.WrongPassword;
                    break;
            }

            // Update UI
            dispatch({
                type: AuthContextActionType.SIGN_IN,
                firebaseUser: undefined,
                me: undefined,
            });

            // Return
            return signInError;
        }
    };

    // Request OTP
    const requestOtp = async (containerOrId: string | HTMLElement, phoneNumber: string): Promise<boolean> => {
        try {
            // Generate recaptcha
            if (!(window as any).recaptchaVerifier) {
                (window as any).recaptchaVerifier = new RecaptchaVerifier(getFirebaseAuth(), containerOrId, {
                    size: 'invisible',
                    callback: (response: any) => {
                        // reCAPTCHA solved, allow signInWithPhoneNumber.
                        console.debug('RecaptchaVerifier Response', response);
                    },
                });
            }

            // Map App verifier
            const appVerifier = (window as any).recaptchaVerifier;

            // Sign in
            const confirmationResult = await signInWithPhoneNumber(getFirebaseAuth(), phoneNumber, appVerifier);

            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            (window as any).confirmationResult = confirmationResult;

            // Ok
            return true;
        } catch (error: any) {
            // Error; SMS not sent
            console.debug(error);

            // Not ok
            return false;
        }
    };

    // Confirm OTP
    const confirmOtp = async (otp: string): Promise<SignInError | undefined> => {
        // Verifu otp
        let confirmationResult = (window as any).confirmationResult;

        try {
            // Confirm OTP
            const userCredential: UserCredential = await confirmationResult.confirm(otp);

            // User signed in successfully. Log.
            logger.debug('ℹ️ User Signed In:', userCredential);

            // Load me
            const me: Me | null = await applyUser(userCredential.user);

            // Evaluate
            if (me) {
                // Login went well
                dispatch({
                    type: AuthContextActionType.SIGN_IN,
                    firebaseUser: userCredential.user,
                    me,
                });

                // Done
                return undefined;
            } else {
                // Update UI
                dispatch({
                    type: AuthContextActionType.SIGN_IN,
                    firebaseUser: undefined,
                    me: undefined,
                });

                // Cannot continue, logout
                return SignInError.FailedToGetMe;
            }
        } catch (error: any) {
            // User couldn't sign in (bad verification code?)
            console.error("User couldn't sign in (bad verification code?)", error);

            // Not ok
            return SignInError.Unknown;
        }
    };

    // Sign out
    const signOut = async (): Promise<void> => {
        try {
            // Check OS
            if (
                EnvironmentManager.instance.current !== Environment.Localhost &&
                OPERATIVE_SYSTEMS_WITH_NOTIFICATIONS.includes(appContext.state.operativeSystem)
            ) {
                // Unsubscribe to notifications
                await unsubscribeToNotifications();

                // Clear Badge Number
                await NativeKnight.instance.setBadgeNumber(0);
            }

            // Clear User Preferences
            localStorage.removeItem(LocalStorageKey.NotificationsUnreadFilter);

            // Close backend
            BackendApiManager.instance.hookUser(undefined);

            // Sign out from Firebase
            await getFirebaseAuth().signOut();

            // Update UI
            dispatch({ type: AuthContextActionType.SIGN_OUT });
        } catch (error: any) {
            // Log
            logger.debug('🔴 Could not Sign Out.', error);

            // Throw error
            throw new Error(error);
        }
    };

    // Get Firebase Auth
    const getFirebaseAuth = (): Auth => {
        const auth = getAuth();
        auth.languageCode = i18next.language.replace('_', '-');
        return auth;
    };

    // Refresh me
    const refreshMe = async (disableLoading?: boolean): Promise<void> => {
        // Eval state.firebaseUser
        if (state.firebaseUser) {
            // Check disableLoading
            if (!disableLoading) {
                // Start loading
                dispatch({
                    type: AuthContextActionType.START_RELOADING_ME,
                });
            }

            // Load me
            const [me]: [Me | null] = await Promise.all([
                // 0: Load me
                applyUser(state.firebaseUser),

                // 1: Get Precise Location
                // appContext.state.userLocation.isPrecise ? appContext.getLocation(true, true) : null,
            ]);

            // Update Badge Number
            NativeKnight.instance.setBadgeNumber(me?.unreadNotificationsCount ?? 0);

            // Evaluate
            if (me) {
                // Update UI
                dispatch({
                    type: AuthContextActionType.REFRESH_ME,
                    me,
                });
            }
        }
    };

    // Check Notifications Permission
    const checkNotificationsPermission = async () => {
        // Check OS
        if (
            EnvironmentManager.instance.current !== Environment.Localhost &&
            OPERATIVE_SYSTEMS_WITH_NOTIFICATIONS.includes(appContext.state.operativeSystem)
        ) {
            // Ask Native Knight
            const notificationsDecision: 'yes' | 'no' | 'explain' | null =
                await NativeKnight.instance.requestNotificationsPermission();

            // Evaluate
            if (notificationsDecision === 'explain') {
                // Update UI
                dispatch({
                    type: AuthContextActionType.SET_NOTIFICATIONS_EXPLAIN_DIALOG_OPEN,
                    notificationsExplainDialogOpen: true,
                });
            } else if (notificationsDecision === 'yes') {
                // Request token
                const token: string | null = await NativeKnight.instance.getFirebaseMessagingToken();

                // Eval token
                if (token) {
                    // Listen for token changes
                    NativeKnightEventEmitter.on(
                        NativeKnightEvents.RETURN_FIREBASE_MESSAGING_TOKEN,
                        subscribeToNotifications,
                    );

                    // Subscribe to notifications
                    await subscribeToNotifications(token);
                }
            }
        }
    };

    // Notifications Explained Decision
    const notificationsExplainedDecision = async (confirmed: boolean) => {
        // Close dialog
        dispatch({
            type: AuthContextActionType.SET_NOTIFICATIONS_EXPLAIN_DIALOG_OPEN,
            notificationsExplainDialogOpen: false,
        });

        // Eval decision
        if (confirmed) {
            // Directly request the permission. Ask Native Knight.
            await NativeKnight.instance.requestNotificationsPermission(true);
        }
    };

    // Mark Notification As Read
    const updateUnreadNotificationsCount = async (newValue: number): Promise<void> => {
        // Eval
        if (state.me) {
            // Dispatch
            dispatch({
                type: AuthContextActionType.REFRESH_ME,
                me: {
                    ...state.me,
                    unreadNotificationsCount: newValue,
                },
            });

            // Update Badge Number
            NativeKnight.instance.setBadgeNumber(newValue);
        }
    };

    // Build
    return (
        <AuthContext.Provider
            value={{
                state,
                // Methods
                restorePreviousSession,
                signIn,
                signOut,
                getFirebaseAuth,
                requestOtp,
                confirmOtp,
                refreshMe,
                checkNotificationsPermission,
                notificationsExplainedDecision,
                updateUnreadNotificationsCount,
                unsubscribeToNotifications,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};
