/**
 * Country Code ISO (ISO 3166-1 alpha-2)
 * https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
 * (We can even segment this by Country inside the country. Read more at:
 * https://en.wikipedia.org/wiki/ISO_3166-2:PT )
 */
export enum CountryCode {
    /**
     * Portugal
     */
    PT = 'PT',

    /**
     * Brazil
     */
    BR = 'BR',
}
