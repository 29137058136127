import styles from './square.module.scss';

export default function Square({
    top,
    left,
    bottom,
    right,
    color,
    position,
    zIndex,
    width,
}: {
    top?: string;
    left?: string;
    bottom?: string;
    right?: string;
    color?: string;
    position?: any;
    zIndex?: number;
    width?: string;
}) {
    // Build
    return (
        <div
            className={styles.square}
            style={{
                top: top,
                left: left,
                bottom: bottom,
                right: right,
                backgroundColor: color,
                position: position ?? 'fixed',
                zIndex: zIndex ?? -1,
                width: width ?? '100px',
            }}
        ></div>
    );
}
