import { Box } from '@mui/material';
import styles from './loader.module.scss';

export function Loader(props: { size?: number }) {
    // Define width and height
    const width = props.size ?? 120;
    const height = (width * 737) / 817;

    // Build
    return (
        <Box
            className={styles.container}
            sx={{
                maskImage: "url('/images/loader-mask.png')",
                width: `${width}px`,
                height: `${height}px`,
            }}
        >
            <Box className={styles.inside}></Box>
        </Box>
    );
}
