import { AxiosResponse } from 'axios';
import { LanguageCode } from '../enums/language-code';
import { NotificationType } from '../enums/notification-type';
import { Notification } from '../interfaces/notification';
import { BackendApiManager } from '../managers/backend-api';
import { Logger } from '../utils/logger';

export class NotificationsRepository {
    // Singleton Instance
    private static _instance: NotificationsRepository;

    // Private
    private logger: Logger;

    static get instance(): NotificationsRepository {
        if (!this._instance) {
            this._instance = new NotificationsRepository();
        }
        return this._instance;
    }

    constructor() {
        // Create Logger
        this.logger = new Logger('NotificationsRepository');
    }

    public rebuildRemoteNotification<T>(notification: string): Notification<T> {
        // Parse notification
        const notificationParsed: {
            bundle: any;
            data: {
                // Append Notification
                _id: string;
                _createdAt: string;
                _updatedAt: string;
                _type: NotificationType;
                _targetUserId: string;
                _fromProviderId?: string;
                _wasRead?: string;
                _languageCode?: LanguageCode;

                // Append Message Notification
                _notificationTitle: string | undefined;
                _notificationBody: string | undefined;
                _notificationImageUrl: string | undefined;

                // Other properties
                [key: string]: any;
            };
            notification: any;
        } = JSON.parse(notification);

        // Rebuild
        return {
            id: notificationParsed.data._id,
            createdAt: new Date(notificationParsed.data._createdAt),
            updatedAt: new Date(notificationParsed.data._updatedAt),

            // Required
            type: notificationParsed.data._type,
            targetUserId: notificationParsed.data._targetUserId,

            // State
            fromProviderId: notificationParsed.data._fromProviderId,
            wasRead: notificationParsed.data._wasRead
                ? notificationParsed.data._wasRead === 'true'
                    ? true
                    : false
                : undefined,
            languageCode: notificationParsed.data._languageCode,
            data: notificationParsed.data as any,
        };
    }

    // Get user notifications
    public async get(
        limit: number,
        skipDate: Date,
        wasRead?: boolean,
    ): Promise<{ totalCount: number; data: Array<Notification<any>> }> {
        // Set url
        let url: string = `/notifications?limit=${limit}&skipDate=${skipDate.toISOString()}`;

        // Add wasRead
        if (wasRead !== undefined) {
            url += `&wasRead=${wasRead}`;
        }

        // Get notifications from backend
        const result: AxiosResponse<Array<Notification<any>>> =
            await BackendApiManager.instance.get<Array<Notification<any>>>(url);

        // Return
        return { data: result.data, totalCount: parseInt(result.headers['x-total-count'] || '', 10) };
    }

    public async markNotificationAsRead(notificationId: string, wasRead: boolean): Promise<void> {
        // Set url
        let url: string = `/notifications/${notificationId}/read/${wasRead}`;

        // Get notifications from backend
        await BackendApiManager.instance.patch<Notification<any>>(url);
    }

    public async markAllNotificationsAsRead(wasRead: boolean): Promise<void> {
        // Set url
        let url: string = `/notifications/read/${wasRead}`;

        // Get notifications from backend
        await BackendApiManager.instance.patch<Notification<any>>(url);
    }
}
