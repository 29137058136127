import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, ClickAwayListener, IconButton, Tooltip, Typography } from '@mui/material';
import { MouseEventHandler, useState } from 'react';

export function Tile({
    label,
    icon,
    counter,
    help,
    badge,
    onClick,
    disabled,
}: {
    label: string;
    icon: React.ReactNode;
    counter?: string;
    help?: string;
    onClick?: MouseEventHandler<any>;
    disabled?: boolean;
    badge?: string;
}) {
    // Hold state
    const [helpTooltipOpen, setHelpTooltipOpen] = useState(false);

    // Actions
    const handleTooltipClose = () => {
        setHelpTooltipOpen(false);
    };

    const handleTooltipOpen = (event: any) => {
        event.stopPropagation();
        setHelpTooltipOpen(true);
    };

    // Render
    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                height: '140px',
            }}
        >
            <Button
                disabled={disabled}
                variant="contained"
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    padding: '0px',
                }}
                onClick={onClick}
            >
                {counter && (
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingTop: '42px',
                        }}
                    >
                        <Box
                            sx={{
                                fontSize: counter.length > 7 ? '28px' : '32px',
                                fontWeight: 'bold',
                            }}
                        >
                            {counter}
                        </Box>
                    </Box>
                )}

                <Box
                    sx={{
                        width: '100%',
                        minHeight: '56px',
                        textAlign: 'left',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    }}
                >
                    <Box
                        sx={{
                            paddingTop: '8px',
                            paddingRight: '4px',
                            svg: {
                                fontSize: '24px',
                            },
                        }}
                    >
                        {icon}
                    </Box>
                    <Box
                        sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {label}
                    </Box>
                </Box>
            </Button>
            {help && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '4px',
                        right: '4px',
                    }}
                >
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                        <div>
                            <Tooltip
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                onClose={handleTooltipClose}
                                open={helpTooltipOpen}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={help}
                            >
                                <IconButton
                                    sx={{
                                        opacity: 0.3,
                                    }}
                                    onClick={handleTooltipOpen}
                                    size="small"
                                >
                                    <InfoIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </ClickAwayListener>
                </Box>
            )}
            {badge && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '4px',
                        left: '4px',
                        backgroundColor: '#fff',
                        borderRadius: '12px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minWidth: '30px',
                        height: '30px',
                    }}
                >
                    <Typography
                        sx={{
                            padding: '5px',
                        }}
                        variant="caption"
                        fontWeight={'bold'}
                        color={'primary'}
                    >
                        {badge.toString()}
                    </Typography>
                </Box>
            )}
        </Box>
    );
}
