import { TextField, TextFieldVariants } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { PhoneNumber } from 'libphonenumber-js/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidationManager } from '../../managers/validation';

/**
 * Based on:
 * https://mui.com/material-ui/react-autocomplete/#country-select
 * https://codesandbox.io/s/fj6p83?file=/src/Demo.tsx
 * @returns
 */
export default function PhoneNumberInput(props: {
    value: string | null | undefined;
    onChange: (value: string | undefined) => void;
    disabled?: boolean;
    variant?: TextFieldVariants;
    error?: boolean;
}) {
    // Translations
    const { t } = useTranslation();

    // Validate it
    let defaultMobilePhoneNumberValue = '';
    let defaultCountry = countries[0];
    if (props.value) {
        try {
            // Parse it
            const parsedPhoneNumber: PhoneNumber | undefined = parsePhoneNumber(props.value);

            // Validate it
            if (
                parsedPhoneNumber &&
                parsedPhoneNumber.isValid() &&
                ['MOBILE'].includes(parsedPhoneNumber.getType() ?? '')
            ) {
                // All good, break parts
                const countryPart = parsedPhoneNumber.countryCallingCode;

                // Set default
                defaultMobilePhoneNumberValue = parsedPhoneNumber.number.replace('+' + countryPart, '');

                // Set country
                const country = countries.find((c) => c.phone === countryPart);

                // Update
                if (country) {
                    defaultCountry = country;
                }
            }
        } catch (error) {
            // Failed to parse
        }
    }

    // Hold State
    const [country, setCountry] = useState<CountryType | null>(defaultCountry);
    const [mobilePhoneNumber, setMobilePhoneNumber] = useState<string>(defaultMobilePhoneNumberValue);

    // Helpers
    const updatePhoneNumber = (country: CountryType | null, mobilePhoneNumber: string) => {
        // If country and mobilePhoneNumber are defined
        if (country && mobilePhoneNumber && mobilePhoneNumber.trim() !== '') {
            // Define
            const phoneNumber = '+' + country?.phone + mobilePhoneNumber;

            // Validate it
            if (ValidationManager.instance.isPhoneNumberValid(phoneNumber, ['MOBILE'])) {
                // Update
                props.onChange(phoneNumber);
            } else {
                // Reset
                props.onChange(undefined);
            }
        } else {
            // Reset
            props.onChange(undefined);
        }
    };

    // Build
    return (
        <Box>
            <Autocomplete
                disabled={props.disabled}
                options={countries}
                autoHighlight
                getOptionLabel={(option) => `${option.flag} ${option.label} (${option.code}) +${option.phone}`}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.flag} {option.label} ({option.code}) +{option.phone}
                    </Box>
                )}
                renderInput={(params) => {
                    return (
                        <TextField
                            error={props.error}
                            {...(params as any)}
                            label={`${t('components.phoneNumberInput.country')}*`}
                            variant={props.variant ?? 'filled'}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            autoComplete="off"
                            disabled={props.disabled}
                            autoFocus={false}
                        />
                    );
                }}
                value={country}
                onChange={(event: any, newValue: CountryType | null) => {
                    // Set country
                    setCountry(newValue);

                    // Update phone number
                    updatePhoneNumber(newValue, mobilePhoneNumber);
                }}
            />
            <TextField
                error={props.error}
                id="phone-number-input"
                label={`${t('components.phoneNumberInput.mobilePhoneNumber')}*`}
                variant={props.variant ?? 'filled'}
                type="tel"
                sx={{
                    marginTop: '16px',
                    width: '100%',
                }}
                autoComplete="tel-national"
                value={mobilePhoneNumber}
                onChange={(event: any) => {
                    // Set phone number
                    setMobilePhoneNumber(event.target.value);

                    // Update phone number
                    updatePhoneNumber(country, event.target.value);
                }}
                disabled={props.disabled}
                autoFocus={false}
            />
        </Box>
    );
}

interface CountryType {
    code: string;
    label: string;
    phone: string;
    flag: string;
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const countries: readonly CountryType[] = [
    { code: 'PT', label: 'Portugal', phone: '351', flag: '🇵🇹' },
    { code: 'ES', label: 'Spain', phone: '34', flag: '🇪🇸' },
    {
        code: 'FR',
        label: 'France',
        phone: '33',
        flag: '🇫🇷',
    },
    { code: 'GB', label: 'United Kingdom', phone: '44', flag: '🇬🇧' },
    {
        code: 'US',
        label: 'United States',
        phone: '1',
        flag: '🇺🇸',
    },
    {
        phone: '49',
        label: 'Germany',
        code: 'DE',
        flag: '🇩🇪',
    },
    {
        phone: '43',
        label: 'Austria',
        code: 'AT',
        flag: '🇦🇹',
    },
    {
        phone: '31',
        label: 'Netherlands',
        code: 'NL',
        flag: '🇳🇱',
    },
    {
        phone: '41',
        label: 'Switzerland',
        code: 'CH',
        flag: '🇨🇭',
    },
    {
        phone: '352',
        label: 'Luxembourg',
        code: 'LU',
        flag: '🇱🇺',
    },
    {
        phone: '590',
        label: 'Guadeloupe',
        code: 'GP',
        flag: '🇬🇵',
    },
];
