import { AxiosResponse } from 'axios';
import { CardType } from '../enums/card-type';
import { Card } from '../interfaces/card';
import { User } from '../interfaces/user';
import { BackendApiManager } from '../managers/backend-api';
import { SanitizationManager } from '../managers/sanitization';
import { Logger } from '../utils/logger';

export class UsersRepository {
    // Singleton Instance
    private static _instance: UsersRepository;

    // Private
    private logger: Logger;

    static get instance(): UsersRepository {
        if (!this._instance) {
            this._instance = new UsersRepository();
        }
        return this._instance;
    }

    constructor() {
        // Create Logger
        this.logger = new Logger('UsersRepository');
    }

    public getFullName(user: User): string | null {
        // Check no name
        if (!user.firstName && !user.lastName) {
            return null;
        }

        // Put together
        const result = `${user.firstName ?? ''}${user.lastName ? ` ${user.lastName}` : ''}`;

        if (result.includes('Â')) {
            console.log('Found Â', result, SanitizationManager.instance.textToTitleCase(result));
        }

        // Text to title case
        return SanitizationManager.instance.textToTitleCase(result);
    }

    public async get(
        skip: number,
        limit: number,
        name?: string,
        phoneNumber?: string,
    ): Promise<{ totalCount: number; data: Array<User> }> {
        // Set url
        let url: string = `/users?limit=${limit}&skip=${skip}`;

        // Add name
        if (name !== undefined) {
            url += `&name=${name}`;
        }

        // Add phoneNumber
        if (phoneNumber !== undefined) {
            url += `&phoneNumber=${phoneNumber}`;
        }

        // Get Users from backend
        const result: AxiosResponse<Array<User>> = await BackendApiManager.instance.get<Array<User>>(url);

        // Return
        return { data: result.data, totalCount: parseInt(result.headers['x-total-count'] || '', 10) };
    }

    public async getById(userId: string): Promise<User | null> {
        // Set url
        const url: string = `/users/${userId}`;

        // Get from backend
        const result: AxiosResponse<User | null> = await BackendApiManager.instance.get<User | null>(url);

        // Return
        return result.data;
    }

    public async getCard<T>(userId: string, type: CardType): Promise<Card<T> | null> {
        // Set url
        const url: string = `/users/${userId}/cards/${type}`;

        // Get from backend
        const result: AxiosResponse<Card<T> | null> = await BackendApiManager.instance.get<Card<T> | null>(url);

        // Return
        return result.data;
    }
}
