import { Box, Button, Typography } from '@mui/material';
import { MouseEventHandler, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../contexts/app/context';
import { AppContextType } from '../../contexts/app/types';
import { ThemeMode } from '../../enums/theme-mode';
import styles from './error-state.module.scss';

export function ErrorState({
    icon,
    onTryAgainClick,
    text,
}: {
    icon: React.ReactNode;
    onTryAgainClick?: MouseEventHandler<HTMLElement> | undefined;
    text?: string;
}) {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);

    // Translations
    const { t } = useTranslation();

    // Render
    return (
        <Box className={styles.error}>
            <Box
                sx={{
                    padding: '32px',
                }}
            >
                <Box
                    className={styles.icon}
                    sx={{
                        opacity: appContext.state.themeMode === ThemeMode.Light ? 0.3 : 0.7,
                    }}
                >
                    {icon}
                </Box>
                <Typography variant="h6">{text ?? t('components.errorState.title')}</Typography>
                {onTryAgainClick && (
                    <Button variant="contained" color="error" sx={{ marginTop: '16px' }} onClick={onTryAgainClick}>
                        {t('components.errorState.tryAgain')}
                    </Button>
                )}
            </Box>
        </Box>
    );
}
