import { PickersLocaleText } from '@mui/x-date-pickers';
import { TimeViewWithMeridiem } from '@mui/x-date-pickers/internals/models';
import moment from 'moment';
import { MomentLanguageCode } from '../enums/moment-language-code';
import { LanguageTranslation } from './types';

// Overriding
moment.updateLocale(MomentLanguageCode.ptPT, {
    week: {
        dow: 1,
    },
    relativeTime: {
        future: 'daqui a %s',
        past: 'há %s atrás',
    },
});

export const pt_PT: LanguageTranslation = {
    components: {
        phoneNumberInput: {
            country: 'País',
            mobilePhoneNumber: 'Número de telemóvel',
        },
        auxPage: {
            back: 'Voltar',
        },
        vaidosaCard: {
            gift: {
                title: 'Presente',
                description: '{{balance}}€ disponíveis',
            },
            founder: {
                title: 'Fundadora',
                description: '{{price}}€',
            },
            loyalty: {
                title: 'Fidelidade',
                description: '{{balance}} pontos / 100',
            },
        },
        errorState: {
            title: 'Ooops! Ocorreu um erro.',
            tryAgain: 'Tentar novamente',
        },
        signInAd: {
            title: 'Olá Vaidosa? 👋',
            description: 'Inicia sessão gratuitamente na aplicação com apenas o teu número de telemóvel para',
            button: 'Iniciar Sessão 🚪',
        },
        timeTraveller: {
            yesterday: 'Ontem',
            today: 'Hoje',
            tomorrow: 'Amanhã',
            previousMonth: 'Mês passado',
            thisMonth: 'Este mês',
            nextMonth: 'Próximo mês',
        },
        userSearch: {
            search: 'Procurar',
            placeholder: 'Nome ou número de telemóvel',
        },
        providerSearch: {
            search: 'Procurar',
            placeholder: 'Nome',
        },
        servicePicker: {
            title: 'Seleciona os serviços',
            placeholder: 'Nome do serviço',
            empty: {
                title: 'Não foi possível encontrar serviços disponíveis.',
                description:
                    'Possivelmente as nossas profissionais não estão disponíveis ou então tenta procurar com outro nome. 😉',
                descriptionCategory:
                    'Possivelmente as nossas profissionais nesta categoria não estão disponíveis. Tenta procurar com outro nome ou procurar em todas as categorias. 😉',
                seeAllCategories: 'Ver todas as categorias',
            },
            bookNow: 'Agendar',
            priceInterval: 'De {{min}} a {{max}}€',
            durationInterval: 'De {{min}} a {{max}}',
            price: '{{price}}€',
            duration: '{{duration}} minutos',
            next: 'Selecionar Profissional',
            select: 'Selecionar',
            selected: 'Selecionado',
            search: 'Procurar',
        },
        locationPicker: {
            title: 'Escolhe uma cidade',
            placeholder: 'Procurar por cidade',
            empty: {
                title: 'Não encontramos a cidade que procuras.',
                description:
                    'Não foi possível encontrar qualquer cidade com o nome que introduziste. Tenta outro nome. 😉',
            },
            whereAreYou: 'Onde estás Vaidosa?',
            locationInput: 'Escreve o nome da cidade mais próxima de ti',
            aroundMe: 'Perto de mim',
            unknownLocation: 'Localização desconhecida',
        },
        timePicker: {
            durationTextHoursAndMinutes: '{{hours}}h {{minutes}}min',
            durationTextHours: '{{hours}}h',
            durationTextMinutes: '{{minutes}}min',
            durationTextZero: '0m',
        },
        bookingCard: {
            cancelLock: 'Cancelar Bloqueio',
            cancelBooking: 'Cancelar Marcação',
            clientDidNotShowUp: 'Cliente não compareceu',
            keep: 'Manter',
            cancel: 'Cancelar',
            confirm: 'Confirmar',
            cancelBookingTitle: 'Cancelar Serviço',
            cancelBookingDescription:
                'Tens a certeza que queres cancelar este serviço? A {{clientName}} vai ser notificada.',
            userNoShowBookingTitle: 'Cliente não compareceu',
            userNoShowBookingDescription:
                'Vamos notificar a {{clientName}} para efetuar o pagamento de 50% deste serviço para poder voltar a marcar novamente.',
            bookedByClient: 'Cliente',
            bookedByProvider: 'Profissional',
        },
    },
    routes: {
        signIn: {
            welcome1: 'Olá Vaidosa!',
            welcome2: 'Bem-vinda à nossa comunidade!',
            welcome3: 'Inicia sessão gratuitamente na aplicação com o teu número de telemóvel.',
            welcomeNewAppointment:
                'Para poderes continuar com a marcação, por favor inicia sessão gratuitamente na aplicação com o teu número de telemóvel.',
            enter: 'Iniciar Sessão',
            legalDocumentsAcceptance:
                'Ao entrar, concordas com os nossos Termos de Utilização e Política de Privacidade.',
            help: 'Precisas de ajuda? Liga-nos aqui.',
            otpMessage: 'Enviamos-te um código de confirmação para o teu número de telemóvel.',
            otpInput: 'Introduz o código de confirmação com 6 dígitos enviado por SMS.',
            otpCode: 'Código de confirmação',
            otpWarn: 'Se não receberes o código de confirmação dentro de 60 segundos, volta para o passo anterior.',
            back: 'Voltar',
            confirm: 'Confirmar',
        },
        termsOfUse: {
            title: 'Condições Gerais',
        },
        privacyPolicy: {
            title: 'Política de Privacidade',
        },
        office: {
            main: {
                bottomNavigation: {
                    home: 'Início',
                    academy: 'Academia',
                    professionals: 'Profissionais',
                    bookings: 'Marcações',
                    card: 'Cartões',
                    more: 'Mais',
                },
                home: {
                    goodMorning: 'Bom dia',
                    goodAfternoon: 'Boa tarde',
                    goodEvening: 'Boa noite',
                    hello: 'Olá',
                    nextAppointment: 'A tua próxima marcação',
                    previousAppointment: 'A tua marcação anterior',
                    noAppointments: {
                        title: 'Marcações',
                        letsgo: 'Vamos lá Vaidosa?',
                        description:
                            'Ainda não tens nenhuma marcação agendada. Vamos lá, agenda já a tua próxima marcação!',
                    },
                    loyalty: 'Fidelidade',
                    gift: 'Presente',
                    points: 'pontos',
                    available: 'disponíveis',
                    whatDoYouWantToDo: 'Encontrar Marcação para Hoje',
                    forYou: 'As tuas marcações',
                    searchByServiceCategory: 'Especialidades',
                    searchByService: 'Procurar serviços por nome',
                    manicure: 'Manicure',
                    pedicure: 'Pedicure',
                    makeup: 'Maquilhagem',
                    yourSalon: 'O teu salão',
                    bookingsSignInAd: 'veres a tua próxima marcação.',
                    knowMore: 'Saber mais',
                    accordions: {
                        administrator: 'Vaidosa Administradora',
                        professional: 'Vaidosa Profissional',
                    },
                    professional: {
                        schedule: {
                            title: 'Agenda',
                            help: 'Serviços feitos este mês',
                            noProvider: 'S/ prestador',
                            weekDayDateFormat: 'D/M',
                            week: 'Semana {{weekNumber}}',
                            hours: 'Horas',
                        },
                        invoicing: {
                            title: 'Faturação',
                            help: 'Faturação deste mês',
                            noProvider: 'S/ prestador',
                        },
                        clients: {
                            title: 'Clientes',
                            help: 'Clientes este mês',
                        },
                        myServices: {
                            title: 'Serviços',
                            help: 'Define preços e tempos de duração dos teus serviços',
                        },
                        ocurrencies: {
                            title: 'Ocorrências',
                            help: 'Registo de situações a resolver',
                        },
                        diaries: {
                            title: 'Diários',
                            help: 'Diários do dia reportados',
                        },
                        providers: {
                            title: 'Profissionais',
                            help: 'Profissionais este mês',
                        },
                    },
                },
                card: {
                    titleLine1: 'Cartões da',
                    titleLine2: 'Vaidosa',
                    transactions: 'Transações',
                    alert: {
                        loyalty:
                            'Por cada marcação avaliada ganhas 10 pontos. Ao atingires 100 pontos, acumulas 1€ em saldo no Cartão Presente para descontares num próximo serviço.',
                        gift: 'Utiliza o saldo do teu Cartão Presente para descontares nos serviços que quiseres. Podes também carregar saldo num Cartão Presente para ofereceres a alguém.',
                    },
                    empty: {
                        loyalty:
                            'Ainda não fizeste nenhum serviço connosco Vaidosa? Vamos lá, agenda já a tua marcação e começa a ganhar pontos!',
                        gift: 'Ainda não ganhaste saldo no teu Cartão Presente. A cada 100 pontos no teu Cartão de Fidelidade oferecemos-te 5€! Está atenta aos nossos passatempos e promoções e ganha ainda mais saldo.',
                    },
                    cardsSignInAd: 'veres os teus pontos e saldo disponível.',
                    transactionsSignInAd: 'consultares as tuas transações de pontos e saldo.',
                    bookingSignInAd: 'veres todas as tuas marcações já completas e agendadas.',
                },
                bookings: {
                    titleLine1: 'As tuas',
                    titleLine2: 'Marcações',
                    empty: {
                        title: 'Ainda não tens nenhuma marcação agendada.',
                        description: 'Vamos lá, agenda já a tua próxima marcação!',
                    },
                    cancel: 'Cancelar Marcação',
                    cancelDescription:
                        'Tens a certeza que queres cancelar a tua marcação? A {{providerName}} vai ser notificada.',
                    keep: 'Manter',
                },
                academy: {
                    titleLine1: 'Academia das',
                    titleLine2: 'Vaidosas',
                },
                more: {
                    titleLine1: 'Mais',
                    titleLine2: 'Vaidosa',
                    legalDocuments: 'Documentos Legais',
                    account: 'Conta',
                    logout: 'Sair',
                    help: 'Precisas de ajuda?',
                    help_call: 'Telefone (10h-13h)',
                    help_whatsapp: 'WhatsApp (24h)',
                    help_website: 'Visita o nosso site',
                },
                bookingButton: 'Agendar serviços',
                professionals: {
                    titleLine1: 'As nossas',
                    titleLine2: 'Profissionais',
                    empty: {
                        title: 'Não encontramos profissionais.',
                        description: 'Tenta procurar com outro nome. 😉',
                    },
                    search: 'Procurar',
                    placeholder: 'Nome da profissional',
                },
            },
            book: {
                title: 'Seleciona uma profissional',
                errorTitle: 'Olá Vaidosa!',
                errorMessage: 'Não foi possível aceder ao sistema. Por favor contacta-nos diretamente.',
                empty: {
                    title: 'Não encontramos nenhuma profissional disponível.',
                    description: 'Experimenta diminuir o número de serviços ou tenta novamente mais tarde. 😉',
                },
                selectProfessional: 'Agendar com a {{name}}',
                minutes: '{{minutes}} min',
                minutesComplete: '{{minutes}} minutes',
                notAvailable: 'Agenda completa',
                availableNow: 'Disponível agora!',
                availableSoon: 'Disponível às {{time}} ({{fromNow}})',
                availableTomorrow: 'Amanhã às {{time}}',
                availableThisWeek: '{{day}} às {{time}}',
                availableAfter: '{{day}} de {{month}} às {{time}} ({{fromNow}})',
                providerBirthday: '{{years}} anos',
                awards: {
                    bestLocation: 'Mais perto',
                    bestAvailability: 'Melhor disponibilidade',
                    bestReviewsCount: 'Mais avaliações',
                    bestAverageRating: 'Melhor avaliação',
                    bestPrice: 'Melhor preço',
                    bestDuration: 'Mais rápida',
                },
                location: 'Localização',
                sort: 'Ordenação',
                sortOptions: {
                    location: 'Localização',
                    availability: 'Disponibilidade',
                    reviewsCount: 'Avaliações',
                    averageRating: 'Pontuação',
                    price: 'Preço',
                    duration: 'Duração',
                },
            },
            notificationsExplainDialog: {
                title: 'Podemos enviar-te notificações? 🔔',
                description:
                    'Vamos notificar-te sobre a tua próxima marcação, quando ganhares pontos, oferecer-te promoções exclusivas, informar-te sobre os novos serviços e muito mais.',
                noThanks: 'Não, obrigada',
                confirm: 'Vamos a isso! 💅',
            },
            notifications: {
                title1: 'Notificação',
                title2: 'Notificações',
                empty: {
                    title: 'Ainda não tens nenhuma notificação.',
                    description: 'Sempre que tivermos comunicações para ti, vamos notificar-te aqui.',
                },
                emptyUnread: {
                    title: 'Não existem notificações por ler.',
                    description:
                        'Todas as notificações estão marcadas como lidas. Revê todas as notificações clicando no botão abaixo.',
                    listAll: 'Ver todas as notificações',
                },
                markAllAsReadConfirmDialog: {
                    title: 'Marcar todas como lidas?',
                    description: 'Todas as notificações serão marcadas como lidas.',
                    confirm: 'Confirmar',
                    cancel: 'Cancelar',
                },
            },
            professional: {
                invoicing: {
                    notAvailableYet: 'A faturação do dia é apenas calculada durante o próprio dia. Volta mais tarde.',
                    emptyTitle: 'Não existem faturas.',
                    emptyDescription: 'Neste dia não foi faturado nenhum serviço fornecido.',
                    dayTotal: 'Total do dia',
                    goodJob: 'Bom trabalho!',
                },
                schedule: {
                    emptyTitle: 'Não existem marcações.',
                    emptyDescription: 'Neste dia não existem marcações agendadas.',
                    summary: 'Resumo do dia',
                    clients: 'Clientes',
                    services: 'Serviços',
                    expectedEarnings: 'Ganho previsto',
                },
                users: {
                    title: 'Clientes',
                    empty: {
                        title: 'Sem clientes.',
                        description: 'Não existem clientes registadas no sistema.',
                    },
                    emptyToday: {
                        title: 'Não há clientes para hoje.',
                        description: 'Neste momento não tens nenhuma cliente agendada para hoje.',
                        listAll: 'Ver todas as clientes',
                    },
                    withoutName: '(Sem nome)',
                },
                services: {
                    title: 'Os Meus Serviços',
                    emptyTitle: 'Não existem serviços.',
                    emptyDescription: 'Não é esperado. Por favor contacta o Administrador do Sistema.',
                    form: {
                        isActive: 'Disponível para clientes',
                        durationInMinutes: 'Duração em minutos',
                        price: 'Preço em euros (€)',
                        photosUrls: 'URLs das fotos',
                        cancel: 'Cancelar',
                        saveChanges: 'Guardar',
                    },
                },
                providers: {
                    title: 'Profissionais',
                    empty: {
                        title: 'Sem profissionais.',
                        description: 'Não existem profissionais registadas no sistema.',
                    },
                    emptyToday: {
                        title: 'Não há profissionais para hoje.',
                        description: 'Neste momento não tens nenhuma profissional agendada para hoje.',
                        listAll: 'Ver todas as profissionais',
                    },
                    withoutName: '(Sem nome)',
                },
            },
            systemAdministator: {
                invoicing: {
                    notAvailableYet: 'A faturação do mês é apenas calculada durante o próprio mês. Volta mais tarde.',
                    emptyTitle: 'Não existem faturas.',
                    emptyDescription: 'Neste mês não foi faturado nenhum serviço.',
                    monthTotal: 'Total do mês',
                    goodJob: 'Bom trabalho!',
                    earned: 'A pagar',
                    contributed: 'Contribuiu',
                    noProvider: 'Sem prestador',
                    totalInvoiced: 'Total faturado',
                    providersEarningsTotal: 'Total a pagar aos prestadores',
                    balanceBeforeExpenses: 'Saldo antes de despesas',
                    businessExpensesTotal: 'Total de despesas',
                    finalBalance: 'Saldo final',
                    isGonnaBeAllRight: 'Vai ficar tudo bem! Continua!',
                },
                schedule: {
                    emptyTitle: 'Não existem marcações.',
                    emptyDescription: 'Neste mês não existem marcações agendadas.',
                    scheduled: 'Agendadas',
                    cancelled: 'Canceladas',
                    completed: 'Concluídas',
                    monthTotal: 'Total do mês',
                    totalScheduled: 'Total agendadas',
                    totalCancelled: 'Total canceladas',
                    totalCompleted: 'Total concluídas',
                    goodJob: 'Bom trabalho!',
                    isGonnaBeAllRight: 'Vai ficar tudo bem! Continua!',
                },
            },
            user: {
                tabs: {
                    profile: 'Perfil',
                    history: 'Histórico',
                    loyalty: 'Fidelidade',
                    gift: 'Presente',
                },
                profile: {
                    firstName: 'Primeiro nome',
                    lastName: 'Último nome',
                    phoneNumber: 'Número de telemóvel',
                    emailAddress: 'Endereço de email',
                    birthDate: 'Data de nascimento',
                    taxPayerNumber: 'Número de contribuinte',
                    isActive: 'Ativa',
                },
                history: {
                    empty: {
                        title: 'Sem marcações',
                        description: 'A cliente nunca fez nenhuma marcação.',
                    },
                },
                card: {
                    empty: {
                        title: 'No transactions',
                        description: 'There are no transactions registered for this card',
                    },
                },
            },
            showcase: {
                scheduleAppointment: 'Agendar serviços',
                call: 'Ligar',
                closed: 'Fechado',
                open: 'Aberto',
                selectSchedule: 'Agendar',
                pickStartHour: 'Seleciona a hora de início',
                location: 'Localização',
                schedule: 'Horário',
                services: 'Serviços',
                noServicesSelected: 'Sem serviços selecionados',
                noServicesSelectedDescription: 'Por favor seleciona pelo menos um serviço para poderes agendar.',
                selectServices: 'Selecionar serviços',
                noAvailabilityForThisDay: 'Sem disponibilidade para este dia',
                noAvailabilityForThisDayDescription: 'Por favor seleciona outro dia.',
                confirmAppointment: 'Confirmar marcação',
                directions: 'Obter direções / Ver mapa',
            },
            newAppointment: {
                title: 'Confirmar marcação',
                emptyTitle: 'Sem serviços selecionados',
                emptyDescription: 'Por favor seleciona pelo menos um serviço para poderes agendar.',
                selectServices: 'Selecionar serviços',
                when: 'Quando',
                who: 'Onde',
                what: 'Serviços',
                you: 'Cliente',
                submit: 'Agendar',
                successTitle: 'Marcação agendada com sucesso!',
                successDescription:
                    'A {{provider}} vai estar à tua espera na hora marcada! Por favor altera ou cancela atempadamente a tua marcação caso não possas comparecer.',
                errorTitle: 'Não foi possivel agendar a tua marcação. Por favor recomeça o processo.',
                restartProcess: 'Recomeçar',
                goingBackToStart: 'Vamos voltar ao início...',
                startMenu: 'Voltar ao início',
                directions: 'Direções',
                form: {
                    firstAndLastName: 'Primeiro e último nome',
                    observations: 'Observações',
                },
                notSignedIn: {
                    title: 'Hey! vamos iniciar sessão?',
                    description:
                        'Para receberes notificações, editar ou cancelar a tua marcação, precisamos que inicies sessão.',
                },
                diffPhoneNumber: {
                    title: 'Número de telemóvel diferente?',
                    description:
                        'Esta marcação foi feita com um número de telemóvel diferente do que tens na tua conta e só esse número irá receber notificações e poderá gerir a marcação.',
                },
                errors: {
                    'provider-not-found':
                        'Não foi possível encontrar o perfil da Profissional. Por favor contacta-nos diretamente.',
                    'provider-services-mismatch':
                        'A Profissional já não tem disponíveis os serviços que selecionaste. Por favor tenta novamente.',
                    'user-not-active': 'A tua conta está desativada. Por favor contacta-nos diretamente.',
                    'user-is-blocked-from-booking':
                        'A tua conta está bloqueada e por isso não podes agendar marcações. Por favor contacta-nos diretamente.',
                    'provider-not-available':
                        'A Profissional já não está disponível para a hora que selecionaste. Por favor tenta novamente.',
                    'user-cannot-be-the-provider': 'Não é possível agendar uma marcação contigo própria.',
                },
                distanceWarningDialog: {
                    title: 'Alerta de Distância!',
                    description:
                        'A <strong>{{providerName}}</strong> está localizada em <strong>{{cityName}}</strong>. a mais de <strong>{{distance}} km</strong> de distância. Tens a certeza que queres continuar com esta marcação?',
                    confirm: 'Confirmar',
                    cancel: 'Cancelar',
                },
            },
            providerNewEvent: {
                title: 'Novo Evento',
                type: 'Tipo',
                when: 'Quando',
                what: 'Serviços',
                you: 'Cliente',
                submit: 'Agendar',
                form: {
                    firstAndLastName: 'Primeiro e último nome',
                    observations: 'Observações',
                },
                lock: {
                    title: 'Bloqueio',
                    start: 'Início',
                    end: 'Fim',
                    details: 'Detalhes',
                    submit: 'Bloquear',
                    observations: 'Observações',
                    info: 'Deves bloquear a tua agenda para limitar a marcação de serviços durante o teu horário de trabalho. Podes bloquear a tua agenda para férias, eventos, ou qualquer outra situação que te impeça de aceitar marcações. Nota que podes criar vários bloqueios para o mesmo dia, mas cada bloqueio tem de começar e terminar no mesmo dia.',
                    errors: {
                        'provider-not-found':
                            'Não foi possível encontrar o perfil da Profissional. Por favor contacta-nos diretamente.',
                        'invalid-dates': 'A data de início tem de ser anterior à data de fim.',
                    },
                    errorTitle: 'Não foi possivel bloquear a tua agenda. Por favor recomeça o processo.',
                },
                appointment: {
                    title: 'Marcação',
                    pickServices: 'Seleciona os serviços',
                    pickDate: 'Seleciona a hora',
                    cancel: 'Cancelar',
                    submit: 'Selecionar',
                    emptyServices: 'Nenhum serviço selecionado.',
                    emptyDate: 'Nenhuma data selecionada.',
                    errors: {
                        'provider-not-found':
                            'Não foi possível encontrar o teu perfil de Profissional. Por favor contacta-nos diretamente.',
                        'provider-services-mismatch':
                            'Já não tens disponíveis os serviços que selecionaste. Por favor tenta novamente.',
                        'user-not-active': 'A conta da cliente está desativada. Por favor contacta-nos diretamente.',
                        'user-is-blocked-from-booking':
                            'A conta da cliente está bloqueada e por isso não podes agendar marcações. Por favor contacta-nos diretamente.',
                        'provider-not-available':
                            'Já não tens disponibilidade para a hora que selecionaste. Por favor tenta novamente.',
                        'user-cannot-be-the-provider': 'Não é possível agendar uma marcação contigo própria.',
                    },
                    errorTitle: 'Não foi possivel agendar a marcação. Por favor recomeça o processo.',
                    info: 'Deves agendar a marcação para que a cliente possa ser informada e lembrada da marcação através de notificações e para que a mesma possa atribuir pontuação pelos teus serviços e com isso ganhar pontos no seu Cartão de Fidelidade.',
                },
            },
        },
    },
    enums: {
        daysOfTheWeek: {
            monday: 'Segunda',
            tuesday: 'Terça',
            wednesday: 'Quarta',
            thursday: 'Quinta',
            friday: 'Sexta',
            saturday: 'Sábado',
            sunday: 'Domingo',
        },
        bookingState: {
            scheduled: 'Agendado ⏰',
            'cancelled-by-provider': 'Cancelado 👩‍⚕️',
            'cancelled-by-user': 'Cancelado ❌',
            'user-no-show': 'Faltou 🙅‍♀️',
            completed: 'Concluído ✅',
            'deleted-by-provider': 'Eliminado 🗑️',
        },
        serviceCategory: {
            manicure: 'Manicure',
            pedicure: 'Pedicure',
            makeup: 'Maquilhagem',
            hair: 'Cabelo',
            facialEpilation: 'Epilação facial',
            eyelashes: 'Pestanas',
            depilation: 'Depilação',
        },
    },
    //
    legal_documents: {
        terms_and_conditions: 'Termos e Condições',
        privacy_policy: 'Política de Privacidade',
    },
    products: {
        bluebird: 'Sioslife',
        bluebird_with_gender: 'à Sioslife',
    },
};

const timeViews: Record<TimeViewWithMeridiem, string> = {
    hours: 'horas',
    minutes: 'minutos',
    seconds: 'segundos',
    meridiem: 'meridiano',
};

export const ptPTPickersLocaleText: Partial<PickersLocaleText<any>> = {
    // Calendar navigation
    previousMonth: 'Mês anterior',
    nextMonth: 'Próximo mês',

    // View navigation
    openPreviousView: 'Abrir próxima seleção',
    openNextView: 'Abrir seleção anterior',
    calendarViewSwitchingButtonAriaLabel: (view) =>
        view === 'year'
            ? 'Seleção de ano está aberta, alternando para seleção de calendário'
            : 'Seleção de calendários está aberta, alternando para seleção de ano',

    // DateRange labels
    start: 'Início',
    end: 'Fim',
    // startDate: 'Start date',
    // startTime: 'Start time',
    // endDate: 'End date',
    // endTime: 'End time',

    // Action bar
    cancelButtonLabel: 'Cancelar',
    clearButtonLabel: 'Limpar',
    okButtonLabel: 'OK',
    todayButtonLabel: 'Hoje',

    // Toolbar titles
    datePickerToolbarTitle: 'Seleciona a data',
    dateTimePickerToolbarTitle: 'Seleciona a data e hora',
    timePickerToolbarTitle: 'Seleciona a hora',
    dateRangePickerToolbarTitle: 'Seleciona o intervalo entre datas',

    // Clock labels
    clockLabelText: (view, time, adapter) =>
        `Seleciona ${timeViews[view]}. ${
            time === null ? 'Hora não selecionada' : `Selecionado a hora ${adapter.format(time, 'fullTime')}`
        }`,
    hoursClockNumberText: (hours) => `${hours} horas`,
    minutesClockNumberText: (minutes) => `${minutes} minutos`,
    secondsClockNumberText: (seconds) => `${seconds} segundos`,

    // Digital clock labels
    selectViewText: (view) => `Seleciona ${timeViews[view]}`,

    // Calendar labels
    calendarWeekNumberHeaderLabel: 'Número da semana',
    calendarWeekNumberHeaderText: '#',
    calendarWeekNumberAriaLabelText: (weekNumber) => `Semana ${weekNumber}`,
    calendarWeekNumberText: (weekNumber) => `${weekNumber}`,

    // Open picker labels
    openDatePickerDialogue: (value, utils) =>
        value !== null && utils.isValid(value)
            ? `Escolha uma data, data selecionada ${utils.format(value, 'fullDate')}`
            : 'Escolha uma data',
    openTimePickerDialogue: (value, utils) =>
        value !== null && utils.isValid(value)
            ? `Escolha uma hora, hora selecionada ${utils.format(value, 'fullTime')}`
            : 'Escolha uma hora',
    // fieldClearLabel: 'Clear value',

    // Table labels
    timeTableLabel: 'escolha uma hora',
    dateTableLabel: 'escolha uma data',

    // Field section placeholders
    fieldYearPlaceholder: (params) => 'A'.repeat(params.digitAmount),
    fieldMonthPlaceholder: (params) => (params.contentType === 'letter' ? 'MMMM' : 'MM'),
    fieldDayPlaceholder: () => 'DD',
    fieldWeekDayPlaceholder: (params) => (params.contentType === 'letter' ? 'SSSS' : 'SS'),
    fieldHoursPlaceholder: () => 'hh',
    fieldMinutesPlaceholder: () => 'mm',
    fieldSecondsPlaceholder: () => 'ss',
    fieldMeridiemPlaceholder: () => 'aa',

    // View names
    // year: 'Year',
    // month: 'Month',
    // day: 'Day',
    // weekDay: 'Week day',
    // hours: 'Hours',
    // minutes: 'Minutes',
    // seconds: 'Seconds',
    // meridiem: 'Meridiem',

    // Common
    // empty: 'Empty',
};
