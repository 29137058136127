import CallIcon from '@mui/icons-material/Call';
import { Avatar, Box, IconButton, Typography, useTheme } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth/context';
import { AuthContextType } from '../../contexts/auth/types';
import { User } from '../../interfaces/user';
import { UsersRepository } from '../../repositories/users';
import { RoutesNames } from '../../routes-names';

export function UserLine({ user }: { user: User }) {
    // Get Contexts
    const authContext: AuthContextType = useContext(AuthContext);

    // Get navigation
    const navigate = useNavigate();

    // Get theme
    const theme = useTheme();

    // Navigate to provider
    const navigateToUser = () => {
        if (authContext.state.me?.systemAdministratorProfile) {
            navigate(`/${RoutesNames.Office}/${RoutesNames.Office_User}/${user.id}`);
        }
    };

    // Get name
    const userCompleteName: string = user ? UsersRepository.instance.getFullName(user) ?? '' : '';

    // Get initials from name
    const initials = userCompleteName
        .split(' ')
        .map((n) => n[0])
        .join('');

    // Build
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
            }}
        >
            <Avatar
                sx={{ width: 48, height: 48, marginRight: '8px', border: '2px solid #38b6ff' }}
                alt={userCompleteName}
                onClick={navigateToUser}
            >
                {initials}
            </Avatar>
            <Box>
                <Typography variant="subtitle1" onClick={navigateToUser} fontWeight={500}>
                    {userCompleteName}
                </Typography>
                <Typography variant="subtitle2" onClick={navigateToUser} fontWeight={500}>
                    {user.phoneNumber}
                </Typography>
            </Box>
            <Box sx={{ flex: 1 }}></Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: '24px',
                }}
            >
                {user.phoneNumber && (
                    <IconButton size="small" aria-label="call" target="_blank" href={`tel:${user.phoneNumber}`}>
                        <CallIcon />
                    </IconButton>
                )}

                {/* {user.phoneNumber && (
                    <IconButton
                        size="small"
                        aria-label="directions"
                        target="_blank"
                        href={`https://wa.me/${user.phoneNumber.replace('+', '')}`}
                    >
                        <WhatsAppIcon />
                    </IconButton>
                )} */}
            </Box>
        </Box>
    );
}
