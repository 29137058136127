export enum CardType {
    /**
     * Loyalty card
     */
    Loyalty = 'loyalty',

    /**
     * Gift card
     */
    Gift = 'gift',

    /**
     * Founder card
     */
    Founder = 'founder',
}
