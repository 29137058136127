export enum RoutesNames {
    // Office
    Office = 'office',
    Office_Main = 'main',
    Office_Notifications = 'notifications',
    Office_User = 'user',
    Office_Professional = 'professional',
    Office_SystemAdministrator = 'system-administrator',
    Office_ServicePicker = 'service-picker',
    Office_LocationPicker = 'location-picker',
    Office_ProviderPicker = 'provider-picker',

    // Office > Main
    Office_Main_Home = 'home',
    Office_Main_Card = 'card',
    Office_Main_Bookings = 'bookings',
    Office_Main_Academy = 'academy',
    Office_Main_More = 'more',
    Office_Showcase = 'showcase',
    Office_NewAppointment = 'new-appointment',
    Office_Main_Professionals = 'professionals',
    Office_ProviderNewEvent = 'provider-new-event',

    // Office > Professional
    Office_Professional_Schedule = 'schedule',
    /**
     * @deprecated
     */
    Office_Professional_Invoicing = 'invoicing',
    /**
     * @deprecated
     */
    Office_Professional_Users = 'users',
    Office_Professional_Services = 'services',

    // Office > System Administrator
    Office_SystemAdministrator_Invoicing = 'invoicing',
    Office_SystemAdministrator_Schedule = 'schedule',
    Office_SystemAdministrator_Users = 'users',
    Office_SystemAdministrator_Providers = 'providers',

    // Public
    SignIn = 'sign-in',
    TermsOfUse = 'terms-of-use',
    PrivacyPolicy = 'privacy-policy',

    // DevTools
    DevTools = 'dev-tools',
    DevTools_NativeKnightDiag = 'native-knight-diag',
}
