import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EventIcon from '@mui/icons-material/Event';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import LoginIcon from '@mui/icons-material/Login';
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grow,
    IconButton,
    Paper,
    Typography,
} from '@mui/material';
import moment from 'moment';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { Link, useNavigate } from 'react-router-dom';
import { EmptyState } from '../../../../components/empty-state/empty-state';
import { ErrorState } from '../../../../components/error-state/error-state';
import { Loader } from '../../../../components/loader/loader';
import { MainOfficePage } from '../../../../components/main-office-page/main-office-page';
import { ProviderLine } from '../../../../components/provider-line/provider-line';
import { SignInAd } from '../../../../components/sign-in-ad/sign-in-ad';
import { AppContext } from '../../../../contexts/app/context';
import { AppContextType } from '../../../../contexts/app/types';
import { AuthContext } from '../../../../contexts/auth/context';
import { AuthContextType } from '../../../../contexts/auth/types';
import { ServiceCategories } from '../../../../databases/service-categories';
import { BookingState } from '../../../../enums/booking-state';
import { Booking } from '../../../../interfaces/booking';
import { Service } from '../../../../interfaces/service';
import { BookingsRepository } from '../../../../repositories/bookings';
import { RoutesNames } from '../../../../routes-names';
import variables from '../../../../variables.module.scss';

function AppointmentCardBooking({ booking }: { booking: Booking }) {
    // Get Contexts
    const appContext: AppContextType = useContext(AppContext);

    // Translations
    const { t } = useTranslation();

    // Build
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                opacity: [
                    BookingState.CancelledByProvider,
                    BookingState.CancelledByUser,
                    BookingState.UserNoShow,
                ].includes(booking.state)
                    ? 0.5
                    : 1,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                }}
            >
                <Box
                    sx={{
                        paddingRight: '8px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {booking.state === BookingState.Scheduled && (
                        <Typography variant="caption" fontWeight={500}>
                            ⏰
                        </Typography>
                    )}
                    {booking.state === BookingState.CancelledByProvider && (
                        <Typography variant="caption" fontWeight={500}>
                            🚫
                        </Typography>
                    )}
                    {booking.state === BookingState.CancelledByUser && (
                        <Typography variant="caption" fontWeight={500}>
                            ❌
                        </Typography>
                    )}
                    {booking.state === BookingState.UserNoShow && (
                        <Typography variant="caption" fontWeight={500}>
                            🚷
                        </Typography>
                    )}
                    {booking.state === BookingState.Completed && (
                        <Typography variant="caption" fontWeight={500}>
                            ✅
                        </Typography>
                    )}
                </Box>
                <Typography variant="caption" fontWeight={500} sx={{}}>
                    {moment(booking.startDate).format('LT')} - {moment(booking.endDate).format('LT')} (
                    {appContext.getDisplayDurationText(booking.serviceData?.durationInMinutes ?? 0)},{' '}
                    {booking.serviceData?.price?.toFixed(2)}€)
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    marginTop: '4px',
                }}
            >
                <Box
                    sx={{
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        paddingTop: '2px',
                        paddingBottom: '2px',
                        backgroundColor: ServiceCategories[booking.service?.category ?? ''].color,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '24px',
                        color: 'white',
                    }}
                >
                    <Typography variant="caption" fontWeight={500}>
                        {t(ServiceCategories[booking.service?.category ?? ''].translationKey)}
                    </Typography>
                </Box>
                <Typography
                    variant="subtitle2"
                    sx={{
                        flex: 1,
                        fontSize: '14px',
                        textDecoration: [
                            BookingState.CancelledByProvider,
                            BookingState.CancelledByUser,
                            BookingState.UserNoShow,
                        ].includes(booking.state)
                            ? 'line-through'
                            : 'none',
                    }}
                >
                    {booking.service?.name}
                </Typography>
                {/* <Box
                    sx={{
                        marginRight: '-4px',
                    }}
                >
                    {booking.state === BookingState.Scheduled && (
                        <IconButton
                            size="small"
                            aria-label="more"
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                // Cancel this booking
                                // -> Implement cancel booking
                            }}
                        >
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                    )}
                </Box> */}
            </Box>
        </Box>
    );
}

function AppointmentCard({
    appointment,
    onAppointmentUpdate,
}: {
    appointment: {
        id: string;
        bookings: Array<Booking>;
    };
    onAppointmentUpdate?: (appointment: { id: string; bookings: Array<Booking> }) => void;
}) {
    // Get Contexts
    const appContext: AppContextType = useContext(AppContext);
    const authContext: AuthContextType = useContext(AuthContext);

    // Get navigation
    const navigate = useNavigate();

    // Translations
    const { t } = useTranslation();

    // Hold State
    const [cancelAppointmentDialogOpen, setCancelAppointmentDialogOpen] = useState(false);

    // Make sure that we have bookings
    if (!appointment.bookings || appointment.bookings.length === 0) {
        return null;
    }

    // Clone bookings
    const bookings = [...appointment.bookings];

    // Invert bookings order
    bookings.reverse();

    // Get first and last booking
    const firstBooking = bookings[0];

    // Provider name
    const providerName = firstBooking?.provider?.name ?? '';

    // Actions: Delete appointment
    const deleteAppointment = async (appointmentId: string) => {
        try {
            // Delete
            (async () => {
                await BookingsRepository.instance.deleteAppointmentAsUser(
                    authContext.state.me?.user?.id ?? '',
                    appointmentId,
                );
                await authContext.refreshMe();
            })();

            // Hold current time
            const now = new Date();

            // Update state of it
            for (const booking of appointment.bookings) {
                // Change state
                booking.state = BookingState.CancelledByUser;
                booking.deletedAt = now;
                booking.deletedByUserId = authContext.state.me?.user?.id;
            }

            // Update state
            if (onAppointmentUpdate) {
                // Call
                onAppointmentUpdate(appointment);
            }
        } catch (error) {
            // Log
            console.error(error);
        }
    };

    // Build
    return (
        <>
            <Grow in={true} mountOnEnter unmountOnExit>
                <Paper
                    sx={{
                        padding: '8px 16px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}
                    >
                        <Box
                            sx={{
                                flex: 1,
                            }}
                        >
                            <Box>
                                <Typography variant="h6">{moment(firstBooking.startDate).fromNow()}</Typography>
                            </Box>
                            <Box>
                                <Typography variant="body2">{moment(firstBooking.startDate).format('LLL')}</Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Box
                                sx={{
                                    marginRight: '-8px',
                                }}
                            >
                                {firstBooking.state !== BookingState.Scheduled && (
                                    <IconButton
                                        aria-label="schedule again"
                                        onClick={() => {
                                            // Get services from all the bookings
                                            const services: Array<Service> = [];
                                            for (const previousBooking of appointment.bookings) {
                                                if (previousBooking.service) {
                                                    services.push(previousBooking.service);
                                                }
                                            }

                                            // Set services
                                            appContext.setServices(services);

                                            // Navigate
                                            navigate(
                                                `/${RoutesNames.Office}/${RoutesNames.Office_Showcase}/${firstBooking.provider?.id}?services=true`,
                                            );
                                        }}
                                    >
                                        <EventRepeatIcon />
                                    </IconButton>
                                )}
                                {firstBooking.state === BookingState.Scheduled && (
                                    <IconButton
                                        aria-label="more"
                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                            // Open dialog
                                            setCancelAppointmentDialogOpen(true);
                                        }}
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            marginTop: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                        }}
                    >
                        {bookings.map((booking: Booking, index: number) => {
                            return <AppointmentCardBooking key={`booking-${index}`} booking={booking} />;
                        })}
                    </Box>
                    {firstBooking.notes && (
                        <Alert
                            sx={{
                                marginTop: '16px',
                            }}
                            severity="info"
                        >
                            {firstBooking.notes}
                        </Alert>
                    )}
                    {firstBooking.provider && (
                        <Box
                            sx={{
                                marginTop: '16px',
                            }}
                        >
                            <ProviderLine provider={firstBooking.provider} />
                        </Box>
                    )}
                </Paper>
            </Grow>
            <Dialog
                open={cancelAppointmentDialogOpen}
                onClose={() => {
                    // Close dialog
                    setCancelAppointmentDialogOpen(false);
                }}
                aria-labelledby="cancel-appointment-title"
                aria-describedby="cancel-appointment-description"
            >
                <DialogTitle color="error" fontWeight="bold" id="cancel-appointment-title">
                    {t('routes.office.main.bookings.cancel')}?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="cancel-appointment-description">
                        {t('routes.office.main.bookings.cancelDescription', {
                            providerName: providerName.split(' ')[0],
                        })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="inherit"
                        onClick={() => {
                            // Close dialog
                            setCancelAppointmentDialogOpen(false);
                        }}
                    >
                        {t('routes.office.main.bookings.keep')}
                    </Button>
                    <Button
                        color="error"
                        onClick={() => {
                            // Close dialog
                            setCancelAppointmentDialogOpen(false);

                            // Delete appointment
                            deleteAppointment(appointment.id);
                        }}
                        autoFocus
                    >
                        {t('routes.office.main.bookings.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export function OfficeMainBookings() {
    // Get context
    const authContext: AuthContextType = useContext(AuthContext);

    // Translations
    const { t } = useTranslation();

    // Get navigation
    const navigate = useNavigate();

    // Hold state
    const [error, setError] = useState<boolean>(false);
    const [totalDocumentsCount, setTotalDocumentsCount] = useState<number>(-1);
    const [documents, setDocuments] = useState<Array<Booking> | null>(null);
    const [appointments, setAppointments] = useState<Array<{
        id: string;
        bookings: Array<Booking>;
    }> | null>(null);

    // Actions: Handle Load More
    const handleLoadMore = async (page: number) => {
        try {
            // Get Date
            let date = moment().add(100, 'years').toDate();

            // Eval
            if (documents && documents.length > 0) {
                // Pick last booking
                const lastNotification: Booking = documents[documents.length - 1];
                date = lastNotification.startDate ?? date;
            }

            // Get bookings
            const results: { totalCount: number; data: Array<Booking> } = await BookingsRepository.instance.get(
                authContext.state.me?.user?.id?.toString() ?? '',
                20,
                date,
            );

            // Init appointments
            const _newSets: {
                [key: string]: Array<Booking>;
            } = {};

            // Group into appointments
            for (const booking of results.data) {
                // Get id
                const id = booking.appointmentId ?? moment(booking.startDate).startOf('day').toISOString();

                // Check if exists
                if (!_newSets[id]) {
                    // Init it now
                    _newSets[id] = [];
                }

                // Add
                _newSets[id].push(booking);
            }

            // Init new appointments
            const newSets: Array<{
                id: string;
                bookings: Array<Booking>;
            }> = [];

            // Get all appointment ids
            const appointmentIds = Object.keys(_newSets);
            for (const appointmentId of appointmentIds) {
                // Add
                newSets.push({
                    id: appointmentId,
                    bookings: _newSets[appointmentId],
                });
            }

            // Update state
            setTotalDocumentsCount(results.totalCount);
            setDocuments([...(documents || []), ...results.data]);
            setAppointments([...(appointments || []), ...newSets]);
        } catch (error) {
            // Log
            console.error(error);

            // Update state
            setError(true);
        }
    };

    // Action: Reset all
    const resetState = async () => {
        // Reset state
        setError(false);
        setDocuments(null);
        setAppointments(null);
        setTotalDocumentsCount(-1);
    };

    // Build
    return (
        <MainOfficePage
            title={[t('routes.office.main.bookings.titleLine1'), t('routes.office.main.bookings.titleLine2')]}
            color={variables.secondColor}
            onRefresh={async () => {
                // Reset state
                resetState();
            }}
            actions={[
                <IconButton
                    aria-label="new-appointment"
                    onClick={() => {
                        // Go to Schedule
                        navigate(`/${RoutesNames.Office}/${RoutesNames.Office_ServicePicker}`);
                    }}
                >
                    <AddIcon />
                </IconButton>,
                !authContext.state.firebaseUser ? (
                    <Link to={`/${RoutesNames.SignIn}`}>
                        <IconButton aria-label="sign-in">
                            <LoginIcon />
                        </IconButton>
                    </Link>
                ) : undefined,
            ]}
        >
            {authContext.state.firebaseUser && (
                <Box
                    sx={{
                        paddingBottom: '24px',
                        position: 'relative',
                        top: '-24px',
                    }}
                >
                    {!error && (
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={handleLoadMore}
                            hasMore={documents?.length !== totalDocumentsCount}
                            loader={
                                <Box
                                    key={`documents-loader`}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '32px',
                                    }}
                                >
                                    <Loader size={48} />
                                </Box>
                            }
                        >
                            {documents?.length === 0 && (
                                <EmptyState
                                    key={`booking-empty`}
                                    icon={<EventIcon />}
                                    title={t('routes.office.main.bookings.empty.title')}
                                    description={t('routes.office.main.bookings.empty.description')}
                                    actions={[
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                // Go to Schedule
                                                navigate(`/${RoutesNames.Office}/${RoutesNames.Office_ServicePicker}`);
                                            }}
                                        >
                                            {t('routes.office.newAppointment.selectServices')}
                                        </Button>,
                                    ]}
                                />
                            )}
                            {documents && documents?.length > 0 && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '24px',
                                    }}
                                >
                                    {appointments?.map(
                                        (
                                            appointment: {
                                                id: string;
                                                bookings: Array<Booking>;
                                            },
                                            index: number,
                                        ) => {
                                            return (
                                                <AppointmentCard
                                                    appointment={appointment}
                                                    key={`appointment-${index}`}
                                                    onAppointmentUpdate={(appointment: {
                                                        id: string;
                                                        bookings: Array<Booking>;
                                                    }) => {
                                                        // Update state
                                                        const newAppointments = [...(appointments || [])];
                                                        const appointmentIndex = newAppointments.findIndex(
                                                            (a) => a.id === appointment.id,
                                                        );
                                                        if (appointmentIndex !== -1) {
                                                            newAppointments[appointmentIndex] = appointment;
                                                        }
                                                        setAppointments(newAppointments);
                                                    }}
                                                />
                                            );
                                        },
                                    )}
                                </Box>
                            )}
                        </InfiniteScroll>
                    )}
                    {error && (
                        <ErrorState
                            icon={<EventIcon />}
                            onTryAgainClick={() => {
                                // Reset state
                                setError(false);
                            }}
                        />
                    )}
                </Box>
            )}
            {!authContext.state.firebaseUser && (
                <Box
                    sx={{
                        paddingBottom: '24px',
                        position: 'relative',
                        top: '-24px',
                    }}
                >
                    {!authContext.state.firebaseUser && (
                        <Grow in={true} mountOnEnter unmountOnExit>
                            <Box
                                sx={{
                                    marginBottom: '8px',
                                }}
                            >
                                <SignInAd reason={`${t('routes.office.main.card.bookingSignInAd')} 🗓️`} />
                            </Box>
                        </Grow>
                    )}
                </Box>
            )}
        </MainOfficePage>
    );
}
