import { Resource } from 'i18next';

/*
 * Language Tags (for the App)
 */
export enum AppLanguageTag {
    /*
     * Portuguese from Portugal
     */
    ptPT = 'pt_PT',

    /*
     * English from US
     */
    enUS = 'en_US',
}

export type LanguagesResource = Resource & {
    [language in AppLanguageTag]: LanguageResource;
};

export interface LanguageResource {
    translation: LanguageTranslation;
}

export interface LanguageTranslation {
    components: {
        phoneNumberInput: {
            country: string;
            mobilePhoneNumber: string;
        };
        auxPage: {
            back: string;
        };
        vaidosaCard: {
            gift: {
                title: string;
                description: string;
            };
            founder: {
                title: string;
                description: string;
            };
            loyalty: {
                title: string;
                description: string;
            };
        };
        errorState: {
            title: string;
            tryAgain: string;
        };
        signInAd: {
            title: string;
            description: string;
            button: string;
        };
        timeTraveller: {
            yesterday: string;
            today: string;
            tomorrow: string;
            previousMonth: string;
            thisMonth: string;
            nextMonth: string;
        };
        userSearch: {
            search: string;
            placeholder: string;
        };
        providerSearch: {
            search: string;
            placeholder: string;
        };
        servicePicker: {
            title: string;
            placeholder: string;
            empty: {
                title: string;
                description: string;
                descriptionCategory: string;
                seeAllCategories: string;
            };
            bookNow: string;
            priceInterval: string;
            durationInterval: string;
            price: string;
            duration: string;
            next: string;
            select: string;
            selected: string;
            search: string;
        };
        locationPicker: {
            title: string;
            placeholder: string;
            empty: {
                title: string;
                description: string;
            };
            whereAreYou: string;
            locationInput: string;
            aroundMe: string;
            unknownLocation: string;
        };
        timePicker: {
            durationTextHoursAndMinutes: string;
            durationTextHours: string;
            durationTextMinutes: string;
            durationTextZero: string;
        };
        bookingCard: {
            cancelLock: string;
            cancelBooking: string;
            clientDidNotShowUp: string;
            keep: string;
            cancel: string;
            confirm: string;
            cancelBookingTitle: string;
            cancelBookingDescription: string;
            userNoShowBookingTitle: string;
            userNoShowBookingDescription: string;
            bookedByClient: string;
            bookedByProvider: string;
        };
    };
    routes: {
        signIn: {
            welcome1: string;
            welcome2: string;
            welcome3: string;
            welcomeNewAppointment: string;
            enter: string;
            legalDocumentsAcceptance: string;
            help: string;
            otpMessage: string;
            otpInput: string;
            otpCode: string;
            otpWarn: string;
            back: string;
            confirm: string;
        };
        termsOfUse: {
            title: string;
        };
        privacyPolicy: {
            title: string;
        };
        office: {
            main: {
                bottomNavigation: {
                    home: string;
                    academy: string;
                    professionals: string;
                    bookings: string;
                    card: string;
                    more: string;
                };
                home: {
                    goodMorning: string;
                    goodAfternoon: string;
                    goodEvening: string;
                    hello: string;
                    nextAppointment: string;
                    previousAppointment: string;
                    noAppointments: {
                        title: string;
                        letsgo: string;
                        description: string;
                    };
                    loyalty: string;
                    gift: string;
                    points: string;
                    available: string;
                    whatDoYouWantToDo: string;
                    forYou: string;
                    searchByServiceCategory: string;
                    searchByService: string;
                    manicure: string;
                    pedicure: string;
                    makeup: string;
                    yourSalon: string;
                    bookingsSignInAd: string;
                    knowMore: string;
                    accordions: {
                        administrator: string;
                        professional: string;
                    };
                    professional: {
                        schedule: {
                            title: string;
                            help: string;
                            noProvider: string;
                            weekDayDateFormat: string;
                            week: string;
                            hours: string;
                        };
                        invoicing: {
                            title: string;
                            help: string;
                            noProvider: string;
                        };
                        clients: {
                            title: string;
                            help: string;
                        };
                        myServices: {
                            title: string;
                            help: string;
                        };
                        ocurrencies: {
                            title: string;
                            help: string;
                        };
                        diaries: {
                            title: string;
                            help: string;
                        };
                        providers: {
                            title: string;
                            help: string;
                        };
                    };
                };
                card: {
                    titleLine1: string;
                    titleLine2: string;
                    transactions: string;
                    alert: {
                        loyalty: string;
                        gift: string;
                    };
                    empty: {
                        loyalty: string;
                        gift: string;
                    };
                    cardsSignInAd: string;
                    transactionsSignInAd: string;
                    bookingSignInAd: string;
                };
                bookings: {
                    titleLine1: string;
                    titleLine2: string;
                    empty: {
                        title: string;
                        description: string;
                    };
                    cancel: string;
                    cancelDescription: string;
                    keep: string;
                };
                academy: {
                    titleLine1: string;
                    titleLine2: string;
                };
                more: {
                    titleLine1: string;
                    titleLine2: string;
                    legalDocuments: string;
                    account: string;
                    logout: string;
                    help: string;
                    help_call: string;
                    help_whatsapp: string;
                    help_website: string;
                };
                bookingButton: string;
                professionals: {
                    titleLine1: string;
                    titleLine2: string;
                    empty: {
                        title: string;
                        description: string;
                    };
                    search: string;
                    placeholder: string;
                };
            };
            book: {
                title: string;
                errorTitle: string;
                errorMessage: string;
                empty: {
                    title: string;
                    description: string;
                };
                selectProfessional: string;
                minutes: string;
                minutesComplete: string;
                notAvailable: string;
                availableNow: string;
                availableSoon: string;
                availableTomorrow: string;
                availableThisWeek: string;
                availableAfter: string;
                providerBirthday: string;
                awards: {
                    bestLocation: string;
                    bestAvailability: string;
                    bestReviewsCount: string;
                    bestAverageRating: string;
                    bestPrice: string;
                    bestDuration: string;
                };
                location: string;
                sort: string;
                sortOptions: {
                    location: string;
                    availability: string;
                    reviewsCount: string;
                    averageRating: string;
                    price: string;
                    duration: string;
                };
            };
            notificationsExplainDialog: {
                title: string;
                description: string;
                noThanks: string;
                confirm: string;
            };
            notifications: {
                title1: string;
                title2: string;
                empty: {
                    title: string;
                    description: string;
                };
                emptyUnread: {
                    title: string;
                    description: string;
                    listAll: string;
                };
                markAllAsReadConfirmDialog: {
                    title: string;
                    description: string;
                    confirm: string;
                    cancel: string;
                };
            };
            professional: {
                invoicing: {
                    notAvailableYet: string;
                    emptyTitle: string;
                    emptyDescription: string;
                    dayTotal: string;
                    goodJob: string;
                };
                schedule: {
                    emptyTitle: string;
                    emptyDescription: string;
                    summary: string;
                    clients: string;
                    services: string;
                    expectedEarnings: string;
                };
                users: {
                    title: string;
                    empty: {
                        title: string;
                        description: string;
                    };
                    emptyToday: {
                        title: string;
                        description: string;
                        listAll: string;
                    };
                    withoutName: string;
                };
                services: {
                    title: string;
                    emptyTitle: string;
                    emptyDescription: string;
                    form: {
                        isActive: string;
                        durationInMinutes: string;
                        price: string;
                        photosUrls: string;
                        cancel: string;
                        saveChanges: string;
                    };
                };
                providers: {
                    title: string;
                    empty: {
                        title: string;
                        description: string;
                    };
                    emptyToday: {
                        title: string;
                        description: string;
                        listAll: string;
                    };
                    withoutName: string;
                };
            };
            systemAdministator: {
                invoicing: {
                    notAvailableYet: string;
                    emptyTitle: string;
                    emptyDescription: string;
                    monthTotal: string;
                    goodJob: string;
                    earned: string;
                    contributed: string;
                    noProvider: string;
                    totalInvoiced: string;
                    providersEarningsTotal: string;
                    balanceBeforeExpenses: string;
                    businessExpensesTotal: string;
                    finalBalance: string;
                    isGonnaBeAllRight: string;
                };
                schedule: {
                    emptyTitle: string;
                    emptyDescription: string;
                    scheduled: string;
                    cancelled: string;
                    completed: string;
                    monthTotal: string;
                    totalScheduled: string;
                    totalCancelled: string;
                    totalCompleted: string;
                    goodJob: string;
                    isGonnaBeAllRight: string;
                };
            };
            user: {
                tabs: {
                    profile: string;
                    history: string;
                    loyalty: string;
                    gift: string;
                };
                profile: {
                    firstName: string;
                    lastName: string;
                    phoneNumber: string;
                    emailAddress: string;
                    birthDate: string;
                    taxPayerNumber: string;
                    isActive: string;
                };
                history: {
                    empty: {
                        title: string;
                        description: string;
                    };
                };
                card: {
                    empty: {
                        title: string;
                        description: string;
                    };
                };
            };
            showcase: {
                scheduleAppointment: string;
                call: string;
                closed: string;
                open: string;
                selectSchedule: string;
                pickStartHour: string;
                location: string;
                schedule: string;
                services: string;
                noServicesSelected: string;
                noServicesSelectedDescription: string;
                selectServices: string;
                noAvailabilityForThisDay: string;
                noAvailabilityForThisDayDescription: string;
                confirmAppointment: string;
                directions: string;
            };
            newAppointment: {
                title: string;
                emptyTitle: string;
                emptyDescription: string;
                selectServices: string;
                when: string;
                who: string;
                what: string;
                you: string;
                submit: string;
                successTitle: string;
                successDescription: string;
                errorTitle: string;
                restartProcess: string;
                goingBackToStart: string;
                startMenu: string;
                directions: string;
                form: {
                    firstAndLastName: string;
                    observations: string;
                };
                notSignedIn: {
                    title: string;
                    description: string;
                };
                diffPhoneNumber: {
                    title: string;
                    description: string;
                };
                errors: {
                    'provider-not-found': string;
                    'provider-services-mismatch': string;
                    'user-not-active': string;
                    'user-is-blocked-from-booking': string;
                    'provider-not-available': string;
                    'user-cannot-be-the-provider': string;
                };
                distanceWarningDialog: {
                    title: string;
                    description: string;
                    confirm: string;
                    cancel: string;
                };
            };
            providerNewEvent: {
                title: string;
                type: string;
                when: string;
                what: string;
                you: string;
                submit: string;
                form: {
                    firstAndLastName: string;
                    observations: string;
                };
                lock: {
                    title: string;
                    start: string;
                    end: string;
                    details: string;
                    submit: string;
                    observations: string;
                    info: string;
                    errors: {
                        'provider-not-found': string;
                        'invalid-dates': string;
                    };
                    errorTitle: string;
                };
                appointment: {
                    title: string;
                    pickServices: string;
                    pickDate: string;
                    cancel: string;
                    submit: string;
                    emptyServices: string;
                    emptyDate: string;
                    errors: {
                        'provider-not-found': string;
                        'provider-services-mismatch': string;
                        'user-not-active': string;
                        'user-is-blocked-from-booking': string;
                        'provider-not-available': string;
                        'user-cannot-be-the-provider': string;
                    };
                    errorTitle: string;
                    info: string;
                };
            };
        };
    };
    enums: {
        daysOfTheWeek: {
            monday: string;
            tuesday: string;
            wednesday: string;
            thursday: string;
            friday: string;
            saturday: string;
            sunday: string;
        };
        bookingState: {
            scheduled: string;
            'cancelled-by-provider': string;
            'cancelled-by-user': string;
            'user-no-show': string;
            completed: string;
            'deleted-by-provider': string;
        };
        serviceCategory: {
            manicure: string;
            pedicure: string;
            makeup: string;
            hair: string;
            facialEpilation: string;
            eyelashes: string;
            depilation: string;
        };
    };
    //
    legal_documents: {
        terms_and_conditions: string;
        privacy_policy: string;
    };
    products: {
        bluebird: string;
        bluebird_with_gender: string;
    };
}
