/**
 * Service category
 */
export enum ServiceCategory {
    /**
     * Manicure
     */
    Manicure = 'manicure',

    /**
     * Pedicure
     */
    Pedicure = 'pedicure',

    /**
     * MakeUp
     */
    Makeup = 'makeup',

    /**
     * Hair
     */
    Hair = 'hair',

    /**
     * Facial epilation
     */
    FacialEpilation = 'facial-epilation',

    /**
     * Eyelashes
     */
    Eyelashes = 'eyelashes',

    /**
     * Depilation
     */
    Depilation = 'depilation',
}
