import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ClearIcon from '@mui/icons-material/Clear';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import PlaceIcon from '@mui/icons-material/Place';
import SearchIcon from '@mui/icons-material/Search';
import {
    Box,
    CircularProgress,
    FormControl,
    Grow,
    IconButton,
    InputLabel,
    OutlinedInput,
    Paper,
    Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { Container } from '../../../components/container/container';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { ErrorState } from '../../../components/error-state/error-state';
import { Loader } from '../../../components/loader/loader';
import { Page } from '../../../components/page/page';
import { AppContext } from '../../../contexts/app/context';
import { AppContextType, UserLocation } from '../../../contexts/app/types';
import { City } from '../../../interfaces/city';
import { CitiesRepository } from '../../../repositories/cities';

function CityTile({ city, disabled }: { city: City; disabled?: boolean }) {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);

    // Get navigation
    const navigate = useNavigate();

    // Build
    return (
        <Grow in={true} mountOnEnter unmountOnExit>
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '16px',
                    padding: '8px',
                    gap: '8px',
                }}
                onClick={() => {
                    // Build info
                    const info: UserLocation = {
                        cityId: city.id ?? '',
                        name: city.name,
                        administratorCityName: city.administratorCityName,
                        isPrecise: false,
                        coordinates: city.location?.coordinates || [0, 0],
                    };

                    // Set Location
                    appContext.setLocation(info);

                    // Navigate Back
                    navigate(-1);
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        gap: '4px',
                        opacity: disabled ? 0.5 : 1,
                    }}
                >
                    {city.administratorCityName ? <LocationCityIcon /> : <AccountBalanceIcon />}
                    <Box>
                        <Typography variant="body1" gutterBottom fontWeight={500}>
                            {city.name}
                        </Typography>
                        {city.administratorCityName && (
                            <Typography variant="body2" gutterBottom>
                                {city.administratorCityName}
                            </Typography>
                        )}
                        {!city.administratorCityName && (
                            <Typography variant="body2" gutterBottom>
                                {city.name}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Paper>
        </Grow>
    );
}

export default function LocationPicker() {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);

    // Translations
    const { t } = useTranslation();

    // Get navigation
    const navigate = useNavigate();

    // Hold state
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [totalDocumentsCount, setTotalDocumentsCount] = useState<number>(-1);
    const [documents, setDocuments] = useState<Array<City> | null>(null);
    const [query, setQuery] = useState('');
    const queryDebouncedValue = useDebounce<string>(query, 500);

    // Load
    const handleLoadMore = async (page: number) => {
        try {
            // Get
            const results: { totalCount: number; data: Array<City> } = await CitiesRepository.instance.getCities(
                documents?.length ?? 0,
                20,
                query && query.trim() !== '' ? query : undefined,
            );

            // Update state
            setTotalDocumentsCount(results.totalCount);
            setDocuments([...(documents || []), ...results.data]);
        } catch (error) {
            // Log
            console.error(error);

            // Update state
            setError(true);
        }
    };

    // Action: Reset all
    const resetState = async () => {
        // Reset state
        setError(false);
        setDocuments(null);
        setTotalDocumentsCount(-1);
    };

    // Actions: getPreciseLocation
    const getPreciseLocation = async () => {
        // Start Loading
        setIsLoading(true);

        // Get
        const location: UserLocation | null = await appContext.getPreciseLocation();

        // Eval
        if (location) {
            // Update
            appContext.setLocation(location);
        }

        // Stop Loading
        setIsLoading(false);

        // Navigate Back
        navigate(-1);
    };

    // On Input Change
    useEffect(() => {
        // Reset state (and fire search by consequence)
        resetState();
    }, [queryDebouncedValue]);

    // Build
    return (
        <Page
            title={t('components.locationPicker.title')}
            onRefresh={async () => {
                // Reset state
                resetState();
            }}
            disableIosUnderTitle={true}
            underBar={
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }}
                >
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                        <InputLabel id="service-picker-input">{t('components.locationPicker.placeholder')}</InputLabel>
                        <OutlinedInput
                            disabled={isLoading}
                            id="service-picker-input"
                            label={t('components.locationPicker.placeholder')}
                            placeholder={`${appContext.state.userLocation.name}${
                                appContext.state.userLocation.administratorCityName
                                    ? `, ${appContext.state.userLocation.administratorCityName}`
                                    : ''
                            }`}
                            fullWidth
                            startAdornment={
                                <SearchIcon
                                    sx={{
                                        marginRight: '8px',
                                    }}
                                />
                            }
                            autoFocus={true}
                            value={query}
                            onChange={(event) => {
                                // Update
                                setQuery(event.target.value);
                            }}
                            endAdornment={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginRight: '-8px',
                                    }}
                                >
                                    {query && (
                                        <IconButton
                                            disabled={isLoading}
                                            aria-label="clear"
                                            onClick={() => {
                                                // Clear Value
                                                setQuery('');
                                            }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    )}
                                    <IconButton
                                        disabled={isLoading}
                                        aria-label="my-location"
                                        onClick={() => {
                                            getPreciseLocation();
                                        }}
                                    >
                                        {isLoading && <CircularProgress size={'24px'} />}
                                        {!isLoading && <MyLocationIcon />}
                                    </IconButton>
                                </Box>
                            }
                        />
                    </FormControl>
                </Box>
            }
            underBarHeight={72}
        >
            <Container>
                {!error && (
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={handleLoadMore}
                        hasMore={documents?.length !== totalDocumentsCount}
                        loader={
                            <Box
                                key={`loader`}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '32px',
                                }}
                            >
                                <Loader size={48} />
                            </Box>
                        }
                    >
                        {documents?.length === 0 && (
                            <EmptyState
                                key={`empty`}
                                icon={<PlaceIcon />}
                                title={t('components.locationPicker.empty.title')}
                                description={t('components.locationPicker.empty.description')}
                                height={55}
                            />
                        )}
                        {documents?.map((document: City, index: number) => {
                            return <CityTile key={`city-${index}`} city={document} disabled={isLoading} />;
                        })}
                    </InfiniteScroll>
                )}
                {error && (
                    <ErrorState
                        icon={<PlaceIcon />}
                        onTryAgainClick={() => {
                            // Reset state
                            setError(false);
                        }}
                    />
                )}
            </Container>
        </Page>
    );
}
