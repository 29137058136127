import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LoginIcon from '@mui/icons-material/Login';
import { Box, Button, CircularProgress, Grow, IconButton, Typography, useTheme } from '@mui/material';
import { MouseEventHandler, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { MainOfficePage } from '../../../../components/main-office-page/main-office-page';
import { OfficeTitle } from '../../../../components/office-title/office-title';
import { AppContext } from '../../../../contexts/app/context';
import { AppContextType } from '../../../../contexts/app/types';
import { AuthContext } from '../../../../contexts/auth/context';
import { AuthContextType } from '../../../../contexts/auth/types';
import { RoutesNames } from '../../../../routes-names';
import styles from './more.module.scss';

function ListButton({
    text,
    color,
    onClick,
    disabled,
    loading,
}: {
    text: string;
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    onClick?: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    loading?: boolean;
}) {
    // Get theme
    const theme = useTheme();

    // Build
    return (
        <Button
            disabled={disabled}
            onClick={onClick}
            variant="text"
            color={color}
            sx={{
                width: '100%',
                textAlign: 'left',
                color: !color ? theme.palette.text.primary : undefined,
            }}
        >
            <Box
                sx={{
                    flex: 1,
                }}
            >
                {text}
            </Box>
            {loading && <CircularProgress size={16} />}
            <ChevronRightIcon />
        </Button>
    );
}

export function OfficeMainMore() {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);
    const authContext: AuthContextType = useContext(AuthContext);

    // Translations
    const { t } = useTranslation();

    // Get navigation
    const navigate = useNavigate();

    // Hold State
    const [loggingOut, setLoggingOut] = useState<boolean>(false);

    // Build
    return (
        <MainOfficePage
            title={[t('routes.office.main.more.titleLine1'), t('routes.office.main.more.titleLine2')]}
            color={'#7dd957'}
            actions={[
                !authContext.state.firebaseUser ? (
                    <Link to={`/${RoutesNames.SignIn}`}>
                        <IconButton aria-label="sign-in">
                            <LoginIcon />
                        </IconButton>
                    </Link>
                ) : undefined,
            ]}
        >
            <Box className={styles.morePage}>
                <Grow in={true} mountOnEnter unmountOnExit>
                    <Box>
                        <OfficeTitle text={t('routes.office.main.more.account')} color={'#7dd957'} />
                        {authContext.state.firebaseUser && (
                            <Box className={styles.buttonsGroup}>
                                <ListButton
                                    color="error"
                                    text={t('routes.office.main.more.logout')}
                                    disabled={loggingOut}
                                    loading={loggingOut}
                                    onClick={async () => {
                                        // Set logging out
                                        setLoggingOut(true);

                                        // Logout
                                        await authContext.signOut();

                                        // Navigate to Home
                                        navigate(`./${RoutesNames.Office_Main_Home}`);
                                    }}
                                />
                            </Box>
                        )}
                        {!authContext.state.firebaseUser && (
                            <Box className={styles.buttonsGroup}>
                                <Link to={`/${RoutesNames.SignIn}`}>
                                    <ListButton color="warning" text={t('routes.signIn.enter')} />
                                </Link>
                            </Box>
                        )}
                    </Box>
                </Grow>

                <Grow
                    in={true}
                    mountOnEnter
                    unmountOnExit
                    style={{
                        transitionDelay: '100ms',
                    }}
                >
                    <Box>
                        <OfficeTitle text={t('routes.office.main.more.help')} color={'#7dd957'} />
                        <Box className={styles.buttonsGroup}>
                            <Link target="_blank" to="tel:+351935375851">
                                <ListButton text={t('routes.office.main.more.help_call')} />
                            </Link>
                            <Link target="_blank" to="https://wa.me/351935375851">
                                <ListButton text={t('routes.office.main.more.help_whatsapp')} />
                            </Link>
                            <Link target="_blank" to="https://asvaidosas.pt/">
                                <ListButton text={t('routes.office.main.more.help_website')} />
                            </Link>
                        </Box>
                    </Box>
                </Grow>

                <Grow
                    in={true}
                    mountOnEnter
                    unmountOnExit
                    timeout={500}
                    style={{
                        transitionDelay: '200ms',
                    }}
                >
                    <Box>
                        <OfficeTitle text={t('routes.office.main.more.legalDocuments')} color={'#7dd957'} />
                        <Box className={styles.buttonsGroup}>
                            <Link to={`../../../${RoutesNames.TermsOfUse}`}>
                                <ListButton text={t('legal_documents.terms_and_conditions')} />
                            </Link>

                            <Link to={`../../../${RoutesNames.PrivacyPolicy}`}>
                                <ListButton text={t('legal_documents.privacy_policy')} />
                            </Link>
                        </Box>
                    </Box>
                </Grow>
            </Box>

            {/* Footer */}
            <Grow
                in={true}
                mountOnEnter
                unmountOnExit
                style={{
                    transitionDelay: '100ms',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant="caption"
                        sx={{
                            marginTop: '32px',
                            textAlign: 'center',
                        }}
                    >
                        {appContext.state.appVersion
                            ? `${appContext.state.appVersion} (${appContext.state.appBuild}) / `
                            : undefined}
                        Etecode © {new Date().getFullYear()}
                    </Typography>
                </Box>
            </Grow>
            <Box
                sx={{
                    height: '50px',
                }}
            ></Box>
        </MainOfficePage>
    );
}
