import i18n, { LanguageDetectorAsyncModule } from 'i18next';
import moment from 'moment';
import 'moment/min/locales';
import { initReactI18next } from 'react-i18next';
import { MomentLanguageCode } from '../enums/moment-language-code';
import { AppLanguageTag, LanguagesResource, LanguageTranslation } from './types';
import { en_US } from './_en_US';
import { pt_PT } from './_pt_PT';

// Define Language Resources
const resources: LanguagesResource = {
    [AppLanguageTag.ptPT]: {
        translation: pt_PT,
    },
    [AppLanguageTag.enUS]: {
        translation: en_US,
    },
};

enum BrowserLanguageCode {
    /**
     * Portuguese
     */
    pt = 'pt',

    /**
     * English
     */
    en = 'en',

    /**
     * Spanish
     */
    es = 'es',
}

export async function getInitAppLanguage(): Promise<{
    appLanguage: AppLanguageTag;
    momentLanguage: MomentLanguageCode;
}> {
    // Language to use (Default: 'pt_PT')
    let appLanguage: AppLanguageTag = AppLanguageTag.ptPT;

    // Moment language (Default: 'pt')
    let momentLanguage: MomentLanguageCode = MomentLanguageCode.ptPT;

    // Browser language code (Example: 'pt', 'en', 'es', etc)
    const browserLanguageCode: string | null = navigator.language ? navigator.language.split('-')[0] : null;

    // Search for the language
    switch (browserLanguageCode) {
        case BrowserLanguageCode.pt:
            appLanguage = AppLanguageTag.ptPT;
            momentLanguage = MomentLanguageCode.ptPT;
            break;
        case BrowserLanguageCode.en:
            appLanguage = AppLanguageTag.enUS;
            momentLanguage = MomentLanguageCode.enGB;
            break;
        default:
            // Default is Portuguese
            break;
    }

    // Return
    return {
        appLanguage: appLanguage,
        momentLanguage: momentLanguage,
    };
}

const languageDetector: LanguageDetectorAsyncModule = {
    type: 'languageDetector',
    async: true,
    detect: (callback: any) => {
        // Get the app's language
        getInitAppLanguage().then(({ appLanguage, momentLanguage }) => {
            // Set moment locale
            moment.locale(momentLanguage);

            // Done
            callback(appLanguage);
        });
    },
    init: () => {},
    cacheUserLanguage: () => {},
};

// Init i18n
export default i18n
    .use(languageDetector)
    .use(initReactI18next)
    .init({
        // Translations resources
        resources,
        // Fallback Language
        fallbackLng: AppLanguageTag.ptPT,
        // Other configs
        interpolation: {
            // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
            escapeValue: false,
        },
    });

type FlattenKeys<T> = T extends object ? { [K in keyof T]: `${K & string}${FlattenKeys<T[K]>}` }[keyof T] : '';
export type AppTranslations = FlattenKeys<LanguageTranslation>;
