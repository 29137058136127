import { Grow, Paper } from '@mui/material';
import { useContext } from 'react';
import { AppContext } from '../../contexts/app/context';
import { AppContextType } from '../../contexts/app/types';
import { OperativeSystem } from '../../enums/operative-system';

export function FooterSheet({ children }: { children: React.ReactNode }) {
    // Get context
    const appContext: AppContextType = useContext(AppContext);

    // Render
    return (
        <Grow in={true}>
            <Paper
                elevation={16}
                sx={{
                    position: 'fixed',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1100,
                }}
            >
                <Paper
                    elevation={0}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        maxHeight: 'none',
                        height: 'auto',
                        paddingLeft: `${appContext.getDefaultSidePaddingValue()}px !important`,
                        paddingRight: `${appContext.getDefaultSidePaddingValue()}px !important`,
                    }}
                >
                    {children}
                </Paper>
                {appContext.state.insets.bottom > 0 && (
                    <Paper
                        elevation={0}
                        sx={{
                            width: '100%',
                            height: `${
                                appContext.state.insets.bottom -
                                (appContext.state.operativeSystem === OperativeSystem.iOS ? 16 : 8)
                            }px`,
                        }}
                    ></Paper>
                )}
            </Paper>
        </Grow>
    );
}
