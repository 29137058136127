import { Avatar, Box, Chip, Paper, Typography } from '@mui/material';
import moment from 'moment';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/auth/context';
import { AuthContextType } from '../../contexts/auth/types';
import { CardType } from '../../enums/card-type';
import { Booking } from '../../interfaces/booking';
import { CardTransaction } from '../../interfaces/card-transaction';
import styles from './card-transaction-line.module.scss';

export function CardTransactionLine({
    transaction,
    bookings,
}: {
    transaction: CardTransaction;
    bookings?: Array<Booking>;
}) {
    // Get Contexts
    const authContext: AuthContextType = useContext(AuthContext);

    // Get Booking Info
    const booking: Booking | undefined = (bookings ?? authContext.state.me?.bookings ?? []).find(
        (booking) => booking.id?.toString() === transaction.bookingId?.toString(),
    );

    // Provider name
    const providerName = booking?.provider?.name?.split(' ')[0] ?? '';

    // Build
    return (
        <Paper className={styles.line}>
            <Box className={styles.info}>
                <Typography variant="body2" component="div" color="gray">
                    {moment(transaction.registeredAt).format('LLL')}
                </Typography>
                <Typography variant="caption" component="div" color="gray">
                    {`(${moment(transaction.registeredAt).fromNow()})`}
                </Typography>
                {booking && (
                    <Box
                        sx={{
                            marginTop: '8px',
                        }}
                    >
                        <Typography
                            variant="body1"
                            component="div"
                            fontWeight={500}
                            className={`${styles.service} ${styles[transaction.cardType]}`}
                        >
                            {booking.service?.name}
                        </Typography>
                        <Chip
                            className={styles.provider}
                            avatar={
                                <Avatar alt={providerName} src={booking.provider?.pictureUrl}>
                                    {providerName[0]}
                                </Avatar>
                            }
                            label={providerName}
                            variant="outlined"
                        />
                    </Box>
                )}
                {!booking && transaction.description && (
                    <Box
                        sx={{
                            marginTop: '8px',
                        }}
                    >
                        <Typography
                            variant="body1"
                            component="div"
                            fontWeight={500}
                            className={`${styles.service} ${styles[transaction.cardType]}`}
                        >
                            {transaction.description}
                        </Typography>
                    </Box>
                )}
            </Box>
            {transaction.cardType === CardType.Loyalty && (
                <Chip
                    label={
                        transaction.amount > 0 ? `+${Math.floor(transaction.amount)}` : Math.floor(transaction.amount)
                    }
                    color={transaction.amount > 0 ? 'success' : 'info'}
                    sx={{
                        fontWeight: 'bold',
                    }}
                />
            )}
            {transaction.cardType === CardType.Gift && (
                <Chip
                    label={
                        transaction.amount > 0
                            ? `+${transaction.amount.toFixed(2)}€`
                            : `${transaction.amount.toFixed(2)}€`
                    }
                    color={transaction.amount > 0 ? 'success' : 'info'}
                    sx={{
                        fontWeight: 'bold',
                    }}
                />
            )}
        </Paper>
    );
}
