import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LoginIcon from '@mui/icons-material/Login';
import { Alert, Box, Grow, IconButton, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CardTransactionLine } from '../../../../components/card-transaction-line/card-transaction-line';
import { MainOfficePage } from '../../../../components/main-office-page/main-office-page';
import { OfficeTitle } from '../../../../components/office-title/office-title';
import { SignInAd } from '../../../../components/sign-in-ad/sign-in-ad';
import VaidosaCard from '../../../../components/vaidosa-card/vaidosa-card.component';
import { AuthContext } from '../../../../contexts/auth/context';
import { AuthContextType } from '../../../../contexts/auth/types';
import { CardType } from '../../../../enums/card-type';
import { Card as ClientCard, GiftCardData, LoyaltyCardData } from '../../../../interfaces/card';
import { CardTransaction } from '../../../../interfaces/card-transaction';
import { RoutesNames } from '../../../../routes-names';
import { emptyId } from '../../../../utils/ts-utils';
import { useQuery } from '../../../../utils/use-query';
import styles from './card.module.scss';

function OfficeMainCardTransactionsList({ card, type }: { card: ClientCard<any>; type: CardType }) {
    // Get Contexts
    const authContext: AuthContextType = useContext(AuthContext);

    // Translations
    const { t } = useTranslation();

    // Get transactions
    let transactions: Array<CardTransaction> = [];
    if (type === CardType.Loyalty) {
        transactions = (card as ClientCard<LoyaltyCardData>).data?.transactions ?? [];
    } else if (type === CardType.Gift) {
        transactions = (card as ClientCard<GiftCardData>).data?.transactions ?? [];
    }

    // Sort
    transactions = transactions.sort((a, b) => {
        if (a.registeredAt > b.registeredAt) {
            return -1;
        }
        if (a.registeredAt < b.registeredAt) {
            return 1;
        }
        return 0;
    });

    // Eval
    if (transactions.length > 0) {
        // Build
        return (
            <Box className={`${styles.list} ${styles[type]}`}>
                {transactions.map((transaction: CardTransaction, index: number) => {
                    return <CardTransactionLine key={`transaction-${index}`} transaction={transaction} />;
                })}
            </Box>
        );
    } else {
        if (!authContext.state.firebaseUser) {
            return <SignInAd reason={`${t('routes.office.main.card.transactionsSignInAd')} 📋`} />;
        } else {
            return (
                <Box className={styles.empty}>
                    {type === CardType.Loyalty && (
                        <EmojiEventsIcon
                            color="action"
                            sx={{
                                fontSize: '700%',
                                marginBottom: '16px',
                                opacity: 0.3,
                            }}
                        />
                    )}
                    {type === CardType.Gift && (
                        <CardGiftcardIcon
                            color="action"
                            sx={{
                                fontSize: '700%',
                                marginBottom: '16px',
                                opacity: 0.3,
                            }}
                        />
                    )}

                    {type === CardType.Loyalty && (
                        <Typography variant="body1" component="div">
                            {t('routes.office.main.card.empty.loyalty')}
                        </Typography>
                    )}
                    {type === CardType.Gift && (
                        <Typography variant="body1" component="div">
                            {t('routes.office.main.card.empty.gift')}
                        </Typography>
                    )}
                </Box>
            );
        }
    }
}

export function OfficeMainCard() {
    // Get Contexts
    const authContext: AuthContextType = useContext(AuthContext);

    // Translations
    const { t } = useTranslation();

    // Read query
    const query = useQuery();

    // Hold state
    const [cardTypes, setCardTypes] = useState([CardType.Gift, CardType.Loyalty]);

    // Map
    const cardsMap: Map<CardType, ClientCard<any> | undefined> = new Map<CardType, ClientCard<any> | undefined>();
    cardsMap.set(CardType.Loyalty, authContext.state.me?.cards.find((card) => card.type === CardType.Loyalty));
    cardsMap.set(CardType.Gift, authContext.state.me?.cards.find((card) => card.type === CardType.Gift));

    // Get current card
    let currentCard = authContext.state.me?.cards?.find((card) => card.type === cardTypes[cardTypes.length - 1]);

    // Check login
    if (!authContext.state.firebaseUser) {
        // Add fake cards
        cardsMap.set(CardType.Loyalty, {
            type: CardType.Loyalty,
            userId: emptyId(),
        });
        cardsMap.set(CardType.Gift, {
            type: CardType.Gift,
            userId: emptyId(),
        });

        // Select first card
        currentCard = cardsMap.get(CardType.Loyalty);
    }

    // On query change
    useEffect(() => {
        // Get selected card type
        const selectedCardType = query.get('cardType') as CardType | undefined;

        // Eval
        if (selectedCardType) {
            // Get index
            const index = cardTypes.findIndex((cardType) => cardType === selectedCardType);

            // If exists
            if (index >= 0) {
                // Move card to the end of the array
                setCardTypes((cards) => {
                    const newCards = [...cards];
                    newCards.splice(index, 1);
                    newCards.push(selectedCardType);
                    return newCards;
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    // Build
    return (
        <MainOfficePage
            title={[t('routes.office.main.card.titleLine1'), t('routes.office.main.card.titleLine2')]}
            color={'#ffc834'}
            onRefresh={async () => {
                // Refresh /me
                await authContext.refreshMe(true);
            }}
            actions={[
                !authContext.state.firebaseUser ? (
                    <Link to={`/${RoutesNames.SignIn}`}>
                        <IconButton aria-label="sign-in">
                            <LoginIcon />
                        </IconButton>
                    </Link>
                ) : undefined,
            ]}
        >
            <Box
                className={styles.box}
                sx={{
                    paddingBottom: '50px',
                }}
            >
                {!authContext.state.firebaseUser && (
                    <Grow
                        in={true}
                        style={{
                            transitionDelay: '100ms',
                        }}
                        timeout={200}
                    >
                        <Box
                            sx={{
                                marginBottom: '8px',
                            }}
                        >
                            <SignInAd reason={`${t('routes.office.main.card.cardsSignInAd')} 💰`} />
                        </Box>
                    </Grow>
                )}

                <Box className={styles.subBox}>
                    <Box className={styles.cards}>
                        {cardTypes.map((cardType: CardType, index: number) => {
                            if (!cardsMap.get(cardType)) {
                                return undefined;
                            }
                            return (
                                <VaidosaCard
                                    key={`vaidosa-card-${index}`}
                                    card={cardsMap.get(cardType) as ClientCard<any>}
                                    index={index}
                                    onClick={() => {
                                        // Move card to the end of the array
                                        setCardTypes((cards) => {
                                            const newCards = [...cards];
                                            newCards.splice(index, 1);
                                            newCards.push(cardType);
                                            return newCards;
                                        });
                                    }}
                                />
                            );
                        })}

                        <Box
                            sx={{
                                marginTop: '32px',
                            }}
                        >
                            {cardTypes[cardTypes.length - 1] === CardType.Loyalty && (
                                <Alert severity="warning">{t('routes.office.main.card.alert.loyalty')}</Alert>
                            )}
                            {cardTypes[cardTypes.length - 1] === CardType.Gift && (
                                <Alert severity="warning">{t('routes.office.main.card.alert.gift')}</Alert>
                            )}
                        </Box>
                    </Box>

                    <Box
                        className={styles.transactions}
                        sx={{
                            width: '100%',
                        }}
                    >
                        <OfficeTitle text={t('routes.office.main.card.transactions')} color="#ffc834" />
                        {currentCard && cardTypes[cardTypes.length - 1] === CardType.Loyalty && (
                            <OfficeMainCardTransactionsList card={currentCard} type={CardType.Loyalty} />
                        )}
                        {currentCard && cardTypes[cardTypes.length - 1] === CardType.Gift && (
                            <OfficeMainCardTransactionsList card={currentCard} type={CardType.Gift} />
                        )}
                    </Box>
                </Box>
            </Box>
        </MainOfficePage>
    );
}
