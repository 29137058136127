import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { NativeKnightEventEmitter, NativeKnightEvents } from '../../..';
import { AuthContext } from '../../../contexts/auth/context';
import { AuthContextType } from '../../../contexts/auth/types';
import { NativeKnight } from '../../../native-knight';

export default function Office() {
    // Get Context
    const authContext: AuthContextType = useContext(AuthContext);

    // Translations
    const { t } = useTranslation();

    // Action App become active
    const onNativeOperative_Event_AppBecomeActive = async (): Promise<any> => {
        // Log
        console.debug('onNativeOperative_Event_AppBecomeActive');

        // Refresh me
        authContext.refreshMe();

        // Get Bottom navigation touch ripples
        const elements = document.getElementsByClassName('MuiTouchRipple-root');
        for (let i = 0; i < elements.length; i++) {
            elements[i].innerHTML = '';
        }
    };

    // Action: New notification
    const onNativeOperative_Event_NewNotification = async (notification: string): Promise<any> => {
        // Log
        console.debug('onNativeOperative_Event_NewNotification ' + authContext.state.me?.unreadNotificationsCount);

        // Update unread notifications count
        authContext.updateUnreadNotificationsCount((authContext.state.me?.unreadNotificationsCount ?? 0) + 1);

        // Show notification
        NativeKnight.instance.showNotification(notification);
    };

    // Component mount
    useEffect(() => {
        const bootstrapAsync = async () => {
            // If a user is logged in
            if (authContext.state.firebaseUser) {
                // Check Notifications Permission
                authContext.checkNotificationsPermission();

                // Update Badge Number
                NativeKnight.instance.setBadgeNumber(authContext.state.me?.unreadNotificationsCount ?? 0);
            }
        };

        // Next
        setTimeout(() => {
            bootstrapAsync();
        }, 500);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Component mount
    useEffect(() => {
        // If a user is logged in
        if (authContext.state.firebaseUser) {
            // Subscribe events
            NativeKnightEventEmitter.on(
                NativeKnightEvents.EVENT_APP_BECOME_ACTIVE,
                onNativeOperative_Event_AppBecomeActive,
            );
            NativeKnightEventEmitter.on(
                NativeKnightEvents.EVENT_NEW_NOTIFICATION,
                onNativeOperative_Event_NewNotification,
            );
        }

        return () => {
            // Unsubscribe events
            NativeKnightEventEmitter.off(
                NativeKnightEvents.EVENT_NEW_NOTIFICATION,
                onNativeOperative_Event_NewNotification,
            );
            NativeKnightEventEmitter.off(
                NativeKnightEvents.EVENT_APP_BECOME_ACTIVE,
                onNativeOperative_Event_AppBecomeActive,
            );
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authContext.state.me]);

    // User is logged in, show office
    return (
        <>
            {/* Navigation Outlet */}
            <Outlet />

            {/* Notifications Explain Dialog */}
            <Dialog
                open={authContext.state.notificationsExplainDialogOpen}
                onClose={() => {}}
                aria-labelledby="alert-dialog-notifications-explain-title"
                aria-describedby="alert-dialog-notifications-explain-description"
                disableEscapeKeyDown={true}
            >
                <DialogTitle fontWeight="bold" id="alert-dialog-notifications-explain-title">
                    {t('routes.office.notificationsExplainDialog.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-notifications-explain-description">
                        {t('routes.office.notificationsExplainDialog.description')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            // Inform user decision
                            authContext.notificationsExplainedDecision(false);
                        }}
                    >
                        {t('routes.office.notificationsExplainDialog.noThanks')}
                    </Button>
                    <Button
                        onClick={() => {
                            // Inform user decision
                            authContext.notificationsExplainedDecision(true);
                        }}
                        variant="contained"
                        autoFocus
                    >
                        {t('routes.office.notificationsExplainDialog.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
