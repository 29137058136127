import { AxiosResponse } from 'axios';
import { Me } from '../interfaces/me';
import { BackendApiManager } from '../managers/backend-api';
import { Logger } from '../utils/logger';

export class MeRepository {
    // Singleton Instance
    private static _instance: MeRepository;

    // Private
    private logger: Logger;

    static get instance(): MeRepository {
        if (!this._instance) {
            this._instance = new MeRepository();
        }
        return this._instance;
    }

    constructor() {
        // Create Logger
        this.logger = new Logger('MeRepository');
    }

    // Get me user session
    public async get(): Promise<Me> {
        // Get User from Backend
        const result: AxiosResponse<Me> = await BackendApiManager.instance.get<Me>('/me');

        // Done
        return result.data;
    }
}
