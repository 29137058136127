import { Theme } from '@mui/material';
import { DeviceOrientation } from '../../enums/device-orientation';
import { OperativeSystem } from '../../enums/operative-system';
import { ThemeMode } from '../../enums/theme-mode';
import { ThemeOption } from '../../enums/theme-option';
import { Provider } from '../../interfaces/provider';
import { ProviderService } from '../../interfaces/provider-service';
import { SafeAreaInsets } from '../../interfaces/safe-area-insets';
import { Service } from '../../interfaces/service';

export interface UserLocation {
    cityId: string;
    name: string;
    administratorCityName?: string;
    isPrecise: boolean;
    coordinates: [number, number];
}

export interface AppContextState {
    sessionWasRestored: boolean;
    appIsReady: boolean;
    operativeSystem: OperativeSystem;
    themeOption: ThemeOption | undefined;
    themeMode: ThemeMode | undefined;
    theme: Theme | undefined;
    insets: SafeAreaInsets;
    orientation: DeviceOrientation | undefined;
    nativeKnightApiVersion: number | undefined;
    sessionId: string | undefined;
    sessionStartedAt: Date | undefined;
    appVersion: string | undefined;
    appBuild: string | undefined;
    userLocation: UserLocation;

    // Schedule Appointment state package
    services: Array<Service>;
    appointment:
        | {
              provider: Provider;
              providerServices: Array<ProviderService>;
              startTime: moment.Moment;
          }
        | undefined;
}

export enum AppContextActionType {
    APP_READY = 'APP_READY',
    SET_LOCATION = 'SET_LOCATION',
    SET_SERVICES = 'SET_SERVICES',
    SET_APPOINTMENT = 'SET_PROVIDER',

    // Native Knight Events
    EVENT_THEME_MODE_CHANGED = 'EVENT_THEME_MODE_CHANGED',
    EVENT_ORIENTATION_CHANGED = 'EVENT_ORIENTATION_CHANGED',
}

export type AppContextAction =
    | {
          type: AppContextActionType.APP_READY;
          appIsReady: boolean;
          orientation: DeviceOrientation;
          insets: SafeAreaInsets;
          userLocation: UserLocation;
          services: Array<Service>;
      }
    | {
          type: AppContextActionType.SET_LOCATION;
          userLocation: UserLocation;
      }
    | {
          type: AppContextActionType.SET_SERVICES;
          services: Array<Service>;
      }
    | {
          type: AppContextActionType.SET_APPOINTMENT;
          appointment:
              | {
                    provider: Provider;
                    providerServices: Array<ProviderService>;
                    startTime: moment.Moment;
                }
              | undefined;
      }
    | {
          type: AppContextActionType.EVENT_THEME_MODE_CHANGED;
          themeOption: ThemeOption | undefined;
          themeMode: ThemeMode | undefined;
          theme: Theme | undefined;
      }
    | {
          type: AppContextActionType.EVENT_ORIENTATION_CHANGED;
          orientation: DeviceOrientation;
          insets: SafeAreaInsets;
      };

export interface AppContextType {
    state: AppContextState;

    // Methods
    init: () => void;
    changeThemeMode: (mode: ThemeMode) => void;
    setAppAsReady: (
        orientation: DeviceOrientation | null,
        insets: SafeAreaInsets | null,
        userLocation: UserLocation,
        services: Array<Service>,
    ) => void;
    getDefaultSidePaddingValue(): number;
    getDeviceUniqueId(): string;
    changeOrientation(orientation: DeviceOrientation, insets: SafeAreaInsets): void;
    getLocation(
        preciseLocation: boolean,
        forceUpdate?: boolean,
    ): Promise<
        UserLocation & {
            wasRestored: boolean;
        }
    >;
    setLocation(location: UserLocation): void;
    getPreciseLocation(): Promise<UserLocation | null>;
    getServices(): Array<Service>;
    setServices(services: Array<Service>): void;
    setAppointment(
        appointment:
            | {
                  provider: Provider;
                  providerServices: Array<ProviderService>;
                  startTime: moment.Moment;
              }
            | undefined,
    ): void;
    getDisplayDurationText(durationInMinutes: number): string;
    fixIosEmptyScrollProblem(): void;
}
