export class Logger {
    private prefix: string | null;

    constructor(context?: string) {
        // Init prefix
        this.prefix = context ? `[${context}]` : null;
    }

    public debug(...args: any[]) {
        console.debug(this.prefix, ...args);
    }

    public log(...args: any[]) {
        console.log(this.prefix, ...args);
    }

    public warn(...args: any[]) {
        console.warn(this.prefix, ...args);
    }

    public error(...args: any[]) {
        console.error(this.prefix, ...args);
    }

    public info(...args: any[]) {
        console.info(this.prefix, ...args);
    }
}
