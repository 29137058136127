/* eslint-disable jsx-a11y/alt-text */
import { Box } from '@mui/system';
import { useContext } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { AppContext } from '../../contexts/app/context';
import { AppContextType } from '../../contexts/app/types';
import { OperativeSystem } from '../../enums/operative-system';

export function ImagesStepper({
    className,
    imagesUrls,
    autoPlay,
}: {
    className?: string;
    imagesUrls?: Array<string>;
    autoPlay?: boolean;
}) {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);

    // Map images
    let _imagesUrls = imagesUrls ?? [];

    // Shuffle photos
    // _imagesUrls = shuffleArray<string>(_imagesUrls);

    // Build
    return (
        <Carousel
            className={className}
            autoPlay={autoPlay === undefined ? true : autoPlay}
            infiniteLoop
            labels={{
                leftArrow: '',
                rightArrow: '',
                item: '',
            }}
            showStatus={false}
            showThumbs={false}
            interval={5000}
            transitionTime={500}
            showArrows={appContext.state.operativeSystem === OperativeSystem._Unknown}
            preventMovementUntilSwipeScrollTolerance={true}
            showIndicators={imagesUrls && imagesUrls?.length > 1}
        >
            {_imagesUrls.map((url, index) => {
                return (
                    <Box
                        key={index}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={url}
                            style={{
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    </Box>
                );
            })}
        </Carousel>
    );
}
