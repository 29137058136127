import { parsePhoneNumber, PhoneNumber } from 'libphonenumber-js/max';

export class SanitizationManager {
    // Singleton Instance
    private static _instance: SanitizationManager;

    // Private constructor
    private constructor() {}

    static get instance(): SanitizationManager {
        if (!this._instance) {
            this._instance = new SanitizationManager();
        }

        return this._instance;
    }

    /**
     * Formats a given Phone Number
     * @param phoneNumber Phone Number to format
     * @returns Formatted Phone Number or null if invalid
     */
    public formatPhoneNumber(phoneNumber: string): string | null {
        try {
            // Parse it
            const parsedPhoneNumber: PhoneNumber | undefined = parsePhoneNumber(phoneNumber);

            // Validate it
            if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
                return parsedPhoneNumber.format('INTERNATIONAL');
            }

            return null;
        } catch (error) {
            // Failed to parse
            return null;
        }
    }

    public textToTitleCase(text: string) {
        // Init result
        let result = '';

        // Split into words
        const words = text.split(' ');

        // For each word
        for (const word of words) {
            // Add to result
            result += `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()} `;
        }

        // Return result
        return result.trim();
    }
}
