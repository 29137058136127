import { useContext } from 'react';
import { AppContext } from '../../contexts/app/context';
import { AppContextType } from '../../contexts/app/types';
import { ThemeMode } from '../../enums/theme-mode';

export default function Logo({ themeMode }: { themeMode?: ThemeMode }) {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);

    // Build
    return (
        <>
            {(themeMode ?? appContext.state.themeMode) === ThemeMode.Light && (
                <img src="/images/logo-light.svg" alt="logo" width="100%" />
            )}
            {(themeMode ?? appContext.state.themeMode) === ThemeMode.Dark && (
                <img src="/images/logo-dark.svg" alt="logo" width="100%" />
            )}
        </>
    );
}
