import EventIcon from '@mui/icons-material/Event';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { Alert, Avatar, Box, Chip, Grow, IconButton, Paper, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Container } from '../../../../components/container/container';
import { EmptyState } from '../../../../components/empty-state/empty-state';
import { ErrorState } from '../../../../components/error-state/error-state';
import { Loader } from '../../../../components/loader/loader';
import { Page } from '../../../../components/page/page';
import { TimeTraveller } from '../../../../components/time-traveller/time-traveller';
import { InvoicingPerProviderStatistics } from '../../../../interfaces/invoicing-per-provider';
import { Provider } from '../../../../interfaces/provider';
import { StatisticsRepository } from '../../../../repositories/statistics';
import { RoutesNames } from '../../../../routes-names';
import { emptyId } from '../../../../utils/ts-utils';

export function OfficeSystemAdministratorInvoicing() {
    // Translations
    const { t } = useTranslation();

    // Get navigation
    const navigate = useNavigate();

    // Get theme
    const theme = useTheme();

    // Get query
    const query = new URLSearchParams(window.location.search);
    const queryValues = {
        month: query.get('month'),
    };

    // Hold state
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [month, setMonth] = useState<moment.Moment>(
        queryValues.month ? moment(queryValues.month).startOf('month') : moment().startOf('month'),
    );
    const [error, setError] = useState<boolean>(false);
    const [notAvailableYet, setNotAvailableYet] = useState<boolean>(false);
    const [cache, setCache] = useState<Map<string, InvoicingPerProviderStatistics>>(
        new Map<string, InvoicingPerProviderStatistics>(),
    );
    const [results, setResults] = useState<InvoicingPerProviderStatistics | null>(null);

    // Action: Reset all
    const load = async () => {
        // Reset state
        setError(false);
        setResults(null);
        setNotAvailableYet(false);

        // Eval date
        if (
            // Next month
            month.isAfter(moment().endOf('month'))
        ) {
            // Set not available yet
            setNotAvailableYet(true);
        } else {
            // Look in cache
            const cached = cache.get(moment(month).startOf('month').toISOString());

            // Eval
            if (cached) {
                // Set
                setResults(cached);

                // Done
                return;
            } else {
                // Start loading
                setIsLoading(true);

                try {
                    // Get invoices
                    const results = await StatisticsRepository.instance.getInvoicingPerProvider(
                        moment(month).startOf('month').toDate(),
                        moment(month).endOf('month').toDate(),
                    );

                    // Set
                    setResults(results);

                    // Set cache
                    setCache(cache.set(moment(month).startOf('month').toISOString(), results));

                    // Stop loading
                    setIsLoading(false);
                } catch (error) {
                    // Set error
                    setError(true);
                }
            }
        }
    };

    // On Month change
    useEffect(() => {
        // Print
        if (month) {
            // Load
            load();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [month, cache]);

    // Calculate totals
    let totalInvoiced = 0;
    let providersEarningsTotal = 0;
    if (results) {
        for (const line of results.lines) {
            // Add to total
            totalInvoiced += line.earnedAmount + line.contributedAmount;

            // Check for provider
            if (line.provider) {
                // Add provider earnings to total
                providersEarningsTotal += line.earnedAmount;
            }
        }
    }
    let balanceBeforeExpenses = totalInvoiced - providersEarningsTotal;
    let businessExpensesTotal = 724;
    let finalBalance = balanceBeforeExpenses - businessExpensesTotal;

    // Build
    return (
        <>
            <Page
                title={t('routes.office.main.home.professional.invoicing.title')}
                onRefresh={async () => {
                    // Clone cache
                    const cacheClone = new Map(cache);

                    // Reset cache
                    cacheClone.delete(moment(month).startOf('month').toISOString());

                    // Update cache
                    setCache(cacheClone);

                    // Reset state
                    load();
                }}
                actions={[
                    <IconButton
                        aria-label="schedule"
                        onClick={() => {
                            // Replace state
                            navigate(`${window.location.pathname}?month=${month.format('YYYY-MM-DD')}`, {
                                replace: true,
                            });

                            // Go to Schedule
                            navigate(
                                `../${RoutesNames.Office_SystemAdministrator_Schedule}?day=${month.format(
                                    'YYYY-MM-DD',
                                )}`,
                            );
                        }}
                    >
                        <EventIcon />
                    </IconButton>,
                ]}
            >
                <Container>
                    {isLoading && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '32px',
                            }}
                        >
                            <Loader size={48} />
                        </Box>
                    )}
                    {!isLoading && !error && (
                        <>
                            {notAvailableYet && (
                                <Alert severity="info">
                                    {t('routes.office.systemAdministator.invoicing.notAvailableYet')} ⏳
                                </Alert>
                            )}
                            {results && results.lines.length === 0 && (
                                <EmptyState
                                    height={70}
                                    icon={<PointOfSaleIcon />}
                                    title={t('routes.office.systemAdministator.invoicing.emptyTitle')}
                                    description={t('routes.office.systemAdministator.invoicing.emptyDescription')}
                                />
                            )}
                            {results &&
                                results.lines.length > 0 &&
                                results.lines.map(
                                    (line: {
                                        provider?: Provider;
                                        earnedAmount: number;
                                        contributedAmount: number;
                                    }) => {
                                        return (
                                            <Grow in={true} mountOnEnter unmountOnExit>
                                                <Paper
                                                    onClick={() => {
                                                        // Replace state
                                                        navigate(
                                                            `${window.location.pathname}?month=${month.format(
                                                                'YYYY-MM-DD',
                                                            )}`,
                                                            {
                                                                replace: true,
                                                            },
                                                        );

                                                        // Target first day of month
                                                        let targetMoment = month.format('YYYY-MM-DD');
                                                        if (month.isSame(moment(), 'month')) {
                                                            // Point to today
                                                            targetMoment = moment().format('YYYY-MM-DD');
                                                        }

                                                        // Go to Invoicing
                                                        navigate(
                                                            `../../${RoutesNames.Office_Professional}/${
                                                                line.provider?.id ?? emptyId()
                                                            }/${
                                                                RoutesNames.Office_Professional_Invoicing
                                                            }/?day=${targetMoment}`,
                                                        );
                                                    }}
                                                    key={`provider-${line.provider ? line.provider.id : 'unknown'}`}
                                                    sx={{
                                                        paddingLeft: '16px',
                                                        paddingRight: '16px',
                                                        paddingTop: '16px',
                                                        marginBottom: '16px',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                flex: 1,
                                                            }}
                                                        >
                                                            {line.provider && (
                                                                <Chip
                                                                    size="medium"
                                                                    avatar={
                                                                        <Avatar
                                                                            alt={line.provider.name}
                                                                            src={line.provider.pictureUrl}
                                                                        />
                                                                    }
                                                                    label={line.provider.name}
                                                                    variant="outlined"
                                                                />
                                                            )}
                                                            {!line.provider && (
                                                                <Typography variant="body2">
                                                                    (
                                                                    {t(
                                                                        'routes.office.systemAdministator.invoicing.noProvider',
                                                                    )}
                                                                    )
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                        {/* <Box>
                                                            {line.provider &&
                                                                (line.provider.percentageEarned ||
                                                                    line.provider.percentageEarned === 0) && (
                                                                    <Typography variant="body2">
                                                                        {line.provider.percentageEarned}%
                                                                    </Typography>
                                                                )}
                                                        </Box> */}
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            marginTop: '8px',
                                                            marginBottom: '8px',
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width: '50%',
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="body2"
                                                                color={theme.palette.error.main}
                                                                fontWeight={'bold'}
                                                            >
                                                                {t('routes.office.systemAdministator.invoicing.earned')}
                                                            </Typography>
                                                            <Typography variant="subtitle1">
                                                                {line.earnedAmount.toFixed(2)}€
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                width: '50%',
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="body2"
                                                                color={theme.palette.success.main}
                                                                fontWeight={'bold'}
                                                            >
                                                                {t(
                                                                    'routes.office.systemAdministator.invoicing.contributed',
                                                                )}
                                                            </Typography>
                                                            <Typography variant="subtitle1">
                                                                {line.contributedAmount.toFixed(2)}€
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Paper>
                                            </Grow>
                                        );
                                    },
                                )}
                            {results && results.lines.length > 0 && (
                                <>
                                    <Typography
                                        variant="h5"
                                        textAlign="center"
                                        sx={{
                                            marginTop: '32px',
                                        }}
                                    >
                                        {t('routes.office.systemAdministator.invoicing.monthTotal')}
                                    </Typography>
                                    <Box
                                        sx={{
                                            marginTop: '16px',
                                            table: {
                                                width: '100%',
                                                'td:last-child': {
                                                    textAlign: 'right',
                                                },
                                                '.balance td': {
                                                    paddingTop: '8px',
                                                },
                                                '.balance td:last-child': {
                                                    borderTop: `1px solid ${theme.palette.text.primary}`,
                                                },
                                            },
                                        }}
                                    >
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Typography variant="body1" color={theme.palette.success.main}>
                                                            {t(
                                                                'routes.office.systemAdministator.invoicing.totalInvoiced',
                                                            )}
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography variant="body1" color={theme.palette.success.main}>
                                                            + {totalInvoiced.toFixed(2)}€
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Typography variant="body1" color={theme.palette.error.main}>
                                                            {t(
                                                                'routes.office.systemAdministator.invoicing.providersEarningsTotal',
                                                            )}
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography variant="body1" color={theme.palette.error.main}>
                                                            - {providersEarningsTotal.toFixed(2)}€
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                <tr className="balance">
                                                    <td>
                                                        <Typography
                                                            variant="body1"
                                                            color={
                                                                balanceBeforeExpenses > 0
                                                                    ? theme.palette.success.main
                                                                    : theme.palette.error.main
                                                            }
                                                            fontWeight={'bold'}
                                                        >
                                                            {t(
                                                                'routes.office.systemAdministator.invoicing.balanceBeforeExpenses',
                                                            )}
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography
                                                            variant="body1"
                                                            color={
                                                                balanceBeforeExpenses > 0
                                                                    ? theme.palette.success.main
                                                                    : theme.palette.error.main
                                                            }
                                                        >
                                                            {balanceBeforeExpenses.toFixed(2)}€
                                                        </Typography>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Box>
                                    <Box
                                        sx={{
                                            marginTop: '16px',
                                            table: {
                                                width: '100%',
                                                'td:last-child': {
                                                    textAlign: 'right',
                                                },
                                                '.balance td': {
                                                    paddingTop: '8px',
                                                },
                                                '.balance td:last-child': {
                                                    borderTop: `1px solid ${theme.palette.text.primary}`,
                                                },
                                            },
                                        }}
                                    >
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Typography variant="body1" color={theme.palette.error.main}>
                                                            {t(
                                                                'routes.office.systemAdministator.invoicing.businessExpensesTotal',
                                                            )}
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography variant="body1" color={theme.palette.error.main}>
                                                            - {businessExpensesTotal.toFixed(2)}€
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                <tr className="balance">
                                                    <td>
                                                        <Typography
                                                            variant="body1"
                                                            color={
                                                                finalBalance > 0
                                                                    ? theme.palette.success.main
                                                                    : theme.palette.error.main
                                                            }
                                                            fontWeight={'bold'}
                                                        >
                                                            {t(
                                                                'routes.office.systemAdministator.invoicing.finalBalance',
                                                            )}
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography
                                                            variant="body1"
                                                            color={
                                                                finalBalance > 0
                                                                    ? theme.palette.success.main
                                                                    : theme.palette.error.main
                                                            }
                                                        >
                                                            {finalBalance.toFixed(2)}€
                                                        </Typography>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Box>
                                    {finalBalance >= 0 && (
                                        <Box
                                            sx={{
                                                marginTop: '32px',
                                            }}
                                        >
                                            <Typography
                                                variant="h4"
                                                textAlign="center"
                                                fontWeight="bold"
                                                sx={{
                                                    marginTop: '16px',
                                                }}
                                            >
                                                {`🎉 ${finalBalance.toFixed(2)}€ 💰`}
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                textAlign="center"
                                                sx={{
                                                    marginTop: '16px',
                                                }}
                                            >
                                                {`💪 ${t('routes.office.systemAdministator.invoicing.goodJob')} 🙏`}
                                            </Typography>
                                        </Box>
                                    )}
                                    {finalBalance < 0 && (
                                        <Box
                                            sx={{
                                                marginTop: '32px',
                                            }}
                                        >
                                            <Typography
                                                variant="h4"
                                                textAlign="center"
                                                fontWeight="bold"
                                                sx={{
                                                    marginTop: '16px',
                                                }}
                                            >
                                                {`👷‍♀️ ${finalBalance.toFixed(2)}€ 💰`}
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                textAlign="center"
                                                sx={{
                                                    marginTop: '16px',
                                                }}
                                            >
                                                {`💪 ${t(
                                                    'routes.office.systemAdministator.invoicing.isGonnaBeAllRight',
                                                )} 🙏`}
                                            </Typography>
                                        </Box>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    {!isLoading && error && (
                        <ErrorState
                            icon={<PointOfSaleIcon />}
                            onTryAgainClick={() => {
                                // Reset state
                                setError(false);
                            }}
                        />
                    )}
                    <Box
                        sx={{
                            height: '160px',
                        }}
                    ></Box>
                </Container>
            </Page>
            <TimeTraveller
                value={month}
                onChange={(value: moment.Moment) => {
                    setMonth(value);
                }}
                disabled={isLoading}
                mode="month"
            />
        </>
    );
}
