import { Typography } from '@mui/material';
import variables from '../../variables.module.scss';

export function OfficeTitle({
    text,
    color,
    align,
}: {
    text: string;
    color?: string;
    align?: 'left' | 'right' | 'center';
}) {
    // Render
    return (
        <Typography
            variant="h5"
            sx={{
                marginBottom: '16px',
                textAlign: align ?? 'right',
                color: color ?? variables.firstColor,
                fontWeight: 500,
            }}
        >
            {text}
        </Typography>
    );
}
