import { Box, CssBaseline, useTheme } from '@mui/material';
import { LocalizationProvider, PickersLocaleText } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { NativeKnightEventEmitter, NativeKnightEvents } from '..';
import { AppContext } from '../contexts/app/context';
import { AppContextType, UserLocation } from '../contexts/app/types';
import { AuthContext } from '../contexts/auth/context';
import { AuthContextType } from '../contexts/auth/types';
import { DeviceOrientation } from '../enums/device-orientation';
import { MomentLanguageCode } from '../enums/moment-language-code';
import { ThemeMode } from '../enums/theme-mode';
import { getInitAppLanguage } from '../i18n';
import { AppLanguageTag } from '../i18n/types';
import { ptPTPickersLocaleText } from '../i18n/_pt_PT';
import { SafeAreaInsets } from '../interfaces/safe-area-insets';
import { Service } from '../interfaces/service';
import { NativeKnight } from '../native-knight';
import { Logger } from '../utils/logger';
import { Loader } from './loader/loader';

// Logger
const logger: Logger = new Logger('App');

export default function App() {
    // Get Contexts
    const appContext: AppContextType = useContext(AppContext);
    const authContext: AuthContextType = useContext(AuthContext);

    // Get theme
    const theme = useTheme();

    // Hold state
    const [languageInfo, setLanguageInfo] = useState<{
        appLanguage: AppLanguageTag;
        momentLanguage: MomentLanguageCode;
    } | null>(null);

    // -------------------------------------------------- //
    // Native OS posted a message to the web app

    // Theme mode change
    const onNativeOperativeSystem_Event_ThemeModeChanged = async (mode: ThemeMode): Promise<any> => {
        // Log
        logger.debug('onNativeOperativeSystem_Event_ThemeModeChanged', mode);

        // Dispatch change
        appContext.changeThemeMode(mode);
    };

    // Orientation change
    const onNativeOperative_Event_OrientationChanged = async (orientation: DeviceOrientation): Promise<any> => {
        // Log
        logger.debug('onNativeOperative_Event_OrientationChanged', orientation);

        // Let the change take place
        setTimeout(async () => {
            // Request safe area insets
            const insets = await NativeKnight.instance.getSafeAreaInsets();
            if (!insets) {
                // Log
                logger.error('onNativeOperative_Event_OrientationChanged: Error requesting safe area insets');
            }

            // Dispatch change
            appContext.changeOrientation(orientation, insets ?? appContext.state.insets);

            // MAYBE SHOULD WAIT A BIT MORE
        }, 0);
    };

    // -------------------------------------------------- //

    // Component mount
    useEffect(() => {
        // Subscribe to events
        NativeKnightEventEmitter.on(
            NativeKnightEvents.EVENT_THEME_MODE_CHANGED,
            onNativeOperativeSystem_Event_ThemeModeChanged,
        );
        NativeKnightEventEmitter.on(
            NativeKnightEvents.EVENT_ORIENTATION_CHANGED,
            onNativeOperative_Event_OrientationChanged,
        );

        const bootstrapAsync = async () => {
            const values: [
                void,
                DeviceOrientation | null,
                SafeAreaInsets | null,
                UserLocation & {
                    wasRestored: boolean;
                },
                Array<Service>,
                {
                    appLanguage: AppLanguageTag;
                    momentLanguage: MomentLanguageCode;
                },
            ] = await Promise.all([
                // 0: Try to restore previous session
                authContext.restorePreviousSession(),

                // 1: Get Orientation
                NativeKnight.instance.getOrientation(),

                // 2: Get Safe Area Insets
                NativeKnight.instance.getSafeAreaInsets(),

                // 3: Get Location
                appContext.getLocation(true),

                // 4: Get Service
                appContext.getServices(),

                // 5: Get Language
                getInitAppLanguage(),
            ]);

            // App is ready
            appContext.setAppAsReady(values[1], values[2], values[3], values[4]);

            // Get Precise Location
            setTimeout(async () => {
                // Check if previous location was precise
                if (values[3].isPrecise && values[3].wasRestored) {
                    // It was, so let's update it
                    const preciseLocation = await appContext.getPreciseLocation();

                    // If we got a precise location
                    if (preciseLocation) {
                        // Set
                        appContext.setLocation(preciseLocation);
                    }
                }
            }, 100);

            // Set language
            setLanguageInfo(values[5]);
        };

        // Next
        bootstrapAsync();

        // Component unmount
        return () => {
            // Unsubscribe from events
            NativeKnightEventEmitter.off(
                NativeKnightEvents.EVENT_THEME_MODE_CHANGED,
                onNativeOperativeSystem_Event_ThemeModeChanged,
            );
            NativeKnightEventEmitter.off(
                NativeKnightEvents.EVENT_ORIENTATION_CHANGED,
                onNativeOperative_Event_OrientationChanged,
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Define locale text
    let localeText: Partial<PickersLocaleText<moment.Moment>> | undefined = undefined;
    switch (languageInfo?.appLanguage) {
        case AppLanguageTag.ptPT:
            localeText = ptPTPickersLocaleText;
            break;
        // English is the default
    }

    // Build
    return (
        <LocalizationProvider dateAdapter={AdapterMoment} localeText={localeText}>
            {/* Material UI Basic CSS */}
            <CssBaseline />

            {/* Main Router */}
            {!authContext.state.isRestoringSession && appContext.state.appIsReady && <Outlet />}

            {/* Splash screen */}
            {(authContext.state.isRestoringSession || !appContext.state.appIsReady) && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                        backgroundColor: theme.palette.background.default,
                    }}
                >
                    <Loader size={80} />
                </Box>
            )}
        </LocalizationProvider>
    );
}
