export enum ThemeOption {
    /**
     * Light
     */
    Light = 'light',

    /**
     * Dark
     */
    Dark = 'dark',

    /**
     * System theme (auto)
     */
    System = 'system',
}
