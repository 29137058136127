import EventIcon from '@mui/icons-material/Event';
import { Box } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '../../../../components/container/container';
import { ErrorState } from '../../../../components/error-state/error-state';
import { Loader } from '../../../../components/loader/loader';
import { Page } from '../../../../components/page/page';
import { ScheduleScreen } from '../../../../components/schedule-screen/schedule-screen';
import { AuthContext } from '../../../../contexts/auth/context';
import { AuthContextType } from '../../../../contexts/auth/types';
import { Provider } from '../../../../interfaces/provider';
import { ProvidersRepository } from '../../../../repositories/providers';
import { emptyId } from '../../../../utils/ts-utils';

export function OfficeProfessionalSchedule() {
    // Get Context
    const authContext: AuthContextType = useContext(AuthContext);

    // Get params
    let { providerId } = useParams();

    // Hold state
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [provider, setProvider] = useState<Provider | null>(null);

    // Actions: Load
    const load = async () => {
        // Evaluate
        if (providerId && authContext.state.me?.provider?.id?.toString() === providerId) {
            // Set
            setProvider(authContext.state.me?.provider);

            // Stop loading
            setIsLoading(false);
        } else if (providerId && providerId === emptyId()) {
            // Set
            setProvider({
                // Id
                id: emptyId(),
                // Required
                name: '',
                slug: '',
            });

            // Stop loading
            setIsLoading(false);
        } else if (providerId) {
            // Get Provider
            (async () => {
                try {
                    // Start loading
                    setIsLoading(true);

                    // Get Provider
                    const data = await ProvidersRepository.instance.getProviderById(providerId);

                    // Set
                    setProvider(data);
                } catch (e) {
                    // Log error
                    console.log(e);

                    // Set error
                    setError(true);
                } finally {
                    // Stop loading
                    setIsLoading(false);
                }
            })();
        }
    };

    // On mount
    useEffect(() => {
        // Get
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Evaluate
    if (!provider) {
        return (
            <Page
                title={''}
                onRefresh={async () => {
                    // Load again
                    load();
                }}
                actions={[]}
            >
                <Container>
                    {isLoading && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '32px',
                            }}
                        >
                            <Loader size={48} />
                        </Box>
                    )}
                    {!isLoading && error && (
                        <ErrorState
                            icon={<EventIcon />}
                            onTryAgainClick={() => {
                                // Reset state
                                setError(false);

                                // Load again
                                load();
                            }}
                        />
                    )}
                </Container>
            </Page>
        );
    } else {
        // Build
        return <ScheduleScreen provider={provider} />;
    }
}
