export enum DeviceOrientation {
    /**
     * Portrait
     */
    Portrait = 'portrait',

    /**
     * Portrait Upside Down
     */
    PortraitUpsideDown = 'portrait-upside-down',

    /**
     * Landscape Left
     */
    LandscapeLeft = 'landscape-left',

    /**
     * Landscape Right
     */
    LandscapeRight = 'landscape-right',
}
