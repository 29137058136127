import { createTheme, Theme } from '@mui/material';

export const lightTheme: Theme = createTheme({
    typography: {
        fontFamily: ['Quicksand', 'sans-serif'].join(','),
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#ff66c4',
            contrastText: '#ffffff',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '20px',
                    fontWeight: 'bold',
                },
            },
        },
    },
});
