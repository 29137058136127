import CardMembershipIcon from '@mui/icons-material/CardMembership';
import EventIcon from '@mui/icons-material/Event';
import HomeIcon from '@mui/icons-material/Home';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { BottomNavigation, BottomNavigationAction, Grow, Paper } from '@mui/material';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Loader } from '../../../../components/loader/loader';
import { AppContext } from '../../../../contexts/app/context';
import { AppContextType } from '../../../../contexts/app/types';
import { AuthContext } from '../../../../contexts/auth/context';
import { AuthContextType } from '../../../../contexts/auth/types';
import { RoutesNames } from '../../../../routes-names';
import styles from './main.module.scss';

enum OfficeMainTabs {
    Home = 0,
    Professionals = 1,
    Bookings = 2,
    Card = 3,
    More = 4,
    //
    // Academy = 4,
}

export default function OfficeMain() {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);
    const authContext: AuthContextType = useContext(AuthContext);

    // Translations
    const { t } = useTranslation();

    // Navigation
    const navigate = useNavigate();

    // Location
    const location = useLocation();

    // State
    const [selectedTabValue, setSelectedTabValue] = useState(-1);
    const [, setScrollY] = useState<number>(0);
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    // Actions
    const handleScroll = (event: any) => {
        // Update scrollY
        setScrollY(window.scrollY);
    };

    const handleResize = (event: any) => {
        // Update windowWidth
        setTimeout(() => {
            setWindowWidth(window.innerWidth);

            // MAYBE SHOULD WAIT A BIT MORE
        }, 100);
    };

    const updateSelectedTabValue = (path: string | undefined) => {
        // Set selected tab
        switch (path) {
            case RoutesNames.Office_Main_Home:
                setSelectedTabValue(OfficeMainTabs.Home);
                break;
            case RoutesNames.Office_Main_Professionals:
                setSelectedTabValue(OfficeMainTabs.Professionals);
                break;
            case RoutesNames.Office_Main_Bookings:
                setSelectedTabValue(OfficeMainTabs.Bookings);
                break;
            case RoutesNames.Office_Main_Card:
                setSelectedTabValue(OfficeMainTabs.Card);
                break;
            case RoutesNames.Office_Main_More:
                setSelectedTabValue(OfficeMainTabs.More);
                break;
        }
    };

    // On mount
    useEffect(() => {
        // Listen for events
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        // On unmount
        return () => {
            // Remove event listeners
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // On location change
    useEffect(() => {
        // Evaluate change
        const parts = location.pathname.split('/');

        // Check if is something to handle
        if (parts[1] === 'office' && parts[2] === 'main') {
            // Update selected tab
            updateSelectedTabValue(parts[3]);
        }
    }, [location]);

    // Define tabs
    const tabs: Array<{
        className: string;
        officeMainTab: OfficeMainTabs;
        label: string;
        icon: ReactNode;
    }> = [
        {
            className: styles.officeMainHome_Home,
            officeMainTab: OfficeMainTabs.Home,
            label: t('routes.office.main.bottomNavigation.home'),
            icon: <HomeIcon />,
        },
        {
            className: styles.officeMainHome_Professionals,
            officeMainTab: OfficeMainTabs.Professionals,
            label: t('routes.office.main.bottomNavigation.professionals'),
            icon: <VolunteerActivismIcon />,
        },
        {
            className: styles.officeMainHome_Bookings,
            officeMainTab: OfficeMainTabs.Bookings,
            label: t('routes.office.main.bottomNavigation.bookings'),
            icon: <EventIcon />,
        },
        {
            className: styles.officeMainHome_Card,
            officeMainTab: OfficeMainTabs.Card,
            label: t('routes.office.main.bottomNavigation.card'),
            icon: <CardMembershipIcon />,
        },
        {
            className: styles.officeMainHome_More,
            officeMainTab: OfficeMainTabs.More,
            label: t('routes.office.main.bottomNavigation.more'),
            icon: <MoreHorizIcon />,
        },
        // {
        //     className: styles.officeMainHome_Academy,
        //     officeMainTab: OfficeMainTabs.Academy,
        //     label: t('routes.office.main.bottomNavigation.academy'),
        //     icon: <SchoolIcon />,
        // }
    ];

    // Build
    return (
        <>
            {/* Outlet */}
            <Outlet />

            {/* Bottom Navigation */}
            <Paper
                elevation={16}
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: `${56 + appContext.state.insets.bottom}px`,
                    zIndex: 10,
                }}
            >
                <BottomNavigation
                    showLabels
                    value={selectedTabValue}
                    onChange={(event, index) => {
                        // Get selected tab
                        const selectedTab = tabs[index];

                        // Set selected tab
                        setSelectedTabValue(selectedTab.officeMainTab);

                        // Navigate
                        switch (selectedTab.officeMainTab) {
                            case OfficeMainTabs.Home:
                                navigate(RoutesNames.Office_Main_Home);
                                break;
                            case OfficeMainTabs.Professionals:
                                navigate(RoutesNames.Office_Main_Professionals);
                                break;
                            case OfficeMainTabs.Bookings:
                                navigate(RoutesNames.Office_Main_Bookings);
                                break;
                            case OfficeMainTabs.Card:
                                navigate(RoutesNames.Office_Main_Card);
                                break;
                            case OfficeMainTabs.More:
                                navigate(RoutesNames.Office_Main_More);
                                break;
                        }
                    }}
                    sx={{
                        width: '100%',
                    }}
                    className={styles.bottomNavigation}
                >
                    {tabs.map((tab, index) => {
                        return (
                            <Grow
                                in={true}
                                mountOnEnter
                                unmountOnExit
                                timeout={(index + 1) * 200}
                                key={tab.officeMainTab}
                            >
                                <BottomNavigationAction
                                    className={`${tab.className} ${
                                        selectedTabValue === tab.officeMainTab && styles.selected
                                    }`}
                                    label={tab.label}
                                    icon={tab.icon}
                                />
                            </Grow>
                        );
                    })}
                </BottomNavigation>
                {appContext.state.insets.bottom > 0 && (
                    <BottomNavigation
                        sx={{
                            width: '100%',
                            height: `${appContext.state.insets.bottom}px`,
                        }}
                    ></BottomNavigation>
                )}

                {/* Me Reloading Loader */}
                {authContext.state.isReloadingMe && (
                    <Paper
                        elevation={10}
                        sx={{
                            position: 'fixed',
                            bottom: `${56 + appContext.state.insets.bottom + 16}px`,
                            width: '70px',
                            height: '60px',
                            left: `${windowWidth / 2 - 70 / 2}px`,
                            zIndex: 10000,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '16px',
                        }}
                    >
                        <Loader size={40} />
                    </Paper>
                )}
            </Paper>
        </>
    );
}
