import { useTranslation } from 'react-i18next';
import { LegalDocumentPage } from '../../components/legal-document-page/legal-document-page';
import { LegalDocuments } from '../../databases/legal-documents';

export default function TermsOfUse() {
    // Translations
    const { t } = useTranslation();

    // Build
    return <LegalDocumentPage title={t('routes.termsOfUse.title')} document={LegalDocuments.TermsOfUse} />;
}
