import Person2Icon from '@mui/icons-material/Person2';
import TodayIcon from '@mui/icons-material/Today';
import { Avatar, Box, Button, Grow, IconButton, Paper, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { BookingState } from '../../enums/booking-state';
import { Booking } from '../../interfaces/booking';
import { User } from '../../interfaces/user';
import { SanitizationManager } from '../../managers/sanitization';
import { BookingsRepository } from '../../repositories/bookings';
import { UsersRepository } from '../../repositories/users';
import { RoutesNames } from '../../routes-names';
import { ErrorState } from '..//error-state/error-state';
import { Loader } from '..//loader/loader';
import { Container } from '../container/container';
import { EmptyState } from '../empty-state/empty-state';
import { Page } from '../page/page';
import { UserSearch } from '../user-search/user-search';
import styles from './users-screen.module.scss';

function UserLine({
    user,
    query,
    todayClientsViewEnabled,
}: {
    user: User;
    query: string;
    todayClientsViewEnabled: boolean;
}) {
    // Translations
    const { t } = useTranslation();

    // Get theme
    const theme = useTheme();

    // Get navigation
    const navigate = useNavigate();

    // Get name
    const userFullName: string | null = UsersRepository.instance.getFullName(user);
    const words = (userFullName ?? '').split(' ');
    const initials = [words[0].charAt(0), words.length > 1 ? words[words.length - 1].charAt(0) : ''].join('').trim();
    const firstAndLastNamesOnly = [words[0], words.length > 1 ? words[words.length - 1] : ''].join(' ').trim();

    // Build
    return (
        <Grow in={true} mountOnEnter unmountOnExit>
            <Box
                className={styles.userLine}
                onClick={() => {
                    // Build url
                    let replaceUrl = `${window.location.pathname}?todayView=${todayClientsViewEnabled}`;

                    // Eval query
                    if (query !== '') {
                        replaceUrl += `&query=${query}`;
                    }

                    // Replace state
                    navigate(replaceUrl, {
                        replace: true,
                    });

                    // Navigate to User Profile
                    navigate(`/${RoutesNames.Office}/${RoutesNames.Office_User}/${user.id}`);
                }}
            >
                <Box className={styles.line1}>
                    <Box className={styles.from}>
                        <Paper
                            elevation={1}
                            sx={{
                                borderRadius: '50%',
                            }}
                        >
                            <Avatar
                                alt={userFullName ?? ''}
                                sx={{
                                    width: 56,
                                    height: 56,
                                    backgroundColor: theme.palette.primary.main,
                                }}
                            >
                                {initials === '' ? null : initials}
                            </Avatar>
                        </Paper>
                    </Box>
                    <Box className={styles.text}>
                        <Box className={styles.title}>
                            {userFullName && (
                                <Typography variant="body1" fontWeight="bold">
                                    {firstAndLastNamesOnly}
                                </Typography>
                            )}
                            {!userFullName && (
                                <Typography variant="body1" fontWeight={500} color="GrayText">
                                    {t('routes.office.professional.users.withoutName')}
                                </Typography>
                            )}
                        </Box>
                        <Box className={styles.body}>
                            <Typography variant="body2">
                                {SanitizationManager.instance.formatPhoneNumber(user.phoneNumber)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Grow>
    );
}

export function UsersScreen() {
    // Get params
    let { providerId } = useParams();

    // Translations
    const { t } = useTranslation();

    // Get query
    const queryString = new URLSearchParams(window.location.search);
    const queryStringValues = {
        todayView: queryString.get('todayView'),
        query: queryString.get('query'),
    };

    // Hold state
    const [error, setError] = useState<boolean>(false);
    const [totalUsersCount, setTotalUsersCount] = useState<number>(-1);
    const [users, setUsers] = useState<Array<User> | null>(null);
    const [todayClientsViewEnabled, setTodayClientsViewEnabled] = useState<boolean>(
        queryStringValues.todayView === 'true' ? true : false,
    );
    const [query, setQuery] = useState<string>(queryStringValues.query ?? '');
    const searchDebouncedValue = useDebounce<string>(query, 500);

    // Load users
    const handleLoadMore = async (page: number) => {
        try {
            // Evaluate running mode
            if (todayClientsViewEnabled) {
                // Get bookings
                const day = moment();
                let results: Array<Booking> = [];
                if (providerId) {
                    // results = await BookingsRepository.instance.getProviderBookings(
                    //     providerId?.toString() ?? emptyId(),
                    //     day.startOf('day').toDate(),
                    //     day.endOf('day').toDate(),
                    // );
                } else {
                    results = await BookingsRepository.instance.getUserBookings(
                        day.startOf('day').toDate(),
                        day.endOf('day').toDate(),
                        undefined,
                        undefined,
                        true,
                    );
                }

                // Exclude bookings by state
                results = results.filter((booking: Booking) => {
                    return booking.state === BookingState.Scheduled || booking.state === BookingState.Completed;
                });

                // Get users
                const users: Array<User> = [];
                for (const booking of results) {
                    if (booking.user) {
                        users.push(booking.user);
                    }
                }

                // Exclude duplicates
                const uniqueUsers: Array<User> = [];
                users.forEach((user: User) => {
                    if (uniqueUsers.findIndex((u: User) => u.id === user.id) === -1) {
                        uniqueUsers.push(user);
                    }
                });

                // Update state
                setTotalUsersCount(uniqueUsers.length);
                setUsers(uniqueUsers);
            } else {
                // Get users
                const results: { totalCount: number; data: Array<User> } = await UsersRepository.instance.get(
                    users?.length ?? 0,
                    20,
                    query !== '' ? query : undefined,
                    query !== '' ? query : undefined,
                );

                // Update state
                setTotalUsersCount(results.totalCount);
                setUsers([...(users || []), ...results.data]);
            }
        } catch (error) {
            // Log
            console.error(error);

            // Update state
            setError(true);
        }
    };

    // Action: Reset all
    const resetState = async () => {
        // Reset state
        setError(false);
        setUsers(null);
        setTotalUsersCount(-1);
    };

    // Action: Toggle Today Clients View Enabled
    const toggleTodayClientsViewEnabled = async () => {
        // Update Preference
        // localStorage.setItem(LocalStorageKey.NotificationsUnreadFilter, todayClientsViewEnabled ? 'false' : 'true');

        // Toogle filter
        setTodayClientsViewEnabled(!todayClientsViewEnabled);

        // Reset state
        resetState();
    };

    // Execute Search
    useEffect(() => {
        // Reset state (and fire search by consequence)
        resetState();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchDebouncedValue]);

    // Build
    return (
        <>
            <Page
                title={t('routes.office.professional.users.title')}
                onRefresh={async () => {
                    // Reset state
                    resetState();
                }}
                actions={[
                    <IconButton
                        color={todayClientsViewEnabled ? 'primary' : 'default'}
                        aria-label="filter-unread"
                        onClick={() => {
                            toggleTodayClientsViewEnabled();
                        }}
                    >
                        <TodayIcon />
                    </IconButton>,
                ]}
            >
                <Container>
                    {!error && (
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={handleLoadMore}
                            hasMore={users?.length !== totalUsersCount}
                            loader={
                                <Box
                                    key={`users-loader`}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '32px',
                                    }}
                                >
                                    <Loader size={48} />
                                </Box>
                            }
                        >
                            {users?.length === 0 && todayClientsViewEnabled === false && (
                                <EmptyState
                                    key={`users-empty`}
                                    icon={<Person2Icon />}
                                    title={t('routes.office.professional.users.empty.title')}
                                    description={t('routes.office.professional.users.empty.description')}
                                />
                            )}
                            {users?.length === 0 && todayClientsViewEnabled === true && (
                                <EmptyState
                                    key={`users-empty`}
                                    icon={<TodayIcon />}
                                    title={t('routes.office.professional.users.emptyToday.title')}
                                    description={t('routes.office.professional.users.emptyToday.description')}
                                    actions={[
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                toggleTodayClientsViewEnabled();
                                            }}
                                        >
                                            {t('routes.office.professional.users.emptyToday.listAll')}
                                        </Button>,
                                    ]}
                                />
                            )}
                            {users?.map((user: User, index: number) => {
                                return (
                                    <UserLine
                                        user={user}
                                        key={`user-${index}`}
                                        query={query}
                                        todayClientsViewEnabled={todayClientsViewEnabled}
                                    />
                                );
                            })}
                        </InfiniteScroll>
                    )}
                    {error && (
                        <ErrorState
                            icon={<Person2Icon />}
                            onTryAgainClick={() => {
                                // Reset state
                                setError(false);
                            }}
                        />
                    )}
                    {!todayClientsViewEnabled && (
                        <Box
                            sx={{
                                height: '120px',
                            }}
                        ></Box>
                    )}
                </Container>
            </Page>
            {!todayClientsViewEnabled && (
                <UserSearch
                    value={query}
                    onChange={(value: string) => {
                        // Update state
                        setQuery(value);
                    }}
                    // disabled={isLoading}
                />
            )}
        </>
    );
}
