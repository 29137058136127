import { Auth, User as FirebaseUser } from 'firebase/auth';
import { SignInError } from '../../enums/sign-in-error';
import { Me } from '../../interfaces/me';

export interface AuthContextState {
    isRestoringSession: boolean;
    firebaseUser?: FirebaseUser;
    isReloadingMe?: boolean;
    me?: Me;
    notificationsExplainDialogOpen: boolean;
}

export enum AuthContextActionType {
    RESTORE_SESSION = 'RESTORE_SESSION',
    SIGN_IN = 'SIGN_IN',
    SIGN_OUT = 'SIGN_OUT',
    REFRESH_ME = 'REFRESH_ME',
    SET_NOTIFICATIONS_EXPLAIN_DIALOG_OPEN = 'SET_NOTIFICATIONS_EXPLAIN_DIALOG_OPEN',
    START_RELOADING_ME = 'START_RELOADING_ME',
}

export type AuthContextAction =
    | {
          type: AuthContextActionType.RESTORE_SESSION;
          firebaseUser?: FirebaseUser;
          me?: Me;
      }
    | {
          type: AuthContextActionType.SIGN_IN;
          firebaseUser?: FirebaseUser;
          me?: Me;
      }
    | {
          type: AuthContextActionType.SIGN_OUT;
      }
    | {
          type: AuthContextActionType.REFRESH_ME;
          me?: Me;
      }
    | {
          type: AuthContextActionType.SET_NOTIFICATIONS_EXPLAIN_DIALOG_OPEN;
          notificationsExplainDialogOpen: boolean;
      }
    | {
          type: AuthContextActionType.START_RELOADING_ME;
      };

export interface SignInCredentials {
    email: string;
    password: string;
}

export interface AuthContextType {
    state: AuthContextState;

    // Methods
    restorePreviousSession: () => Promise<void>;
    signIn: (credentials: SignInCredentials) => Promise<SignInError | undefined>;
    signOut: () => Promise<void>;
    getFirebaseAuth: () => Auth;
    requestOtp: (containerOrId: string | HTMLElement, phoneNumber: string) => Promise<boolean>;
    confirmOtp: (otp: string) => Promise<SignInError | undefined>;
    refreshMe: (disableLoading?: boolean) => Promise<void>;
    checkNotificationsPermission: () => Promise<void>;
    notificationsExplainedDecision: (confirmed: boolean) => Promise<void>;
    updateUnreadNotificationsCount: (newValue: number) => Promise<void>;
    unsubscribeToNotifications: () => Promise<void>;
}
