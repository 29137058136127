import { AxiosResponse } from 'axios';
import { InvoicingPerProviderStatistics } from '../interfaces/invoicing-per-provider';
import { SchedulePerProviderStatistics } from '../interfaces/schedule-per-provider';
import { BackendApiManager } from '../managers/backend-api';
import { Logger } from '../utils/logger';

export class StatisticsRepository {
    // Singleton Instance
    private static _instance: StatisticsRepository;

    // Private
    private logger: Logger;

    static get instance(): StatisticsRepository {
        if (!this._instance) {
            this._instance = new StatisticsRepository();
        }
        return this._instance;
    }

    constructor() {
        // Create Logger
        this.logger = new Logger('StatisticsRepository');
    }

    public async getInvoicingPerProvider(startDate: Date, endDate: Date): Promise<InvoicingPerProviderStatistics> {
        // Set url
        const url: string = `/statistics/invoicing-per-provider?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;

        // Get notifications from backend
        const result: AxiosResponse<InvoicingPerProviderStatistics> =
            await BackendApiManager.instance.get<InvoicingPerProviderStatistics>(url);

        // Return
        return result.data;
    }

    public async getSchedulePerProvider(startDate: Date, endDate: Date): Promise<SchedulePerProviderStatistics> {
        // Set url
        const url: string = `/statistics/schedule-per-provider?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;

        // Get notifications from backend
        const result: AxiosResponse<SchedulePerProviderStatistics> =
            await BackendApiManager.instance.get<SchedulePerProviderStatistics>(url);

        // Return
        return result.data;
    }
}
