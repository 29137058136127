import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FooterSheet } from '../footer-sheet/footer-sheet';
import { TimeTravellerNav } from '../time-traveller-nav/time-traveller-nav';

export function TimeTraveller({
    value,
    onChange,
    disabled,
    mode,
    minDate,
}: {
    value: moment.Moment;
    onChange?: (value: moment.Moment) => void;
    disabled?: boolean;
    mode?: 'day' | 'month';
    minDate?: moment.Moment;
}) {
    // Translations
    const { t } = useTranslation();

    // Hold State
    const [title, setTitle] = useState<string>('');

    // On internal Value change
    useEffect(() => {
        // Init
        let newTitle = '';

        // Check mode
        if (!mode || mode === 'day') {
            // Eval
            if (value.isSame(moment(), 'day')) {
                newTitle = t('components.timeTraveller.today');
            } else if (value.isSame(moment().add(1, 'day'), 'day')) {
                newTitle = t('components.timeTraveller.tomorrow');
            } else if (value.isSame(moment().subtract(1, 'day'), 'day')) {
                newTitle = t('components.timeTraveller.yesterday');
            } else {
                newTitle = value.fromNow();
            }

            // Add day of the week
            setTitle(`${newTitle}, ${value.format('dddd')}`);
        } else if (mode === 'month') {
            // Eval
            if (value.isSame(moment(), 'month')) {
                newTitle = t('components.timeTraveller.thisMonth');
            } else if (value.isSame(moment().add(1, 'month'), 'month')) {
                newTitle = t('components.timeTraveller.nextMonth');
            } else if (value.isSame(moment().subtract(1, 'month'), 'month')) {
                newTitle = t('components.timeTraveller.previousMonth');
            } else {
                newTitle = value.fromNow();
            }

            // Set title
            setTitle(newTitle);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    // Render
    return (
        <FooterSheet>
            <Box
                sx={{
                    marginTop: '16px',
                    maxWidth: '425px',
                    width: '100%',
                    textAlign: 'center',
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        marginBottom: '16px',
                        textAlign: 'center',
                        color: '#ff66c4',
                    }}
                >
                    {title}
                </Typography>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    marginBottom: '16px',
                }}
            >
                <TimeTravellerNav value={value} onChange={onChange} disabled={disabled} mode={mode} minDate={minDate} />
            </Box>
        </FooterSheet>
    );
}
