import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import EventIcon from '@mui/icons-material/Event';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import InstagramIcon from '@mui/icons-material/Instagram';
import LoginIcon from '@mui/icons-material/Login';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Person2Icon from '@mui/icons-material/Person2';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import SearchIcon from '@mui/icons-material/Search';
import SpeedIcon from '@mui/icons-material/Speed';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Badge,
    Box,
    Button,
    Fab,
    Fade,
    FormControl,
    Grow,
    IconButton,
    InputLabel,
    OutlinedInput,
    Paper,
    Typography,
    useTheme,
} from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { NativeKnightEventEmitter, NativeKnightEvents } from '../../../..';
import { LocationSearchBox } from '../../../../components/location-search-box/location-search-box';
import { MainOfficePage } from '../../../../components/main-office-page/main-office-page';
import { OfficeTitle } from '../../../../components/office-title/office-title';
import { SignInAd } from '../../../../components/sign-in-ad/sign-in-ad';
import Square from '../../../../components/square/square.component';
import { Tile } from '../../../../components/tile/tile';
import { AppContext } from '../../../../contexts/app/context';
import { AppContextType } from '../../../../contexts/app/types';
import { AuthContext } from '../../../../contexts/auth/context';
import { AuthContextType } from '../../../../contexts/auth/types';
import { ServiceCategories, ServiceCategoryData } from '../../../../databases/service-categories';
import { BookingState } from '../../../../enums/booking-state';
import { CardType } from '../../../../enums/card-type';
import { Environment } from '../../../../enums/environment';
import { OperativeSystem } from '../../../../enums/operative-system';
import { ServiceCategory } from '../../../../enums/service-category';
import { Booking } from '../../../../interfaces/booking';
import { Card as ClientCard, GiftCardData, LoyaltyCardData } from '../../../../interfaces/card';
import { Service } from '../../../../interfaces/service';
import { EnvironmentManager } from '../../../../managers/environment';
import { RoutesNames } from '../../../../routes-names';
import variables from '../../../../variables.module.scss';
import styles from './home.module.scss';

function BookingWidget() {
    // Get Contexts
    const appContext: AppContextType = useContext(AppContext);
    const authContext: AuthContextType = useContext(AuthContext);

    // Translations
    const { t } = useTranslation();

    // Get navigation
    const navigate = useNavigate();

    // Next Booking
    let nextBookings: Array<Booking> = [];
    let previousBookings: Array<Booking> = [];

    // Eval state
    if (authContext.state.me?.bookings && authContext.state.me?.bookings.length > 0) {
        // Look at the first one
        if (authContext.state.me?.bookings[0].state === BookingState.Scheduled) {
            // Next
            nextBookings.push(...authContext.state.me?.bookings);
        } else {
            // Previous
            previousBookings.push(...authContext.state.me?.bookings);
        }
    }

    // Component type
    let componentType: 'next' | 'previous' | 'none' = 'none';
    let bookings: Array<Booking> = [];

    // Eval
    if (nextBookings.length > 0) {
        // Use next bookings
        componentType = 'next';
        bookings = nextBookings;
    } else if (previousBookings.length > 0) {
        // Use previous bookings
        componentType = 'previous';
        bookings = previousBookings;
    } else {
        // No bookings
        componentType = 'none';
    }

    // Provider name
    const providerName = bookings[0]?.provider?.name ?? '';

    // Generate maps link
    const mapsLink = `https://www.google.com/maps/search/?api=1&query=${bookings[0]?.provider?.location?.coordinates[1]},${bookings[0]?.provider?.location?.coordinates[0]}`;

    // Build
    return (
        <Grow in={true}>
            <Box>
                {/* <OfficeTitle text={t(titleTranslationKey)} color={'#ff66c4'} /> */}
                {authContext.state.firebaseUser && (
                    <Paper
                        className={styles.bookingWidget}
                        sx={{
                            // backgroundColor: '#fff',
                            width: '100%',
                            // maxWidth: '400px',
                            borderRadius: '8px',
                            zIndex: 1,
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                    >
                        {componentType !== 'none' && (
                            <Square
                                bottom="-70px"
                                left="-60px"
                                width="100px"
                                position="absolute"
                                zIndex={-1}
                                color="#ffc834"
                            />
                        )}
                        {componentType === 'none' && (
                            <Square
                                bottom="-60px"
                                right="-70px"
                                width="100px"
                                position="absolute"
                                zIndex={-1}
                                color="#ffc834"
                            />
                        )}
                        <Box className={styles.bookingWidgetLine1}>
                            {componentType !== 'none' && (
                                <Box className={styles.bookingWidgetLineDesc}>
                                    <Box className={styles.bookingWidgetLine1FromNow}>
                                        <Typography variant="h6">{moment(bookings[0].startDate).fromNow()}</Typography>
                                    </Box>
                                    <Box className={styles.bookingWidgetLine1Date}>
                                        <Typography variant="body2">
                                            {moment(bookings[0].startDate).format('LLL')}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                            {componentType === 'none' && (
                                <Box className={styles.bookingWidgetLineDesc}>
                                    <Box className={styles.bookingWidgetLine1FromNow}>
                                        <Typography variant="h6">
                                            {t('routes.office.main.home.noAppointments.letsgo')}
                                        </Typography>
                                    </Box>
                                    <Box className={styles.bookingWidgetLine1Date}>
                                        <Typography variant="body2">
                                            {t('routes.office.main.home.noAppointments.description')}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                            <Box className={styles.bookingWidgetLineButtons}>
                                {componentType === 'next' && (
                                    <Fab
                                        size="small"
                                        color="primary"
                                        aria-label="directions"
                                        target="_blank"
                                        href={mapsLink}
                                    >
                                        <ForkRightIcon />
                                    </Fab>
                                )}
                                {componentType === 'previous' && (
                                    <Fab
                                        size="small"
                                        color="primary"
                                        aria-label="book appointment again"
                                        onClick={() => {
                                            // Get services from all the bookings
                                            const services: Array<Service> = [];
                                            for (const previousBooking of previousBookings) {
                                                if (previousBooking.service) {
                                                    services.push(previousBooking.service);
                                                }
                                            }

                                            // Set services
                                            appContext.setServices(services);

                                            // Navigate
                                            navigate(
                                                `/${RoutesNames.Office}/${RoutesNames.Office_Showcase}/${previousBookings[0].provider?.id}?services=true`,
                                            );
                                        }}
                                    >
                                        <EventRepeatIcon />
                                    </Fab>
                                )}
                                {componentType === 'none' && (
                                    <Fab
                                        size="small"
                                        color="primary"
                                        aria-label="book an appointment"
                                        onClick={() => {
                                            // Navigate
                                            navigate(`/${RoutesNames.Office}/${RoutesNames.Office_ServicePicker}`);
                                        }}
                                    >
                                        <EditCalendarIcon />
                                    </Fab>
                                )}
                            </Box>
                        </Box>
                        <Box className={styles.bookingWidgetLine2}>
                            {bookings.map((booking: Booking, index: number) => {
                                return (
                                    <Box
                                        key={`booking-${index}`}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '8px',
                                            marginTop: '4px',
                                            opacity: ![BookingState.Scheduled, BookingState.Completed].includes(
                                                booking.state,
                                            )
                                                ? 0.5
                                                : 1,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                paddingLeft: '8px',
                                                paddingRight: '8px',
                                                paddingTop: '2px',
                                                paddingBottom: '2px',
                                                backgroundColor:
                                                    ServiceCategories[booking.service?.category ?? ''].color,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderRadius: '24px',
                                                color: 'white',
                                            }}
                                        >
                                            <Typography variant="caption" fontWeight={500}>
                                                {t(ServiceCategories[booking.service?.category ?? ''].translationKey)}
                                            </Typography>
                                        </Box>
                                        <Typography
                                            variant="subtitle2"
                                            sx={{
                                                fontSize: '14px',
                                                textDecoration: ![
                                                    BookingState.Scheduled,
                                                    BookingState.Completed,
                                                ].includes(booking.state)
                                                    ? 'line-through'
                                                    : 'none',
                                            }}
                                        >
                                            {booking.service?.name}
                                        </Typography>
                                    </Box>
                                );
                            })}
                        </Box>
                        {componentType !== 'none' && (
                            <Box
                                className={styles.bookingWidgetLine3}
                                onClick={() => {
                                    // Navigate
                                    navigate(
                                        `/${RoutesNames.Office}/${RoutesNames.Office_Showcase}/${bookings[0].provider?.id}`,
                                    );
                                }}
                            >
                                <Avatar
                                    sx={{ width: 48, height: 48, marginRight: '8px', border: '2px solid #ff66c4' }}
                                    alt={providerName[0]}
                                    src={bookings[0].provider?.pictureUrl}
                                >
                                    {providerName[0]}
                                </Avatar>
                                <Typography variant="subtitle1">{providerName}</Typography>
                            </Box>
                        )}
                        {componentType === 'none' && (
                            <Box
                                className={styles.bookingWidgetLine3}
                                sx={{
                                    paddingTop: '0 !important',
                                    paddingBottom: '8px !important',
                                }}
                            ></Box>
                        )}
                    </Paper>
                )}
                {!authContext.state.firebaseUser && (
                    <SignInAd reason={`${t('routes.office.main.home.bookingsSignInAd')} 🗓️`} />
                )}
            </Box>
        </Grow>
    );
}

function ServiceCategoryTile({ data }: { data: ServiceCategoryData }) {
    // Translations
    const { t } = useTranslation();

    // Navigation
    const navigate = useNavigate();

    // Build
    return (
        <Grow in={true} mountOnEnter unmountOnExit>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
                onClick={() => {
                    // Navigate
                    navigate(`../../${RoutesNames.Office_ServicePicker}?category=${data.category}`);
                }}
            >
                <Paper
                    sx={{
                        borderRadius: '50%',
                        width: '100%',
                        maxWidth: '80px',
                        aspectRatio: '1 / 1',
                        backgroundColor: data.color,
                        marginBottom: '4px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <img
                        src={`/images/service-categories-icons/${data.category}.svg`}
                        alt={t(data.translationKey)}
                        style={{
                            width: '50%',
                        }}
                    />
                </Paper>
                <Typography variant="button" gutterBottom fontWeight={'bold'} textAlign="center">
                    {t(data.translationKey)}
                </Typography>
            </Box>
        </Grow>
    );
}

export function OfficeMainHome() {
    // Get Contexts
    const appContext: AppContextType = useContext(AppContext);
    const authContext: AuthContextType = useContext(AuthContext);

    // Translations
    const { t } = useTranslation();

    // Get navigation
    const navigate = useNavigate();

    // Get theme
    const theme = useTheme();

    // Get query
    const query = new URLSearchParams(window.location.search);
    const queryValues = {
        refreshMe: query.get('refreshMe'),
    };

    // State default values
    let defaultOpenPanel: string | false = false;
    if (EnvironmentManager.instance.current === Environment.Production) {
        if (authContext.state.me?.systemAdministratorProfile) {
            defaultOpenPanel = 'panel1';
        } else if (authContext.state.me?.provider?.isActive) {
            defaultOpenPanel = 'panel2';
        }
    }

    // Default values Actions
    const getWelcomeMessage = (): [string, string] => {
        // Figure out the welcome message
        let welcomeMessage = '';
        let welcomeMessageEnd = '';
        if (new Date().getHours() >= 6 && new Date().getHours() < 12) {
            // If it's before 12pm, say good morning
            welcomeMessage = t('routes.office.main.home.goodMorning');
            welcomeMessageEnd = '! ☀️';
        } else if (new Date().getHours() >= 12 && new Date().getHours() < 19) {
            // If it's after 12pm, say good afternoon
            welcomeMessage = t('routes.office.main.home.goodAfternoon');
            welcomeMessageEnd = '! 🙋‍♀️';
        } else {
            // If it's after 7pm, say good evening
            welcomeMessage = t('routes.office.main.home.goodEvening');
            welcomeMessageEnd = '. 🌙';
        }

        return [welcomeMessage, welcomeMessageEnd];
    };

    // Get default values
    const defaultWelcomeMessages: [string, string] = getWelcomeMessage();

    // Hold state
    const [expanded, setExpanded] = useState<string | false>(defaultOpenPanel);
    const [welcomeMessage, setWelcomeMessage] = useState<string>(defaultWelcomeMessages[0]);
    const [welcomeMessageEnd, setWelcomeMessageEnd] = useState<string>(defaultWelcomeMessages[1]);

    // Actions
    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    // Map Cards
    const cardsMap: Map<CardType, ClientCard<any> | undefined> = new Map<CardType, ClientCard<any> | undefined>();
    cardsMap.set(CardType.Loyalty, authContext.state.me?.cards.find((card) => card.type === CardType.Loyalty));
    cardsMap.set(CardType.Gift, authContext.state.me?.cards.find((card) => card.type === CardType.Gift));

    // Events
    const onNativeOperative_Event_AppBecomeActive = async (): Promise<any> => {
        // Get default values
        const welcomeMessages: [string, string] = getWelcomeMessage();

        // Update welcome message
        setWelcomeMessage(welcomeMessages[0]);
        setWelcomeMessageEnd(welcomeMessages[1]);
    };

    // On mount
    useEffect(() => {
        // Subscribe events
        if (appContext.state.operativeSystem === OperativeSystem._Unknown) {
            window.addEventListener('focus', onNativeOperative_Event_AppBecomeActive);
        } else {
            NativeKnightEventEmitter.on(
                NativeKnightEvents.EVENT_APP_BECOME_ACTIVE,
                onNativeOperative_Event_AppBecomeActive,
            );
        }

        // Check query values - Refresh /me
        if (queryValues.refreshMe) {
            // Replace URL
            navigate(`${window.location.pathname}`, {
                replace: true,
            });

            // Refresh /me
            authContext.refreshMe(false);
        }

        return () => {
            // Unsubscribe events
            if (appContext.state.operativeSystem === OperativeSystem._Unknown) {
                window.removeEventListener('focus', onNativeOperative_Event_AppBecomeActive);
            } else {
                NativeKnightEventEmitter.off(
                    NativeKnightEvents.EVENT_APP_BECOME_ACTIVE,
                    onNativeOperative_Event_AppBecomeActive,
                );
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Build
    return (
        <MainOfficePage
            title={[welcomeMessage, (authContext.state.me?.user?.firstName ?? 'Vaidosa') + welcomeMessageEnd]}
            color={'#ff66c4'}
            actions={[
                <IconButton
                    aria-label="instagram"
                    target="_blank"
                    href="https://www.instagram.com/asvaidosas.guimaraes/"
                >
                    <InstagramIcon />
                </IconButton>,
                authContext.state.firebaseUser ? (
                    <Link to={`../../${RoutesNames.Office_Notifications}`}>
                        <IconButton aria-label="notifications">
                            <Badge
                                badgeContent={authContext.state.me?.unreadNotificationsCount ?? 0}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                color="primary"
                                sx={{
                                    span: {
                                        backgroundColor: '#ffc834',
                                        color: '#000',
                                    },
                                }}
                            >
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                    </Link>
                ) : (
                    <Link to={`/${RoutesNames.SignIn}`}>
                        <IconButton aria-label="sign-in">
                            <LoginIcon />
                        </IconButton>
                    </Link>
                ),
            ]}
            onRefresh={async () => {
                // Refresh /me
                await authContext.refreshMe(true);
            }}
        >
            <Box
                sx={{
                    paddingBottom: '24px',
                    position: 'relative',
                    top: '-24px',
                }}
            >
                {/* Management Panel */}
                {(authContext.state.me?.systemAdministratorProfile || authContext.state.me?.provider?.isActive) && (
                    <Box
                        sx={{
                            marginBottom: '24px',
                        }}
                    >
                        {authContext.state.me?.systemAdministratorProfile && (
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} id="administrator">
                                    <Typography fontWeight={'bold'}>
                                        👩‍💼 {t('routes.office.main.home.accordions.administrator')}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box className={styles.professionalActions}>
                                        <Box>
                                            <Tile
                                                label={t('routes.office.main.home.professional.schedule.title')}
                                                icon={<EventIcon />}
                                                counter={
                                                    authContext.state.me.systemAdministratorOverview?.completedServicesThisMonthCount.toString() ??
                                                    '0'
                                                }
                                                help={t('routes.office.main.home.professional.schedule.help')}
                                                onClick={() => {
                                                    navigate(
                                                        `../../${RoutesNames.Office_SystemAdministrator}/${RoutesNames.Office_SystemAdministrator_Schedule}`,
                                                    );
                                                }}
                                                badge={authContext.state.me.systemAdministratorOverview?.scheduledServicesForTodayCount?.toString()}
                                            />
                                        </Box>
                                        <Box>
                                            <Tile
                                                disabled
                                                label={t('routes.office.main.home.professional.invoicing.title')}
                                                icon={<PointOfSaleIcon />}
                                                counter={
                                                    authContext.state.me.systemAdministratorOverview
                                                        ?.earnedThisMonth !== undefined
                                                        ? `${authContext.state.me.systemAdministratorOverview?.earnedThisMonth.toFixed(
                                                              2,
                                                          )}€`
                                                        : undefined
                                                }
                                                help={t('routes.office.main.home.professional.invoicing.help')}
                                                onClick={() => {
                                                    navigate(
                                                        `../../${RoutesNames.Office_SystemAdministrator}/${RoutesNames.Office_SystemAdministrator_Invoicing}`,
                                                    );
                                                }}
                                                badge={`${
                                                    authContext.state.me.systemAdministratorOverview?.estimatedEarningsForToday?.toFixed(
                                                        2,
                                                    ) ?? '0.00'
                                                }€`}
                                            />
                                        </Box>
                                        <Box>
                                            <Tile
                                                label={t('routes.office.main.home.professional.providers.title')}
                                                icon={<VolunteerActivismIcon />}
                                                counter={authContext.state.me.systemAdministratorOverview?.providersWorkingThisMonthCount.toString()}
                                                help={t('routes.office.main.home.professional.providers.help')}
                                                badge={authContext.state.me.systemAdministratorOverview?.scheduledProvidersForTodayCount?.toString()}
                                                onClick={() => {
                                                    navigate(
                                                        `../../${RoutesNames.Office_SystemAdministrator}/${
                                                            RoutesNames.Office_SystemAdministrator_Providers
                                                        }?todayView=${
                                                            (authContext.state.me?.systemAdministratorOverview
                                                                ?.scheduledClientsForTodayCount ?? 0) > 0
                                                                ? 'true'
                                                                : 'false'
                                                        }`,
                                                    );
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <Tile
                                                label={t('routes.office.main.home.professional.clients.title')}
                                                icon={<Person2Icon />}
                                                counter={authContext.state.me.systemAdministratorOverview?.clientsAttendedThisMonthCount.toString()}
                                                help={t('routes.office.main.home.professional.clients.help')}
                                                badge={authContext.state.me.systemAdministratorOverview?.scheduledClientsForTodayCount?.toString()}
                                                onClick={() => {
                                                    navigate(
                                                        `../../${RoutesNames.Office_SystemAdministrator}/${
                                                            RoutesNames.Office_SystemAdministrator_Users
                                                        }?todayView=${
                                                            (authContext.state.me?.systemAdministratorOverview
                                                                ?.scheduledClientsForTodayCount ?? 0) > 0
                                                                ? 'true'
                                                                : 'false'
                                                        }`,
                                                    );
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                width: '100% !important',
                                            }}
                                        >
                                            <Button
                                                sx={{
                                                    width: '100%',
                                                }}
                                                variant="contained"
                                                startIcon={<SpeedIcon />}
                                                onClick={() => {
                                                    navigate(
                                                        `../../../${RoutesNames.DevTools}/${RoutesNames.DevTools_NativeKnightDiag}`,
                                                    );
                                                }}
                                            >
                                                Native Knight Diagnostics
                                            </Button>
                                        </Box>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        )}
                        {(authContext.state.me?.provider?.isActive ||
                            (authContext.state.me?.provider && authContext.state.me?.systemAdministratorProfile)) && (
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} id="professional">
                                    <Typography fontWeight={'bold'}>
                                        👩‍⚕️ {t('routes.office.main.home.accordions.professional')}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box className={styles.professionalActions}>
                                        <Box>
                                            <Tile
                                                label={t('routes.office.main.home.professional.schedule.title')}
                                                icon={<EventIcon />}
                                                counter={
                                                    authContext.state.me.providerOverview?.completedServicesThisMonthCount.toString() ??
                                                    '0'
                                                }
                                                help={t('routes.office.main.home.professional.schedule.help')}
                                                onClick={() => {
                                                    navigate(
                                                        `../../${RoutesNames.Office_Professional}/${authContext.state.me?.provider?.id}/${RoutesNames.Office_Professional_Schedule}`,
                                                    );
                                                }}
                                                badge={authContext.state.me.providerOverview?.scheduledServicesForTodayCount?.toString()}
                                            />
                                        </Box>
                                        <Box>
                                            <Tile
                                                label={t('routes.office.main.home.professional.myServices.title')}
                                                icon={<VolunteerActivismIcon />}
                                                counter={
                                                    authContext.state.me.providerOverview?.validProviderServicesCount.toString() ??
                                                    '0'
                                                }
                                                help={t('routes.office.main.home.professional.myServices.help')}
                                                onClick={() => {
                                                    navigate(
                                                        `../../${RoutesNames.Office_Professional}/${authContext.state.me?.provider?.id}/${RoutesNames.Office_Professional_Services}`,
                                                    );
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </Box>
                )}

                {/* Location */}
                <Box
                    sx={{
                        // border: '1px solid #ff66c4',
                        marginBottom: '16px',
                    }}
                >
                    <LocationSearchBox />
                </Box>

                {/* Search Widget */}
                <Box className={styles.line0}>
                    {/* <OfficeTitle text={t('routes.office.main.home.searchByService')} color={'#ff66c4'} /> */}
                    <FormControl
                        sx={{
                            width: '100%',
                            boxShadow: '0 0 0 rgba($first-color, 0.1)',
                            animation: 'first-input-pulse 1.5s infinite',
                            borderRadius: '8px',
                        }}
                        variant="outlined"
                    >
                        <InputLabel
                            htmlFor="service-picker-input"
                            sx={{
                                fontWeight: 'bold',
                                paddingRight: '8px',
                                backgroundColor: theme.palette.background.default,
                            }}
                        >
                            {t('routes.office.main.home.whatDoYouWantToDo')}
                        </InputLabel>
                        <OutlinedInput
                            id="service-picker-input"
                            label={t('routes.office.main.home.whatDoYouWantToDo')}
                            placeholder={t('routes.office.main.home.searchByService')}
                            onFocus={(event) => {
                                event.stopPropagation();
                                event.preventDefault();

                                // Navigate to Service Picker
                                navigate(`../../${RoutesNames.Office_ServicePicker}`);
                            }}
                            fullWidth
                            startAdornment={
                                <SearchIcon
                                    sx={{
                                        marginRight: '8px',
                                    }}
                                />
                            }
                            sx={{
                                fieldset: {
                                    borderColor: variables.firstColor,
                                },
                            }}
                        />
                    </FormControl>
                </Box>

                {/* Booking Widget */}
                <Box
                    sx={{
                        marginTop: '24px',
                    }}
                >
                    <OfficeTitle text={t('routes.office.main.home.forYou')} color={'#ff66c4'} />
                    <Box className={styles.line0}>
                        <Box className={styles.widget1}>
                            <BookingWidget />
                        </Box>
                    </Box>
                </Box>

                {/* What do you want to do */}
                <Box
                    className={styles.line0}
                    sx={{
                        marginTop: '24px',
                    }}
                >
                    <OfficeTitle text={t('routes.office.main.home.searchByServiceCategory')} color={'#ff66c4'} />
                    <Box>
                        <Box
                            sx={{
                                marginBottom: '16px',
                                display: 'flex',
                                gap: '16px',
                            }}
                        >
                            <Box
                                sx={{
                                    flex: 1,
                                }}
                            >
                                <ServiceCategoryTile data={ServiceCategories[ServiceCategory.Manicure]} />
                            </Box>
                            <Box
                                sx={{
                                    flex: 1,
                                }}
                            >
                                <ServiceCategoryTile data={ServiceCategories[ServiceCategory.Pedicure]} />
                            </Box>
                            <Box
                                sx={{
                                    flex: 1,
                                }}
                            >
                                <ServiceCategoryTile data={ServiceCategories[ServiceCategory.Makeup]} />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                marginBottom: '16px',
                                display: 'flex',
                                gap: '16px',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '25%',
                                }}
                            >
                                <ServiceCategoryTile data={ServiceCategories[ServiceCategory.Hair]} />
                            </Box>
                            <Box
                                sx={{
                                    width: '25%',
                                }}
                            >
                                <ServiceCategoryTile data={ServiceCategories[ServiceCategory.FacialEpilation]} />
                            </Box>
                            <Box
                                sx={{
                                    width: '25%',
                                }}
                            >
                                <ServiceCategoryTile data={ServiceCategories[ServiceCategory.Eyelashes]} />
                            </Box>
                            <Box
                                sx={{
                                    width: '25%',
                                }}
                            >
                                <ServiceCategoryTile data={ServiceCategories[ServiceCategory.Depilation]} />
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {/* Cards */}
                <Box className={styles.line1}>
                    <Box className={styles.widget2_3}>
                        <Box className={styles.widget2}>
                            <OfficeTitle text={t('routes.office.main.home.loyalty')} color={'#ff66c4'} />
                            <Fade in={true}>
                                <Paper
                                    className={styles.widget2Paper}
                                    sx={{
                                        backgroundColor: '#fff',
                                        width: '100%',
                                        maxWidth: '400px',
                                        minHeight: '120px',
                                        borderRadius: '8px',
                                        zIndex: 1,
                                        position: 'relative',
                                        overflow: 'hidden',
                                    }}
                                    onClick={() => {
                                        // Navigate
                                        authContext.state.me?.cards.find((card) => card.type === CardType.Loyalty) &&
                                            navigate(`../${RoutesNames.Office_Main_Card}?cardType=${CardType.Loyalty}`);
                                    }}
                                >
                                    <Square top="-15%" right="-5%" width="40px" position="absolute" zIndex={1} />
                                    <Square
                                        bottom="-15%"
                                        left="-5%"
                                        width="40px"
                                        color="#38B6FF"
                                        position="absolute"
                                        zIndex={1}
                                    />
                                    {authContext.state.firebaseUser &&
                                        cardsMap.get(CardType.Loyalty) &&
                                        cardsMap.get(CardType.Loyalty)?.data && (
                                            <>
                                                <Typography variant="h4" color={'#000'}>
                                                    {`${Math.floor(
                                                        (cardsMap.get(CardType.Loyalty)?.data as LoyaltyCardData)
                                                            .balance,
                                                    )} / 100`}
                                                </Typography>
                                                <Typography variant="body1" color={'#000'}>
                                                    {t('routes.office.main.home.points')}
                                                </Typography>
                                            </>
                                        )}
                                    {!authContext.state.firebaseUser && (
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                navigate(
                                                    `../${RoutesNames.Office_Main_Card}?cardType=${CardType.Loyalty}`,
                                                );
                                            }}
                                        >
                                            {t('routes.office.main.home.knowMore')}
                                        </Button>
                                    )}
                                </Paper>
                            </Fade>
                        </Box>
                        <Box className={styles.widget3}>
                            <OfficeTitle text={t('routes.office.main.home.gift')} color={'#ff66c4'} />
                            <Fade in={true}>
                                <Paper
                                    className={styles.widget3Paper}
                                    sx={{
                                        width: '100%',
                                        maxWidth: '400px',
                                        minHeight: '120px',
                                        borderRadius: '8px',
                                        zIndex: 1,
                                        position: 'relative',
                                        overflow: 'hidden',
                                    }}
                                    onClick={() => {
                                        // Navigate
                                        authContext.state.me?.cards.find((card) => card.type === CardType.Gift) &&
                                            navigate(`../${RoutesNames.Office_Main_Card}?cardType=${CardType.Gift}`);
                                    }}
                                >
                                    <Square
                                        top="-15%"
                                        right="-5%"
                                        width="40px"
                                        position="absolute"
                                        zIndex={1}
                                        color="#7dd957"
                                    />
                                    <Square
                                        bottom="-15%"
                                        left="-5%"
                                        width="40px"
                                        color="#38b6ff"
                                        position="absolute"
                                        zIndex={1}
                                    />
                                    {authContext.state.firebaseUser &&
                                        cardsMap.get(CardType.Gift) &&
                                        cardsMap.get(CardType.Gift)?.data && (
                                            <>
                                                <Typography variant="h4" color={'#fff'}>
                                                    {`${(
                                                        cardsMap.get(CardType.Gift)?.data as GiftCardData
                                                    ).balance.toFixed(2)} €`}
                                                </Typography>
                                                <Typography variant="body1" color={'#fff'}>
                                                    {t('routes.office.main.home.available')}
                                                </Typography>
                                            </>
                                        )}
                                    {!authContext.state.firebaseUser && (
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            onClick={() => {
                                                navigate(
                                                    `../${RoutesNames.Office_Main_Card}?cardType=${CardType.Gift}`,
                                                );
                                            }}
                                        >
                                            {t('routes.office.main.home.knowMore')}
                                        </Button>
                                    )}
                                </Paper>
                            </Fade>
                        </Box>
                    </Box>
                </Box>

                {/* <Box id="line4">Campanhas / publicidade / noticias</Box>
            <Box id="line6">Artigos do blog</Box> */}
            </Box>
        </MainOfficePage>
    );
}
