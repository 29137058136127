import { ThemeProvider } from '@emotion/react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import i18n from 'i18next';
import { AppContext } from '../contexts/app/context';
import { AppContextProvider } from '../contexts/app/provider';
import { AppContextType } from '../contexts/app/types';
import { AuthContextProvider } from '../contexts/auth/provider';
import { lightTheme } from '../themes/light';
import App from './app';

export default function Root() {
    // Get query
    const query = new URLSearchParams(window.location.search);
    const queryValues = {
        os: query.get('os'),
        initialTheme: query.get('initialTheme'),
        version: query.get('version'),
        sessionId: query.get('sessionId'),
        appVersion: query.get('appVersion'),
        appBuild: query.get('appBuild'),
    };

    // Build the app
    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language}>
            <AppContextProvider
                os={queryValues.os as any}
                themeMode={queryValues.initialTheme as any}
                nativeKnightApiVersion={queryValues.version as any}
                sessionId={queryValues.sessionId as any}
                appVersion={queryValues.appVersion as any}
                appBuild={queryValues.appBuild as any}
            >
                <AppContext.Consumer>
                    {(appContext: AppContextType) => (
                        <ThemeProvider theme={appContext.state.theme ?? lightTheme}>
                            <AuthContextProvider>
                                <App />
                            </AuthContextProvider>
                        </ThemeProvider>
                    )}
                </AppContext.Consumer>
            </AppContextProvider>
        </LocalizationProvider>
    );
}
