import CallIcon from '@mui/icons-material/Call';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import EmailIcon from '@mui/icons-material/Email';
import EventIcon from '@mui/icons-material/Event';
import HistoryIcon from '@mui/icons-material/History';
import PersonIcon from '@mui/icons-material/Person';
import { BottomNavigation, BottomNavigationAction, Box, Grow, IconButton, Paper, TextField } from '@mui/material';
import moment from 'moment';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BookingCard } from '../../../components/booking-card/booking-card';
import { CardTransactionLine } from '../../../components/card-transaction-line/card-transaction-line';
import { Container } from '../../../components/container/container';
import { EmptyState } from '../../../components/empty-state/empty-state';
import { ErrorState } from '../../../components/error-state/error-state';
import { Loader } from '../../../components/loader/loader';
import { Page } from '../../../components/page/page';
import VaidosaCard from '../../../components/vaidosa-card/vaidosa-card.component';
import { AppContext } from '../../../contexts/app/context';
import { AppContextType } from '../../../contexts/app/types';
import { CardType } from '../../../enums/card-type';
import { Booking } from '../../../interfaces/booking';
import { Card, GiftCardData, LoyaltyCardData } from '../../../interfaces/card';
import { CardTransaction } from '../../../interfaces/card-transaction';
import { User } from '../../../interfaces/user';
import { BookingsRepository } from '../../../repositories/bookings';
import { UsersRepository } from '../../../repositories/users';
import styles from './user.module.scss';

const SCROLL_THRESHOLD = 16;
const TOOLBAR_HEIGHT = 56;

function ProfileTab({ user }: { user: User | null }) {
    // Translations
    const { t } = useTranslation();

    // Build
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '8px',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '600px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                }}
            >
                <TextField
                    sx={{
                        width: '100%',
                    }}
                    label={t('routes.office.user.profile.firstName')}
                    value={user?.firstName}
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    sx={{
                        width: '100%',
                    }}
                    label={t('routes.office.user.profile.lastName')}
                    value={user?.lastName}
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                    }}
                >
                    <TextField
                        sx={{
                            width: '100%',
                            marginRight: '8px',
                        }}
                        type="phone"
                        label={t('routes.office.user.profile.phoneNumber')}
                        value={user?.phoneNumber}
                        InputProps={{
                            readOnly: true,
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <IconButton aria-label="call" href={`tel:${user?.phoneNumber}`} target="_blank">
                        <CallIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                    }}
                >
                    <TextField
                        sx={{
                            width: '100%',
                            marginRight: '8px',
                        }}
                        type="email"
                        label={t('routes.office.user.profile.emailAddress')}
                        value={user?.email}
                        InputProps={{
                            readOnly: true,
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <IconButton aria-label="email" href={`mailto:${user?.email}`} target="_blank">
                        <EmailIcon />
                    </IconButton>
                </Box>
                <TextField
                    sx={{
                        width: '100%',
                    }}
                    type="date"
                    label={t('routes.office.user.profile.birthDate')}
                    value={user?.birthday ? moment(user?.birthday).format('YYYY-MM-DD') : ''}
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    sx={{
                        width: '100%',
                    }}
                    label={t('routes.office.user.profile.taxPayerNumber')}
                    value={user?.taxPayerNumber}
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    sx={{
                        width: '100%',
                    }}
                    label={t('routes.office.user.profile.isActive')}
                    value={user?.isActive}
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    disabled
                    sx={{
                        width: '100%',
                    }}
                    label={'id'}
                    value={user?.id}
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Box>
        </Box>
    );
}

function HistoryTab({ userId, currentTab }: { userId: string; currentTab: number }) {
    // Translations
    const { t } = useTranslation();

    // Hold state
    const [isloading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [results, setResults] = useState<Array<Booking> | null>(null);

    // Actions: Load
    const load = async () => {
        try {
            // Reset error
            setError(false);

            // Start loading
            setIsLoading(true);

            // Get
            const data = await BookingsRepository.instance.getUserBookings(
                new Date('2023-01-01'),
                moment().add(1, 'year').toDate(),
                userId,
                'startDate DESC, createdAt DESC',
            );

            // Set
            setResults(data);
        } catch (e) {
            // Log error
            console.log(e);

            // Set error
            setError(true);
        } finally {
            // Stop loading
            setIsLoading(false);
        }
    };

    // On tab change
    useEffect(() => {
        // Load
        load();

        // Scroll to top
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Build
    return (
        <>
            {isloading && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '32px',
                    }}
                >
                    <Loader size={48} />
                </Box>
            )}
            {!isloading && error && (
                <ErrorState
                    icon={<HistoryIcon />}
                    onTryAgainClick={() => {
                        // Load again
                        load();
                    }}
                />
            )}
            {!isloading && !error && results && results.length > 0 && (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '600px',
                        }}
                    >
                        {results.map((booking: Booking) => {
                            // Build
                            return (
                                <BookingCard
                                    booking={booking}
                                    key={`booking-${booking.id?.toString()}`}
                                    viewMode="user"
                                />
                            );
                        })}
                    </Box>
                </Box>
            )}
            {!isloading && !error && results && results.length === 0 && (
                <EmptyState
                    height={70}
                    icon={<EventIcon />}
                    title={t('routes.office.user.history.empty.title')}
                    description={t('routes.office.user.history.empty.description')}
                />
            )}
        </>
    );
}

function CardTab({ userId, type }: { userId: string; type: CardType }) {
    // Translations
    const { t } = useTranslation();

    // Get navigation
    // const navigate = useNavigate();

    // Hold state
    const [isloading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [results, setResults] = useState<Array<CardTransaction> | null>(null);
    const [card, setCard] = useState<Card<any> | null>(null);
    const [bookings, setBookings] = useState<Array<Booking> | null>(null);

    // Actions: Load
    const load = async () => {
        try {
            // Reset error
            setError(false);

            // Start loading
            setIsLoading(true);

            // Get
            const data: [Card<any> | null, Array<Booking>] = await Promise.all([
                // 0: Card
                UsersRepository.instance.getCard(userId, type),

                // 1: Bookings
                type === CardType.Loyalty
                    ? BookingsRepository.instance.getUserBookings(
                          new Date('2023-01-01'),
                          moment().toDate(),
                          userId,
                          'startDate DESC, createdAt DESC',
                      )
                    : [],
            ]);

            // Eval
            if (data[0]) {
                switch (type) {
                    case CardType.Loyalty:
                        setCard(data[0] as Card<LoyaltyCardData>);
                        setResults((data[0] as Card<LoyaltyCardData>).data?.transactions?.reverse() ?? []);
                        setBookings(data[1] ?? []);
                        break;
                    case CardType.Gift:
                        setCard(data[0] as Card<GiftCardData>);
                        setResults((data[0] as Card<GiftCardData>).data?.transactions?.reverse() ?? []);
                        setBookings(data[1] ?? []);
                        break;
                }
            }
        } catch (e) {
            // Log error
            console.log(e);

            // Set error
            setError(true);
        } finally {
            // Stop loading
            setIsLoading(false);
        }
    };

    // On tab change
    useEffect(() => {
        // Load
        load();

        // Scroll to top
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Build
    return (
        <>
            {isloading && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '32px',
                    }}
                >
                    <Loader size={48} />
                </Box>
            )}
            {!isloading && error && (
                <ErrorState
                    icon={type === CardType.Loyalty ? <CardMembershipIcon /> : <CardGiftcardIcon />}
                    onTryAgainClick={() => {
                        // Load again
                        load();
                    }}
                />
            )}
            {!isloading && !error && card && (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '8px',
                    }}
                >
                    <VaidosaCard card={card} index={0} direction="left" />
                </Box>
            )}
            {!isloading && !error && results && results.length > 0 && bookings && (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '32px',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '600px',
                        }}
                    >
                        {results.map((transaction: CardTransaction, index: number) => {
                            // Build
                            return (
                                <CardTransactionLine
                                    key={`transaction-${index}`}
                                    transaction={transaction}
                                    bookings={bookings}
                                />
                            );
                        })}
                    </Box>
                </Box>
            )}
            {!isloading && !error && results && results.length === 0 && (
                <EmptyState
                    height={60}
                    icon={type === CardType.Loyalty ? <CardMembershipIcon /> : <CardGiftcardIcon />}
                    title={t('routes.office.user.card.empty.title')}
                    description={t('routes.office.user.card.empty.description')}
                />
            )}
        </>
    );
}

export default function OfficeUser() {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);

    // Get params
    let { userId } = useParams();

    // Translations
    const { t } = useTranslation();

    // Get query
    const query = new URLSearchParams(window.location.search);
    const queryValues = {
        tab: query.get('tab'),
    };

    // Hold state
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [user, setUser] = useState<User | null>(null);
    const [currentTab, setCurrentTab] = useState(queryValues.tab ? parseInt(queryValues.tab ?? '0') : 0);

    // Actions: Load Profile
    const loadProfile = async () => {
        try {
            // Reset error
            setError(false);

            // Start loading
            setIsLoading(true);

            // Get user
            const data = await UsersRepository.instance.getById(userId ?? '');

            // Set
            setUser(data);
        } catch (e) {
            // Log error
            console.log(e);

            // Set error
            setError(true);
        } finally {
            // Stop loading
            setIsLoading(false);
        }
    };

    // On mount
    useEffect(() => {
        // Get
        loadProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // On tab change
    useEffect(() => {
        // Scroll to top
        window.scrollTo(0, 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab]);

    // Get name
    const userComplateName: string | null = user ? UsersRepository.instance.getFullName(user) : '';
    const words = userComplateName?.split(' ') ?? [];
    let userFullName = [words[0], words.length > 1 ? words[words.length - 1] : ''].join(' ').trim();

    // Check age
    if (user?.birthday) {
        // Mark today
        const today = new Date();

        // Calculate age in years
        let age = today.getFullYear() - user.birthday.getFullYear();

        // Check month
        const month = today.getMonth() - user.birthday.getMonth();

        // Eval
        if (month < 0 || (month === 0 && today.getDate() < user.birthday.getDate())) {
            // Decrease age
            age--;
        }

        // Append age to title
        userFullName += `, ${age}`;
    }

    // Define tabs
    const tabs: Array<{
        index: number;
        label: string;
        icon: ReactNode;
    }> = [
        {
            index: 0,
            label: t('routes.office.user.tabs.profile'),
            icon: <PersonIcon />,
        },
        {
            index: 1,
            label: t('routes.office.user.tabs.history'),
            icon: <HistoryIcon />,
        },
        {
            index: 2,
            label: t('routes.office.user.tabs.loyalty'),
            icon: <CardMembershipIcon />,
        },
        {
            index: 3,
            label: t('routes.office.user.tabs.gift'),
            icon: <CardGiftcardIcon />,
        },
    ];

    // Build
    return (
        <>
            <Page
                title={userFullName ?? ''}
                onRefresh={async () => {
                    // Load again
                    loadProfile();
                }}
                actions={[]}
            >
                <Container>
                    {isLoading && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '32px',
                            }}
                        >
                            <Loader size={48} />
                        </Box>
                    )}
                    {!isLoading && !error && userId && (
                        <Box sx={{ width: '100%' }}>
                            {currentTab === 0 && <ProfileTab user={user} />}
                            {currentTab === 1 && <HistoryTab userId={userId} currentTab={currentTab} />}
                            {currentTab === 2 && <CardTab userId={userId} type={CardType.Loyalty} />}
                            {currentTab === 3 && <CardTab userId={userId} type={CardType.Gift} />}
                        </Box>
                    )}
                    {!isLoading && error && (
                        <ErrorState
                            icon={<PersonIcon />}
                            onTryAgainClick={() => {
                                // Load again
                                loadProfile();
                            }}
                        />
                    )}
                </Container>
                <Box
                    sx={{
                        height: `${TOOLBAR_HEIGHT + appContext.state.insets.bottom + SCROLL_THRESHOLD}px`,
                    }}
                ></Box>
            </Page>

            {/* Bottom Navigation */}
            <Paper
                elevation={16}
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: `${56 + appContext.state.insets.bottom}px`,
                    zIndex: 10,
                }}
            >
                <BottomNavigation
                    showLabels
                    value={currentTab}
                    onChange={(event, index) => {
                        // Get selected tab
                        const selectedTab = tabs[index];

                        // Set selected tab
                        setCurrentTab(selectedTab.index);
                    }}
                    sx={{
                        width: '100%',
                    }}
                    className={styles.bottomNavigation}
                >
                    {tabs.map((tab, index) => {
                        return (
                            <Grow in={true} mountOnEnter unmountOnExit timeout={(index + 1) * 200} key={tab.index}>
                                <BottomNavigationAction
                                    className={`${currentTab === tab.index && styles.selected}`}
                                    label={tab.label}
                                    icon={tab.icon}
                                />
                            </Grow>
                        );
                    })}
                </BottomNavigation>
                {appContext.state.insets.bottom > 0 && (
                    <BottomNavigation
                        sx={{
                            width: '100%',
                            height: `${appContext.state.insets.bottom}px`,
                        }}
                    ></BottomNavigation>
                )}
            </Paper>
        </>
    );
}
