import { NumberType, parsePhoneNumber, PhoneNumber } from 'libphonenumber-js/max';

export class ValidationManager {
    // Singleton Instance
    private static _instance: ValidationManager;

    // Private constructor
    private constructor() {}

    static get instance(): ValidationManager {
        if (!this._instance) {
            this._instance = new ValidationManager();
        }

        return this._instance;
    }

    /**
     * Validates if a given Phone Number is valid
     * @param phoneNumber Phone Number to validate
     * @param types Allowed types to consider this phone number as valid
     * @returns Valid or not
     */
    public isPhoneNumberValid(phoneNumber: string, types?: Array<NumberType>): boolean {
        try {
            // Parse it
            const parsedPhoneNumber: PhoneNumber | undefined = parsePhoneNumber(phoneNumber);

            // Validate it
            return (
                parsedPhoneNumber &&
                parsedPhoneNumber.isValid() &&
                (types ? types.includes(parsedPhoneNumber.getType()) : true)
            );
        } catch (error) {
            // Failed to parse
            return false;
        }
    }
}
