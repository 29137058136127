import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Box, Button, Grow, IconButton, Typography } from '@mui/material';
import { MouseEventHandler, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../contexts/app/context';
import { AppContextType } from '../../contexts/app/types';
import { ServiceCategories } from '../../databases/service-categories';
import { Service } from '../../interfaces/service';
import { FooterSheet } from '../footer-sheet/footer-sheet';

export function SelectedServices({
    onClick,
    nextLabel,
    services,
}: {
    onClick?: MouseEventHandler<any> | undefined;
    nextLabel: string;
    services?: Array<Service>;
}) {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);

    // Translations
    const { t } = useTranslation();

    // Pick the right list of services
    const servicesList = services ?? appContext.state.services;

    // Build
    return (
        <>
            {servicesList.length > 0 && (
                <>
                    <Box
                        className="content-padding-for-footer-sheet"
                        sx={{
                            height: `${68.5 + servicesList.length * 48}px`,
                        }}
                    ></Box>
                    <FooterSheet>
                        <Box
                            sx={{
                                maxWidth: '425px',
                                width: '100%',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '16px',
                                flexDirection: 'column',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                }}
                            >
                                {servicesList.map((service, index) => {
                                    return (
                                        <Grow in={true} key={`selected-service-${index}`}>
                                            <Box
                                                className={`selected-service-${index}`}
                                                key={`selected-service-${index}`}
                                                sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    gap: '8px',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    marginTop: '8px',
                                                }}
                                            >
                                                {service.category && (
                                                    <Box
                                                        sx={{
                                                            paddingLeft: '8px',
                                                            paddingRight: '8px',
                                                            paddingTop: '2px',
                                                            paddingBottom: '2px',
                                                            backgroundColor: ServiceCategories[service.category].color,
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            borderRadius: '24px',
                                                            color: 'white',
                                                        }}
                                                    >
                                                        <Typography variant="caption" fontWeight={500}>
                                                            {t(ServiceCategories[service.category].translationKey)}
                                                        </Typography>
                                                    </Box>
                                                )}
                                                <Typography
                                                    variant="caption"
                                                    fontWeight={500}
                                                    textAlign={'left'}
                                                    sx={{
                                                        flex: 1,
                                                    }}
                                                >
                                                    {service.name}
                                                </Typography>
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => {
                                                        // Remove Service
                                                        appContext.setServices(
                                                            appContext.state.services.filter(
                                                                (s) => s.id !== service.id,
                                                            ),
                                                        );
                                                    }}
                                                >
                                                    <RemoveCircleOutlineIcon />
                                                </IconButton>
                                            </Box>
                                        </Grow>
                                    );
                                })}
                            </Box>
                            <Button
                                variant="contained"
                                endIcon={<NavigateNextIcon />}
                                fullWidth
                                sx={{
                                    marginTop: '16px',
                                }}
                                onClick={onClick}
                            >
                                {nextLabel}
                            </Button>
                        </Box>
                    </FooterSheet>
                </>
            )}
        </>
    );
}
