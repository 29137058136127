import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Container, IconButton, TextField, Typography } from '@mui/material';
import i18n from 'i18next';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Logo from '../../components/logo/logo';
import { default as PhoneNumberInput } from '../../components/phone-number-input/phone-number-input';
import Square from '../../components/square/square.component';
import { SubmitButton, SubmitButtonState } from '../../components/submit-button/submit-button';
import { AppContext } from '../../contexts/app/context';
import { AppContextType } from '../../contexts/app/types';
import { AuthContext } from '../../contexts/auth/context';
import { AuthContextType } from '../../contexts/auth/types';
import { OperativeSystem } from '../../enums/operative-system';
import { SignInError } from '../../enums/sign-in-error';
import { AppLanguageTag } from '../../i18n/types';
import { NativeKnight } from '../../native-knight';
import { RoutesNames } from '../../routes-names';
import styles from './sign-in.module.scss';

export default function SignIn() {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);
    const authContext: AuthContextType = useContext(AuthContext);

    // Get query
    const query = new URLSearchParams(window.location.search);
    const queryValues = {
        phoneNumber: query.get('phoneNumber'),
        redirectTo: query.get('redirectTo'),
    };

    // Hold Refs
    const submitButtonRef = useRef<HTMLElement>(null);

    // Hold state
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
        queryValues.phoneNumber ? `+${queryValues.phoneNumber}` : undefined,
    );
    const [requestButtonState, setRequestButtonState] = useState(SubmitButtonState.Normal);
    const [confirmButtonState, setConfirmButtonState] = useState(SubmitButtonState.Normal);
    const [otpRequested, setOtpRequested] = useState(false);
    const [otp, setOtp] = useState('');

    // Translations
    const { t } = useTranslation();

    // Get navigation
    const navigate = useNavigate();

    // Actions
    const requestOtp = (event: any) => {
        // Prevent default
        event.preventDefault();

        // Validate phoneNumber
        if (!phoneNumber) {
            return;
        }

        // Start Loading
        setRequestButtonState(SubmitButtonState.Loading);

        try {
            // Create recaptcha element
            const recaptchaElement = document.createElement('div');
            recaptchaElement.id = 'recaptcha';
            document.getElementById('recaptchaContainer')?.appendChild(recaptchaElement);
        } catch (e) {
            // Log
            console.debug(e);
        }

        // Request OTP
        authContext.requestOtp('recaptcha', phoneNumber).then((success: boolean) => {
            // Eval
            if (success) {
                // Update state
                setOtpRequested(true);
                setRequestButtonState(SubmitButtonState.Normal);
            } else {
                // Update state
                setRequestButtonState(SubmitButtonState.Error);

                // Show message
                if (appContext.state.operativeSystem === OperativeSystem._Unknown) {
                    // Alert
                    alert('Request OTP failed');
                } else {
                    NativeKnight.instance.showAlert('Request OTP', 'Failed');
                }
            }
        });
    };

    const confirmOtp = (otp: string) => {
        // Start Loading
        setConfirmButtonState(SubmitButtonState.Loading);

        // Confirm
        authContext.confirmOtp(otp).then((signInError: SignInError | undefined) => {
            if (signInError) {
                // Update state
                setConfirmButtonState(SubmitButtonState.Error);

                // Show message
                // if (appContext.state.operativeSystem === OperativeSystem._Unknown) {
                //     // Alert
                //     alert('Confirm OTP failed - ' + signInError);
                // } else {
                //     NativeKnight.instance.showAlert('Confirm OTP failed', signInError);
                // }
            } else {
                // Update state
                setConfirmButtonState(SubmitButtonState.Normal);

                // System will redirect to office automatically
            }
        });
    };

    const onOtpChange = (event: any) => {
        // Get OTP value
        const otp = event.target.value;

        // Update state
        setOtp(otp);

        // Evaluate length
        if (otp.length === 6) {
            // Auto submit
            confirmOtp(otp);
        }
    };

    const resetOtpRequest = () => {
        // Clear Captcha
        if ((window as any).recaptchaVerifier) {
            (window as any).recaptchaVerifier.clear();
            (window as any).recaptchaVerifier = undefined;
        }

        // Delete element
        const recaptchaElement = document.getElementById('recaptcha');
        if (recaptchaElement) {
            recaptchaElement.remove();
        }

        // Go back
        setOtpRequested(false);

        // Scroll to top
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    };

    // On mount
    useEffect(() => {
        // If not logged in
        if (!authContext.state.firebaseUser) {
            // Reset
            resetOtpRequest();

            // Update Badge Number
            NativeKnight.instance.setBadgeNumber(0);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Build
    if (authContext.state.firebaseUser) {
        // Eval redirect
        if (queryValues.redirectTo === RoutesNames.Office_NewAppointment) {
            // Redirect to
            return (
                <Navigate
                    to={`/${RoutesNames.Office}/${RoutesNames.Office_NewAppointment}?redirectedFrom=${RoutesNames.SignIn}`}
                />
            );
        }

        // User is logged in, redirect to office
        return <Navigate to={`/${RoutesNames.Office}`} />;
    } else {
        // User is not logged in, show sign in page
        return (
            <Container
                maxWidth="xs"
                className={styles.container}
                sx={{
                    display: 'flex',
                    height: '100vh',
                }}
            >
                <Square top="-25px" right="-25px" />
                <Square bottom="-25px" left="-25px" color="#38B6FF" />
                <Box
                    sx={{
                        margin: 'auto',
                        padding: '16px',
                    }}
                >
                    {!otpRequested && (
                        <>
                            <Box
                                sx={{
                                    paddingTop: '16px',
                                }}
                            >
                                <Logo />
                            </Box>
                            {!queryValues.redirectTo && (
                                <Typography
                                    variant="body2"
                                    gutterBottom
                                    textAlign="center"
                                    sx={{
                                        marginTop: '32px',
                                        marginBottom: '16px',
                                    }}
                                >
                                    {t('routes.signIn.welcome3')} 📱
                                </Typography>
                            )}
                            {queryValues.redirectTo === RoutesNames.Office_NewAppointment && (
                                <Typography
                                    variant="body2"
                                    gutterBottom
                                    textAlign="center"
                                    sx={{
                                        marginTop: '32px',
                                        marginBottom: '16px',
                                    }}
                                >
                                    {t('routes.signIn.welcomeNewAppointment')} 📱
                                </Typography>
                            )}
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                            >
                                <form onSubmit={requestOtp}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <PhoneNumberInput
                                            value={phoneNumber}
                                            onChange={(value: string | undefined) => {
                                                setPhoneNumber(value);
                                            }}
                                            disabled={requestButtonState === SubmitButtonState.Loading}
                                        />
                                        <Box
                                            sx={{
                                                marginTop: '16px',
                                            }}
                                        >
                                            <SubmitButton
                                                label={`${t('routes.signIn.enter')} 🚪`}
                                                state={requestButtonState}
                                                onStateChange={(state: SubmitButtonState) => {
                                                    setRequestButtonState(state);
                                                }}
                                                innerRef={submitButtonRef}
                                                disabled={
                                                    !phoneNumber || requestButtonState === SubmitButtonState.Loading
                                                }
                                                type="submit"
                                            />
                                        </Box>
                                    </Box>
                                </form>
                            </Box>
                            <Box className={styles.legalDocuments} sx={{ textAlign: 'center', marginTop: '16px' }}>
                                {i18n.language === AppLanguageTag.ptPT && (
                                    <Typography variant="caption" gutterBottom textAlign="center" sx={{}}>
                                        Ao iniciar sessão, concordas com as nossas{' '}
                                        <Link to={`../${RoutesNames.TermsOfUse}`}>Condições Gerais</Link> e{' '}
                                        <Link to={`../${RoutesNames.PrivacyPolicy}`}>Política de Privacidade</Link>.
                                    </Typography>
                                )}
                                {i18n.language === AppLanguageTag.enUS && (
                                    <Typography variant="caption" gutterBottom textAlign="center" sx={{}}>
                                        By logging in, you agree to our{' '}
                                        <Link to={`../${RoutesNames.TermsOfUse}`}>General Conditions</Link> and{' '}
                                        <Link to={`../${RoutesNames.PrivacyPolicy}`}>Privacy Policy</Link>.
                                    </Typography>
                                )}
                            </Box>
                            <Box className={styles.legalDocuments} sx={{ textAlign: 'center', marginTop: '16px' }}>
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    sx={{
                                        zIndex: 2,
                                    }}
                                    onClick={() => {
                                        // Go back
                                        navigate(-1);
                                    }}
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                            </Box>
                        </>
                    )}
                    {otpRequested && (
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        >
                            <Box
                                sx={{
                                    paddingTop: '16px',
                                }}
                            >
                                <Logo />
                            </Box>
                            <Typography
                                variant="body1"
                                gutterBottom
                                textAlign="center"
                                sx={{
                                    marginTop: '32px',
                                    marginBottom: '16px',
                                }}
                            >
                                {t('routes.signIn.otpInput')}
                            </Typography>
                            <form
                                onSubmit={(event: any) => {
                                    // Prevent default
                                    event.preventDefault();

                                    // Submit OTP
                                    confirmOtp(otp);
                                }}
                            >
                                <TextField
                                    variant="filled"
                                    label={t('routes.signIn.otpCode')}
                                    value={otp}
                                    onChange={onOtpChange}
                                    autoComplete="one-time-code"
                                    type="number"
                                    sx={{
                                        width: '100%',
                                    }}
                                    disabled={confirmButtonState === SubmitButtonState.Loading}
                                    autoFocus
                                />
                                <Box
                                    sx={{
                                        marginTop: '16px',
                                    }}
                                >
                                    <SubmitButton
                                        label={`${t('routes.signIn.confirm')} ✅`}
                                        state={confirmButtonState}
                                        onStateChange={(state: SubmitButtonState) => {
                                            setConfirmButtonState(state);
                                        }}
                                        disabled={
                                            !otp || otp.length < 6 || confirmButtonState === SubmitButtonState.Loading
                                        }
                                        type="submit"
                                    />
                                    <Button
                                        sx={{
                                            marginTop: '8px',
                                            width: '100%',
                                        }}
                                        onClick={() => {
                                            // Reset
                                            resetOtpRequest();
                                        }}
                                        disabled={confirmButtonState === SubmitButtonState.Loading}
                                    >
                                        {t('routes.signIn.back')}
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    )}
                    <div id="recaptchaContainer"></div>
                </Box>
            </Container>
        );
    }
}
