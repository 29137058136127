import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LockIcon from '@mui/icons-material/Lock';
import LockClockIcon from '@mui/icons-material/LockClock';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Radio,
    RadioGroup,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import i18n from 'i18next';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Container } from '../../../components/container/container';
import { Loader } from '../../../components/loader/loader';
import { OfficeTitle } from '../../../components/office-title/office-title';
import { Page } from '../../../components/page/page';
import PhoneNumberInput from '../../../components/phone-number-input/phone-number-input';
import { ServiceCategoryData, ServiceCategoryDataLine } from '../../../components/services-screen/services-screen';
import { TimeTravellerNav } from '../../../components/time-traveller-nav/time-traveller-nav';
import { AppContext } from '../../../contexts/app/context';
import { AppContextType } from '../../../contexts/app/types';
import { AuthContext } from '../../../contexts/auth/context';
import { AuthContextType } from '../../../contexts/auth/types';
import { ServiceCategories } from '../../../databases/service-categories';
import { ServiceCategory } from '../../../enums/service-category';
import { ThemeMode } from '../../../enums/theme-mode';
import { AppLanguageTag } from '../../../i18n/types';
import { ProviderService } from '../../../interfaces/provider-service';
import { BookingsRepository } from '../../../repositories/bookings';
import { ProvidersRepository } from '../../../repositories/providers';
import { RoutesNames } from '../../../routes-names';
import { generateTimeSlots, hexToRgb, moveElementOnArray } from '../../../utils/ts-utils';
import variables from '../../../variables.module.scss';

function OfficeProviderNewEventNewAppointment() {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);
    const authContext: AuthContextType = useContext(AuthContext);

    // Translations
    const { t } = useTranslation();

    // Get navigation
    const navigate = useNavigate();

    // Get query
    const query = new URLSearchParams(window.location.search);
    const queryValues = {
        day: query.get('day'),
    };

    // Calculate initial date time
    let initialStartDateTime = queryValues.day ? moment(queryValues.day) : null;

    // Hold State
    const [servicesCategoryData, setServicesCategoryData] = useState<Array<ServiceCategoryData> | null>(null);
    const [selectedProviderServices, setSelectedProviderServices] = useState<Array<ProviderService>>([]);
    const [startDateTime, setStartDateTime] = useState<moment.Moment | null>(null);
    const [name, setName] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>('');
    const [observations, setObservations] = useState<string>('');
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

    // Hold State (for errors)
    const [nameError, setNameError] = useState<boolean>(false);
    const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
    const [termsNotAccepted, setTermsNotAccepted] = useState<boolean>(false);

    // Hold State for Dialogs
    const [servicesPickerDialogOpen, setServicesPickerDialogOpen] = useState<boolean>(false);
    const [servicesPickedIndex, setServicesPickedIndex] = useState<{
        [key: string]: ProviderService;
    }>({});
    const [datePickerDialogOpen, setDatePickerDialogOpen] = useState<boolean>(false);
    const [availability, setAvailability] = useState<Array<Date> | null | undefined>(undefined);
    const [selectedSlot, setSelectedSlot] = useState<string | null>(null);
    const [nextAvailableBookingDay, setNextAvailableBookingDay] = useState<moment.Moment | undefined>(undefined);
    const [nextAvailableBookingTime, setNextAvailableBookingTime] = useState<moment.Moment | undefined>(undefined);
    const [day, setDay] = useState<moment.Moment>(moment());

    const [submitButtonState, setSubmitButtonState] = useState<'ok' | 'loading' | 'error'>('ok');

    // Hold State (for UI)
    const [errorMessage, setErrorMessage] = useState<string>(t('routes.office.providerNewEvent.errorTitle'));
    const [errorCode, setErrorCode] = useState<string | null>(null);

    // Init
    let newTitle = '';
    let newTitleSub = '';

    // Eval
    if (startDateTime) {
        if (startDateTime.isSame(moment(), 'day')) {
            newTitle = t('components.timeTraveller.today');
        } else if (startDateTime.isSame(moment().add(1, 'day'), 'day')) {
            newTitle = t('components.timeTraveller.tomorrow');
        } else if (startDateTime.isSame(moment().subtract(1, 'day'), 'day')) {
            newTitle = t('components.timeTraveller.yesterday');
        } else {
            newTitle = startDateTime.fromNow();
        }

        // Add day of the week
        newTitleSub = `${startDateTime.format('dddd')}, ${startDateTime.format('DD/MM/YYYY HH:mm')}`;
    }

    // Calculate start and end times
    let accumulatedTime = 0;
    const times: Array<{
        start: moment.Moment;
        end: moment.Moment;
    }> = [];
    if (startDateTime) {
        for (const providerService of selectedProviderServices) {
            // Get duration
            const duration = providerService.durationInMinutes ?? 0;

            // Calculate start
            const start = startDateTime.clone().add(accumulatedTime, 'minutes');

            // Calculate end
            const end = start.clone().add(duration, 'minutes');

            // Add
            times.push({
                start: start,
                end: end,
            });

            // Accumulate
            accumulatedTime += duration;
        }
    }

    // Actions: Update Availability
    const updateAvailability = async (dayToUse?: moment.Moment) => {
        // Eval
        if (selectedProviderServices.length === 0) {
            // Nothing selected, therefore, nothing to do
            return;
        }

        // Eval keepDay
        let nextAvailableBookingDayMoment: moment.Moment | undefined = undefined;
        let nextAvailableBookingTimeMoment: moment.Moment | undefined = undefined;
        if (!dayToUse) {
            // Get
            const nextAvailableBookingDate = await ProvidersRepository.instance.getProviderNextAvailability(
                authContext.state.me?.provider?.id ?? '',
                selectedProviderServices.map((providerService) => providerService.serviceId),
            );

            // Parse
            nextAvailableBookingDayMoment = moment(nextAvailableBookingDate.start).startOf('day');
            nextAvailableBookingTimeMoment = moment(nextAvailableBookingDate.start);

            // Set
            setNextAvailableBookingDay(nextAvailableBookingDayMoment);
            setNextAvailableBookingTime(nextAvailableBookingTimeMoment);
            setDay(nextAvailableBookingDayMoment);
        } else if (nextAvailableBookingTime) {
            // Check if it's the same day
            if (dayToUse.isSame(nextAvailableBookingDay, 'day')) {
                // Set
                nextAvailableBookingDayMoment = nextAvailableBookingDay;
                nextAvailableBookingTimeMoment = nextAvailableBookingTime;
            }
        }

        // Get availability
        const data = await ProvidersRepository.instance.getProviderDayAvailability(
            authContext.state.me?.provider?.id ?? '',
            nextAvailableBookingDayMoment?.toDate() ?? dayToUse?.toDate() ?? day.toDate(),
        );

        // Calculate job duration needed
        let jobDuration = 0;
        for (const providerService of selectedProviderServices) {
            // Check
            if (providerService) {
                // Add
                jobDuration += providerService.durationInMinutes ?? 0;
            }
        }

        // Generate time slots
        const timeSlots = generateTimeSlots(
            nextAvailableBookingDayMoment,
            data,
            jobDuration,
            nextAvailableBookingTimeMoment ?? dayToUse ?? day,
        );

        // Eval
        if (timeSlots.length > 0) {
            // Set First Slot as selected
            setSelectedSlot(moment(timeSlots[0]).format('HH:mm'));
        } else {
            // Set
            setSelectedSlot(null);
        }

        // Set
        setAvailability(timeSlots);
    };

    // Actions: Submit
    const submit = () => {
        // Clear all errors
        setNameError(false);
        setPhoneNumberError(false);
        setTermsNotAccepted(false);
        setErrorMessage('');
        setErrorCode(null);

        // Flag
        let hasErrors = false;

        // Validate Name
        if (!name || name.trim() === '') {
            setNameError(true);
            hasErrors = true;
        }

        // Validate Phone Number
        if (!phoneNumber || phoneNumber.trim() === '') {
            setPhoneNumberError(true);
            hasErrors = true;
        }

        // Validate Terms
        if (!termsAccepted) {
            setTermsNotAccepted(true);
            hasErrors = true;
        }

        // Validate start date time
        if (!startDateTime || startDateTime.isBefore(moment())) {
            hasErrors = true;
        }

        // Validate provider services
        if (selectedProviderServices.length === 0) {
            hasErrors = true;
        }

        // Check for errors
        if (!hasErrors) {
            // Start loading
            setSubmitButtonState('loading');

            // Sent to backend
            (async () => {
                if (startDateTime) {
                    try {
                        await BookingsRepository.instance.createAppointment({
                            // Booking
                            providerId: authContext.state.me?.provider?.id ?? '',
                            servicesIds: selectedProviderServices.map((providerService) => {
                                return providerService.serviceId;
                            }),
                            startTime: startDateTime.toDate(),
                            // Client
                            clientName: name,
                            clientPhoneNumber: phoneNumber ?? '',
                            clientNotes: observations.trim() !== '' ? observations : undefined,
                        });

                        // Navigate back but point to the start date day
                        if (startDateTime && !startDateTime.isSame(initialStartDateTime, 'day')) {
                            navigate(
                                `/${RoutesNames.Office}/${RoutesNames.Office_Professional}/${authContext.state.me
                                    ?.provider?.id}/schedule?day=${startDateTime.format('YYYY-MM-DD')}`,
                                {
                                    replace: true,
                                },
                            );
                        } else {
                            // Just navigate back
                            navigate(-1);
                        }
                    } catch (error: any) {
                        // Look for a status code
                        if (
                            error.response &&
                            error.response.data &&
                            error.response.data.errors &&
                            error.response.data.errors.length > 0
                        ) {
                            // Display the error message
                            console.warn(error.response.data.errors[0].code);
                            console.log(error.response.status);

                            // Set error message
                            setErrorMessage(
                                t(
                                    `routes.office.providerNewEvent.appointment.errors.${error.response.data.errors[0].code}`,
                                ),
                            );
                            setErrorCode(error.response.data.errors[0].code);
                        } else {
                            // Default error
                            setErrorMessage(t('routes.office.providerNewEvent.appointment.errorTitle'));
                        }

                        // Set button error
                        setSubmitButtonState('error');

                        // Set timeout
                        setTimeout(() => {
                            setSubmitButtonState('ok');
                        }, 1000);
                    }
                }
            })();
        } else {
            // Set button error
            setSubmitButtonState('error');

            // Set timeout
            setTimeout(() => {
                setSubmitButtonState('ok');
            }, 1000);
        }
    };

    // On mount
    useEffect(() => {
        // Check provider
        if (authContext.state.me?.provider) {
            (async () => {
                // Load provider services
                const providerServices = await ProvidersRepository.instance.getProviderServices(
                    authContext.state.me?.provider?.id ?? '',
                    0,
                    1000,
                    true,
                );

                // Filter services: only active ones
                providerServices.data = providerServices.data.filter((providerService) => providerService.isActive);

                // Init results
                const results: Array<ServiceCategoryData> = [];

                // Put everything together
                const serviceCategories: Array<ServiceCategory> = Object.keys(
                    ServiceCategories,
                ) as Array<ServiceCategory>;

                // For each category
                for (const serviceCategory of serviceCategories) {
                    // Create lines
                    const lines: Array<ServiceCategoryDataLine> = [];

                    // For each provider service
                    for (const providerService of providerServices.data) {
                        // Check if it's the same category
                        if (providerService.service && providerService.service.category === serviceCategory) {
                            // Add line
                            lines.push({
                                providerId: providerService.providerId,
                                service: providerService.service,
                                providerService: providerService,
                                form: {
                                    isActive: providerService?.isActive ?? false,
                                    durationInMinutes: providerService?.durationInMinutes ?? 0,
                                    price: providerService?.price ?? 0,
                                    photosUrls: providerService?.photosUrls ?? [],
                                },
                            });
                        }
                    }

                    // Eval if we have at least on service on the category
                    if (lines.length > 0) {
                        // Sort lines by display order
                        lines.sort((a, b) => {
                            return (a.service.displayOrder ?? 0) - (b.service.displayOrder ?? 0);
                        });

                        // Create a new Entry
                        results.push({
                            category: serviceCategory,
                            lines,
                        });
                    }
                }

                // Set provider services
                setServicesCategoryData(results);
            })();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Check provider
    if (!authContext.state.me?.provider) {
        return <></>;
    }

    // Flags
    const dateEditIsAllowed = selectedProviderServices.length > 0;
    const clientEditIsAllowed = startDateTime !== null;

    // Build
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                }}
            >
                <Box>
                    <Alert
                        sx={{
                            marginBottom: '16px',
                        }}
                        severity="info"
                    >
                        {t('routes.office.providerNewEvent.appointment.info')}
                    </Alert>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                                flex: 1,
                            }}
                        >
                            <OfficeTitle color="#ff66c4" text={t('routes.office.providerNewEvent.what')} align="left" />
                        </Box>
                        <IconButton
                            sx={{
                                marginRight: '-8px',
                            }}
                            aria-label="edit"
                            onClick={() => {
                                // Build index
                                const index: {
                                    [key: string]: ProviderService;
                                } = {};

                                // Add
                                selectedProviderServices.forEach((providerService) => {
                                    index[providerService.id ?? ''] = providerService;
                                });

                                // Select services
                                setServicesPickedIndex(index);

                                // Open
                                setServicesPickerDialogOpen(true);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    </Box>
                    {selectedProviderServices.length === 0 && (
                        <Typography variant="body1">
                            {t('routes.office.providerNewEvent.appointment.emptyServices')}
                        </Typography>
                    )}
                    {selectedProviderServices.length > 0 && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '8px',
                            }}
                        >
                            {selectedProviderServices.map((providerService, index) => {
                                return (
                                    <Box key={`provider-service-${index}`}>
                                        {times[index] && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    gap: '8px',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        flex: 1,
                                                    }}
                                                >{`${times[index].start.format('HH:mm')} - ${times[index].end.format(
                                                    'HH:mm',
                                                )}`}</Box>
                                                {times.length > 1 && (
                                                    <>
                                                        <IconButton
                                                            size="small"
                                                            aria-label="up"
                                                            onClick={() => {
                                                                // Move up
                                                                const newOrder = moveElementOnArray(
                                                                    [...selectedProviderServices],
                                                                    index,
                                                                    index - 1,
                                                                );

                                                                // Set
                                                                setSelectedProviderServices(newOrder);
                                                            }}
                                                            disabled={index === 0}
                                                        >
                                                            <ArrowUpwardIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            size="small"
                                                            aria-label="down"
                                                            onClick={() => {
                                                                // Move down
                                                                const newOrder = moveElementOnArray(
                                                                    [...selectedProviderServices],
                                                                    index,
                                                                    index + 1,
                                                                );

                                                                // Set
                                                                setSelectedProviderServices(newOrder);
                                                            }}
                                                            disabled={index === times.length - 1}
                                                        >
                                                            <ArrowDownwardIcon />
                                                        </IconButton>
                                                    </>
                                                )}
                                            </Box>
                                        )}
                                        {providerService.service && (
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    gap: '8px',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    marginTop:
                                                        appContext.state.appointment?.providerServices.length === 1
                                                            ? '8px'
                                                            : '0',
                                                }}
                                            >
                                                {providerService.service.category && (
                                                    <Box
                                                        sx={{
                                                            paddingLeft: '8px',
                                                            paddingRight: '8px',
                                                            paddingTop: '2px',
                                                            paddingBottom: '2px',
                                                            backgroundColor:
                                                                ServiceCategories[providerService.service.category]
                                                                    .color,
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            borderRadius: '24px',
                                                            color: 'white',
                                                        }}
                                                    >
                                                        <Typography variant="caption" fontWeight={500}>
                                                            {t(
                                                                ServiceCategories[providerService.service.category]
                                                                    .translationKey,
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                )}
                                                <Typography
                                                    variant="caption"
                                                    fontWeight={500}
                                                    textAlign={'left'}
                                                    sx={{
                                                        flex: 1,
                                                    }}
                                                >
                                                    {providerService.service.name}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                );
                            })}
                        </Box>
                    )}
                </Box>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                                flex: 1,
                                opacity: dateEditIsAllowed ? 1 : 0.5,
                            }}
                        >
                            <OfficeTitle color="#ff66c4" text={t('routes.office.providerNewEvent.when')} align="left" />
                        </Box>
                        <IconButton
                            disabled={!dateEditIsAllowed}
                            sx={{
                                marginRight: '-8px',
                            }}
                            aria-label="edit"
                            onClick={() => {
                                // Open
                                setDatePickerDialogOpen(true);

                                // Reset availability
                                setAvailability(undefined);

                                // Update Availability
                                updateAvailability();
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    </Box>
                    {!startDateTime && (
                        <Typography
                            variant="body1"
                            sx={{
                                opacity: dateEditIsAllowed ? 1 : 0.5,
                            }}
                        >
                            {t('routes.office.providerNewEvent.appointment.emptyDate')}
                        </Typography>
                    )}
                    {startDateTime && (
                        <>
                            <Typography variant="body1" gutterBottom>
                                {newTitleSub}
                            </Typography>
                            <Typography variant="body2">{newTitle}</Typography>
                        </>
                    )}
                </Box>
                <Box>
                    <Box
                        sx={{
                            opacity: clientEditIsAllowed ? 1 : 0.5,
                        }}
                    >
                        <OfficeTitle color="#ff66c4" text={t('routes.office.providerNewEvent.you')} align="left" />
                    </Box>
                    <Box
                        sx={{
                            marginTop: '16px',
                            marginBottom: '16px',
                        }}
                    >
                        <PhoneNumberInput
                            disabled={!clientEditIsAllowed}
                            error={phoneNumberError}
                            variant="outlined"
                            value={phoneNumber}
                            onChange={(value: string | undefined) => {
                                // Clear errors
                                setPhoneNumberError(false);

                                // Set phone number
                                setPhoneNumber(value);
                            }}
                        />
                    </Box>
                    <FormControl
                        fullWidth
                        disabled={!clientEditIsAllowed}
                        sx={{
                            marginBottom: '16px',
                        }}
                    >
                        <InputLabel id="first-and-last-names-label">
                            {t('routes.office.providerNewEvent.form.firstAndLastName')}*
                        </InputLabel>
                        <OutlinedInput
                            error={nameError}
                            id="first-and-last-names"
                            value={name}
                            fullWidth
                            label={`${t('routes.office.providerNewEvent.form.firstAndLastName')}*`}
                            onChange={(event) => {
                                // Clear errors
                                setNameError(false);

                                // Set name
                                setName(event.target.value);
                            }}
                            endAdornment={
                                name && name.trim() !== '' ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="clear-name"
                                            onClick={() => {
                                                setName('');
                                            }}
                                            edge="end"
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ) : null
                            }
                        />
                    </FormControl>
                    <FormControl fullWidth disabled={!clientEditIsAllowed}>
                        <InputLabel id="observations-label">
                            {t('routes.office.providerNewEvent.form.observations')}
                        </InputLabel>
                        <OutlinedInput
                            multiline
                            rows={2}
                            id="observations"
                            value={observations}
                            fullWidth
                            label={t('routes.office.providerNewEvent.form.observations')}
                            onChange={(event) => {
                                // Set observations
                                setObservations(event.target.value);
                            }}
                        />
                    </FormControl>
                    <FormGroup>
                        <FormControlLabel
                            disabled={!clientEditIsAllowed}
                            control={
                                <Checkbox
                                    value={termsAccepted}
                                    onChange={(event) => {
                                        setTermsAccepted(event.target.checked);
                                    }}
                                    sx={{
                                        color: termsNotAccepted ? 'red' : 'text.primary',
                                    }}
                                />
                            }
                            label={
                                <Box
                                    sx={{
                                        fontSize: '14px',
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        paddingTop: '16px',
                                        'a, a:hover, a:visited, a:active': {
                                            color: 'inherit',
                                            textDecoration: 'underline',
                                        },
                                    }}
                                >
                                    {i18n.language === AppLanguageTag.ptPT && (
                                        <Typography variant="caption" gutterBottom textAlign="left" sx={{}}>
                                            Confirmo que eu e a cliente lemos e concordamos com as{' '}
                                            <Link to={`/${RoutesNames.TermsOfUse}`}>Condições Gerais</Link> e{' '}
                                            <Link to={`/${RoutesNames.PrivacyPolicy}`}>Política de Privacidade</Link>.
                                        </Typography>
                                    )}
                                    {i18n.language === AppLanguageTag.enUS && (
                                        <Typography variant="caption" gutterBottom textAlign="left" sx={{}}>
                                            I confirm that the client and I have read and agreed to the{' '}
                                            <Link to={`/${RoutesNames.TermsOfUse}`}>General Conditions</Link> and{' '}
                                            <Link to={`/${RoutesNames.PrivacyPolicy}`}>Privacy Policy</Link>.
                                        </Typography>
                                    )}
                                </Box>
                            }
                        />
                    </FormGroup>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        disabled={submitButtonState === 'loading' || !clientEditIsAllowed}
                        endIcon={
                            submitButtonState === 'loading' ? (
                                <CircularProgress size={18} color="inherit" />
                            ) : (
                                <CheckCircleOutlineIcon />
                            )
                        }
                        fullWidth
                        onClick={() => {
                            // Submit
                            submit();
                        }}
                        color={submitButtonState === 'error' ? 'error' : 'primary'}
                    >
                        {t('routes.office.providerNewEvent.submit')}
                    </Button>
                </Box>
                {errorCode && (
                    <Alert severity="error">
                        {errorMessage} ({errorCode})
                    </Alert>
                )}
                <Box
                    sx={{
                        height: '32px',
                    }}
                ></Box>
            </Box>
            <Dialog
                open={servicesPickerDialogOpen}
                onClose={() => {
                    // Close
                    setServicesPickerDialogOpen(false);

                    // Clear
                    setServicesPickedIndex({});
                }}
                scroll="paper"
                aria-labelledby="service-picker-dialog-title"
                aria-describedby="service-picker-dialog-description"
            >
                <DialogTitle fontWeight="bold" id="service-picker-dialog-title">
                    {t('routes.office.providerNewEvent.appointment.pickServices')}
                </DialogTitle>
                <DialogContent dividers={true}>
                    {!servicesCategoryData && (
                        <Box
                            sx={{
                                minWidth: '200px',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Loader size={48} />
                        </Box>
                    )}
                    {servicesCategoryData && servicesCategoryData.length > 0 && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '8px',
                                marginLeft: '-16px',
                                marginRight: '-16px',
                                marginTop: '-8px',
                                marginBottom: '-8px',
                            }}
                        >
                            {servicesCategoryData.map((serviceCategoryData: ServiceCategoryData, index: number) => {
                                // Build
                                return (
                                    <Box
                                        key={`serviceCategoryData-${serviceCategoryData.category}`}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '8px',
                                        }}
                                    >
                                        {serviceCategoryData.lines.map(
                                            (line: ServiceCategoryDataLine, index: number) => {
                                                // Define background color
                                                let background = undefined;
                                                if (servicesPickedIndex[line.providerService?.id ?? '']) {
                                                    // Convert to RGB
                                                    const mainRGB = hexToRgb(variables.firstColor);
                                                    const backRGB = hexToRgb(
                                                        appContext.state.themeMode === ThemeMode.Light
                                                            ? '#ffffff'
                                                            : '#383838',
                                                    );

                                                    // Build
                                                    background = `linear-gradient(270deg, rgba(${backRGB?.r}, ${backRGB?.g}, ${backRGB?.b}, 1) 32%, rgba(${mainRGB?.r}, ${mainRGB?.g}, ${mainRGB?.b}, 1) 100%)`;
                                                }

                                                return (
                                                    <Box
                                                        key={`serviceCategoryData-${serviceCategoryData.category}-line-${index}`}
                                                        sx={{
                                                            padding: '4px',
                                                            paddingLeft: '16px',
                                                            paddingRight: '16px',
                                                            borderRadius: '4px',
                                                            background,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-start',
                                                            gap: '8px',
                                                            minWidth: '240px',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                flex: 1,
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'flex-start',
                                                                gap: '2px',
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    paddingLeft: '8px',
                                                                    paddingRight: '8px',
                                                                    paddingTop: '2px',
                                                                    paddingBottom: '2px',
                                                                    backgroundColor:
                                                                        ServiceCategories[serviceCategoryData.category]
                                                                            .color,
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    borderRadius: '24px',
                                                                    color: 'white',
                                                                }}
                                                            >
                                                                <Typography variant="caption" fontWeight={500}>
                                                                    {t(
                                                                        ServiceCategories[serviceCategoryData.category]
                                                                            .translationKey,
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                            <Typography variant="body1" fontWeight={500}>
                                                                {line.providerService?.service?.name}
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    gap: '16px',
                                                                    opacity: 0.8,
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        gap: '2px',
                                                                        minWidth: '64px',
                                                                    }}
                                                                >
                                                                    <LocalOfferIcon fontSize="small" />
                                                                    <Typography variant="body2" fontWeight={500}>
                                                                        {line.providerService?.price?.toFixed(2)}€
                                                                    </Typography>
                                                                </Box>

                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        gap: '2px',
                                                                    }}
                                                                >
                                                                    <HourglassTopIcon fontSize="small" />
                                                                    <Typography variant="body2" fontWeight={500}>
                                                                        {appContext.getDisplayDurationText(
                                                                            line.providerService?.durationInMinutes ??
                                                                                0,
                                                                        )}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box>
                                                            <Checkbox
                                                                checked={
                                                                    servicesPickedIndex[
                                                                        line.providerService?.id ?? ''
                                                                    ] !== undefined &&
                                                                    servicesPickedIndex[
                                                                        line.providerService?.id ?? ''
                                                                    ] !== null
                                                                }
                                                                onChange={(event) => {
                                                                    // Eval
                                                                    if (event.target.checked) {
                                                                        if (line.providerService) {
                                                                            setServicesPickedIndex({
                                                                                ...servicesPickedIndex,
                                                                                [line.providerService?.id ?? '']:
                                                                                    line.providerService,
                                                                            });
                                                                        }
                                                                    } else {
                                                                        const newServicesPickedIndex = {
                                                                            ...servicesPickedIndex,
                                                                        };
                                                                        delete newServicesPickedIndex[
                                                                            line.providerService?.id ?? ''
                                                                        ];
                                                                        setServicesPickedIndex(newServicesPickedIndex);
                                                                    }
                                                                }}
                                                            />
                                                        </Box>
                                                    </Box>
                                                );
                                            },
                                        )}
                                    </Box>
                                );
                            })}
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            // Close
                            setServicesPickerDialogOpen(false);

                            // Clear
                            setServicesPickedIndex({});
                        }}
                        color="inherit"
                    >
                        {t('routes.office.providerNewEvent.appointment.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            // Close
                            setServicesPickerDialogOpen(false);

                            // Clear Start Date Time (since list changed)
                            setStartDateTime(null);

                            // Set Provider Services
                            setSelectedProviderServices(Object.values(servicesPickedIndex));

                            // Clear
                            setServicesPickedIndex({});
                        }}
                        disabled={!servicesCategoryData}
                    >
                        {t('routes.office.providerNewEvent.appointment.submit')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={datePickerDialogOpen}
                onClose={() => {
                    // Close
                    setDatePickerDialogOpen(false);
                }}
                scroll="paper"
                aria-labelledby="date-picker-dialog-title"
                aria-describedby="date-picker-dialog-description"
            >
                <DialogTitle fontWeight="bold" id="date-picker-dialog-title">
                    {t('routes.office.providerNewEvent.appointment.pickDate')}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Box
                        sx={{
                            marginLeft: '-16px',
                            marginRight: '-16px',
                            height: '60vh',
                        }}
                    >
                        <Box>
                            <TimeTravellerNav
                                minDate={nextAvailableBookingDay}
                                value={day}
                                onChange={(value: moment.Moment) => {
                                    // Set Day
                                    setDay(value);

                                    // Update Availability
                                    updateAvailability(value);
                                }}
                                disabled={availability === undefined}
                            />
                        </Box>
                        {availability === undefined && (
                            <Box
                                sx={{
                                    minWidth: '200px',
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '32px',
                                }}
                            >
                                <Loader size={48} />
                            </Box>
                        )}
                        {availability && availability.length === 0 && (
                            <>
                                <Typography
                                    variant="h6"
                                    align="center"
                                    sx={{
                                        marginTop: '16px',
                                        paddingLeft: '16px',
                                        paddingRight: '16px',
                                    }}
                                >
                                    {t('routes.office.showcase.noAvailabilityForThisDay')}
                                </Typography>
                                <Typography variant="body1" align="center">
                                    {t('routes.office.showcase.noAvailabilityForThisDayDescription')}
                                </Typography>
                            </>
                        )}
                        {availability && availability.length > 0 && (
                            <FormControl
                                sx={{
                                    marginTop: '16px',
                                    marginBottom: '16px',
                                }}
                            >
                                <RadioGroup
                                    row
                                    aria-labelledby="availability-radio-buttons-group-label"
                                    name="availability-radio-buttons-group"
                                    value={selectedSlot}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        // Set
                                        setSelectedSlot((event.target as HTMLInputElement).value);
                                    }}
                                    sx={{
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '8px',
                                    }}
                                >
                                    {availability.map((slot: Date, index: number) => {
                                        // Build
                                        return (
                                            <FormControlLabel
                                                sx={{
                                                    width: '90px',
                                                    border: '1px solid #ff66c4',
                                                    borderRadius: '24px',
                                                    margin: '0',
                                                }}
                                                key={`availability-slot-${index}`}
                                                value={moment(slot).format('HH:mm')}
                                                control={<Radio />}
                                                label={moment(slot).format('HH:mm')}
                                            />
                                        );
                                    })}
                                </RadioGroup>
                            </FormControl>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            // Close
                            setDatePickerDialogOpen(false);
                        }}
                        color="inherit"
                    >
                        {t('routes.office.providerNewEvent.appointment.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            // Close
                            setDatePickerDialogOpen(false);

                            // Split slot into hours and minutes
                            const [hours, minutes] = selectedSlot?.split(':') ?? ['0', '0'];

                            // Build value
                            const value = moment(day)
                                .startOf('day')
                                .set('hour', parseInt(hours))
                                .set('minute', parseInt(minutes));

                            // Set Start Date Time (since list changed)
                            setStartDateTime(value);
                        }}
                        disabled={!servicesCategoryData}
                    >
                        {t('routes.office.providerNewEvent.appointment.submit')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

function OfficeProviderNewEventNewLock() {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);
    const authContext: AuthContextType = useContext(AuthContext);

    // Translations
    const { t } = useTranslation();

    // Get navigation
    const navigate = useNavigate();

    // Get query
    const query = new URLSearchParams(window.location.search);
    const queryValues = {
        day: query.get('day'),
    };

    // Calculate initial date time
    let initialStartDateTime = queryValues.day ? moment(queryValues.day) : null;
    if (!initialStartDateTime || initialStartDateTime.isBefore(moment())) {
        // Cannot be on the past
        initialStartDateTime = moment().add(1, 'minute');
    }
    let initialEndDateTime = initialStartDateTime.clone().endOf('day');

    // Make seconds and milliseconds 0
    initialStartDateTime = initialStartDateTime.seconds(0).milliseconds(0);
    initialEndDateTime = initialEndDateTime.seconds(0).milliseconds(0);

    // Hold State
    const [startDateTime, setStartDateTime] = useState<moment.Moment | null>(initialStartDateTime);
    const [endDateTime, setEndDateTime] = useState<moment.Moment | null>(initialEndDateTime);
    const [observations, setObservations] = useState<string>('');

    // Hold State (for errors)
    const [submitButtonState, setSubmitButtonState] = useState<'ok' | 'loading' | 'error'>('ok');

    // Hold State (for UI)
    const [errorMessage, setErrorMessage] = useState<string>(t('routes.office.providerNewEvent.errorTitle'));
    const [errorCode, setErrorCode] = useState<string | null>(null);

    // Hold Refs
    const startDateTimeButtonRef = useRef<HTMLDivElement>(null);
    const endDateTimeButtonRef = useRef<HTMLDivElement>(null);

    // Init
    let newStartDateTimeTitle = '';
    let newStartDateTimeTitleSub = '';
    let newEndDateTimeTitle = '';
    let newEndDateTimeTitleSub = '';

    // Eval
    if (startDateTime) {
        if (startDateTime.isSame(moment(), 'day')) {
            newStartDateTimeTitle = t('components.timeTraveller.today');
        } else if (startDateTime.isSame(moment().add(1, 'day'), 'day')) {
            newStartDateTimeTitle = t('components.timeTraveller.tomorrow');
        } else if (startDateTime.isSame(moment().subtract(1, 'day'), 'day')) {
            newStartDateTimeTitle = t('components.timeTraveller.yesterday');
        } else {
            newStartDateTimeTitle = startDateTime.fromNow();
        }

        // Add day of the week
        newStartDateTimeTitleSub = `${startDateTime.format('dddd')}, ${startDateTime.format('DD/MM/YYYY HH:mm')}`;
    }

    // Eval
    if (endDateTime) {
        // Calculate duration
        newEndDateTimeTitle = appContext.getDisplayDurationText(endDateTime.diff(startDateTime, 'minutes'));

        // Add day of the week
        newEndDateTimeTitleSub = `${endDateTime.format('dddd')}, ${endDateTime.format('DD/MM/YYYY HH:mm')}`;
    }

    // Calculate start and end times
    let accumulatedTime = 0;
    const times: Array<{
        start: moment.Moment;
        end: moment.Moment;
    }> = [];
    if (appContext.state.appointment) {
        for (const providerService of appContext.state.appointment?.providerServices) {
            if (providerService.service) {
                // Get duration
                const duration = providerService.durationInMinutes ?? 0;

                // Calculate start
                const start = appContext.state.appointment.startTime.clone().add(accumulatedTime, 'minutes');

                // Calculate end
                const end = start.clone().add(duration, 'minutes');

                // Add
                times.push({
                    start: start,
                    end: end,
                });

                // Accumulate
                accumulatedTime += duration;
            }
        }
    }

    // Actions: Submit
    const submit = () => {
        // Clear all errors
        setErrorMessage('');
        setErrorCode(null);

        // Flag
        let hasErrors = false;

        // Validate start date time
        if (!startDateTime || startDateTime.isBefore(moment())) {
            hasErrors = true;
        }

        // Validate end date time
        if (!endDateTime || endDateTime.isSameOrBefore(startDateTime)) {
            hasErrors = true;
        }

        // Validate they are on the same day
        if (startDateTime && endDateTime && !startDateTime.isSame(endDateTime, 'day')) {
            hasErrors = true;
        }

        // Check for errors
        if (!hasErrors) {
            // Start loading
            setSubmitButtonState('loading');

            // Sent to backend
            (async () => {
                if (authContext.state.me?.provider?.id && startDateTime && endDateTime) {
                    try {
                        await ProvidersRepository.instance.createBooking(
                            authContext.state.me?.provider?.id,
                            startDateTime.toDate(),
                            endDateTime.toDate(),
                            observations.trim() !== '' ? observations : undefined,
                        );

                        // Navigate back but point to the start date day
                        if (initialStartDateTime && !startDateTime.isSame(initialStartDateTime, 'day')) {
                            navigate(
                                `/${RoutesNames.Office}/${RoutesNames.Office_Professional}/${
                                    authContext.state.me.provider.id
                                }/schedule?day=${startDateTime.format('YYYY-MM-DD')}`,
                                {
                                    replace: true,
                                },
                            );
                        } else {
                            // Just navigate back
                            navigate(-1);
                        }
                    } catch (error: any) {
                        // Look for a status code
                        if (
                            error.response &&
                            error.response.data &&
                            error.response.data.errors &&
                            error.response.data.errors.length > 0
                        ) {
                            // Display the error message
                            console.warn(error.response.data.errors[0].code);
                            console.log(error.response.status);

                            // Set error message
                            setErrorMessage(
                                t(`routes.office.providerNewEvent.lock.errors.${error.response.data.errors[0].code}`),
                            );
                            setErrorCode(error.response.data.errors[0].code);
                        } else {
                            // Default error
                            setErrorMessage(t('routes.office.providerNewEvent.lock.errorTitle'));
                        }

                        // Set button error
                        setSubmitButtonState('error');

                        // Set timeout
                        setTimeout(() => {
                            setSubmitButtonState('ok');
                        }, 1000);
                    }
                }
            })();
        } else {
            // Set button error
            setSubmitButtonState('error');

            // Set timeout
            setTimeout(() => {
                setSubmitButtonState('ok');
            }, 1000);
        }
    };

    // Check provider
    if (!authContext.state.me?.provider) {
        return <></>;
    }

    // Build
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
            }}
        >
            <Box>
                <Alert
                    sx={{
                        marginBottom: '16px',
                    }}
                    severity="info"
                >
                    {t('routes.office.providerNewEvent.lock.info')}
                </Alert>
                <Box
                    sx={{
                        display: 'flex',
                    }}
                >
                    <Box
                        sx={{
                            flex: 1,
                        }}
                    >
                        <OfficeTitle
                            color="#ff66c4"
                            text={t('routes.office.providerNewEvent.lock.start')}
                            align="left"
                        />
                    </Box>
                    <IconButton
                        sx={{
                            marginRight: '-8px',
                        }}
                        aria-label="edit"
                        onClick={() => {
                            if (startDateTimeButtonRef) {
                                startDateTimeButtonRef.current?.click();
                            }
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                </Box>
                <Typography variant="body1" gutterBottom>
                    {newStartDateTimeTitleSub}
                </Typography>
                <Typography variant="body2">{newStartDateTimeTitle}</Typography>
                <MobileDateTimePicker
                    ref={startDateTimeButtonRef}
                    sx={{
                        display: 'none',
                    }}
                    value={startDateTime}
                    minDateTime={moment()}
                    onChange={(newValue) => setStartDateTime(newValue)}
                />
            </Box>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                    }}
                >
                    <Box
                        sx={{
                            flex: 1,
                        }}
                    >
                        <OfficeTitle color="#ff66c4" text={t('routes.office.providerNewEvent.lock.end')} align="left" />
                    </Box>
                    <IconButton
                        sx={{
                            marginRight: '-8px',
                        }}
                        aria-label="edit"
                        onClick={() => {
                            if (endDateTimeButtonRef) {
                                endDateTimeButtonRef.current?.click();
                            }
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                </Box>
                <Typography variant="body1" gutterBottom>
                    {newEndDateTimeTitleSub}
                </Typography>
                {endDateTime?.isAfter(startDateTime) && <Typography variant="body2">{newEndDateTimeTitle}</Typography>}
                <MobileDateTimePicker
                    ref={endDateTimeButtonRef}
                    sx={{
                        display: 'none',
                    }}
                    value={endDateTime}
                    minDateTime={startDateTime?.clone().add(1, 'minute')}
                    maxDateTime={startDateTime?.clone().endOf('day')}
                    onChange={(newValue) => setEndDateTime(newValue)}
                />
            </Box>

            <Box>
                <OfficeTitle color="#ff66c4" text={t('routes.office.providerNewEvent.lock.details')} align="left" />
                <FormControl fullWidth>
                    <InputLabel id="observations-label">
                        {t('routes.office.providerNewEvent.form.observations')}
                    </InputLabel>
                    <OutlinedInput
                        multiline
                        rows={2}
                        id="observations"
                        value={observations}
                        fullWidth
                        label={t('routes.office.providerNewEvent.form.observations')}
                        onChange={(event) => {
                            // Set observations
                            setObservations(event.target.value);
                        }}
                    />
                </FormControl>
            </Box>
            <Box>
                <Button
                    variant="contained"
                    disabled={submitButtonState === 'loading'}
                    endIcon={
                        submitButtonState === 'loading' ? <CircularProgress size={18} color="inherit" /> : <LockIcon />
                    }
                    fullWidth
                    onClick={() => {
                        // Submit
                        submit();
                    }}
                    color={submitButtonState === 'error' ? 'error' : 'primary'}
                >
                    {t('routes.office.providerNewEvent.lock.submit')}
                </Button>
            </Box>
            {errorCode && (
                <Alert severity="error">
                    {errorMessage} ({errorCode})
                </Alert>
            )}
            <Box
                sx={{
                    height: '32px',
                }}
            ></Box>
        </Box>
    );
}

export function OfficeProviderNewEvent() {
    // Translations
    const { t } = useTranslation();

    // Get navigation
    // const navigate = useNavigate();

    // Hold state
    const [eventType, setEventType] = useState<'new-appointment' | 'new-lock'>('new-appointment');

    // Build
    return (
        <Page
            title={t('routes.office.providerNewEvent.title')}
            // actions={[
            //     <IconButton
            //         aria-label="exit"
            //         onClick={() => {
            //             navigate(`/${RoutesNames.Office}`);
            //         }}
            //     >
            //         <CloseIcon />
            //     </IconButton>,
            // ]}
            headerBackground={eventType === 'new-appointment' ? variables.firstColor : variables.secondColor}
        >
            <Container>
                <ToggleButtonGroup
                    sx={{
                        marginBottom: '24px',
                    }}
                    color="primary"
                    size="small"
                    fullWidth
                    value={eventType}
                    exclusive
                    onChange={(
                        event: React.MouseEvent<HTMLElement>,
                        newType: 'new-appointment' | 'new-lock' | null,
                    ) => {
                        if (newType) {
                            setEventType(newType);
                        }
                    }}
                    aria-label="text alignment"
                >
                    <ToggleButton value="new-appointment" aria-label="new appointment">
                        <EditCalendarIcon
                            sx={{
                                marginRight: '4px',
                            }}
                        />
                        {t('routes.office.providerNewEvent.appointment.title')}
                    </ToggleButton>
                    <ToggleButton value="new-lock" aria-label="new lock">
                        <LockClockIcon
                            sx={{
                                marginRight: '4px',
                            }}
                        />
                        {t('routes.office.providerNewEvent.lock.title')}
                    </ToggleButton>
                </ToggleButtonGroup>
                {eventType === 'new-appointment' && <OfficeProviderNewEventNewAppointment />}
                {eventType === 'new-lock' && <OfficeProviderNewEventNewLock />}
            </Container>
        </Page>
    );
}
