import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import LockClockIcon from '@mui/icons-material/LockClock';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import {
    Alert,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grow,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Typography,
    useTheme,
} from '@mui/material';
import moment from 'moment';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../contexts/app/context';
import { AppContextType } from '../../contexts/app/types';
import { AuthContext } from '../../contexts/auth/context';
import { AuthContextType } from '../../contexts/auth/types';
import { ServiceCategories } from '../../databases/service-categories';
import { BookingState } from '../../enums/booking-state';
import { ThemeMode } from '../../enums/theme-mode';
import { Booking } from '../../interfaces/booking';
import { BookingsRepository } from '../../repositories/bookings';
import { hexToRgb } from '../../utils/ts-utils';
import variables from '../../variables.module.scss';
import { ProviderLine } from '../provider-line/provider-line';
import { UserLine } from '../user-line/user-line';

export function BookingCard({
    booking,
    viewMode,
    onBookingUpdate,
}: {
    booking: Booking;
    viewMode?: 'provider' | 'user';
    onBookingUpdate?: (booking: Booking) => void;
}) {
    // Get Contexts
    const appContext: AppContextType = useContext(AppContext);
    const authContext: AuthContextType = useContext(AuthContext);

    // Translations
    const { t } = useTranslation();

    // Get theme
    const theme = useTheme();

    // Hold State
    const [moreMenuOpenAnchorElement, setMoreMenuOpenAnchorElement] = useState<null | HTMLElement>(null);
    const [cancelBookingDialogOpen, setCancelBookingDialogOpen] = useState(false);
    const [userNoShowBookingDialogOpen, setUserNoShowBookingDialogOpen] = useState(false);

    // Handle more menu
    const moreMenuOpen = Boolean(moreMenuOpenAnchorElement);

    // Actions: More menu close
    const handleCloseMoreMenu = () => {
        setMoreMenuOpenAnchorElement(null);
    };

    // Start moment
    const startMoment = moment(booking.startDate);
    const endMoment = moment(booking.endDate);
    const endOfTheNextDay = startMoment.clone().add(1, 'day').endOf('day');

    // Calculate total
    let total = 0;

    // State
    let stateChipColor: 'default' | 'success' | 'error' | 'warning' = 'default';
    let earnedChipColor: 'default' | 'success' | 'error' = 'default';
    let earnedChipDisabled: boolean = false;
    if (booking.state === BookingState.Completed) {
        stateChipColor = 'success';
        earnedChipColor = 'success';
        earnedChipDisabled = false;
    } else if ([BookingState.CancelledByUser, BookingState.UserNoShow].includes(booking.state)) {
        stateChipColor = 'error';
        earnedChipColor = 'default';
        earnedChipDisabled = true;
    } else if ([BookingState.CancelledByProvider].includes(booking.state)) {
        stateChipColor = 'warning';
        earnedChipColor = 'default';
        earnedChipDisabled = true;
    }

    // Validate more options
    const canCancelBooking =
        (booking.state === BookingState.Scheduled || booking.state === BookingState.Completed) &&
        moment().isBefore(endOfTheNextDay);
    const canUserNoShow =
        (booking.state === BookingState.Scheduled || booking.state === BookingState.Completed) &&
        moment().isBefore(endOfTheNextDay);

    // Actions: Cancel booking
    const cancelBooking = async () => {
        try {
            // Delete
            BookingsRepository.instance.deleteBookingAsProvider(
                authContext.state.me?.provider?.id ?? '',
                booking.id ?? '',
            );

            // Hold current time
            const now = new Date();

            // Change state
            booking.state = BookingState.CancelledByProvider;
            booking.deletedAt = now;
            booking.deletedByUserId = authContext.state.me?.user?.id;

            // Update state
            if (onBookingUpdate) {
                // Call
                onBookingUpdate(booking);
            }
        } catch (error) {
            // Log
            console.error(error);
        }
    };

    // Actions: User no show booking
    const userNoShowBooking = async () => {
        try {
            // Delete
            BookingsRepository.instance.markBookingAsUserNoShow(
                authContext.state.me?.provider?.id ?? '',
                booking.id ?? '',
            );

            // Change state
            booking.state = BookingState.UserNoShow;

            // Update state
            if (onBookingUpdate) {
                // Call
                onBookingUpdate(booking);
            }
        } catch (error) {
            // Log
            console.error(error);
        }
    };

    // Define background color
    // Convert to RGB
    const mainRGB = hexToRgb(booking.userId ? variables.firstColor : variables.secondColor);
    const backRGB = hexToRgb(appContext.state.themeMode === ThemeMode.Light ? '#ffffff' : '#121212');

    // Build
    let background = `linear-gradient(270deg, rgba(${backRGB?.r}, ${backRGB?.g}, ${backRGB?.b}, 1) 97%, rgba(${mainRGB?.r}, ${mainRGB?.g}, ${mainRGB?.b}, 1) 100%)`;

    // Build
    return (
        <>
            <Grow in={true} mountOnEnter unmountOnExit>
                <Paper
                    elevation={2}
                    sx={{
                        background,
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        paddingTop: '16px',
                        paddingBottom: '16px',
                        marginBottom: '16px',
                    }}
                >
                    {booking.userId && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <EditCalendarIcon
                                fontSize="small"
                                sx={{
                                    marginRight: '4px',
                                }}
                            />
                            <Typography variant="body2">
                              {`${t('routes.office.providerNewEvent.appointment.title')} | ${
                                    booking.createdByUserId === booking.userId
                                        ? t('components.bookingCard.bookedByClient')
                                        : t('components.bookingCard.bookedByProvider')
                                }`}
                            </Typography>
                        </Box>
                    )}
                    {!booking.userId && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <LockClockIcon
                                fontSize="small"
                                sx={{
                                    marginRight: '4px',
                                }}
                            />
                            <Typography variant="body2"> {t('routes.office.providerNewEvent.lock.title')}</Typography>
                        </Box>
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            marginBottom: '8px',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            {viewMode === 'user' && (
                                <Typography variant="body2">{`${startMoment.format('L')}`}</Typography>
                            )}
                            <Typography variant="h6">{`${startMoment.format('LT')} - ${endMoment.format(
                                'LT',
                            )}`}</Typography>
                            {startMoment.isAfter(moment()) && (
                                <Typography
                                    variant="body2"
                                    sx={{
                                        marginBottom: '4px',
                                    }}
                                >
                                    ({startMoment.fromNow()})
                                </Typography>
                            )}
                        </Box>
                        <Chip
                            label={t(`enums.bookingState.${booking.state}`)}
                            color={stateChipColor}
                            sx={{
                                marginLeft: '16px',
                                fontWeight: 'bold',
                            }}
                        />
                        {(!viewMode || viewMode === 'provider') && (
                            <Box
                                sx={{
                                    marginRight: '-8px',
                                    marginLeft: '4px',
                                }}
                            >
                                <IconButton
                                    aria-label="more"
                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                        setMoreMenuOpenAnchorElement(event.currentTarget);
                                    }}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    id="more-menu"
                                    anchorEl={moreMenuOpenAnchorElement}
                                    open={moreMenuOpen}
                                    onClose={handleCloseMoreMenu}
                                >
                                    {booking.userId && (
                                        <>
                                            <MenuItem
                                                onClick={() => {
                                                    // Close Menu
                                                    handleCloseMoreMenu();

                                                    // Open Dialog
                                                    setCancelBookingDialogOpen(true);
                                                }}
                                                disabled={!canCancelBooking}
                                            >
                                                <ListItemIcon>
                                                    <DeleteForeverIcon fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>{t('components.bookingCard.cancelBooking')}</ListItemText>
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                    // Close Menu
                                                    handleCloseMoreMenu();

                                                    // Open Dialog
                                                    setUserNoShowBookingDialogOpen(true);
                                                }}
                                                disabled={!canUserNoShow}
                                            >
                                                <ListItemIcon>
                                                    <NoAccountsIcon fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    {t('components.bookingCard.clientDidNotShowUp')}
                                                </ListItemText>
                                            </MenuItem>
                                        </>
                                    )}
                                    {!booking.userId && (
                                        <>
                                            <MenuItem
                                                onClick={() => {
                                                    // Close Menu
                                                    handleCloseMoreMenu();

                                                    // Open Dialog
                                                    cancelBooking();
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <DeleteForeverIcon fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>{t('components.bookingCard.cancelLock')}</ListItemText>
                                            </MenuItem>
                                        </>
                                    )}
                                </Menu>
                            </Box>
                        )}
                    </Box>
                    {booking.service && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '8px',
                                    marginBottom: '8px',
                                }}
                            >
                                <Box
                                    sx={{
                                        paddingLeft: '8px',
                                        paddingRight: '8px',
                                        paddingTop: '2px',
                                        paddingBottom: '2px',
                                        backgroundColor: ServiceCategories[booking.service?.category ?? ''].color,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '24px',
                                        color: 'white',
                                    }}
                                >
                                    <Typography variant="caption" fontWeight={500}>
                                        {t(ServiceCategories[booking.service?.category ?? ''].translationKey)}
                                    </Typography>
                                </Box>
                                <Box sx={{}}>
                                    <Typography variant="subtitle2">
                                        {`${appContext.getDisplayDurationText(
                                            booking.serviceData?.durationInMinutes ?? 0,
                                        )}, ${booking.serviceData?.price?.toFixed(2) ?? 0}€`}
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography
                                sx={{
                                    flex: 1,
                                }}
                                variant="body1"
                                fontWeight={500}
                            >
                                {booking.service?.name}
                            </Typography>
                            {total !== 0 && (
                                <Chip
                                    label={`${total.toFixed(2)}€`}
                                    color={earnedChipColor}
                                    disabled={earnedChipDisabled}
                                    sx={{
                                        marginLeft: '16px',
                                        fontWeight: 'bold',
                                    }}
                                />
                            )}
                        </Box>
                    )}
                    {(!viewMode || viewMode === 'provider') && booking.user && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '8px',
                                width: '100%',
                            }}
                        >
                            <UserLine user={booking.user} />
                        </Box>
                    )}
                    {viewMode === 'user' && booking.provider && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '8px',
                                width: '100%',
                            }}
                        >
                            <ProviderLine provider={booking.provider} />
                        </Box>
                    )}
                    {booking.notes && (
                        <Alert
                            sx={{
                                marginTop: '8px',
                            }}
                            severity="info"
                        >
                            {booking.notes}
                        </Alert>
                    )}
                </Paper>
            </Grow>
            <Dialog
                open={cancelBookingDialogOpen}
                onClose={() => {
                    // Close dialog
                    setCancelBookingDialogOpen(false);
                }}
                aria-labelledby="cancel-booking-title"
                aria-describedby="cancel-booking-description"
            >
                <DialogTitle color="error" fontWeight="bold" id="cancel-booking-title">
                    {t('components.bookingCard.cancelBookingTitle')}?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="cancel-booking-description">
                        {t('components.bookingCard.cancelBookingDescription', {
                            clientName: booking.user?.firstName,
                        })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="inherit"
                        onClick={() => {
                            // Close dialog
                            setCancelBookingDialogOpen(false);
                        }}
                    >
                        {t('components.bookingCard.keep')}
                    </Button>
                    <Button
                        color="error"
                        onClick={() => {
                            // Close dialog
                            setCancelBookingDialogOpen(false);

                            // Delete appointment
                            cancelBooking();
                        }}
                        autoFocus
                    >
                        {t('components.bookingCard.cancelBookingTitle')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={userNoShowBookingDialogOpen}
                onClose={() => {
                    // Close dialog
                    setUserNoShowBookingDialogOpen(false);
                }}
                aria-labelledby="user-no-show-booking-title"
                aria-describedby="user-no-show-booking-description"
            >
                <DialogTitle color={theme.palette.warning.main} fontWeight="bold" id="user-no-show-booking-title">
                    {t('components.bookingCard.userNoShowBookingTitle')}?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="user-no-show-booking-description">
                        {t('components.bookingCard.userNoShowBookingDescription', {
                            clientName: booking.user?.firstName,
                        })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="inherit"
                        onClick={() => {
                            // Close dialog
                            setUserNoShowBookingDialogOpen(false);
                        }}
                    >
                        {t('components.bookingCard.cancel')}
                    </Button>
                    <Button
                        color="warning"
                        onClick={() => {
                            // Close dialog
                            setUserNoShowBookingDialogOpen(false);

                            // Delete appointment
                            userNoShowBooking();
                        }}
                        autoFocus
                    >
                        {t('components.bookingCard.userNoShowBookingTitle')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
