import LoginIcon from '@mui/icons-material/Login';
import { Box, Fab, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutesNames } from '../../routes-names';
import Square from '../square/square.component';
import styles from './sign-in-ad.module.scss';

export function SignInAd({ reason }: { reason: string }) {
    // Translations
    const { t } = useTranslation();

    // Navigation
    const navigate = useNavigate();

    // Build
    return (
        <Paper
            className={styles.bookingWidget}
            sx={{
                // backgroundColor: '#fff',
                width: '100%',
                // maxWidth: '400px',
                borderRadius: '8px',
                zIndex: 1,
                position: 'relative',
                overflow: 'hidden',
                paddingBottom: '8px',
            }}
        >
            <Square bottom="-60px" right="-70px" width="100px" position="absolute" zIndex={-1} color="#ffc834" />
            <Box className={styles.bookingWidgetLine1}>
                <Box className={styles.bookingWidgetLineDesc}>
                    <Box className={styles.bookingWidgetLineDescTitle}>
                        <Typography variant="h6">{t('components.signInAd.title')}</Typography>
                    </Box>
                    <Box className={styles.bookingWidgetLineDescDesc}>
                        <Typography variant="body2">{`${t('components.signInAd.description')} ${reason}`}</Typography>
                    </Box>
                </Box>
                <Box className={styles.bookingWidgetLineButtons}>
                    <Fab
                        size="small"
                        color="primary"
                        aria-label="sign in"
                        onClick={() => {
                            navigate(`/${RoutesNames.SignIn}`);
                        }}
                    >
                        <LoginIcon />
                    </Fab>
                </Box>
            </Box>
        </Paper>
    );
}
