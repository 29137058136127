/**
 * Booking state
 */
export enum BookingState {
    /**
     * Scheduled
     */
    Scheduled = 'scheduled',

    /**
     * Cancelled by provider
     */
    CancelledByProvider = 'cancelled-by-provider',

    /**
     * Cancelled by user
     */
    CancelledByUser = 'cancelled-by-user',

    /**
     * User no show
     */
    UserNoShow = 'user-no-show',

    /**
     * Completed
     */
    Completed = 'completed',
}
