import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { AppContext } from '../../contexts/app/context';
import { AppContextType } from '../../contexts/app/types';
import { ThemeMode } from '../../enums/theme-mode';
import { hexToRgb } from '../../utils/ts-utils';
import variables from '../../variables.module.scss';
import { Loader } from '../loader/loader';

export function Page(props: {
    children: React.ReactNode;
    title: string;
    backButtonLabel?: string;
    hideBackButton?: boolean;
    hideTitle?: boolean;
    actions?: Array<React.ReactNode>;
    onRefresh?: () => Promise<any>;
    underBar?: React.ReactNode;
    underBarHeight?: number;
    disableIosUnderTitle?: boolean;
    headerBackground?: string;
    handleScroll?: (event: any) => void;
}) {
    // Get Context
    const appContext: AppContextType = useContext(AppContext);

    // Hold State
    const [scrollY, setScrollY] = useState<number>(0);

    // Get navigation
    const navigate = useNavigate();

    // Translations
    const { t } = useTranslation();

    // Actions
    const goBack = () => {
        navigate(-1);
    };

    const handleScroll = (event: any) => {
        // Update scroll
        setScrollY(window.scrollY);

        // Call custom scroll event
        if (props.handleScroll) {
            props.handleScroll(event);
        }
    };

    // On mount
    useEffect(() => {
        // Listen for scroll event
        window.addEventListener('scroll', handleScroll);

        // Scroll to top
        window.scrollTo(0, 0);

        // On unmount
        return () => {
            // Remove scroll event listener
            window.removeEventListener('scroll', handleScroll);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Calculate Header Background Color
    let headerBackground = undefined;
    if (props.headerBackground) {
        // Convert to RGB
        const mainRGB = hexToRgb(props.headerBackground);
        const backRGB = hexToRgb(appContext.state.themeMode === ThemeMode.Light ? '#ffffff' : '#000000');

        // Build
        headerBackground = `linear-gradient(0deg, rgba(${backRGB?.r}, ${backRGB?.g}, ${backRGB?.b}, 1) 32%, rgba(${mainRGB?.r}, ${mainRGB?.g}, ${mainRGB?.b}, 1) 100%)`;
    } else {
        // Convert to RGB
        const mainRGB = hexToRgb(variables.firstColor);
        const backRGB = hexToRgb(appContext.state.themeMode === ThemeMode.Light ? '#ffffff' : '#000000');

        // Build
        headerBackground = `linear-gradient(0deg, rgba(${backRGB?.r}, ${backRGB?.g}, ${backRGB?.b}, 1) 32%, rgba(${mainRGB?.r}, ${mainRGB?.g}, ${mainRGB?.b}, 1) 100%)`;
    }

    // Build
    return (
        <PullToRefresh
            isPullable={props.onRefresh !== undefined}
            onRefresh={props.onRefresh ?? (() => Promise.resolve())}
            refreshingContent={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        paddingTop: `${appContext.state.insets.top + 16}px`,
                    }}
                >
                    <Loader size={36} />
                </Box>
            }
            pullingContent={<></>}
            pullDownThreshold={67 + appContext.state.insets.top}
            maxPullDownDistance={95 + appContext.state.insets.top}
        >
            <Box sx={{ flexGrow: 1, minHeight: '100%' }}>
                <AppBar
                    position="fixed"
                    elevation={scrollY > 16 ? 4 : 0}
                    color="inherit"
                    sx={{
                        height: `${56 + appContext.state.insets.top + (props.underBarHeight ?? 0)}px`,
                        backgroundColor: appContext.state.themeMode === ThemeMode.Light ? '#fff' : '#000',
                        background: headerBackground,
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Toolbar
                        sx={{
                            height: '56px !important',
                            minHeight: '56px !important',
                            paddingLeft: `${appContext.getDefaultSidePaddingValue()}px !important`,
                            paddingRight: `${appContext.getDefaultSidePaddingValue()}px !important`,
                        }}
                    >
                        {appContext.state.insets.left > 0 && (
                            <Box
                                sx={{
                                    width: `${appContext.state.insets.left}px`,
                                }}
                            ></Box>
                        )}
                        {/* Left Actions */}
                        {!props.hideBackButton && (
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                sx={{
                                    zIndex: 2,
                                }}
                                onClick={goBack}
                            >
                                {appContext.state.operativeSystem === 'ios' && <ArrowBackIosIcon />}
                                {appContext.state.operativeSystem === 'ios' && (
                                    <Typography
                                        variant="body1"
                                        component="div"
                                        sx={{
                                            position: 'relative',
                                            left: '-6px',
                                        }}
                                    >
                                        {props.backButtonLabel ? props.backButtonLabel : t('components.auxPage.back')}
                                    </Typography>
                                )}
                                {appContext.state.operativeSystem !== 'ios' && <ArrowBackIcon />}
                            </IconButton>
                        )}

                        {/* Title */}
                        {appContext.state.operativeSystem === 'ios' && (
                            <Box
                                sx={{
                                    flexGrow: 1,
                                }}
                            ></Box>
                        )}
                        {appContext.state.operativeSystem === 'ios' && (scrollY > 26 || props.disableIosUnderTitle) && (
                            <Box
                                sx={{
                                    zIndex: 1,
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {!props.hideTitle && (
                                    <Typography
                                        variant={props.title && props.title.length > 20 ? 'body2' : 'body1'}
                                        component="div"
                                        sx={{
                                            flexGrow: 1,
                                            textAlign: 'center',
                                        }}
                                    >
                                        {props.title}
                                    </Typography>
                                )}
                            </Box>
                        )}
                        {appContext.state.operativeSystem !== 'ios' && !props.hideTitle && (
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                    flexGrow: 1,
                                }}
                            >
                                {props.title}
                            </Typography>
                        )}

                        {/* Right Action s*/}
                        <Box
                            sx={{
                                zIndex: 2,
                                display: 'flex',
                                marginRight: '-8px',
                            }}
                        >
                            {props.actions?.map((action: React.ReactNode, index: number) => {
                                return <Box key={`action_${index}`}>{action}</Box>;
                            })}
                        </Box>

                        {appContext.state.insets.right > 0 && (
                            <Box
                                sx={{
                                    width: `${appContext.state.insets.right}px`,
                                }}
                            ></Box>
                        )}
                    </Toolbar>
                    {props.underBar && props.underBarHeight && (
                        <Toolbar
                            sx={{
                                height: `${props.underBarHeight}px !important`,
                                minHeight: `${props.underBarHeight}px !important`,
                                paddingLeft: `${appContext.getDefaultSidePaddingValue()}px !important`,
                                paddingRight: `${appContext.getDefaultSidePaddingValue()}px !important`,
                                paddingBottom: '16px !important',
                            }}
                        >
                            {appContext.state.insets.left > 0 && (
                                <Box
                                    sx={{
                                        width: `${appContext.state.insets.left}px`,
                                    }}
                                ></Box>
                            )}
                            {props.underBar}
                            {appContext.state.insets.right > 0 && (
                                <Box
                                    sx={{
                                        width: `${appContext.state.insets.right}px`,
                                    }}
                                ></Box>
                            )}
                        </Toolbar>
                    )}
                </AppBar>
                <Box
                    sx={{
                        paddingTop: `${56 + appContext.state.insets.top + (props.underBarHeight ?? 0)}px`,
                        paddingBottom: `${16 + appContext.state.insets.bottom}px`,
                        paddingLeft: `${appContext.getDefaultSidePaddingValue() + appContext.state.insets.left}px`,
                        paddingRight: `${appContext.getDefaultSidePaddingValue() + appContext.state.insets.right}px`,
                        minHeight: '100%',
                    }}
                >
                    {appContext.state.operativeSystem === 'ios' && !props.disableIosUnderTitle && !props.hideTitle && (
                        <Typography
                            variant="h4"
                            component="div"
                            sx={{
                                marginBottom: '16px',
                            }}
                        >
                            {props.title}
                        </Typography>
                    )}
                    {props.children}
                </Box>
            </Box>
        </PullToRefresh>
    );
}
